import { ReactNode } from 'react';

import { LocalDate } from '@js-joda/core';

import { Chip, Typography, lighten } from '@mui/material';

import { VacationsScheduleEntry, VacationsScheduleSummary } from '@octopus/api';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import {
  VacationsApprovedStatus,
  vacationsApprovedStatus,
} from '../utils/types';

export type VacationInfoTagsVariants =
  | 'warning'
  | 'success'
  | 'info'
  | 'default'
  | 'error';

const statusMap = {
  warning: {
    backgroundColor: '#FEF7F1',
    color: '#93500B',
  },
  success: {
    backgroundColor: '#F4FBF6',
    color: '#066F4F',
  },
  info: {
    backgroundColor: '#F0F6FF',
    color: '#0058DB',
  },
  default: {
    backgroundColor: '#F7F7F8',
    color: '#616161',
  },
  error: {
    backgroundColor: '#FDF2F4',
    color: '#BC1A41',
  },
};

export const VacationInfoTags = ({
  longText,
  text,
  variant,
}: {
  text: string | ReactNode;
  variant: VacationInfoTagsVariants;
  longText?: boolean;
}) => {
  const { color, backgroundColor } = statusMap[variant];
  return (
    <Typography
      sx={{
        backgroundColor,
        color,
      }}
      px={1}
      py={0.5}
      borderRadius={0.5}
      fontSize={12}
      fontWeight={500}
      variant="body1"
      width="fit-content"
      height="fit-content"
      whiteSpace={longText ? 'normal' : 'nowrap'}
    >
      {text}
    </Typography>
  );
};

export const VacationsChip = ({
  text,
  variant,
  disabled,
}: {
  text: string | ReactNode;
  variant: VacationInfoTagsVariants;
  disabled?: boolean;
}) => {
  const { color, backgroundColor } = statusMap[variant];
  return (
    <Chip
      size="small"
      label={text}
      sx={{
        backgroundColor,
        color: disabled ? lighten(color, 0.5) : color,
        fontSize: '12px',
        p: 0.5,
      }}
    />
  );
};

export const VacationStatusTag = ({
  summary,
  isPayroll,
}: {
  summary: VacationsScheduleSummary | VacationsScheduleEntry;
  isPayroll?: boolean;
}) => {
  const { status, startDate, endDate } = summary;
  const isApproved = vacationsApprovedStatus.includes(
    status as VacationsApprovedStatus,
  );

  const now = LocalDate.now();
  const inProgress =
    isApproved &&
    LocalDate.parse(startDate).isBefore(now) &&
    LocalDate.parse(endDate).isAfter(now);

  const isFinished = isApproved && LocalDate.parse(endDate).isBefore(now);

  let { text, variant } = isPayroll
    ? vacationsPayrollStatusMap[status] || {}
    : vacationsStatusMap[status] || {};

  if (inProgress && !isPayroll) {
    text = 'Em andamento';
    variant = 'info';
  } else if (isFinished && !isPayroll) {
    text = 'Finalizada';
    variant = 'default';
  }

  return text && variant && <VacationInfoTags text={text} variant={variant} />;
};

export const vacationsStatusMap: {
  [key: string]: {
    text: string;
    variant: VacationInfoTagsVariants;
  };
} = {
  [vacationsScheduleStatuses.created]: {
    text: 'Em aprovação',
    variant: 'warning',
  },
  [vacationsScheduleStatuses.waitingApproval]: {
    text: 'Em aprovação',
    variant: 'warning',
  },
  [vacationsScheduleStatuses.approved]: {
    text: 'Agendada',
    variant: 'success',
  },
  [vacationsScheduleStatuses.rejected]: {
    text: 'Recusada',
    variant: 'error',
  },
  [vacationsScheduleStatuses.canceled]: {
    text: 'Cancelada',
    variant: 'error',
  },
  [vacationsScheduleStatuses.payrollCreationScheduled]: {
    text: 'Agendada',
    variant: 'success',
  },
  [vacationsScheduleStatuses.payrollCreated]: {
    text: 'Agendada',
    variant: 'success',
  },
  [vacationsScheduleStatuses.payrollApproved]: {
    text: 'Agendada',
    variant: 'success',
  },
  [vacationsScheduleStatuses.payrollArchived]: {
    text: 'Recusada',
    variant: 'error',
  },
  [vacationsScheduleStatuses.contractTerminated]: {
    text: 'Recusada',
    variant: 'error',
  },
};

export const vacationsPayrollStatusMap: {
  [key: string]: {
    text: string;
    variant: VacationInfoTagsVariants;
  };
} = {
  [vacationsScheduleStatuses.payrollCreationScheduled]: {
    text: 'Programado',
    variant: 'default',
  },
  [vacationsScheduleStatuses.payrollCreated]: {
    text: 'Prévia',
    variant: 'warning',
  },
  [vacationsScheduleStatuses.payrollApproved]: {
    text: 'Aprovado',
    variant: 'success',
  },
};
