import dayjs from 'dayjs';

import { Box } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import {
  vacationsPayslipStatuses,
  vacationsScheduleStatuses,
} from '@octopus/vacations-types';

import { ExpandableTypography } from '../../../../modules/components/ExpandableTypography';

import { AlertWithPopover } from './Alert';
import { PayrollSchedule } from './PayrollSchedule';

export const daysTakenColumn: GridColDef = {
  sortable: false,
  field: 'daysTaken',
  headerName: 'Duração',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.daysTaken;
  },
  renderCell: ({ value }) => {
    return (
      <ExpandableTypography>
        {value ? `${value} dias` : '-'}
      </ExpandableTypography>
    );
  },
};

export const periodColumn: GridColDef = {
  sortable: false,
  field: 'vacationPeriod',
  headerName: 'Período de gozo',
  renderHeader: (params) => {
    return <Box whiteSpace="nowrap">{params.colDef.headerName}</Box>;
  },
  valueGetter: (params: GridValueGetterParams) => {
    const startDate = dayjs(params.row.startDate).format('DD/MM/YY');
    const endDate = dayjs(params.row.endDate).format('DD/MM/YY');
    return `${startDate} a ${endDate}`;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value || '-'}</ExpandableTypography>;
  },
};

export const parametersColumn: GridColDef = {
  sortable: false,
  field: 'parameters',
  headerName: 'Parâmetros',
  valueGetter: (params: GridValueGetterParams) => {
    const { thirteenthAdvance, daysSold, type } = params.row;
    return {
      thirteenthAdvance,
      daysSold,
      type,
    };
  },
  renderCell: ({ value: { thirteenthAdvance, daysSold, type } }) => {
    const abono = daysSold ? 'Abono' : '';
    const adiantamento = thirteenthAdvance ? 'Adiantamento 13º' : '';
    const coletivas = type === 'group' ? 'Coletivas' : '';
    const parameters = [abono, adiantamento, coletivas].filter(Boolean) || [
      '-',
    ];
    return (
      <Box display="flex" flexDirection="column" gap={0.5} mb={0.5}>
        {parameters.map((parameter) => {
          const isLast = parameter === parameters[parameters.length - 1];
          return (
            <ExpandableTypography fontSize={12} color="#616161" key={parameter}>
              {`${parameter}${isLast ? '' : ','}`}
            </ExpandableTypography>
          );
        })}
      </Box>
    );
  },
};

export const approvalDeadlineColumn: GridColDef = {
  sortable: true,
  field: 'approvalDeadline',
  headerName: 'Até',
  valueGetter: (params: GridValueGetterParams) => {
    const {
      approvalDeadline,
      status,
      payslipStatus,
      payrollCreationScheduledAt,
    } = params.row;
    return {
      payslipStatus,
      status,
      approvalDeadline: approvalDeadline
        ? dayjs(approvalDeadline).format('DD/MM/YYYY')
        : '-',
      hasWarning:
        approvalDeadline &&
        dayjs().add(30, 'days').isAfter(dayjs(approvalDeadline)),
      isPastDeadline:
        approvalDeadline && dayjs().isAfter(dayjs(approvalDeadline)),
      isScheduled:
        status === vacationsScheduleStatuses.payrollCreationScheduled,
      scheduleCreationDate: payrollCreationScheduledAt
        ? dayjs(payrollCreationScheduledAt).format('DD/MM/YYYY')
        : '-',
    };
  },
  renderCell: ({
    value: {
      approvalDeadline,
      hasWarning,
      isPastDeadline,
      isScheduled,
      scheduleCreationDate,
      status,
      payslipStatus,
    },
  }) => {
    const popoverContent = isPastDeadline
      ? 'Passou da data limite'
      : 'Próximo da data limite';
    const variant = isPastDeadline ? 'error' : hasWarning ? 'warning' : null;

    if (
      status === vacationsScheduleStatuses.payrollApproved &&
      payslipStatus === vacationsPayslipStatuses.sent
    ) {
      return null;
    }

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        {isScheduled ? (
          <PayrollSchedule value={scheduleCreationDate} />
        ) : (
          <AlertWithPopover
            value={approvalDeadline}
            variant={variant}
            popoverContent={popoverContent}
          />
        )}
      </Box>
    );
  },
};
