import {
  ApprovalWorkflowV2Input,
  AuditableVersionedEntry,
} from '@octopus/workflows-api';

export type ApprovalWorkflowV2 = AuditableVersionedEntry & {
  partitionKey: string;
  sortKey: string;

  id: string;
  sourceApp: 'vacations' | 'onboarding';
  organizationId: string;

  steps: ApprovalStep[];

  payload: Record<string, string>;
  status: ApprovalWorkflowV2Status;

  approvalDeadline: string;
  timeout: number;

  workflowVersion: 'v2' | 'v1';

  createdByName: string;
  creationUserRole?: WorkflowRoles;

  contractId?: string;
  draftId?: string;
};

export type CreateWorkflowV2TaskInput = ApprovalWorkflowV2Input & {
  idempotencyId: string;
  id: string;
  organizationId: string;
  timeout: number | undefined;
};

type Review = {
  action: 'approve' | 'reject' | 'terminate' | 'cancel' | 'timeout';
  date: string;
  userId: string;
  userName: string;
  comment?: string;
  override: boolean;
};

export type ReviewInput = {
  organizationId: string;
  id: string;
  userId: string;
  userName?: string;

  taskToken?: string;

  action: ReviewAction;
  comment?: string;

  role: WorkflowRoles;

  // if true, will accept the review if the userId is an admin (even if it's not the manager, etc)
  override: boolean;
};

export type ApprovalStep = {
  requests: ApprovalRequest[];
  status: 'pending' | 'finished';
};

export type ApprovalRequest = {
  role: WorkflowRoles;
  status: 'pending' | 'requested' | 'finished';

  taskToken?: string;
  review?: Review;
};

export const workflowRoles = {
  'membership:owner': 'membership:owner',
  manager: 'manager',
  'resource:owner': 'resource:owner',
  system: 'system',
} as const;

export type WorkflowRoles = keyof typeof workflowRoles;

export const approvalWorkflowStatuses = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
  timeout: 'timeout',
  error: 'error',
  canceled: 'canceled',
  terminated: 'terminated',
} as const;

export type ApprovalWorkflowV2Status = keyof typeof approvalWorkflowStatuses;

export const reviewActions = {
  /**
   * approved or rejected by any reviewer with authorization on the workflow
   * may trigger notifications
   */
  approve: 'approve',
  reject: 'reject',

  /**
   * subject of the workflow is terminated and it should no longer wait for approval
   * notifications may not be triggered for this status
   */
  terminate: 'terminate',

  /**
   * cancelled by the user that created the workflow
   */
  cancel: 'cancel',

  timeout: 'timeout',
} as const;

export type ReviewAction = keyof typeof reviewActions;

export const workflowActions = {
  create: 'create',
  delete: 'delete',
  error: 'error',
  timeout: 'timeout',
} as const;

export type WorkflowAction = keyof typeof workflowActions;

export type ApprovalWorkflowV2CreatePayload = Omit<
  ApprovalWorkflowV2,
  keyof ApprovalWorkflowV2Keys | keyof AuditableVersionedEntry
>;

export type ApprovalWorkflowV2UpdatePayload =
  Partial<ApprovalWorkflowV2CreatePayload>;

export type ApprovalWorkflowV2Keys = {
  partitionKey: {
    organizationId: string;
  };
  sortKey: {
    id: string;
  };
};

export type WorkflowNotificationContext = {
  organizationId: string;
  resourceId: string;
  resourceType: typeof approvalWorkflowV2ResourceType;

  status: string;
  approvalDeadline: string;

  pendingApprovalRoles: string[];

  contractId?: string;
  draftId?: string;

  payload: ApprovalWorkflowV2['payload'];
  sourceApp: ApprovalWorkflowV2['sourceApp'];

  requesterName: string;
};

export const approvalWorkflowV2ResourceType = 'approvalWorkflowV2';
