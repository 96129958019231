import {
  PayrollInputsPayload,
  fetchPostPayrollInputsConfiguration,
  fetchPutPayrollInputsConfiguration,
} from '@octopus/api';

export default function createBaseRpaInputConfiguration({
  organizationId,
  companyId,
  existingPayload,
}: {
  organizationId: string;
  companyId: string;
  existingPayload?: PayrollInputsPayload;
}): PayrollInputsPayload {
  const baseBody: PayrollInputsPayload = {
    serviceValue: {
      id: 'serviceValue',
      type: 'rpa',
      label: 'Valor de serviço',
      target: 'rpa',
      payloadType: 'currency',
      tag: 'events',
    },
    paymentDate: {
      id: 'paymentDate',
      type: 'rpa',
      label: 'Data de pagamento',
      target: 'rpa',
      payloadType: 'date',
      tag: 'events',
    },
    aliquotaISS: {
      id: 'aliquotaISS',
      type: 'rpa',
      label: 'Aliquota do ISS',
      target: 'rpa',
      payloadType: 'percentage',
      tag: 'events',
    },
  };
  console.log('Updating inputConfiguration', {
    ...baseBody,
    ...existingPayload,
  });

  if (existingPayload) {
    fetchPutPayrollInputsConfiguration({
      pathParams: {
        organizationId,
        companyId,
      },
      body: { ...baseBody, ...existingPayload },
    });
    return { ...baseBody, ...existingPayload };
  }

  fetchPostPayrollInputsConfiguration({
    pathParams: {
      organizationId,
      companyId,
    },
    body: baseBody,
  });
  return baseBody;
}
