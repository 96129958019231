import { FilePreviewImage } from './FilePreviewImage';
import { FilePreviewList } from './FilePreviewList';

const FilePreviewType = {
  none: 'none',
  image: 'image',
  list: 'list',
} as const;

type FilePreviewType = (typeof FilePreviewType)[keyof typeof FilePreviewType];

function FilePreviewComponent({
  file,
  setFile,
  showDeleteButton,
  filePreviewType,
  isLoading,
  showDeleteConfirmation = true,
}: {
  file?: File;
  setFile: (file?: File) => void;
  showDeleteButton: boolean;
  filePreviewType: FilePreviewType;
  isLoading: boolean;
  showDeleteConfirmation?: boolean;
}) {
  if (!file) {
    return undefined;
  }

  switch (filePreviewType) {
    case 'image':
      return (
        <FilePreviewImage
          file={file}
          setFile={setFile}
          showDeleteButton={showDeleteButton}
        />
      );
    case 'list':
      return (
        <FilePreviewList
          file={file}
          deleteFile={() => setFile(null)}
          isLoading={isLoading}
          showDeleteButton={showDeleteButton}
          showDeleteConfirmation={showDeleteConfirmation}
        />
      );
    default:
      return null;
  }
}

export { FilePreviewComponent, FilePreviewType };
