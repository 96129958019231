import { MutableRefObject } from 'react';

import {
  ColDef,
  KeyCreatorParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';

import { Box } from '@mui/system';

import {
  ContractSummary,
  PayrollInputsConfig,
  PayrollInputsEntry,
} from '@octopus/api';

import UserAvatar from '../../../../modules/components/UserAvatar';
import { EmployeeIdentifierCell } from '../../[period]/[type]/inputs/EmployeeIdentifierCell';
import { IdentifierHeader } from '../../[period]/[type]/inputs/IdentifierHeader';
import { newInputColDef } from '../../[period]/[type]/inputs/inputColDef';
import {
  ColumnInfo,
  EMPLOYEE_COLUMN_ID,
  PayrollEmployeeData,
  RowInfo,
} from '../../[period]/[type]/inputs/types';
import {
  EmployeesState,
  HasPayrollBeenEdited,
} from '../../[period]/[type]/inputs/useSubmissionState';

import { InputNameCellEditor } from './InputNameCellEditor';

type ColDefProps = {
  config: PayrollInputsConfig;
  showColumnInfo: (ref: HTMLElement, info: ColumnInfo) => void;
  showRowInfo: (ref: HTMLElement, info: RowInfo) => void;
  hideInfoPoppers: () => void;
  state: EmployeesState;
  hasPayrollBeenEdited: HasPayrollBeenEdited;
  autonomoContracts: ContractSummary[];
  setSearchTerm: (searchTerm: string | undefined) => void;
  onEditorCreated?: (editor: InputNameCellEditor) => void;
  onEditorDestroyed?: () => void;
  legalEntityIdRef: MutableRefObject<string>;
};

export function prepareColDef({
  config,
  showColumnInfo,
  showRowInfo,
  hideInfoPoppers,
  state,
  hasPayrollBeenEdited,
  autonomoContracts,
  setSearchTerm,
  onEditorCreated,
  onEditorDestroyed,
  legalEntityIdRef,
}: ColDefProps): ColDef<PayrollEmployeeData, ContractSummary | string>[] {
  const rpaBaseColumns: string[] = [
    'serviceValue',
    'aliquotaISS',
    'paymentDate',
  ] as const;
  return [
    {
      colId: EMPLOYEE_COLUMN_ID,
      headerName: 'Autônomo',
      headerComponent: IdentifierHeader,
      cellRendererParams: {
        showRowInfo,
        hasPayrollBeenEdited,
        showAsAvatar: true,
        emptyHintText: 'Matrícula ou nome...',
      },
      cellRenderer: EmployeeIdentifierCell,
      editable: (params: { data: PayrollEmployeeData }) =>
        params.data.contractId === '',
      valueGetter: (params: { data: PayrollEmployeeData }) =>
        params.data.name as string,
      valueSetter: ({
        oldValue,
        newValue,
        data: { payrollId },
      }: ValueSetterParams<PayrollEmployeeData, string | ContractSummary>) => {
        if (!oldValue) {
          state.edit.addEmployee({
            payrollId,
            name: (newValue as ContractSummary).name,
            contractId: (newValue as ContractSummary).contractId,
            employeeId: (newValue as ContractSummary).employeeId,
            personId: (newValue as ContractSummary).personId,
            inputs: {},
            legalEntityId: legalEntityIdRef.current,
          });
        }
        return true;
      },
      keyCreator: (params: KeyCreatorParams) => params.value.name,
      singleClickEdit: true,
      cellEditor: InputNameCellEditor,
      cellEditorParams: {
        values: () => {
          const filteredContracts = autonomoContracts.filter(
            (contract) =>
              contract.contractId &&
              !state.data.find(
                (employee) => employee.contractId === contract.contractId,
              ),
          );
          return filteredContracts;
        },
        onSearch: (searchText: string) => {
          setSearchTerm(searchText);
        },
        onSearchComplete: (
          editor: InputNameCellEditor<PayrollEmployeeData, string>,
        ) => {
          onEditorCreated?.(editor);
        },
        onDestroy: () => {
          onEditorDestroyed?.();
        },
        searchDebounceDelay: 300,
        cellRenderer: (props: CustomCellRendererProps) => {
          return (
            <Box sx={{ paddingTop: '10px', height: '50px' }}>
              <UserAvatar
                name={props?.value?.name || props?.value}
                expandNameOnHover={true}
                sx={{
                  '--UserAvatar-name-max-width': '12.5em',
                }}
              />
            </Box>
          );
        },
        cellHeight: 40,
      },
      flex: 2,
      pinned: 'left',
      sortable: true,
      resizable: false,
      suppressMovable: true,
      comparator: (a, b) => (a as string).localeCompare(b as string),
      suppressHeaderMenuButton: true,
      suppressHeaderContextMenu: true,
      minWidth: 250,
      cellStyle: {
        padding: '0',
      },
    },
    ...rpaBaseColumns.reduce<
      ColDef<PayrollEmployeeData, string | ContractSummary>[]
    >(
      (acc, id) =>
        config.payload[id]
          ? [
              ...acc,
              newInputColDef({
                entry: config.payload[id],
                showColumnInfo,
                hideInfoPoppers,
                hasBeenEdited: () => false, // TODO: change this when showing Edited
                valueGetter: ({
                  data: { inputs },
                }: ValueGetterParams<PayrollEmployeeData, string>) =>
                  inputs[config.payload[id].id],
                valueSetter: ({
                  newValue,
                  data: { payrollId },
                }: ValueSetterParams<PayrollEmployeeData, string>) => {
                  state.edit.set(payrollId, config.payload[id].id, newValue);
                  return true;
                },
              }) as ColDef<PayrollEmployeeData, string | ContractSummary>,
            ]
          : acc,
      [],
    ),
    ...Object.values(config.payload)
      .filter(
        (entry: PayrollInputsEntry) =>
          entry.target === 'rpa' && !rpaBaseColumns.includes(entry.id),
      )
      .sort((a, b) => a.tag.localeCompare(b.tag))
      .map(
        (entry) =>
          newInputColDef({
            entry,
            showColumnInfo,
            hideInfoPoppers,
            hasBeenEdited: () => false, // TODO: change this when showing Edited
            valueGetter: ({
              data: { inputs },
            }: ValueGetterParams<PayrollEmployeeData, string>) =>
              inputs[entry.id],
            valueSetter: ({
              newValue,
              data: { payrollId },
            }: ValueSetterParams<PayrollEmployeeData, string>) => {
              state.edit.set(payrollId, entry.id, newValue);
              return true;
            },
          }) as ColDef<PayrollEmployeeData, string | ContractSummary>,
      ),
  ];
}
