import { useState } from 'react';

import { Box, Collapse, Divider } from '@mui/material';

import {
  VacationsScheduleSummary,
  useGetPayroll,
  useGetVacationsSchedule,
} from '@octopus/api';

import { DrawerCard } from './DrawerCard';
import { VacationSkeletonCard } from './DrawerSkeleton';
import { PayrollActions } from './PayrollActions';
import { ScheduleCardBody } from './ScheduleCardBody';
import { ScheduleCardHeader } from './ScheduleCardHeader';

export const PayrollCard = ({
  summary,
  onSuccess,
}: {
  summary: VacationsScheduleSummary;
  onSuccess?: () => Promise<void>;
}) => {
  const [expandButtonState, setExpandButtonState] = useState(true);
  const handleExpandButtonClick = () => {
    setExpandButtonState(!expandButtonState);
  };

  const {
    data: vacationSchedule,
    isLoading,
    refetch,
  } = useGetVacationsSchedule({
    pathParams: {
      organizationId: summary.organizationId,
      contractId: summary.contractId,
      sequence: summary.sequence.toString(),
    },
  });

  const hasPayroll = !!vacationSchedule?.payrollId;
  const { data: payroll, isLoading: isPayrollLoading } = useGetPayroll(
    {
      pathParams: {
        organizationId: summary.organizationId,
        companyId: summary.companyId,
        payrollId: vacationSchedule?.payrollId,
      },
    },
    {
      enabled: hasPayroll,
    },
  );

  const refetchData = async () => {
    await Promise.all([refetch(), onSuccess()]);
  };

  if (isLoading || (hasPayroll && isPayrollLoading)) {
    return <VacationSkeletonCard index={summary.sequence.toString()} />;
  }

  return (
    <DrawerCard>
      <Box display="flex" flexDirection="column" width="100%">
        <ScheduleCardHeader
          summary={summary}
          handleExpandButtonClick={handleExpandButtonClick}
          expandButtonState={expandButtonState}
        />

        <Collapse in={expandButtonState}>
          <Divider />

          <ScheduleCardBody summary={summary} payroll={payroll} />

          <Divider />
          <PayrollActions
            vacationSchedule={vacationSchedule}
            payroll={payroll}
            onSuccess={refetchData}
          />
        </Collapse>
      </Box>
    </DrawerCard>
  );
};
