import React, { useEffect, useRef, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { PersonRemoveOutlined } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  Popover,
  Skeleton,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import {
  ContractSummary,
  SearchInput,
  fetchSearchAllContracts,
  useGetLegalEntities,
} from '@octopus/api';
import {
  WorkerCategory,
  contractStatuses,
  contractTypes,
  workerCategory,
} from '@octopus/contract-types';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  GridColDef,
  GridValueGetterParams,
  makeDateRangeFilter,
  makeElementListFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import WorkerCategoryTag from '../../modules/components/contracts/WorkerCategoryTag';
import { ExpandableTypography } from '../../modules/components/ExpandableTypography';
import UserAvatar from '../../modules/components/UserAvatar';
import { DataFetching } from '../../modules/dataFetching';
import { StatusBadge } from '../../modules/people/person/components/StatusBadge';
import { prepareDataGridSearchInput } from '../../utils';
import { useFFlags } from '../fflags';

import { addTerminationExclusionToFilter } from './terminationFilter';

type PeopleTableState = {
  totals:
    | {
        totalCount: number;
        current: number;
      }
    | undefined;
  unfilteredCounters: {
    [key: string]: number;
  };
  filteredCounters: {
    [key: string]: number;
  };
  filters: {
    titles: {
      id: string;
      name: string;
      count: number;
    }[];
    departments: {
      name: string;
      count: number;
    }[];
    contractTypes: {
      name: string;
      count: number;
    }[];
    workerCategory: {
      name: string;
      count: number;
    }[];
    legalEntities: {
      id: string;
      name: string;
    }[];
    companyId: {
      id: string;
      name: string;
    }[];
    managers: {
      contractId: string;
      name: string;
    }[];
  };
  peopleGroupsConfig: {
    [key: string]: {
      key: string;
      label: string;
      count: number;
    };
  };
};

const peopleGroupsConfig = {
  all: {
    key: 'all',
    label: 'Todos',
    count: 0,
  },

  'br:clt': {
    key: contractTypes.brClt,
    label: 'Colaboradores',
    count: 0,
  },

  'br:pj': {
    key: contractTypes.brPj,
    label: 'Prestadores de serviço',
    count: 0,
  },

  'br:clt|clt:autonomo': {
    key: 'br:clt|clt:autonomo',
    label: 'Autônomos',
    count: 0,
  },
};

function addContractTypeFilter(
  searchInput: SearchInput,
  tab: 'all' | 'br:clt' | 'br:pj' | 'br:clt|clt:autonomo',
) {
  if (!searchInput.filtering) {
    searchInput.filtering = {};
  }

  if (!searchInput.filtering.elements) {
    searchInput.filtering.elements = {};
  }
  searchInput.counting = {
    filtered: {
      total: true,
      byProp: {
        contractType: Object.values(contractTypes),
        workerCategory: Object.values(workerCategory),
        status: Object.values(contractStatuses),
      },
    },
    unfiltered: {
      byProp: {
        contractType: Object.values(contractTypes),
        workerCategory: Object.values(workerCategory),
        status: Object.values(contractStatuses),
      },
    },
  };
  if (tab === 'all') {
    return;
  }
  const [contractType, category] = tab.split('|');
  searchInput.filtering.elements['contractType'] = [contractType];
  if (category) {
    if (category === workerCategory.cltAutonomo) {
      searchInput.filtering.elements['workerCategory'] = [category];
    }
  } else {
    searchInput.filtering.elements['workerCategory'] = [
      { not: workerCategory.cltAutonomo },
    ];
  }
}

function addCompanyFilter(searchInput: SearchInput, companyId: string) {
  if (!searchInput.filtering.elements) {
    searchInput.filtering.elements = {};
  }
  if (companyId) {
    searchInput.filtering.elements['companyId'] = [companyId];
  }
}

function getSelectableTabs(peopleGroupsConfig?: {
  [key: string]: {
    key: string;
    label: string;
    count: number;
  };
}) {
  if (!peopleGroupsConfig) {
    return [];
  }
  return Object.values(peopleGroupsConfig).filter((tab) => tab.count > 0);
}

export function PeopleTable({
  organizationId,
  companyId,
  onContractClick,
  showTerminated,
  setShowTerminated,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
  onContractClick: (contractId: string) => void;
  showTerminated: boolean;
  setShowTerminated: (value: boolean) => void;
}) {
  const initialState: PeopleTableState = {
    totals: undefined,
    unfilteredCounters: {},
    filteredCounters: {},
    filters: {
      titles: [],
      departments: [],
      contractTypes: [],
      workerCategory: [],
      legalEntities: [],
      companyId: [],
      managers: [],
    },
    peopleGroupsConfig,
  };

  const { fflags } = useFFlags();
  const [state, setState] = React.useState<PeopleTableState>(initialState);
  const filters = useFilters(state.filters, {
    canAccessOrgStructure: fflags.canAccessOrgStructure.enabled,
  });
  const dataGridProps = useDataGrid({
    filters,
  });

  const [tab, setTab] = useState<keyof typeof peopleGroupsConfig>('all');

  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;

  const searchInput = prepareDataGridSearchInput(dataGridProps);
  addCompanyFilter(searchInput, companyId);

  addContractTypeFilter(searchInput, tab);
  const selectableTabs = getSelectableTabs(state.peopleGroupsConfig);
  if (!showTerminated) {
    addTerminationExclusionToFilter(searchInput);
  }

  const contractQuery = useQuery({
    queryKey: [organizationId, searchInput],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => {
      return fetchSearchAllContracts({
        pathParams: {
          organizationId: organizationId ?? '',
        },
        body: searchInput,
      });
    },
    enabled: !!organizationId && !!companyId,
  });

  useEffect(() => {
    if (!contractQuery.isError && contractQuery.data) {
      // sums value for each contract type to get the Total tab
      // we can't just use current total as when there is a filtered contract type
      // current will be the value of that tab considering the filter (it is part of the query)
      // and unfilteredTotal won't be right
      const totalCount =
        Object.values(
          contractQuery.data.metadata?.buckets?.counters?.byProp[
            'contractType'
          ],
        )?.reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0) -
        (showTerminated ||
        searchInput.filtering.elements['status']?.includes(
          contractStatuses.terminated,
        )
          ? 0
          : (contractQuery.data.metadata?.unfiltered?.counters?.byProp[
              'status'
            ]?.[contractStatuses.terminated] ?? 0));
      setState((state) => {
        return {
          ...state,
          totals: {
            current: contractQuery.data.total,
            totalCount: totalCount,
          },
          unfilteredCounters: {
            all: totalCount,
            ...(contractQuery.data.metadata?.unfiltered?.counters?.byProp ??
              {}),
          },
          filteredCounters: {
            all: contractQuery.data.total,
            ...(contractQuery.data.metadata?.filtered?.counters?.byProp ?? {}),
          },
          filters: {
            ...state.filters,
            ...contractQuery.data.filters,
            showTerminated,
          },
          peopleGroupsConfig:
            tab !== 'all'
              ? state.peopleGroupsConfig
              : {
                  ...state.peopleGroupsConfig,
                  all: {
                    ...state.peopleGroupsConfig.all,
                    count: totalCount,
                  },
                  'br:clt': {
                    ...state.peopleGroupsConfig['br:clt'],
                    count:
                      (contractQuery.data.metadata?.filtered?.counters?.byProp[
                        'contractType'
                      ]?.[contractTypes.brClt] ?? 0) -
                      (contractQuery.data.metadata?.filtered?.counters?.byProp[
                        'workerCategory'
                      ]?.[workerCategory.cltAutonomo] ?? 0),
                  },
                  'br:pj': {
                    ...state.peopleGroupsConfig['br:pj'],
                    count:
                      contractQuery.data.metadata?.filtered?.counters?.byProp[
                        'contractType'
                      ]?.[contractTypes.brPj] ?? 0,
                  },
                  'br:clt|clt:autonomo': {
                    ...state.peopleGroupsConfig['br:clt|clt:autonomo'],
                    count:
                      contractQuery.data.metadata?.filtered?.counters?.byProp[
                        'workerCategory'
                      ]?.[workerCategory.cltAutonomo] ?? 0,
                  },
                },
        };
      });
    }
  }, [contractQuery.isError, contractQuery.data, showTerminated]);

  const legalEntitiesQuery = useGetLegalEntities(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        companyId: companyId ?? '',
      },
    },
    {
      enabled: !!organizationId && !!companyId,
    },
  );

  useEffect(() => {
    if (!legalEntitiesQuery.isError && legalEntitiesQuery.data) {
      setState((state) => ({
        ...state,
        filters: {
          ...state.filters,
          legalEntities:
            legalEntitiesQuery?.data?.data?.map((legalEntity) => ({
              id: legalEntity.legalEntityId,
              name: legalEntity.br.nomeFantasia,
            })) ?? [],
        },
      }));
    }
  }, [legalEntitiesQuery.isError, legalEntitiesQuery.data]);

  const managersQuery = useQuery({
    queryKey: ['managers', organizationId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return fetchSearchAllContracts({
        pathParams: {
          organizationId: organizationId ?? '',
        },
      });
    },
    enabled: !!organizationId && fflags.canAccessOrgStructure.enabled,
  });

  useEffect(() => {
    if (managersQuery.isError || !managersQuery.data) return;
    setState((state) => ({
      ...state,
      filters: {
        ...state.filters,
        managers: managersQuery.data?.data?.map(({ contractId, name }) => ({
          contractId,
          name,
        })),
      },
    }));
  }, [managersQuery.isError, managersQuery.data]);

  const switchTab = (tab: string) => {
    setTab(tab as keyof typeof peopleGroupsConfig);
  };

  const totals = (() => {
    if (contractQuery.isFetching || contractQuery.isLoading) {
      return undefined;
    }

    const current = state?.totals?.current ?? 0;
    const total = state?.totals?.totalCount ?? 0;

    return {
      current,
      all: total,
    };
  })();

  return (
    <>
      <Box
        paddingBottom={1.5}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box alignSelf="stretch" width={'100%'}>
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            totals={totals}
            typeOfResultLabel={'resultados'}
          />
        </Box>
      </Box>
      {selectableTabs.length > 2 && (
        <Box
          my={2}
          sx={(theme) => ({
            boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
          })}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            value={tab}
            onChange={(_, newTab) => switchTab(newTab)}
            textColor="inherit"
            TabIndicatorProps={{}}
            data-testid="people-contractType-tabs"
          >
            {selectableTabs.map((tabConfig) => (
              <Tab
                key={tabConfig.key}
                value={tabConfig.key}
                icon={
                  <PeopleTabLabel
                    isSelected={tabConfig.key === tab}
                    config={tabConfig}
                    count={tabConfig.count}
                    showCount={true}
                  />
                }
                data-testid={`people-contractType-tab-${tabConfig.key}`}
              />
            ))}
          </Tabs>
          <ActionMenu
            showTerminated={showTerminated}
            setShowTerminated={setShowTerminated}
          />
        </Box>
      )}
      <DataFetching
        fetchResult={contractQuery}
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          const response = data;

          return (
            <Box mt={2}>
              {response ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={response.total || 0}
                  getRowId={(row) => row.contractId}
                  rows={response.data}
                  columns={columnsByTab[tab]}
                  onRowClick={(params) =>
                    onContractClick(params.row.contractId)
                  }
                />
              ) : null}
            </Box>
          );
        }}
      />
    </>
  );
}

function useFilters(
  { titles, departments, legalEntities, managers }: PeopleTableState['filters'],
  fflags: { canAccessOrgStructure: boolean },
): FilterOptions {
  return [
    makeElementListFilter({
      label: 'Cargo',
      propertyToFilter: 'title',
      elements: titles.map(({ id }) => id),
      labels: titles.reduce(
        (acc, { id, name }) => {
          acc[id] = name;
          return acc;
        },
        {} as Record<string, string>,
      ),
    }),
    makeElementListFilter({
      label: 'Departamento',
      propertyToFilter: 'department',
      elements: departments.map(({ name }) => name),
    }),
    makeDateRangeFilter({
      label: 'Data de admissão',
      propertyToFilter: 'admissionDate',
    }),
    makeElementListFilter({
      label: 'Situação',
      propertyToFilter: 'status',
      elements: ['active', 'onLeave', 'terminated'],
      labels: {
        active: 'Ativo',
        onLeave: 'Afastado',
        terminated: 'Desligado / Inativo',
      },
      disableSearch: true,
      disableSelectAll: true,
      sortElements: false,
    }),
    ...(legalEntities.length > 1
      ? [
          makeElementListFilter({
            label: 'Empregador',
            propertyToFilter: 'legalEntityId.keyword',
            elements: legalEntities.map(({ id }) => id),
            labels: legalEntities.reduce(
              (acc, { id, name }) => {
                acc[id] = name;
                return acc;
              },
              {} as Record<string, string>,
            ),
          }),
        ]
      : []),
    ...(fflags.canAccessOrgStructure && managers.length > 0
      ? [
          makeElementListFilter({
            label: 'Gestor(a)',
            propertyToFilter: 'manager',
            elements: managers.map(({ contractId }) => contractId),
            labels: managers.reduce(
              (labels, { contractId, name }) => ({
                ...labels,
                [contractId]: name,
              }),
              {} as Record<string, string>,
            ),
          }),
        ]
      : []),
  ];
}

type PeopleTabLabelProps = {
  isSelected: boolean;
  config: (typeof peopleGroupsConfig)[keyof typeof peopleGroupsConfig];
  count: number;
  showCount: boolean;
};

function PeopleTabLabel({
  isSelected,
  config: { label },
  count,
  showCount,
}: PeopleTabLabelProps) {
  const fontWeight = isSelected ? 700 : 500;
  const color = isSelected ? 'primary.main' : 'text.secondary';

  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography variant="body1" fontWeight={fontWeight} color={color}>
        {label}
      </Typography>
      {showCount && count > 0 && (
        <Box
          paddingY={0.25}
          paddingX={1}
          alignItems={'center'}
          borderRadius={2}
          bgcolor={'strokes.secondary'}
          height={'20px'}
        >
          <Typography variant="caption" fontWeight={fontWeight} color={color}>
            {count}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

const nameColumn: GridColDef<ContractSummary> = {
  field: 'name',
  headerName: 'Nome',
  renderHeader: (params) => {
    return <Box ml={2}>{params.field}</Box>;
  },
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.name;
  },
  renderCell: ({ value, row }) => {
    return (
      <UserAvatar
        name={value}
        pictureUrl={row.pictureUrl}
        expandNameOnHover={true}
        sx={{
          '--UserAvatar-name-max-width': '12.5em',
        }}
      />
    );
  },
};

const cargoColumn: GridColDef = {
  field: 'title',
  headerName: 'Cargo',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.titleName;
  },
  renderCell: ({ value }) => {
    return (
      <ExpandableTypography expandTextOnHover={true}>
        {value}
      </ExpandableTypography>
    );
  },
};

const departmentColumn: GridColDef = {
  field: 'department',
  headerName: 'Departamento',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.department;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value}</ExpandableTypography>;
  },
};

const admissionDateColumn: GridColDef = {
  field: 'admissionDate',
  headerName: 'Admissão / Início',
  flex: 1,
  valueGetter: (params: GridValueGetterParams) => {
    return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
      Date.parse(params.row.admissionDate),
    );
  },
};

const statusColumn: GridColDef = {
  field: 'status',
  headerName: 'Situação',
  valueGetter: (params: GridValueGetterParams) => {
    return {
      status: params.row.status,
      contractType: params.row.contractType,
    };
  },
  renderCell: ({ value }) => {
    const { status, contractType } = value;
    return <StatusBadge status={status} contractType={contractType} />;
  },
};

const contractTypeColumn: GridColDef = {
  field: 'contractType',
  headerName: 'Atuação',
  valueGetter: (params: GridValueGetterParams) => {
    const workerCategory = params.row.workerCategory
      ? (params.row.workerCategory as WorkerCategory)
      : params.row.contractType === contractTypes.brClt
        ? 'clt:geral'
        : 'pj';

    return { workerCategory };
  },
  renderCell: ({ value }) => {
    const { workerCategory } = value;
    return <WorkerCategoryTag workerCategory={workerCategory} />;
  },
};

const columnsByTab: Record<keyof typeof peopleGroupsConfig, GridColDef[]> = {
  all: [
    nameColumn,
    cargoColumn,
    departmentColumn,
    admissionDateColumn,
    contractTypeColumn,
    statusColumn,
  ],
  'br:clt': [
    nameColumn,
    cargoColumn,
    departmentColumn,
    {
      ...admissionDateColumn,
      headerName: 'Data de admissão',
    },
    statusColumn,
  ],
  'br:pj': [
    nameColumn,
    cargoColumn,
    departmentColumn,
    {
      ...admissionDateColumn,
      headerName: 'Início de contrato',
    },
    statusColumn,
  ],
  'br:clt|clt:autonomo': [
    nameColumn,
    cargoColumn,
    departmentColumn,
    {
      ...admissionDateColumn,
      headerName: 'Data de admissão',
    },
    statusColumn,
  ],
};

function ActionMenu({
  showTerminated,
  setShowTerminated,
}: {
  showTerminated: boolean;
  setShowTerminated: (value: boolean) => void;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  return (
    <Box display={'flex'} justifyContent="flex-end" alignItems={'flex-start'}>
      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVertIcon
          fontSize="inherit"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Box p={0.5} display={'flex'} alignItems={'center'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            py={1}
            px={1.5}
            gap={2}
            alignItems={'center'}
          >
            <Box width={'16px'} height={'16px'} color={'text.secondary'}>
              <PersonRemoveOutlined />
            </Box>

            <Typography fontSize={'14px'} py={1} variant={'body1'}>
              Mostrar desligados e inativos
            </Typography>
          </Box>
          <Box px={1}>
            <Switch
              checked={showTerminated}
              onChange={() => {
                setShowTerminated(!showTerminated);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
