import { useState } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { Button } from '@octopus/ui/design-system';

export function ConfirmModal({
  open,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(open);
  const handleConfirm = async () => {
    setIsSubmitting(true);
    await onConfirm();
    setIsSubmitting(false);
    setModalIsOpen(false);
  };

  const content = [
    {
      title: 'Os dados para pagamento ficaram disponíveis',
      text: 'Na aba ”Pagamentos e recibos” será possível ver os detalhes do pagamento para o colaborador, incluindo o valor final e a conta ou pix destino do pagamento.',
    },
    {
      title: 'O recibo vai ficar disponível para ser enviado',
      text: 'Na aba ”Pagamentos e recibos” será possível ver o recibo de pagamento, que poderá ser enviado a qualquer momento para a pessoa colaboradora.',
    },
  ];
  return (
    <Dialog
      open={modalIsOpen}
      sx={{
        px: 4,
        py: 5,
      }}
      data-testid="confirm-vacations-modal"
    >
      <DialogTitle mt={3}>
        <Typography fontWeight={700} fontSize="32px">
          Aprovar cálculo de férias
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography fontWeight="bold" fontSize="16px">
          Ao confirmar, o que acontece em seguida:
        </Typography>
        <List
          disablePadding
          sx={{
            listStyleType: 'disc',
            '& .MuiListItem-root': {
              display: 'list-item',
            },
            pl: 3,
          }}
        >
          {content.map((item) => (
            <ListItem sx={{ mt: 2 }} disablePadding key={item.title}>
              <Box display="flex" flexDirection="column">
                <Typography fontWeight={500} fontSize="16px">
                  {item.title}
                </Typography>
                <Typography mt={0.5} variant="subtitle1" fontSize="12px">
                  {item.text}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variantSemantic="secondary">
          Cancelar
        </Button>
        <Button isLoading={isSubmitting} onClick={handleConfirm}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
