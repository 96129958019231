import { Submission } from '@conform-to/react';
import { Schema, z } from 'zod';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { UI_TYPE } from '@octopus/libs/forms';
import { Button } from '@octopus/ui/design-system';

import { Form } from '../../../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../../../modules/form/useFormFromDefinition';

export function RejectModal({
  open,
  isSubmitting,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  isSubmitting: boolean;
  onCancel: () => void;
  onConfirm: (_: any, formData: Submission<Schema, string[], Schema>) => void;
}) {
  const rejectFormDefinition = useFormFromDefinition(
    [
      {
        label: 'Motivo da recusa',
        name: 'rejectReason',
        type: z.string(),
        uiType: UI_TYPE.TEXTAREA,
        extraProps: {
          rows: 3,
        },
      },
    ],
    {
      id: 'rejectForm',
      onSubmit: onConfirm,
      persistLocal: false,
    },
  );

  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      data-testid="confirm-vacations-modal"
    >
      <DialogTitle mt={3}>
        <Typography fontWeight={700} fontSize="32px">
          Recusar solicitação
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography fontWeight={500} fontSize="16px" color="allVariants">
            Ao recusar esta solicitação ela será cancelada e um aviso será
            enviado para o colaborador. Escreva abaixo o motivo da recusa, e
            possíveis orientações caso seja necessária uma nova solicitação.
          </Typography>
          <Typography
            fontWeight="bold"
            fontSize="16px"
            color="error.lowEmphasis"
            sx={{ mt: 2 }}
          >
            Atenção, esta ação não pode ser revertida.
          </Typography>
        </Box>
        <Box mt={3}>
          <Form
            metaForm={rejectFormDefinition.metaForm}
            payloadForm={rejectFormDefinition.payloadForm}
          >
            <Form.Field for={rejectFormDefinition.fields.rejectReason} />
          </Form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variantSemantic="secondary">
          Cancelar
        </Button>
        <Button
          isLoading={isSubmitting}
          type="submit"
          variantSemantic="error"
          form={rejectFormDefinition.id}
        >
          Recusar solicitação
        </Button>
      </DialogActions>
    </Dialog>
  );
}
