
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';


export function CreateRPAsModal({
  onClick,
  close,
}: {
  onClick: () => void;
  close: () => void;
}) {
  return <InitialScene confirm={onClick} close={close} />;
}

function InitialScene({
  close,
  confirm,
}: {
  close: () => void;
  confirm: () => void;
}) {
  return (
    <Box width={'600px'}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'flex-start',
            m: 0,
            width: '100%',
          }}
        >
          <Typography variant="h5" fontSize="24px" fontWeight={700}>
            Criar pagamentos
          </Typography>

          <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
            <Typography variant="body1">
              {`Ao confirmar, o que acontece em seguida:`}
            </Typography>
            <Box display="flex" flexDirection="column">
              <Box component="ul" pl={2} display="flex" flexDirection="column">
                <li>
                  <Typography fontWeight={500} variant="body1" fontSize="16px">
                    Criação de pagamentos para aprovação
                  </Typography>
                  <Typography
                    fontWeight={450}
                    variant="subtitle1"
                    fontSize="14px"
                  >
                    Os pagamentos ficaram disponíveis para aprovação na aba
                    ”Abertas”. RPAs e arquivos de pagamento serão gerados apenas
                    após a aprovação.
                  </Typography>
                </li>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={() => {
            close();
          }}
          data-testid="cancel-approve-payroll-button"
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={confirm}
          data-testid="confirm-approve-payroll-button"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Box>
  );
}
