import {
  VacationsScheduleList,
  VacationsScheduleSummary,
  fetchGetPayroll,
  fetchSearchAllScheduledVacations,
} from '@octopus/api';
import { payrollStatuses } from '@octopus/payroll-types';
import { VacationsScheduleStatus } from '@octopus/vacations-types';
import { ApprovalRequest } from '@octopus/workflows-types-v2';

import { pollUntil } from '../../../utils/pollUntil';

type PollingSearchInput = {
  organizationId: string;
  contractId: string;
  id: string;
  vacationsScheduleStatusesList: string[];
};

export const pollingReviewSchedule = async ({
  organizationId,
  contractId,
  id,
  role,
  status,
  scheduleStatus,
}: {
  organizationId: string;
  contractId: string;
  id: string;
  role?: ApprovalRequest['role'];
  status?: ApprovalRequest['status'];
  scheduleStatus?: VacationsScheduleStatus[];
}): Promise<VacationsScheduleList> => {
  const assertion = (result: VacationsScheduleList) => {
    if (!role || !status) {
      return !!result.data.find(
        (schedule: VacationsScheduleSummary) =>
          schedule?.approval?.steps?.length > 0,
      );
    }
    return !!result.data.find((schedule: VacationsScheduleSummary) =>
      schedule?.approval?.steps?.find((step) =>
        step.requests.find(
          (request) => request.status === status && request.role === role,
        ),
      ),
    );
  };

  return pollUntil<VacationsScheduleList>({
    action: () =>
      fetchSearchAllScheduledVacations({
        pathParams: { organizationId },
        body: {
          filtering: {
            elements: {
              contractId: [contractId],
              id: [id],
              status: scheduleStatus,
            },
          },
        },
      }),
    assertion,
    intervalMillis: 2 * 1000,
    timeoutSeconds: 60,
  });
};

export const pollingSearch = async ({
  organizationId,
  contractId,
  id,
  vacationsScheduleStatusesList,
}: PollingSearchInput): Promise<VacationsScheduleSummary> => {
  let vacation = null;
  const maxAttempts = 30; // 1 minute  (lambda timeout)
  const delay = 2 * 1000; // 2 seconds
  let attempts = 0;

  do {
    attempts++;
    vacation = await fetchSearchAllScheduledVacations({
      pathParams: { organizationId },
      body: {
        filtering: {
          elements: {
            status: vacationsScheduleStatusesList,
            contractId: [contractId],
            id: [id],
          },
        },
      },
    });

    if (vacation.total > 0) {
      return vacation.data[0];
    }

    await new Promise((resolve) => setTimeout(resolve, delay));
  } while (attempts < maxAttempts);

  return undefined;
};

export const pollingPayroll = async ({
  organizationId,
  companyId,
  payrollId,
}: {
  organizationId: string;
  companyId: string;
  payrollId: string;
}): Promise<void> => {
  const maxAttempts = 30; // 1 minute  (lambda timeout)
  const delay = 2 * 1000; // 2 seconds
  let attempts = 0;
  do {
    attempts++;
    const payroll = await fetchGetPayroll({
      pathParams: {
        organizationId,
        companyId,
        payrollId,
      },
    });
    if (payroll?.status === payrollStatuses.open) {
      return;
    }
    await new Promise((resolve) => setTimeout(resolve, delay));
  } while (attempts < maxAttempts);
};
