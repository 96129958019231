import { useState } from 'react';

import {
  Box,
  Button,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import {
  NotificationAction,
  NotificationConfigurationInput,
  NotificationTiming,
} from '@octopus/api';

import { NotificationRecipients, VacationsContexts } from './NotificationTypes';

export type HandleChange = (
  field: keyof NotificationConfigurationInput,
  value: NotificationConfigurationInput[keyof NotificationConfigurationInput],
) => void;

export type NotificationProps = {
  formData: NotificationConfigurationInput;
  handleChange: HandleChange;
};

export const RuleIdField = (props: NotificationProps) => {
  const { formData, handleChange } = props;

  return (
    <Box my={2}>
      <Typography variant="body2">ID da Regra:</Typography>
      <TextField
        fullWidth
        margin="dense"
        value={formData?.ruleId}
        onChange={(e) => handleChange('ruleId', e.target.value)}
      />
    </Box>
  );
};

export const NameField = (props: NotificationProps) => {
  const { formData, handleChange } = props;

  return (
    <Box my={2}>
      <Typography variant="body2">Nome:</Typography>
      <TextField
        fullWidth
        margin="dense"
        value={formData?.name}
        onChange={(e) => handleChange('name', e.target.value)}
      />
    </Box>
  );
};

export const DescriptionField = (props: NotificationProps) => {
  const { formData, handleChange } = props;

  return (
    <Box my={2}>
      <Typography variant="body2">Descrição:</Typography>
      <TextField
        fullWidth
        multiline
        rows={4}
        margin="dense"
        value={formData?.description}
        onChange={(e) => handleChange('description', e.target.value)}
      />
    </Box>
  );
};

export const TagsField = (props: NotificationProps) => {
  const { formData, handleChange } = props;
  const [tagInput, setTagInput] = useState('');

  const addTag = () => {
    if (tagInput.trim()) {
      handleChange('tags', [...(formData?.tags || []), tagInput]);
      setTagInput('');
    }
  };

  const removeTag = (tag: string) => {
    handleChange(
      'tags',
      formData?.tags?.filter((t: string) => t !== tag),
    );
  };

  return (
    <Box my={2}>
      <Typography variant="body2">Tags:</Typography>
      <Box display="flex" gap={2} mt={1}>
        <TextField
          margin="dense"
          fullWidth
          value={tagInput}
          onChange={(e) => setTagInput(e.target.value)}
        />
        <Button color="primaryAlt" onClick={addTag}>
          Adicionar
        </Button>
      </Box>
      <Box mt={1} gap={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tags</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData?.tags?.map((tag: string, index: number) => (
              <TableRow key={index}>
                <TableCell>{tag}</TableCell>
                <TableCell>
                  <Button color="error" onClick={() => removeTag(tag)}>
                    Excluir
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export const ActionsField = (props: NotificationProps) => {
  const { formData, handleChange } = props;
  const { resourceType } = formData;
  const [actionInput, setActionInput] = useState<{
    type: string;
    template: string;
  }>();

  const addAction = () => {
    handleChange('actions', [
      ...(formData?.actions || []),
      { type: 'email', recipient: actionInput } as NotificationAction,
    ]);
    setActionInput(undefined);
  };

  const removeAction = (index: number) => {
    handleChange(
      'actions',
      formData?.actions?.filter((_, i) => i !== index),
    );
  };

  return (
    <Box my={2}>
      <Typography variant="body1">Ações</Typography>
      <Box display="flex" flexDirection="column" mt={1}>
        <Typography variant="body2">Tipo usuário:</Typography>
        <TextField
          select
          value={actionInput?.type || ''}
          margin="dense"
          onChange={(e) =>
            setActionInput({ ...actionInput, type: e.target.value as any })
          }
        >
          <MenuItem key="empty" value="">
            Selecione
          </MenuItem>
          {Object.values(NotificationRecipients).map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </TextField>

        {actionInput?.type && (
          <>
            <Typography variant="body2">Template:</Typography>
            <TextField
              value={actionInput?.template || ''}
              select
              margin="dense"
              onChange={(e) =>
                setActionInput({
                  ...actionInput,
                  template: e.target.value as any,
                })
              }
            >
              <MenuItem key="empty" value="">
                Selecione
              </MenuItem>
              {NotificationRecipients[actionInput.type]?.templates[
                resourceType
              ]?.map((value: string) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}

        <Button sx={{ marginTop: 1 }} color="primaryAlt" onClick={addAction}>
          Adicionar
        </Button>
      </Box>

      {/* Lista de Ações */}
      <Box my={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo usuário</TableCell>
              <TableCell>Template</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData?.actions?.map((action, index) => (
              <ActionTable
                key={index}
                action={action}
                onDelete={() => removeAction(index)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export const TimingsField = (props: NotificationProps) => {
  const { formData, handleChange } = props;
  const { resourceType } = formData;
  const [timingInput, setTimingInput] = useState<NotificationTiming>();
  const addTiming = () => {
    const { when } = timingInput;
    let newTiming = timingInput;
    if (when === 'before' || when === 'after') {
      newTiming = {
        ...timingInput,
        seconds: timingInput?.seconds || 0,
        days: timingInput?.days || 0,
      };
    }
    handleChange('timings', [...(formData?.timings || []), newTiming]);
    setTimingInput(undefined);
  };

  const removeTiming = (index: number) => {
    handleChange(
      'timings',
      formData?.timings?.filter((_, i) => i !== index),
    );
  };

  return (
    <Box my={2}>
      <Typography variant="body2">Quando:</Typography>
      <Box display="flex" flexDirection="column" mt={1}>
        <TextField
          value={timingInput?.when || ''}
          margin="dense"
          select
          onChange={(e) => setTimingInput({ when: e.target.value as any })}
        >
          <MenuItem key="empty" value="">
            Selecione
          </MenuItem>
          <MenuItem key="immediately" value="immediately">
            Imediatamente
          </MenuItem>
          <MenuItem key="before" value="before">
            Antes
          </MenuItem>
          <MenuItem key="after" value="after">
            Depois
          </MenuItem>
          <MenuItem key="at" value="at">
            Quando a data atual for igual ao campo
          </MenuItem>
        </TextField>
        {timingInput?.when !== 'immediately' && (
          <Box display="flex" flexDirection="column" mt={1}>
            <Typography variant="body2">Campo:</Typography>
            <TextField
              select
              value={timingInput?.referenceField || ''}
              margin="dense"
              onChange={(e) =>
                setTimingInput({
                  ...timingInput,
                  referenceField: e.target.value,
                })
              }
            >
              <MenuItem key="empty" value="">
                Selecione
              </MenuItem>
              {Object.entries(
                VacationsContexts[
                  resourceType as keyof typeof VacationsContexts
                ].timingFields,
              ).map(([field, value]) => (
                <MenuItem key={field} value={field}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        {(timingInput?.when === 'before' || timingInput?.when === 'after') && (
          <Box display="flex" flexDirection="column" mt={1}>
            <Typography variant="body2">Segundos:</Typography>
            <TextField
              margin="dense"
              type="number"
              value={timingInput?.seconds || 0}
              onChange={(e) =>
                setTimingInput({
                  ...timingInput,
                  seconds: parseInt(e.target.value, 10) || 0,
                })
              }
            />
            <Typography variant="body2">Dias:</Typography>
            <TextField
              margin="dense"
              type="number"
              value={timingInput?.days || 0}
              onChange={(e) =>
                setTimingInput({
                  ...timingInput,
                  days: parseInt(e.target.value, 10) || 0,
                })
              }
            />
          </Box>
        )}
        <Button sx={{ marginTop: 2 }} color="primaryAlt" onClick={addTiming}>
          Adicionar
        </Button>
      </Box>

      {/* Lista de Timings */}
      <Box my={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Quando</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formData?.timings?.map((timing, index) => (
              <TimingTable
                key={index}
                timing={timing}
                onDelete={() => removeTiming(index)}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

const TimingTable = (props: {
  timing: NotificationTiming;
  onDelete: () => void;
}) => {
  const { timing, onDelete } = props;
  const translateMap = {
    before: 'antes',
    after: 'depois',
  };

  const formatTimingLabel = (timing: NotificationTiming): string => {
    if (timing?.when === 'immediately') return 'Imediatamente';
    if (timing?.when === 'at')
      return `Quando a data atual for igual ao campo ${timing?.referenceField}`;
    if (timing?.when === 'before' || timing?.when === 'after')
      return `
        Quando a data atual for
        ${timing?.days || 0} dias e ${timing?.seconds || 0} segundos
        ${translateMap[timing?.when]} a data do campo
        ${timing?.referenceField}
      `;
    return 'Desconhecido';
  };

  return (
    <TableRow>
      <TableCell>{formatTimingLabel(timing)}</TableCell>
      <TableCell>
        <Button color="error" onClick={onDelete}>
          Excluir
        </Button>
      </TableCell>
    </TableRow>
  );
};

const ActionTable = (props: {
  action: NotificationAction;
  onDelete: () => void;
}) => {
  const { action, onDelete } = props;
  return (
    <TableRow>
      <TableCell>
        {NotificationRecipients[action.recipient.type].label}
      </TableCell>
      <TableCell>{action.recipient.template}</TableCell>
      <TableCell>
        <Button color="error" onClick={onDelete}>
          Excluir
        </Button>
      </TableCell>
    </TableRow>
  );
};
