import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Button, Typography } from '@mui/material';
import { Box, useMediaQuery, useTheme } from '@mui/system';

export function UploadComponent({
  setFile,
  disabled,
  disabledMessage,
  description,
  acceptedFileTypes,
  maxFileSize = 5000000,
}: {
  setFile: (file: File) => void;
  disabled?: boolean;
  disabledMessage?: string;
  description?: string;
  acceptedFileTypes?: Record<string, string[]>;
  maxFileSize?: number;
}) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (disabled && disabledMessage) {
        setError(disabledMessage);
        return;
      }
      if (acceptedFiles[0].size > maxFileSize) {
        setError(`O arquivo deve ter no máximo ${maxFileSize / 1000000}MB`);
        return;
      }
      setFile(acceptedFiles[0]);
    },
    [disabled, disabledMessage, setFile, maxFileSize],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes || {
      'image/*': ['.jpeg', '.png'],
      'application/pdf': ['.pdf'],
    },
  });

  const [error, setError] = useState(undefined as string);

  const bgColorDragging = '#EDEDED';
  const bgColorNotDragging = '#FFF';

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const bgColor = isSmall
    ? 'background.primary'
    : isDragActive
      ? bgColorDragging
      : bgColorNotDragging;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      borderRadius={1}
      bgcolor={bgColor}
      height={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      {...getRootProps()}
      boxSizing={'border-box'}
      sx={(theme) => ({
        padding: 3,
        border: `1px dashed ${theme.palette.strokes.light}`,
      })}
    >
      <Box display={'flex'} flexDirection={'row'} bgcolor={bgColor}>
        <FileUploadOutlinedIcon
          sx={(theme) => ({
            width: '24px',
            height: '24px',
            [theme.breakpoints.up('md')]: {
              color: theme.palette.text.secondary,
            },
            [theme.breakpoints.down('md')]: {
              color: theme.palette.primary.main,
            },
          })}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        bgcolor={bgColor}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        {disabled && (
          <Typography
            bgcolor={bgColor}
            textAlign={'center'}
            variant="button"
            color="text.secondary"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {disabledMessage}
          </Typography>
        )}
        {!disabled && (
          <Typography
            bgcolor={bgColor}
            textAlign={'center'}
            variant="button"
            color="text.secondary"
            sx={{ whiteSpace: 'pre-line' }}
          >
            Para adicionar um documento{' '}
            <Typography
              variant="button"
              sx={{
                color: (theme) => theme.palette.primary.main,
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              clique aqui{' '}
              <input type={'file'} {...getInputProps()} disabled={disabled} />
            </Typography>
            ou arraste-o para cá.{'\n'}
            {description ||
              `São aceitos arquivos no formato PDF, JPG e PNG, com tamanho máximo de ${maxFileSize / 1000000}MB.`}
          </Typography>
        )}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}
        bgcolor={bgColor}
        width={'100%'}
        maxWidth={'100%'}
        sx={(theme) => ({
          textAlign: 'center',
          [theme.breakpoints.up('md')]: {
            display: 'none',
          },
        })}
      >
        {disabled && (
          <Typography
            bgcolor={bgColor}
            textAlign={'center'}
            variant="button"
            color="text.secondary"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {disabledMessage}
          </Typography>
        )}{' '}
        <Box
          display={'flex'}
          flexDirection={'column'}
          bgcolor={bgColor}
          justifyContent={'center'}
        >
          {!disabled && (
            <Button variant={'text'} component={'label'}>
              <label htmlFor={'fileInput'}>
                Toque aqui para tirar uma foto ou enviar o arquivo do seu
                documento
              </label>
              <input
                disabled={disabled}
                type={'file'}
                hidden={true}
                accept={'.pdf,.png,.jpg,.jpeg'}
                id={'fileInput'}
                onChange={(event) => {
                  setFile(event.target.files[0]);
                }}
              />
            </Button>
          )}
          {!disabled && (
            <Typography
              bgcolor={bgColor}
              textAlign={'center'}
              variant="caption"
              color="text.secondary"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {description ||
                `São aceitos arquivos no formato PDF, JPG e PNG, com tamanho máximo de ${maxFileSize / 1000000}MB.`}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        bgcolor={bgColor}
        gap={2}
        maxWidth={'280px'}
      >
        {error && (
          <Typography fontSize={'12px'} lineHeight={'16px'} color={'error'}>
            {error}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export async function uploadFile(uploadUrl: string, file: File) {
  const data = await file.arrayBuffer();

  const resp = await fetch(uploadUrl, {
    method: 'PUT',
    body: data,
  });

  if (resp.status !== 200) {
    throw new Error('Erro ao enviar arquivo');
  }

  return resp;
}
