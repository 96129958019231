const AdmissionErrorCodes = {
  ADM00001:
    'Essa Matrícula já está sendo utilizada. Por favor escolha uma Matrícula diferente',
  ADM00002:
    'Essa Matrícula eSocial já está sendo utilizada. Por favor escolha uma Matrícula eSocial diferente',
  ADM00003: 'PersonId já existe',
  ADM00004: 'Usuário já existe no sistema.',
  ADM00005: 'Usuário já definiu senha permanente para a conta.',
} as const;

export default AdmissionErrorCodes;
