import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { UnidadeSalarioFixo } from '@octopus/esocial/mapper';
import {
  BrasilAmountType,
  IFormDefinition,
  UI_TYPE,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper } from '../inputUtils';
import {
  AdmissionFormState,
  ENABLED_UNIDADE_SALARIO_FIXO,
  FormStepDefinition,
} from '../types';

const getFieldDefinition = ({
  formState,
}: {
  formState: AdmissionFormState;
}): IFormDefinition => {
  const { salary, unidadeBaseCalculo } = formState;

  return [
    {
      label: 'Salário base',
      type: BrasilAmountType,
      name: 'salary',
      uiType: UI_TYPE.TEXT_MONEY,
      placeholder: '0,00',
      value: salary || '',
      hint: {
        title: 'Valor do salário base do colaborador',
        body: 'Este campo define o salário inicial do colaborador, servindo de base para cálculos de benefícios, horas extras, encargos sociais, entre outros pagamentos e descontos.',
      },
    },
    {
      label: 'Unidade para base de cálculo',
      type: z.number().int(),
      name: 'unidadeBaseCalculo',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o tipo de salário',
      options: getOptionsFromMapper({
        mapper: UnidadeSalarioFixo,
        currentValue: unidadeBaseCalculo,
        enabledKeys: ENABLED_UNIDADE_SALARIO_FIXO,
        selectIfOnlyOne: true,
      }),
      hint: {
        title: 'Unidade utilizada para cálculo do salário (ex.: mensal)',
        body: 'Definir a unidade de cálculo do salário é essencial para a gestão da folha de pagamento e conformidade com as leis trabalhistas.',
      },
    },
  ].filter((item) => item);
};

export const getRemuneracaoFields = ({
  formState,
  remuneracaoFormSteps,
}: {
  formState: AdmissionFormState;
  remuneracaoFormSteps: AdmissionDraftInputFormStep;
}): FormStepDefinition => ({
  definition: getFieldDefinition({ formState }),
  options: {
    id: admissionDraftFormSteps.remuneracao,
    title: 'Remuneração',
    completed: remuneracaoFormSteps?.completed,
    reviewed: remuneracaoFormSteps?.reviewed,
  },
});
