import { Reducer, useCallback, useEffect, useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { type Submission } from '@conform-to/react';

import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, Divider, Skeleton, Typography } from '@mui/material';

import {
  ReportsDefinitionsEntry,
  fetchGenerateReport,
  useGetReportDefinition,
} from '@octopus/api';
import {
  ISerializedFormDefinition,
  formDefinitionsAdapter,
} from '@octopus/libs/forms';
import { Snackbar, SnackbarQueue } from '@octopus/ui/design-system';

import Download from '../../../assets/download.svg';
import { BackButton } from '../../../modules/components/BackButton';
import { DataFetching } from '../../../modules/dataFetching';
import {
  downloadBase64PdfFile,
  downloadBase64XLSXFile,
} from '../../../modules/downloads/download';
import { FormFromDefinition } from '../../../modules/form';
import { downloadPdfReport } from '../../../modules/pdf/reports';

function getFormDefFromReportDef(reportDef: ReportsDefinitionsEntry) {
  try {
    return formDefinitionsAdapter.fromApiModel(
      reportDef.parameters as ISerializedFormDefinition,
    );
  } catch (error) {
    console.error('=== Error parsing form definition', error);
    return [];
  }
}

export function ReportFormPage({ organizationId }: { organizationId: string }) {
  const { reportId } = useParams();

  const getReportFormResult = useGetReportDefinition({
    pathParams: {
      organizationId,
      reportId,
    },
  });

  type TSubmissionState =
    | {
        name: 'SUBMITTING';
        timeMs: number;
        timeDiffMs: number;
      }
    | {
        name: 'SUBMITTED_SUCCESS';
        timeMs: number;
        timeDiffMs: number;
      }
    | {
        name: 'SUBMITTED_ERROR';
        error: unknown;
        timeMs: number;
        timeDiffMs: number;
      }
    | {
        name: 'IDLE';
        timeMs: number;
        timeDiffMs: number;
      };

  type TSubmissionAction =
    | {
        type: 'START';
      }
    | {
        type: 'END';
        payload?: {
          error?: unknown;
        };
      }
    | {
        type: 'RESET';
      };

  const [submissionState, submissionDispatch] = useReducer(
    ((previouState, action) => {
      const timeMs = performance.now();

      const stateName = (() => {
        if (action.type === 'START') {
          return 'SUBMITTING';
        } else if (action.type === 'END' && !action.payload?.error) {
          return 'SUBMITTED_SUCCESS';
        } else if (action.type === 'END' && action.payload?.error) {
          return 'SUBMITTED_ERROR';
        }
        return 'IDLE';
      })();

      return {
        name: stateName,
        timeMs,
        timeDiffMs: timeMs - previouState.timeMs,
      };
    }) as Reducer<TSubmissionState, TSubmissionAction>,
    undefined,
    () => {
      return {
        name: 'IDLE',
        timeMs: performance.now(),
        timeDiffMs: 0,
      } as TSubmissionState;
    },
  );

  useEffect(() => {
    console.log(
      '===Form Submission=',
      submissionState.name,
      `took ${submissionState.timeDiffMs.toFixed(0)}ms`,
    );
  }, [submissionState.name, submissionState.timeDiffMs]);

  // Use useRef to avoid clearing the form when changing the format
  const format = useRef('xls');
  const setFormat = (newFormat: string) => {
    format.current = newFormat;
  };

  const onSubmitForm = useCallback(
    (
      _event: React.FormEvent<HTMLFormElement>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      submission: Submission<any, string[], any>,
    ) => {
      submissionDispatch({
        type: 'START',
      });
      const reportId = getReportFormResult.data?.id;
      const isPdf = format.current === 'pdf';
      const fetchReport = isPdf ? downloadPdfReport : fetchGenerateReport;
      const downloadBase64File = isPdf
        ? downloadBase64PdfFile
        : downloadBase64XLSXFile;
      const generateReportPromise = fetchReport({
        pathParams: {
          organizationId,
        },
        body: {
          reportId,
          parameters:
            'value' in submission ? submission.value : submission.payload,
        },
      });

      generateReportPromise
        .then((data) => {
          downloadBase64File(data.fileContent, data.fileName);
          submissionDispatch({
            type: 'END',
          });
        })
        .catch((error) => {
          submissionDispatch({
            type: 'END',
            payload: {
              error: error,
            },
          });
        });
    },
    [getReportFormResult.data?.id, organizationId, format],
  );

  return (
    <Box>
      <BackButton />
      <Box
        sx={{
          boxSizing: 'border-box',
          maxWidth: '664px',
          margin: '0 auto',
          paddingY: '72px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
          data-testid="payrolls-period-header"
        >
          <DataFetching
            fetchResult={getReportFormResult}
            Loading={() => (
              <>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  Gerar relatório
                </Typography>
                <Typography variant="h2">
                  <Skeleton sx={{ width: '33vw' }} />
                </Typography>
                <Typography variant="body2" sx={{ marginBlock: 1 }}>
                  <Skeleton sx={{ width: '50vw' }} />
                </Typography>
              </>
            )}
            Data={({ data }) => (
              <>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  Gerar relatório
                </Typography>
                <Typography variant="h2">{data?.name}</Typography>
                <Typography
                  variant="body2"
                  sx={{ marginBlock: 1, color: 'text.secondary' }}
                >
                  {data?.description}
                </Typography>
              </>
            )}
          />
        </Box>
        <Divider light sx={{ marginBlock: 1.5 }} />

        <Box
          component="section"
          sx={{
            paddingBlock: 5,
          }}
        >
          <DataFetching
            fetchResult={getReportFormResult}
            Loading={() => (
              <Skeleton
                sx={{
                  width: '100%',
                  height: '10rem',
                }}
              />
            )}
            Data={({ data: reportDefinition }) => (
              <FormFromDefinition
                onSubmit={onSubmitForm}
                definition={getFormDefFromReportDef(reportDefinition)}
                submitLabel={'Gerar relatório'}
                availableFormats={reportDefinition?.availableFormats}
                setFormat={setFormat}
              />
            )}
          />
        </Box>
      </Box>

      <SnackbarQueue>
        <Snackbar
          isOpen={submissionState.name === 'SUBMITTING'}
          hasCloseAction
          StartAdornment={<img src={Download} alt="download" />}
          Message={
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.primaryAlt.contrastText,
              }}
            >
              Seu relatório está sendo gerado e o download iniciará em instantes
            </Typography>
          }
        />
        <Snackbar
          onClose={() => submissionDispatch({ type: 'RESET' })}
          isOpen={submissionState.name === 'SUBMITTED_SUCCESS'}
          autoHideDuration={15000}
          hasCloseAction
          StartAdornment={<CheckCircle />}
          Message={
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.primaryAlt.contrastText,
              }}
            >
              O download do seu relatório foi finalizado com sucesso
            </Typography>
          }
        />
        <Snackbar
          variant="error"
          onClose={() => submissionDispatch({ type: 'RESET' })}
          isOpen={submissionState.name === 'SUBMITTED_ERROR'}
          autoHideDuration={15000}
          hasCloseAction
          StartAdornment={<Cancel />}
          Message={
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.primaryAlt.contrastText,
              }}
            >
              Houve um problema no download do seu relatório, por favor tente
              novamente
            </Typography>
          }
        />
      </SnackbarQueue>
    </Box>
  );
}
