import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Close } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Divider,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import {
  UserSearchList,
  UserSummary,
  useGetMembershipEntry,
} from '@octopus/api';

import { QueryResult } from '../../modules/types';

import { UserPermissionsSelect } from './components/permissions';
import { UserManagementUtils } from './components/utils';

export type UserDetailsProps = {
  user: UserSummary;
  controls: {
    close: () => void;
    hasNext: boolean;
    hasPrevious: boolean;
    onNext: () => void;
    onPrevious: () => void;
    afterUpdate: () => void;
  };
};
export function UserDetails({ user, controls }: UserDetailsProps) {
  const [membership] = user.memberships;

  const { isLoading: isMembershipLoading, data: membershipData } =
    useGetMembershipEntry(
      {
        pathParams: {
          organizationId: membership.organizationId,
          membershipId: membership.membershipId,
        },
      },
      {
        enabled: !!membership,
      },
    );

  const { label, value } = UserManagementUtils.formatDatetime(
    user.latestLoginDate,
  );
  const latestLoginDate = label === 'Nunca' || label === 'Hoje' ? label : value;

  return (
    <Box sx={{ width: '640px' }}>
      <UserDetailsHeader user={user} controls={controls} />
      <Box sx={{ pt: 4, px: 5 }}>
        <Typography variant="body2" color="text.secondary" fontWeight="bold">
          Detalhes
        </Typography>
        <Divider sx={{ my: 1 }} />
        {isMembershipLoading || !membershipData ? (
          <Skeleton variant="rounded" height={100} />
        ) : (
          <Box sx={{ pt: '9px' }}>
            {/* <UserField label="Criação do acesso" value={user.name} /> */}
            <UserDetailsField label="Email de acesso">
              {/* component with PopoverMenu for updating email goes here */}
              {user.email}
            </UserDetailsField>
            <UserDetailsField label="Permissões">
              <UserPermissionsSelect
                membership={membershipData}
                afterUpdate={controls.afterUpdate}
              />
            </UserDetailsField>
            <UserDetailsField label="Último acesso">
              {latestLoginDate}
            </UserDetailsField>
            <UserDetailsField label="Vínculos ativos">
              <UserActiveContracts user={user} />
            </UserDetailsField>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function UserActiveContracts({ user }: { user: UserSummary }) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {user.contracts.map((contract) => (
        <Box key={contract.contractId}>
          <Link to={`/people/${contract.contractId}`}>
            <Typography variant="body2" color="primary">
              {contract.contractId}
            </Typography>
          </Link>
        </Box>
      ))}
    </Box>
  );
}

type UserFieldProps = {
  label: string;
  children: React.ReactNode;
};
function UserDetailsField({ label, children }: UserFieldProps) {
  const valueComponent =
    typeof children === 'string' ? (
      <Box sx={{ width: '100%' }}>
        <Typography variant="body2" color="text.secondary">
          {children}
        </Typography>
      </Box>
    ) : (
      <Box sx={{ width: '100%' }}>{children}</Box>
    );

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      sx={{
        width: '100%',
      }}
    >
      <Box sx={{ width: '100%', height: '28px', pb: 0.5 }}>
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
      </Box>
      {valueComponent}
    </Box>
  );
}

function UserDetailsHeader({ user, controls }: UserDetailsProps) {
  return (
    <Box
      gap={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'top',
        justifyContent: 'space-between',
        pt: 5,
        pb: 2,
        px: 5,
      }}
    >
      {/* header: user name  */}
      <Typography variant="h6">{user.name}</Typography>

      <Box display="flex" flexDirection="row" alignItems="center" gap={1.5}>
        {/* header: previous and next buttons */}
        <Box>
          <IconButton
            onClick={controls.onPrevious}
            disabled={!controls.hasPrevious}
            sx={(theme) => ({
              py: 0,
              px: 0.5,
              height: '32px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '8px 0 0 8px',
              border: `1px solid ${theme.palette.strokes.light}`,
              borderRight: controls.hasNext
                ? `0.5px solid ${theme.palette.strokes.light}`
                : undefined,
            })}
          >
            <ExpandLessIcon />
          </IconButton>
          <IconButton
            disabled={!controls.hasNext}
            onClick={controls.onNext}
            sx={(theme) => ({
              py: 0,
              px: 0.5,
              height: '32px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '0 8px 8px 0',
              border: `1px solid ${theme.palette.strokes.light}`,
              borderLeft: `0.5px solid ${theme.palette.strokes.light}`,
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
        {/* header: close button */}
        <Box display="flex" flexDirection="column" textAlign="right">
          <Button
            color="secondary"
            size="small"
            onClick={controls.close}
            sx={{
              borderColor: 'transparent',
              minWidth: '32px',
              minHeight: '32px',
              height: '32px',
              width: '32px',
            }}
          >
            <Close sx={{ fontSize: '24px', padding: 0.5 }} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

type UserSelection = {
  user: UserSummary;
  index: number;
  hasNext: boolean;
  hasPrevious: boolean;
};
export function useUserDetailsControls(
  usersQuery: QueryResult<UserSearchList>,
  refetch: () => void,
) {
  const [selectedUser, setSelectedUser] = useState<UserSelection>();
  const deselectUser = () => setSelectedUser(undefined);
  const getUserIndex = (user: UserSummary) => {
    return usersQuery.data?.data?.findIndex((u) => u.userId === user.userId);
  };
  const selectUser = (user: UserSummary, index?: number) => {
    const selectedIndex = index ?? getUserIndex(user);
    setSelectedUser({
      user,
      index: selectedIndex,
      hasNext: selectedIndex < usersQuery.data?.data?.length - 1,
      hasPrevious: selectedIndex > 0,
    });
  };
  const selectNextUser = () => {
    if (!selectedUser) return;
    const nextIndex = selectedUser.index + 1;
    const nextUser = usersQuery.data?.data?.[nextIndex];
    if (!nextUser) return;
    selectUser(nextUser, nextIndex);
  };
  const selectPreviousUser = () => {
    if (!selectedUser) return;
    const previousIndex = selectedUser.index - 1;
    const previousUser = usersQuery.data?.data?.[previousIndex];
    if (!previousUser) return;
    selectUser(previousUser, previousIndex);
  };
  const afterUpdate = () => {
    deselectUser();
    refetch();
  };

  return {
    /** currently selected user in the table whose details are being shown */
    selectedUser,
    /** function to deselect the user */
    deselectUser,
    /** function to select a user in the table and show it's details */
    selectUser,
    /** function to select the next user in the table and show it's details: used by "next arrow button" */
    selectNextUser,
    /** function to select the previous user in the table and show it's details: used by "previous arrow button" */
    selectPreviousUser,
    /** function to be called after an update operation is performed successfully on the current selected user e.g. change permissions */
    afterUpdate,
  };
}
