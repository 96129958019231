import { ZodOptional, ZodString, z } from 'zod';

import {
  AdmissionDraftEntry,
  AdmissionDraftInputFormSteps,
  ContractBRCltCodigoCategoria,
  CustomFieldEntry,
  fetchListCustomFields,
} from '@octopus/api';
import { WorkerCategoryType } from '@octopus/esocial/contracts';
import {
  IFormDefinition,
  IFormDefinitionEntry,
  NEW_UI_TYPE,
  UI_TYPE,
  dateYearMonthMinMaxSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { parseMask, withRetry } from '../../../../../utils';
import { toCheckboxValue, toFieldValue } from '../inputUtils';
import {
  AdmissionFormState,
  CustomFieldsKey,
  FormStepDefinition,
} from '../types';

const FORMSTATE_NAME_KEY = 'customFields_';
const MULTISELECT_ALL_ENABLED_THRESHOLD = 10;

type CustomFieldsFilledBy = 'admin' | 'worker';
type GetCustomFieldsStepsDefinition = {
  readonly organizationId: string;
  readonly formState: AdmissionFormState;
  readonly formSteps: AdmissionDraftInputFormSteps;
  readonly filledBy: CustomFieldsFilledBy;
};
export async function getCustomFieldsStepDefinition({
  organizationId,
  formState,
  formSteps,
  filledBy,
}: GetCustomFieldsStepsDefinition): Promise<FormStepDefinition | undefined> {
  if (!organizationId || !formState?.workerCategory) {
    return undefined;
  }

  const data = await listCustomFields({
    organizationId,
    workerCategory: formState.workerCategory,
    filledBy,
  }).catch((error) => {
    console.error('Error fetching custom fields', error);
  });

  if (!data || data.length === 0) return undefined;

  const stepName =
    filledBy === 'admin' ? 'custom_fields_admin' : 'custom_fields_worker';

  return {
    definition: customFieldsToStepDefinition(formState, data),
    options: {
      title:
        filledBy === 'admin' ? 'Campos personalizados' : 'Outras informações',
      id: admissionDraftFormSteps[stepName],
      completed: formSteps[admissionDraftFormSteps[stepName]]?.completed,
      reviewed: formSteps[admissionDraftFormSteps[stepName]]?.reviewed,
    },
  };
}

type CustomFieldToFormField = (
  formState: AdmissionFormState,
  field: CustomFieldEntry,
) => IFormDefinitionEntry;

const baseCustomFieldMapper = (
  formState: AdmissionFormState,
  field: CustomFieldEntry,
) => ({
  name: toFormStateName(field.name),
  value: formState[toFormStateName(field.name)] ?? '',
  label: field.label,
});

const customFieldMappers: Record<
  CustomFieldEntry['type'],
  CustomFieldToFormField
> = {
  text: (formState, field) => ({
    ...baseCustomFieldMapper(formState, field),
    type: z.string().optional(),
    uiType: UI_TYPE.TEXT,
    mask: 'mask' in field ? parseMask(field.mask) : undefined,
  }),
  longtext: (formState, field) => ({
    ...baseCustomFieldMapper(formState, field),
    type: z.string().optional(),
    uiType: UI_TYPE.TEXTAREA,
    extraProps: { rows: 3 },
  }),
  link: (formState, field) => ({
    ...baseCustomFieldMapper(formState, field),
    type: z.string().url().optional(),
    uiType: UI_TYPE.TEXT,
  }),
  checkbox: (formState, field) => ({
    ...baseCustomFieldMapper(formState, field),
    type: z.boolean().optional(),
    uiType: NEW_UI_TYPE.CHECKBOX,
    value: toCheckboxValue(formState[toFormStateName(field.name)]),
  }),
  date: (formState, field) => ({
    ...baseCustomFieldMapper(formState, field),
    type: dateYearMonthMinMaxSchema({
      min: 'startDate' in field ? field.startDate : undefined,
      max: 'endDate' in field ? field.endDate : undefined,
    })
      // form builder does not support ZodOptional<ZodEffects<...>>
      // the cast was necessary to please the compiler.
      // despite of that, the field works as intended
      .optional() as unknown as ZodOptional<ZodString>,
    uiType: UI_TYPE.TEXT_DATE_PICKER,
    extraProps: {
      minDate: 'startDate' in field ? field.startDate : undefined,
      maxDate: 'endDate' in field ? field.endDate : undefined,
    },
  }),
  select: (formState, field) => ({
    ...baseCustomFieldMapper(formState, field),
    type: z.string().optional(),
    uiType: UI_TYPE.SELECT,
    options:
      'options' in field
        ? field.options.map(({ label, value }) => ({
            value,
            label: label ?? value,
            selected: formState[toFormStateName(field.name)] === value,
          }))
        : [],
  }),
  multiselect: (formState, field) => ({
    ...baseCustomFieldMapper(formState, field),
    type: z.array(z.string()).optional(),
    uiType: UI_TYPE.SELECT_CHECKBOX_LIST,
    options:
      'options' in field
        ? field.options.map(({ label, value }) => ({
            value,
            label: label ?? value,
            selected: formState[toFormStateName(field.name)]?.includes(value),
          }))
        : [],
    extraProps: {
      disableSelectAll:
        'options' in field &&
        field.options.length < MULTISELECT_ALL_ENABLED_THRESHOLD,
    },
  }),
  number: (formState, field) => ({
    ...baseCustomFieldMapper(formState, field),
    type: (() => {
      let schema = z.number();
      if ('isInteger' in field && field.isInteger) {
        schema = schema.int();
      }
      if ('min' in field && typeof field.min === 'number') {
        schema = schema.min(field.min);
      }
      if ('max' in field && typeof field.max === 'number') {
        schema = schema.max(field.max);
      }
      return schema.optional();
    })(),
    uiType: UI_TYPE.TEXT_NUMBER,
  }),
} as const;

const toFormFieldEntry =
  (formState: AdmissionFormState) =>
  (field: CustomFieldEntry): IFormDefinitionEntry =>
    customFieldMappers[field.type](formState, field);

function customFieldsToStepDefinition(
  formState: AdmissionFormState,
  fields: CustomFieldEntry[],
): IFormDefinition {
  return fields.map(toFormFieldEntry(formState));
}

type ListCustomFieldsParams = {
  readonly organizationId: string;
  readonly workerCategory: WorkerCategoryType;
  readonly filledBy: CustomFieldsFilledBy;
};

type CategoryTypeFilter = 'clt' | 'pj' | ContractBRCltCodigoCategoria;
const toCategoryTypeFilter = (cat: WorkerCategoryType): CategoryTypeFilter => {
  if (cat === 'pj') return 'pj';
  if (cat === 'clt:geral') return 'clt';
  if (cat === 'clt:estagiario') return 901;
  return undefined;
};

const doListCustomFields = ({
  organizationId,
  workerCategory,
  filledBy,
}: ListCustomFieldsParams): Promise<CustomFieldEntry[]> => {
  const filter = toCategoryTypeFilter(workerCategory);
  return fetchListCustomFields({
    pathParams: { organizationId },
    queryParams: {
      'app-visibility': 'admission',
      'admission-registered-by': filledBy,
      ...(filter ? { 'contract-types': filter.toString() } : {}),
    },
  });
};

const listCustomFields = withRetry(
  {
    attempts: 3,
    backoff: {
      min: 100,
      max: 300,
      jitter: 50,
    },
  },
  doListCustomFields,
);

const toFormStateName = (fieldName: string): CustomFieldsKey =>
  `${FORMSTATE_NAME_KEY}${fieldName}`;

const toFieldName = (formStateName: CustomFieldsKey): string =>
  formStateName.replace(FORMSTATE_NAME_KEY, '');

const entryToFormState = (entry: AdmissionDraftEntry) =>
  entry.customFields
    ? Object.entries(entry.customFields).reduce(
        (acc: Record<CustomFieldsKey, unknown>, [key, val]) => {
          acc[toFormStateName(key)] = val;
          return acc;
        },
        {},
      )
    : {};

type Primitives = string | number | boolean | string[];
const formStateToEntry = (formState: AdmissionFormState) =>
  Object.entries(formState)
    .filter(([key, _val]) => key.startsWith(FORMSTATE_NAME_KEY))
    .reduce((acc: Record<string, Primitives>, [key, val]) => {
      acc[toFieldName(key as CustomFieldsKey)] = toFieldValue(val);
      return acc;
    }, {});

export const CustomFieldsHelper = {
  entryToFormState,
  formStateToEntry,
};
