import {
  ContractDocumentAllowedContentType,
  ContractDocumentType,
} from '@octopus/api';

export const DOCUMENT_TYPE_LABELS: Record<ContractDocumentType, string> = {
  rg: 'RG',
  cnh: 'CNH',
  cpf: 'CPF',
  titulo_eleitor: 'Título de Eleitor',
  certidao_nascimento: 'Certidão de Nascimento',
  certidao_casamento: 'Certidão de Casamento',
  passaporte: 'Passaporte',
  comprovante_residencia: 'Comprovante de Residência',
  certificado_formacao: 'Certificado de Formação',
  cartao_cnpj: 'Cartão CNPJ',
  contrato_social: 'Contrato Social',
  outro: 'Outro',
};

export const ALLOWED_CONTENT_TYPES: Record<
  string,
  ContractDocumentAllowedContentType
> = {
  '.pdf': 'application/pdf',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.gif': 'image/gif',
  '.bmp': 'image/bmp',
  '.tiff': 'image/tiff',
  '.tif': 'image/tiff',
  '.webp': 'image/webp',
  '.doc': 'application/msword',
  '.docx':
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx':
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.txt': 'text/plain',
  '.csv': 'text/csv',
  '.rtf': 'application/rtf',
  '.odt': 'application/vnd.oasis.opendocument.text',
  '.ods': 'application/vnd.oasis.opendocument.spreadsheet',
  '.odp': 'application/vnd.oasis.opendocument.presentation',
};
