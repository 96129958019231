import { useEffect, useMemo, useState } from 'react';

import { Box, Skeleton } from '@mui/material';

import {
  LegalEntityEntry,
  PayrollEntry,
  fetchGetPayroll,
  fetchSearchAllPayrolls,
  useGetLegalEntity,
  useGetPayroll,
} from '@octopus/api';

import { getNextPeriod, getPreviousPeriod } from '../../../utils';
import { DataFetching } from '../../dataFetching';
import { PeriodFormat } from '../../types';
import { ArrowNavigation } from '../Navigator';

export type PayrollDetailsWindowsProps = {
  payroll: PayrollEntry;
  legalEntity: LegalEntityEntry;
  comparisonPeriod: string | null | undefined;
  comparisonPayroll: PayrollEntry | undefined;
  isLoadingComparisonPayroll?: boolean;
  periodNavigation: ArrowNavigation;
  workerNavigation: ArrowNavigation;
  onExit: () => void;
};

export function PayrollDetailsComponent({
  organizationId,
  companyId,
  period,
  type,
  contractId,
  payrollId,
  compareToPeriod,
  workerNavigation,
  onExit,
  payrollWindowDetailsRender,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
  period: PeriodFormat;
  compareToPeriod?: PeriodFormat;
  type: string;
  payrollId: string;
  workerNavigation: ArrowNavigation;
  onExit: () => void;
  payrollWindowDetailsRender: (
    props: PayrollDetailsWindowsProps,
  ) => React.ReactElement;
}) {
  const [currentPeriodPayrollId, setCurrentPeriodPayrollId] =
    useState<string>(payrollId);
  const [nextPeriodsPayrollIds, setNextPeriodsPayrollIds] = useState<string[]>(
    [],
  );
  const [previousPeriodsPayrollIds, setPreviousPeriodsPayrollIds] = useState<
    string[]
  >([]);

  const [comparisonPayrollId, setComparisonPayrollId] = useState<string | null>(
    undefined,
  );

  const [comparisonPayroll, setComparisonPayroll] = useState<
    PayrollEntry | undefined
  >(undefined);

  const isLoadingComparisonPayroll =
    comparisonPayrollId !== null && !comparisonPayroll;

  useEffect(() => {
    setCurrentPeriodPayrollId(payrollId);
  }, [payrollId]);

  const useGetPayrollResult = useGetPayroll({
    pathParams: {
      organizationId,
      companyId,
      payrollId: currentPeriodPayrollId,
    },
  });

  const useGetLegalEntityResult = useGetLegalEntity(
    {
      pathParams: {
        organizationId: useGetPayrollResult.data?.organizationId,
        companyId: useGetPayrollResult.data?.companyId,
        legalEntityId: useGetPayrollResult.data?.legalEntityId,
      },
    },
    {
      enabled:
        !useGetPayrollResult.isLoading &&
        !!useGetPayrollResult.data?.organizationId &&
        !!useGetPayrollResult.data?.companyId &&
        !!useGetPayrollResult.data?.legalEntityId,
    },
  );

  const { data: currentPayroll } = useGetPayrollResult;

  const isComparingDifferentPeriods = currentPayroll?.period
    ? compareToPeriod && currentPayroll.period !== compareToPeriod
    : period && compareToPeriod && period !== compareToPeriod;

  useEffect(
    function fetchComparisonPayroll() {
      if (!isComparingDifferentPeriods || !comparisonPayrollId) {
        setComparisonPayroll(undefined);
        return;
      }
      fetchGetPayroll({
        pathParams: {
          organizationId,
          companyId,
          payrollId: comparisonPayrollId,
        },
      })
        .then(setComparisonPayroll)
        .catch((err) => {
          console.log('Failed to retrieve comparison payroll', err);
        });
    },
    [
      isComparingDifferentPeriods,
      comparisonPayrollId,
      organizationId,
      companyId,
    ],
  );

  useEffect(() => {
    if (type !== 'monthly') {
      return;
    }

    const fetchSearchPreviousPayrolls = fetchSearchAllPayrolls({
      pathParams: {
        organizationId,
        companyId,
      },
      body: {
        filtering: {
          elements: {
            contractId: [contractId],
            type: [type],
          },
          ranges: {
            period: {
              max: getPreviousPeriod(period),
            },
          },
        },
        sorting: [
          {
            field: 'period',
            order: 'desc',
          },
        ],
      },
    });

    const fetchSearchNextPayrolls = fetchSearchAllPayrolls({
      pathParams: {
        organizationId: organizationId,
        companyId: companyId,
      },
      body: {
        filtering: {
          elements: {
            contractId: [contractId],
            type: [type],
          },
          ranges: {
            period: {
              min: getNextPeriod(period),
            },
          },
        },
        sorting: [
          {
            field: 'period',
            order: 'asc',
          },
        ],
      },
    });

    Promise.allSettled([
      fetchSearchPreviousPayrolls,
      fetchSearchNextPayrolls,
    ] as const).then((results) => {
      const [previousPayrollsResult, nextPayrollsResult] = results;

      let comparisonPayrollId;
      if (previousPayrollsResult.status === 'fulfilled') {
        setPreviousPeriodsPayrollIds(
          previousPayrollsResult.value.data.map((payroll) => payroll.payrollId),
        );
        for (const payroll of previousPayrollsResult.value.data) {
          if (payroll.period === compareToPeriod) {
            comparisonPayrollId = payroll.payrollId;
            break;
          }
        }
      } else {
        console.error(
          'Failed to retrieve previous periods',
          previousPayrollsResult.reason,
        );
      }

      if (nextPayrollsResult.status === 'fulfilled') {
        setNextPeriodsPayrollIds(
          nextPayrollsResult.value.data.map((payroll) => payroll.payrollId),
        );
        for (const payroll of nextPayrollsResult.value.data) {
          if (payroll.period === compareToPeriod) {
            comparisonPayrollId = payroll.payrollId;
            break;
          }
        }
      } else {
        console.error(
          'Failed to retrieve next periods',
          nextPayrollsResult.reason,
        );
      }

      setComparisonPayrollId(comparisonPayrollId || null);
    });
  }, [
    organizationId,
    companyId,
    payrollId,
    contractId,
    period,
    type,
    compareToPeriod,
  ]);

  const periodNavigation = useMemo(
    () => ({
      canGoBackwards: previousPeriodsPayrollIds.length > 0,
      canGoForward: nextPeriodsPayrollIds.length > 0,
      goBackwards: () => {
        if (previousPeriodsPayrollIds.length === 0) {
          return;
        }
        const [newPeriod, ...previous] = previousPeriodsPayrollIds;
        const next = [currentPeriodPayrollId, ...nextPeriodsPayrollIds];
        setPreviousPeriodsPayrollIds(previous);
        setNextPeriodsPayrollIds(next);
        setCurrentPeriodPayrollId(newPeriod as string);
      },
      goForward: () => {
        if (nextPeriodsPayrollIds.length === 0) {
          return;
        }
        const [newPeriod, ...next] = nextPeriodsPayrollIds;
        const previous = [currentPeriodPayrollId, ...previousPeriodsPayrollIds];
        setPreviousPeriodsPayrollIds(previous);
        setNextPeriodsPayrollIds(next);
        setCurrentPeriodPayrollId(newPeriod as string);
      },
    }),
    [previousPeriodsPayrollIds, nextPeriodsPayrollIds, currentPeriodPayrollId],
  );

  return (
    <DataFetching<{
      payrollData: PayrollEntry;
      legalEntityData: LegalEntityEntry;
    }>
      containerSx={{ height: '100%', overflow: 'hidden' }}
      fetchResult={{
        results: {
          payrollData: useGetPayrollResult,
          legalEntityData: useGetLegalEntityResult,
        },
      }}
      Loading={() => (
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          p={4}
          width="560px"
        >
          <Skeleton variant="rounded" width="100%" height={60} />
          <Skeleton variant="rounded" width="100%" height={50} sx={{ mt: 1 }} />
          <Box display="flex" pt={2} pb={3} gap="4px">
            <Skeleton variant="rounded" width={70} height={30} />
            <Skeleton variant="rounded" width={70} height={30} />
          </Box>
          <Skeleton variant="rounded" width={100} height={40} />
          {new Array(5).fill(null).map((_, index) => (
            <Skeleton key={index} variant="rounded" width="100%" height={40} />
          ))}
        </Box>
      )}
      Data={({ data }) => {
        if (!data) {
          return null;
        }
        const { payrollData: payroll, legalEntityData } = data;

        return (
          payroll &&
          payrollWindowDetailsRender({
            payroll: payroll,
            legalEntity: legalEntityData,
            comparisonPeriod: isComparingDifferentPeriods && compareToPeriod,
            isLoadingComparisonPayroll: isLoadingComparisonPayroll,
            comparisonPayroll: comparisonPayroll,
            periodNavigation: periodNavigation,
            workerNavigation: workerNavigation,
            onExit: onExit,
          })
        );
      }}
    />
  );
}
