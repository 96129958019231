import { z } from 'zod';

import { optionalDateYearMonthSchema } from '@octopus/libs/forms';

export const duracaoSchema = z
  .object({
    type: z.number().int().min(1).max(2).optional(),
    duracaoContr: z
      .number()
      .int()
      .min(1)
      .max(2 * 365)
      .optional(),
    periodoExperienciaEnabled: z.string().optional(),
    probationPeriod: z.string().optional(),
    dtTerm: optionalDateYearMonthSchema,
    jovemAprendizEnabled: z.string().optional(),
  })
  .superRefine((tpContr, ctx) => {
    const {
      type,
      dtTerm,
      jovemAprendizEnabled,
      duracaoContr,
      periodoExperienciaEnabled,
      probationPeriod,
    } = tpContr || {};

    if (type === 1) {
      return true;
    }

    if (jovemAprendizEnabled === 'true') {
      if (dtTerm == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['dtTerm'],
        });
      }
      return true;
    }

    if (periodoExperienciaEnabled === 'true') {
      if (probationPeriod == null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['probationPeriod'],
        });
      }
    } else {
      if (!duracaoContr || duracaoContr < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          type: 'number',
          inclusive: true,
          path: ['duracaoContr'],
        });
      }

      if (duracaoContr > 2 * 365) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: 2 * 365,
          type: 'number',
          inclusive: true,
          path: ['duracaoContr'],
        });
      }
    }

    return true;
  });
