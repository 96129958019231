import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { leftPad } from 'aws-cdk/lib/api/util/string-manipulation';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Skeleton,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  PjPaymentRequestSummary,
  fetchSearchByContractPjPaymentRequests,
} from '@octopus/api';
import {
  formatDateBR,
  formatMoney,
  formatPeriodDate,
} from '@octopus/formatters';
import {
  PaymentRequestStatus,
  paymentRequestStatuses,
} from '@octopus/pj-payment-types';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  GridColDef,
  makeDateRangeFilter,
  makeElementListFilter,
  makeMoneyRangeFilter,
  makeYearMonthPickerFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import requestPaymentPj from '../../../assets/requestPaymentPj.svg';
import requestPaymentPjWhite from '../../../assets/requestPaymentPj_white.svg';
import { PaymentRequestStatusTag } from '../../../modules/components/pj/pjComponents';
import { DataFetching } from '../../../modules/dataFetching';
import { getActiveElementFilters, getActiveRangeFilters } from '../../../utils';

export function ContractorPaymentRequestListPage({
  organizationId,
  companyId,
  contractId,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="background.paper"
      sx={(theme) => ({
        [theme.breakpoints.up('sm')]: {
          mx: 8,
        },
        [theme.breakpoints.up('md')]: {
          my: 7,
        },
        [theme.breakpoints.down('md')]: {
          paddingBottom: 12,
        },
      })}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth="1064px"
        width="100%"
        gap={1.5}
      >
        <PaymentRequestsTitle />

        <PaymentRequestsContent
          organizationId={organizationId}
          contractId={contractId}
          companyId={companyId}
        />
      </Box>
    </Box>
  );
}

function PaymentRequestsContent({
  organizationId,
  companyId,
  contractId,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
}) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  if (isSmall) {
    return (
      <PaymentRequestSmallView
        organizationId={organizationId}
        contractId={contractId}
        companyId={companyId}
      />
    );
  }

  return (
    <PaymentRequestTable
      companyId={companyId}
      organizationId={organizationId}
      contractId={contractId}
    />
  );
}

function PaymentRequestSmallView({
  organizationId,
  companyId,
  contractId,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
}) {
  const navigate = useNavigate();

  return (
    <Box>
      <Box display={'flex'} flexDirection={'column'} px={2.5} marginBottom={11}>
        <PaymentRequestCards
          organizationId={organizationId}
          companyId={companyId}
          contractId={contractId}
        />
      </Box>
      <Box
        display="block"
        position="absolute"
        bottom="70px"
        width="100%"
        my={1.5}
        zIndex={9999}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems={'center'}
          height="48px"
        >
          <IconButton
            sx={{
              borderRadius: '40px',
              border: '1px solid',
              borderColor: 'primary.main',
              bgcolor: 'primary.main',
              ':hover': {
                opacity: 1,
                bgcolor: 'primary.dark',
              },
              px: 2.5,
              py: 1.5,
            }}
            onClick={() => {
              navigate('/contractor/payment-requests/new');
            }}
          >
            <Box display={'flex'} gap={1} justifyContent={'center'}>
              <img src={requestPaymentPjWhite} alt="Nova Solicitação" />
              <Typography
                variant={'body1'}
                fontWeight={500}
                color={'secondary.main'}
              >
                Nova Solicitação
              </Typography>
            </Box>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

function PaymentRequestCards({
  organizationId,
  companyId,
  contractId,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
}) {
  const searchQuery = useQuery({
    queryKey: [organizationId, companyId],
    queryFn: () => {
      return fetchSearchByContractPjPaymentRequests({
        pathParams: {
          organizationId: organizationId ?? '',
          companyId: companyId ?? '',
          contractId: contractId ?? '',
        },
        body: {
          pagination: {
            page: 0,
            size: 50,
          },
          sorting: [
            {
              field: 'createdOn',
              order: 'desc',
            },
          ],
        },
      });
    },
    enabled: !!organizationId && !!companyId,
  });

  if (searchQuery.isLoading || searchQuery.isFetching) {
    return <Skeleton variant="rounded" height={400} width="100%" />;
  }

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      borderRadius={'12px'}
      border={'1px solid #EDEDED'}
    >
      {searchQuery.data?.data.map((entry, index) => (
        <Box
          borderBottom={
            index !== searchQuery.data.data.length - 1
              ? '1px solid #EDEDED'
              : '0'
          }
        >
          <PaymentRequestCard key={entry.id} entry={entry} />
        </Box>
      ))}
    </Box>
  );
}

function PaymentRequestCard({ entry }: { entry: PjPaymentRequestSummary }) {
  const navigate = useNavigate();

  return (
    <Box
      p={2}
      display={'flex'}
      flexDirection={'column'}
      gap={0.5}
      onClick={() => {
        navigate(`/contractor/payment-requests/${entry.id}`);
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant={'body1'} fontWeight={700}>
          {formatPeriodDate(entry.period)}
        </Typography>
        <Typography variant={'body1'} fontWeight={500}>
          {formatMoney(entry.grossAmount)}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant={'caption'} fontWeight={500}>
          {leftPad(
            entry.invoice?.number ? `${entry.invoice.number}` : '',
            9,
            '0',
          )}
        </Typography>
        <PaymentRequestStatusTag status={entry.status} />
      </Box>
    </Box>
  );
}

function PaymentRequestTable({
  organizationId,
  companyId,
  contractId,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
}) {
  const navigate = useNavigate();
  const filters = useFilters();
  const { filteringProps, searchProps, sortingProps, paginationProps } =
    useDataGrid({
      filters,
    });

  const [showNotasRecusadas, setShowNotasRecusadas] = useState(true);
  const [showNotasCanceladas, setShowNotasCanceladas] = useState(true);

  const elementFilters = getActiveElementFilters(filteringProps);
  const rangeFilters = getActiveRangeFilters(filteringProps);

  const statusToFilter: PaymentRequestStatus[] = [
    paymentRequestStatuses.created,
    paymentRequestStatuses.approved,
  ];

  if (showNotasRecusadas) {
    statusToFilter.push(paymentRequestStatuses.rejected);
  }

  if (showNotasCanceladas) {
    statusToFilter.push(paymentRequestStatuses.deleted);
  }

  const searchQuery = useQuery({
    queryKey: [
      organizationId,
      companyId,
      paginationProps,
      elementFilters,
      rangeFilters,
      searchProps.searchTerm,
      sortingProps,
    ],
    queryFn: () => {
      return fetchSearchByContractPjPaymentRequests({
        pathParams: {
          organizationId: organizationId ?? '',
          companyId: companyId ?? '',
          contractId: contractId ?? '',
        },
        body: {
          pagination: {
            page: paginationProps.page,
            size: paginationProps.rowsPerPage,
          },
          query: searchProps.searchTerm,
          sorting: [
            {
              field: 'createdOn',
              order: sortingProps.order ?? 'desc',
            },
          ],
          filtering: {
            elements: {
              ...elementFilters,
              status: statusToFilter,
            },
            ...(rangeFilters ? { ranges: rangeFilters } : {}),
          },
          counting: {
            filtered: {
              total: true,
              byProp: {
                status: statusToFilter,
              },
            },
          },
        },
      });
    },
    enabled: !!organizationId && !!companyId,
  });

  useEffect(() => {
    searchQuery.refetch();
  }, [showNotasRecusadas, showNotasCanceladas]);

  return (
    <Box display={'flex'} flexDirection={'column'} px={2.5}>
      <Box display={'flex'} width={'100%'} bgcolor={'background.paper'}>
        <Box width={'100%'}>
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            searchPlaceholder="Procurar..."
          >
            <Box display={'flex'} gap={1} marginLeft={'auto'} py={0.5}>
              <Button
                color={'primaryAlt'}
                sx={{
                  borderRadius: '8px',
                  padding: '4px',
                  border: '1px solid #EDEDED',
                  px: 2,
                  py: 1,
                  gap: 1,
                }}
                onClick={() => {
                  navigate('/contractor/payment-requests/new');
                }}
              >
                Nova solicitação
              </Button>
              <ActionMenu
                showNotasRecusadas={showNotasRecusadas}
                setShowNotasCanceladas={setShowNotasCanceladas}
                setShowNotasRecusadas={setShowNotasRecusadas}
                showNotasCanceladas={showNotasCanceladas}
              />
            </Box>
          </DataGridToolbar>
        </Box>
      </Box>

      <Box>
        <DataFetching
          fetchResult={searchQuery}
          Loading={() => (
            <Box py={1}>
              <Skeleton variant="rounded" height={400} width="100%" />
            </Box>
          )}
          Data={({ data: list }) => {
            return (
              <DataGrid
                sortingProps={sortingProps}
                paginationProps={paginationProps}
                totalRowCount={list?.total ?? 0}
                rows={list?.data ?? []}
                getRowId={(row) => `${row.id}`}
                onRowClick={({ row }) => {
                  navigate(`/contractor/payment-requests/${row.id}`);
                }}
                columns={columns}
                emptyMessage={
                  'Não existem solicitações de pagamento recebidas no momento.'
                }
              />
            );
          }}
        />
      </Box>
    </Box>
  );
}

function PaymentRequestsTitle() {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1.5}
      px={2.5}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          py: 2,
        },
        [theme.breakpoints.down('md')]: {
          pb: 4,
          pt: 5,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          width: '40px',
          height: '40px',
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
      >
        <img
          src={requestPaymentPj}
          height={40}
          width={40}
          alt="Solicitações de pagamento"
        />
      </Box>

      <Typography variant="h1" data-testid="receipts-title">
        Solicitações de pagamento
      </Typography>
    </Box>
  );
}

function useFilters(): FilterOptions {
  return [
    makeYearMonthPickerFilter({
      label: 'Competência',
      propertyToFilter: 'period',
    }),
    makeMoneyRangeFilter({
      label: 'Valor da nota',
      propertyToFilter: 'grossAmount',
      getRangeMin: () => 0,
      getRangeMax: () => 1_000_000,
    }),
    makeDateRangeFilter({
      label: 'Data da solicitação',
      propertyToFilter: 'createdOn',
    }),
    makeElementListFilter({
      label: 'Situação',
      propertyToFilter: 'status',
      elements: [...Object.values(paymentRequestStatuses)],
      labels: {
        [paymentRequestStatuses.created]: 'Aguardando aprovação',
        [paymentRequestStatuses.approved]: 'Aprovada',
        [paymentRequestStatuses.rejected]: 'Recusada ',
        [paymentRequestStatuses.deleted]: 'Cancelada',
      },
      disableSearch: true,
      disableSelectAll: false,
      sortElements: true,
    }),
    makeElementListFilter({
      label: 'Arquivo da NF',
      propertyToFilter: 'invoiceUploaded',
      elements: ['true', 'false'],
      labels: {
        true: 'Com arquivo de NF',
        false: 'Sem arquivo de NF',
      },
      disableSearch: true,
      disableSelectAll: true,
      sortElements: false,
    }),
  ].filter(Boolean);
}

function ActionMenu({
  showNotasRecusadas,
  setShowNotasRecusadas,
  showNotasCanceladas,
  setShowNotasCanceladas,
}: {
  showNotasRecusadas: boolean;
  setShowNotasRecusadas: (value: boolean) => void;
  showNotasCanceladas: boolean;
  setShowNotasCanceladas: (value: boolean) => void;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVertIcon
          fontSize="inherit"
          sx={{
            width: '16px',
            height: '16px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        sx={{
          m: 1,
        }}
        data-testid="notas-recusadas-popover"
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          p={0.5}
          gap={0.25}
          alignItems={'space-between'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            px={1.5}
            gap={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography fontSize={'14px'} variant={'body1'}>
              Mostrar notas recusadas
            </Typography>
            <Box>
              <Switch
                checked={showNotasRecusadas}
                onChange={() => {
                  setShowNotasRecusadas(!showNotasRecusadas);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            px={1.5}
            gap={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography fontSize={'14px'} variant={'body1'}>
              Mostrar notas canceladas
            </Typography>
            <Box>
              <Switch
                checked={showNotasCanceladas}
                onChange={() => {
                  setShowNotasCanceladas(!showNotasCanceladas);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

const columns: GridColDef<PjPaymentRequestSummary>[] = [
  {
    field: 'invoiceNumber',
    headerName: 'Número da nota',
    sortable: true,
    renderHeader: (params) => {
      return <Box ml={2}>{params.field}</Box>;
    },
    renderCell: (params) => {
      return (
        <Box
          display={'flex'}
          gap={1}
          paddingX={1}
          alignSelf={'stretch'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box
            padding={'0px 8px'}
            gap={1.5}
            display={'flex'}
            alignItems={'center'}
          >
            <Typography variant="body2" fontSize="14px" lineHeight="20px">
              {params.row.invoice?.number ?? '--'}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'period',
    headerName: 'Competência',
    sortable: true,
    valueGetter: (params) => {
      return formatPeriodDate(params.row.period);
    },
  },
  {
    field: 'amount',
    headerName: 'Valor',
    sortable: false,
    valueGetter: (params) => formatMoney(params.row.grossAmount) || '--',
  },
  {
    field: 'createdOn',
    headerName: 'Data da solicitação',
    sortable: true,
    valueGetter: (params) => {
      return formatDateBR(params.row.createdOn);
    },
  },
  {
    field: 'status',
    headerName: 'Situação',
    sortable: true,

    renderCell: (params) => {
      return (
        <Box py={1.5} display={'flex'} alignItems={'center'}>
          <PaymentRequestStatusTag status={params.row.status} />
        </Box>
      );
    },
  },
];
