import { useMaskito } from '@maskito/react';

import {
  ContractBRCltEntryAprendiz,
  ContractBRCltEntryAprendizDireto,
  ContractBRCltEntryAprendizIndireto,
  ContractBRCltEntryTrabalho,
} from '@octopus/api';
import { ModalidadeAprendiz } from '@octopus/esocial/mapper';
import { formatCNPJ, formatCPF, getOnlyDigits } from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../../../form/Field/MaskitoOptions';
import { Record, RecordEntry, RecordEntryGroup } from '../../../Record';
import { BaseRecordProps } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type JovemAprendizRecordData = {
  trabalho: ContractBRCltEntryTrabalho;
  aprendiz: ContractBRCltEntryAprendiz | undefined;
};

export type JovemAprendizRecordProps = BaseRecordProps<JovemAprendizRecordData>;

export function JovemAprendizRecord(props: JovemAprendizRecordProps) {
  const { data } = props;
  const { trabalho, aprendiz } = data;

  const initialFormData = {
    ...data,
    aprendiz: applyFormating(data.aprendiz),
  };

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit<JovemAprendizRecordData>({
      data: initialFormData,
      edit: props.edit,
    });

  const updateAprendiz =
    (prop: string, formatter?: (val: string) => string | number) =>
    (value: string) =>
      updateData((data) => ({
        ...data,
        aprendiz: {
          ...data.aprendiz,
          [prop]: formatter ? formatter(value) : value,
        },
      }));

  const Masks = {
    cnpjEntQual: useMaskito({
      options: MaskitoOptionsBR.cnpj,
    }),
    nrInscCpf: useMaskito({
      options: MaskitoOptionsBR.cpf,
    }),
    nrInscCnpj: useMaskito({
      options: MaskitoOptionsBR.cnpj,
    }),
  };

  if (!aprendiz) return null;
  const { indAprend } = aprendiz;

  switch (indAprend) {
    case 'direta':
      return (
        <Record
          title="Aprendiz"
          hide={trabalho.codCateg !== 103}
          edit={editRecordProps}
        >
          <RecordEntryGroup>
            <RecordEntry label="Modalidade de contratação">
              {ModalidadeAprendiz.getByCode(aprendiz?.indAprend)}
            </RecordEntry>

            <RecordEntry
              label="Razão social da entidade qualificadora"
              edit={{
                editing,
                type: 'text',
                value: (formData?.aprendiz as ContractBRCltEntryAprendizDireto)
                  ?.razaoSocialEntQual,
                onChange: updateAprendiz('razaoSocialEntQual'),
                hasError: hasError('br/aprendiz/razaoSocialEntQual'),
              }}
            >
              {aprendiz?.razaoSocialEntQual}
            </RecordEntry>

            <RecordEntry
              label="CNPJ da entidade qualificadora"
              edit={{
                editing,
                type: 'text',
                value: (formData?.aprendiz as ContractBRCltEntryAprendizDireto)
                  ?.cnpjEntQual,
                onChange: updateAprendiz('cnpjEntQual', getOnlyDigits),
                mask: Masks.cnpjEntQual,
                hasError: hasError('br/aprendiz/cnpjEntQual'),
              }}
            >
              {formatCNPJ(aprendiz?.cnpjEntQual)}
            </RecordEntry>
          </RecordEntryGroup>
        </Record>
      );
    case 'indireta':
      return (
        <Record title="Aprendiz">
          <RecordEntryGroup>
            <RecordEntry label="Modalidade de contratação">
              {ModalidadeAprendiz.getByCode(indAprend)}
            </RecordEntry>
            <RecordEntry label="Tipo da inscrição">
              {aprendiz?.tpInsc}
            </RecordEntry>
            <RecordEntry
              label="Número da inscrição"
              edit={{
                editing,
                type: 'text',
                value: (
                  formData?.aprendiz as ContractBRCltEntryAprendizIndireto
                )?.tpInsc,
                onChange: updateAprendiz('nrInsc', getOnlyDigits),
                mask:
                  aprendiz.tpInsc === 'cpf'
                    ? Masks.nrInscCpf
                    : Masks.nrInscCnpj,
                hasError: hasError('br/aprendiz/nrInsc'),
              }}
            >
              {aprendiz?.nrInsc}
            </RecordEntry>
          </RecordEntryGroup>
        </Record>
      );
  }
}

function applyFormating(
  aprendiz: ContractBRCltEntryAprendiz | undefined,
): ContractBRCltEntryAprendiz | undefined {
  if (!aprendiz) return aprendiz;

  if (aprendiz.indAprend === 'direta') {
    return {
      ...aprendiz,
      cnpjEntQual: formatCNPJ(aprendiz.cnpjEntQual),
    };
  }

  if (aprendiz.indAprend === 'indireta') {
    return {
      ...aprendiz,
      nrInsc:
        aprendiz.tpInsc === 'cpf'
          ? formatCPF(aprendiz.nrInsc)
          : formatCNPJ(aprendiz.nrInsc),
    };
  }

  return undefined;
}
