import { AuditableVersionedEntry } from '@octopus/api';

export type VacationsConfiguration = AuditableVersionedEntry & {
  organizationId: string;

  paymentDateConfiguration: PaymentDateConfiguration;

  extraDaysForCollectiveHolidays?: ExtraDaysForCollectiveHolidaysConfiguration;

  daysBeforePaymentDateToCreatePayroll: number;

  /**
   * membershipType: setOfRules
   */
  rules: {
    [K in MembershipType]: RulesList;
  };

  groupVacationsConfiguration: GroupVacationsConfiguration;

  approvalConfiguration: ApprovalConfiguration;
};

export type GroupVacationsConfiguration = {
  rules: GroupVacationsRuleList;
};

export type PaymentDateConfiguration = {
  typeOfDays: 'calendar' | 'banking';
  amountOfDaysBeforeStart: number;
  adminsAllowedToChange: boolean;
  approvalDeadlineThresholdDays: number;

  paymentWeekDays: PaymentWeekDay[];
};

export type PaymentWeekDay =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday';

export type Month =
  | 'january'
  | 'february'
  | 'march'
  | 'april'
  | 'may'
  | 'june'
  | 'july'
  | 'august'
  | 'september'
  | 'october'
  | 'november'
  | 'december';

export type VacationsConfigurationByMembership = Omit<
  VacationsConfiguration,
  'rules'
> & {
  membershipType: MembershipType;
  rules: RulesList;
};

export type RulesList = { [K in CreateScheduleRule]: RuleConfiguration } & {
  daysBeforeDSR: DaysBeforeDSRRuleConfiguration;
  daysBeforeStart: DaysBeforeStartRuleConfiguration;
  thirteenthAdvanceAllowedMonths: ThirteenthAdvanceAllowedMonthsRuleConfiguration;
  howManyFractionsMayHave: HowManyFractionsMayHaveRuleConfiguration;
};

export type GroupVacationsRuleList = {
  [K in GroupVacationsRule]: RuleConfiguration;
} & {
  daysBeforeDSR: DaysBeforeDSRRuleConfiguration;
  maximumPeriods: MaxPeriodsRuleConfiguration;
  minimumDays: MinimumDaysRuleConfiguration;
};

/**
 * these are the id's of the rules that will be executed in createSchedule runtime
 * each id added here must have an implementation in the ruleEngines that uses them
 * (there's a test validating that)
 */
export const createScheduleRules = {
  art134AtLeast14Days: 'art134AtLeast14Days',
  art134AtLeast5Days: 'art134AtLeast5Days',
  contractMustBeActive: 'contractMustBeActive',
  daysAvailable: 'daysAvailable',
  // afeta calendario
  daysBeforeDSR: 'daysBeforeDSR',
  daysBeforeStart: 'daysBeforeStart',

  howManyFractionsMayHave: 'howManyFractionsMayHave',
  mayNotOverlapVacationsDays: 'mayNotOverlapVacationsDays',

  // afeta tela
  mayRequestThirteenthAdvance: 'mayRequestThirteenthAdvance',
  mayOnlySellAThirdOfVacations: 'mayOnlySellAThirdOfVacations',
  mayOnlySellVacationsOnce: 'mayOnlySellVacationsOnce',
  maySellVacations: 'maySellVacations',
  mayScheduleVacations: 'mayScheduleVacations',
  mustNotHaveLeaves: 'mustNotHaveLeaves',
  thirteenthAdvance: 'thirteenthAdvance',
  thirteenthAdvanceAllowedMonths: 'thirteenthAdvanceAllowedMonths',
} as const;

export const groupVacationsRules = {
  minimumDays: 'minimumDays',
  daysBeforeDSR: 'daysBeforeDSR',
  maximumPeriods: 'maximumPeriods',
} as const;

export const membershipTypes = {
  owner: 'owner',
  internal: 'internal',
  ['tako:support']: 'tako:support',
} as const;

export type VacationsConfigurationKeys = {
  organizationId: string;
};

export type VacationsConfigurationCreatePayload = Omit<
  VacationsConfiguration,
  keyof VacationsConfigurationKeys | keyof AuditableVersionedEntry
>;

export type VacationsConfigurationUpdatePayload =
  Partial<VacationsConfigurationCreatePayload>;

export type CreateScheduleRule = keyof typeof createScheduleRules;
export type GroupVacationsRule = keyof typeof groupVacationsRules;
export type MembershipType = keyof typeof membershipTypes;

export type RuleConfiguration = {
  id: CreateScheduleRule | GroupVacationsRule;
  enabled: boolean;
};

export type DaysBeforeDSRRuleConfiguration = RuleConfiguration & {
  id:
    | typeof createScheduleRules.daysBeforeDSR
    | typeof groupVacationsRules.daysBeforeDSR;
  days: number;
};

export type MaxPeriodsRuleConfiguration = RuleConfiguration & {
  id: typeof groupVacationsRules.maximumPeriods;
  max: number;
};

export type MinimumDaysRuleConfiguration = RuleConfiguration & {
  id: typeof groupVacationsRules.minimumDays;
  min: number;
};

export type DaysBeforeStartRuleConfiguration = RuleConfiguration & {
  id: typeof createScheduleRules.daysBeforeStart;
  minimum: number;
  maximum: number;
};

export type ExtraDaysForCollectiveHolidaysRule = {
  day: number;
  month: number;
  onlyWeekdays: boolean;
  start: string;
  end: string;
};

export type ExtraDaysForCollectiveHolidaysConfiguration = {
  nonDeductibleDays: ExtraDaysForCollectiveHolidaysRule[];
};

export type ThirteenthAdvanceAllowedMonthsRuleConfiguration =
  RuleConfiguration & {
    id: typeof createScheduleRules.thirteenthAdvanceAllowedMonths;
    monthsAllowedToRequest: Month[];
  };

export type HowManyFractionsMayHaveRuleConfiguration = RuleConfiguration & {
  id: typeof createScheduleRules.howManyFractionsMayHave;
  maximum: number;
};

export const scheduleErrorMessages: {
  [key in CreateScheduleRule]: {
    summary: string;
    description?: string;
  };
} = {
  mayRequestThirteenthAdvance: {
    summary: 'Não pode solicitar adiantamento do 13º salário',
    description: 'Não é permitido solicitar adiantamento do 13º salário',
  },
  maySellVacations: {
    summary: 'Não pode vender férias',
    description: 'Não é permitido vender férias',
  },
  mayScheduleVacations: {
    summary: 'Não pode agendar férias',
    description: 'Não é permitido ao colaborador agendar férias',
  },
  daysAvailable: {
    summary: 'Dias insuficientes',
    description:
      'A quantidade de dias requisitados deve ser menor ou igual a de dias disponíveis',
  },
  daysBeforeDSR: {
    summary: 'Dias antes de DSR',
    description: `As preferencias de sua empresa não permitem começar as férias com essa quantidade de dias antes de um dia de descanso.`,
  },
  daysBeforeStart: {
    summary: 'Pedido de férias com antecedencia menor que permitida',
    description:
      'As preferencias de sua empresa não permitem pedir as férias com essa antecedência',
  },
  mayOnlySellAThirdOfVacations: {
    summary: 'Só pode vender um terço das férias',
    description: 'Só é permitido vender um terço das férias.',
  },
  mayOnlySellVacationsOnce: {
    summary: 'Só pode vender as férias uma vez',
    description: 'Só é permitido vender as férias uma vez.',
  },
  art134AtLeast14Days: {
    summary: 'Uma das férias tem que ter 14 dias',
    description:
      'Art. 134 da CLT: as férias podem ser fracionadas em até 3 períodos. Um deve ter no mínimo 14 dias de duração',
  },
  art134AtLeast5Days: {
    summary: 'Duração mínima de férias é 5 dias',
    description:
      'Art. 134 da CLT: as férias podem ser fracionadas em até 3 períodos. Nenhum período pode ser menor que 5 dias.',
  },
  contractMustBeActive: {
    summary: 'Contrato deve estar ativo durante o período de férias',
    description:
      'As férias não podem ser criadas em período de afastamento ou durante outras férias.',
  },
  thirteenthAdvance: {
    summary: 'Não pode solicitar adiantamento do 13º salário',
    description:
      'Só pode solicitar o adiantamento do 13º se não foi solicitado no ano de início das férias',
  },
  mayNotOverlapVacationsDays: {
    summary: 'Não pode ter duas férias ativas ao mesmo tempo.',
    description: 'Não é permitido ter dois pedidos de férias no mesmo período.',
  },
  mustNotHaveLeaves: {
    summary: 'Não pode ter afastamentos durante o período de férias',
    description: 'Não é permitido ter afastamentos durante o período de férias',
  },
  thirteenthAdvanceAllowedMonths: {
    summary: 'Só pode solicitar adiantamento do 13º em meses específicos',
    description:
      'A configuração da empresa não permite adiantamento de 13º no mês das férias',
  },
  howManyFractionsMayHave: {
    summary: 'Máximo de períodos de férias atingido.',
    description:
      'A quantidade de frações de férias não pode ser maior do que o configurado. \n' +
      'Utilize todos os dias disponíveis, ou procure o RH para mais informações.',
  },
};

export type ApprovalConfiguration = {
  steps: ApprovalStep[];
};

export type ApprovalStep = {
  roles: ApprovalWorkflowV2Roles[];
};

export type ApprovalWorkflowV2Roles =
  | 'membership:owner'
  | 'manager'
  | 'resource:owner'
  | 'system';
