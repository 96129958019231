import { Box } from '@mui/material';

export const DrawerCard = ({
  children,
}: {
  children: React.ReactNode;
  size?: 'regular' | 'large';
}) => {
  return (
    <Box
      px={3}
      py={2.5}
      borderRadius="12px"
      mb={1}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.strokes.light}`,
      })}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      {children}
    </Box>
  );
};
