import { useState } from 'react';

import { Box, Collapse, Divider } from '@mui/material';

import {
  VacationsScheduleSummary,
  useGetVacationsSchedule,
} from '@octopus/api';

import { DrawerCard } from './DrawerCard';
import { VacationSkeletonCard } from './DrawerSkeleton';
import { ScheduleCardBody } from './ScheduleCardBody';
import { ScheduleCardHeader } from './ScheduleCardHeader';
import { ScheduleReview } from './ScheduleReview';

export const ScheduleCard = ({
  summary,
  onSuccess,
  isManager,
}: {
  summary: VacationsScheduleSummary;
  onSuccess?: () => Promise<void>;
  isManager: boolean;
}) => {
  const [expandButtonState, setExpandButtonState] = useState(true);
  const handleExpandButtonClick = () => {
    setExpandButtonState(!expandButtonState);
  };

  const {
    data: vacationSchedule,
    isLoading,
    refetch,
  } = useGetVacationsSchedule({
    pathParams: {
      organizationId: summary.organizationId,
      contractId: summary.contractId,
      sequence: summary.sequence.toString(),
    },
  });

  const refetchData = async () => {
    await Promise.all([refetch(), onSuccess()]);
  };

  if (isLoading) {
    return <VacationSkeletonCard index={summary.sequence.toString()} />;
  }

  return (
    <DrawerCard>
      <Box display="flex" flexDirection="column" width="100%">
        <ScheduleCardHeader
          summary={summary}
          handleExpandButtonClick={handleExpandButtonClick}
          expandButtonState={expandButtonState}
        />

        <Collapse in={expandButtonState}>
          <Divider />

          <ScheduleCardBody summary={summary} />

          <ScheduleReview
            vacationSchedule={vacationSchedule}
            onReview={refetchData}
            isManager={isManager}
          />
        </Collapse>
      </Box>
    </DrawerCard>
  );
};
