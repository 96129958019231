import { useEffect } from 'react';

import { isNil } from 'lodash';

import {
  ContractBRCltEntryDuracao,
  ContractBRCltEntryRegime,
  ContractBRCltEntrySituacao,
} from '@octopus/api';
import { contractStatuses, contractTypes } from '@octopus/contract-types';
import { MotivoAfastamento, TipoContrato } from '@octopus/esocial/mapper';
import {
  calculateDaysDifferenceBetweenDates,
  formatBooleanBR,
  formatDateBR,
  formatDateWithDaysAdded,
  formatNumberOfDays,
} from '@octopus/formatters';

import { StatusBadge } from '../../../../people/person/components/StatusBadge';
import {
  OptionsRecordEntrySelectOption,
  Record,
  RecordEntry,
  mapperToOptions,
} from '../../../Record';
import { BaseRecordProps, booleanOptions } from '../../common';
import { useRecordEdit } from '../../useRecordEdit';

type DetalhesDoContratoData = {
  situacao: ContractBRCltEntrySituacao;
  duracao: ContractBRCltEntryDuracao;
  regime: ContractBRCltEntryRegime;
};

const PROBATION_DAY_OPTIONS = [15, 30, 45, 60, 90];

export type DetalhesDoContratoProps = BaseRecordProps<DetalhesDoContratoData>;

export function DetalhesDoContrato(props: DetalhesDoContratoProps) {
  const { situacao, duracao, regime } = props.data;

  const { editing, formData, updateData, editRecordProps, hasError } =
    useRecordEdit(props);

  const prepareOnChange = (
    key: string,
    formatter: (val: string) => string | number | boolean = (val) => val,
  ) => {
    return (value: string) =>
      updateData((data) => {
        if (key === 'dtPerExpTerm') {
          return {
            ...data,
            duracao: {
              ...data.duracao,
              dtPerExpTerm: value,
              dtTerm: value,
              dtPerExpProrTerm: null,
              clauAssec: data.duracao.clauAssec ?? false,
            },
          };
        }
        if (key === 'dtPerExpProrTerm') {
          return {
            ...data,
            duracao: {
              ...data.duracao,
              dtTerm:
                new Date() > new Date(formData.duracao.dtPerExpTerm)
                  ? value
                  : formData.duracao.dtPerExpTerm,
              dtPerExpProrTerm: value,
              clauAssec: data.duracao.clauAssec ?? false,
            },
          };
        }
        if (key === 'tpContr' && value === 'probation') {
          return {
            ...data,
            duracao: {
              ...data.duracao,
              tpContr: 2,
              dtPerExpTerm: '',
              clauAssec: data.duracao.clauAssec ?? false,
            },
          };
        }
        return {
          ...data,
          duracao: {
            ...data.duracao,
            dtPerExpTerm: key === 'tpContr' ? null : data.duracao.dtPerExpTerm,
            dtPerExpProrTerm:
              key === 'tpContr' ? null : data.duracao.dtPerExpProrTerm,
            clauAssec: data.duracao.clauAssec ?? false,
            [key]: formatter(value),
          },
        };
      });
  };

  const getProbationPeriodOptions = (
    isExtension: boolean,
  ): OptionsRecordEntrySelectOption[] => {
    const { dtAdm } = formData.regime;
    const { dtPerExpTerm } = formData.duracao;

    if (isExtension) {
      const maxDays = 90;
      return PROBATION_DAY_OPTIONS.filter((days) => {
        const totalDays =
          days + calculateDaysDifferenceBetweenDates(dtAdm, dtPerExpTerm);
        return totalDays <= maxDays;
      }).map((days) => ({
        value: formatDateWithDaysAdded(dtPerExpTerm, days),
        label: `${days} dias`,
      }));
    }

    return PROBATION_DAY_OPTIONS.map((days) => ({
      value: formatDateWithDaysAdded(dtAdm, days - 1),
      label: `${days} dias`,
    }));
  };

  useEffect(() => {
    if (formData.duracao.tpContr === 1) {
      updateData((data) => ({
        ...data,
        duracao: {
          ...data.duracao,
          dtTerm: null,
          dtPerExpTerm: null,
          dtPerExpProrTerm: null,
          clauAssec: null,
          objDet: null,
        },
      }));
    }
  }, [formData.duracao.tpContr]);

  const status = (() => {
    switch (situacao.tipo) {
      case 'ativo':
        return contractStatuses.active;
      case 'afastado':
        return contractStatuses.onLeave;
      case 'desligado':
        return contractStatuses.terminated;
      case 'emAdmissao':
        return contractStatuses.onAdmission;
      default:
        return contractStatuses.active;
    }
  })();

  return (
    <Record title="Detalhes do contrato" edit={editRecordProps}>
      <RecordEntry label={'Situação'}>
        <StatusBadge status={status} contractType={contractTypes.brClt} />
      </RecordEntry>
      <RecordEntry
        label={'Data do desligamento'}
        hide={situacao.tipo !== 'desligado'}
      >
        {formatDateBR(
          (
            situacao as Extract<
              ContractBRCltEntrySituacao,
              { tipo: 'desligado' }
            >
          ).dtDeslig,
        )}
      </RecordEntry>
      <RecordEntry
        label={'Início do afastamento'}
        hide={situacao.tipo !== 'afastado'}
      >
        {formatDateBR(
          (
            situacao as Extract<
              ContractBRCltEntrySituacao,
              { tipo: 'afastado' }
            >
          ).dtIniAfast,
        )}
      </RecordEntry>
      <RecordEntry
        label={'Motivo do afastamento'}
        hide={situacao.tipo !== 'afastado'}
      >
        {MotivoAfastamento.getByCode(
          (
            situacao as Extract<
              ContractBRCltEntrySituacao,
              { tipo: 'afastado' }
            >
          ).codMotAfast,
        )}
      </RecordEntry>
      <RecordEntry
        label={'Data da admissão'}
        hide={situacao.tipo !== 'emAdmissao'}
      >
        {formatDateBR(
          (
            situacao as Extract<
              ContractBRCltEntrySituacao,
              { tipo: 'emAdmissao' }
            >
          ).dtAdm,
        )}
      </RecordEntry>
      <RecordEntry
        label="Tipo de contrato"
        edit={{
          editing,
          type: 'options',
          onChange: prepareOnChange('tpContr', parseInt),
          value: isNil(formData.duracao.dtPerExpTerm)
            ? formData.duracao.tpContr
            : 'probation',
          options: [
            ...mapperToOptions({ mapper: TipoContrato }),
            {
              label: 'Prazo determinado, período de experiência',
              value: 'probation',
            },
          ],
          hasError: hasError('br/duracao/tpContr'),
        }}
      >
        {duracao.dtPerExpTerm
          ? 'Prazo determinado, período de experiência'
          : TipoContrato.getByCode(duracao.tpContr)}
      </RecordEntry>
      <RecordEntry
        label="Data do término"
        hide={
          (!editing &&
            (duracao.tpContr === 1 || !isNil(duracao.dtPerExpTerm))) ||
          (editing &&
            (formData.duracao.tpContr === 1 ||
              !isNil(formData.duracao.dtPerExpTerm)))
        }
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value:
            formData.duracao.tpContr === 1
              ? undefined
              : formData.duracao.dtTerm,
          onChange: prepareOnChange('dtTerm'),
          disabled: formData.duracao.tpContr === 1,
          hasError: hasError('br/duracao/dtTerm'),
        }}
      >
        {duracao.dtTerm ? formatDateBR(duracao.dtTerm) : undefined}
      </RecordEntry>
      <RecordEntry
        label="Contém cláusula assecuratória?"
        hide={true}
        edit={{
          type: 'options',
          editing,
          value: 'false',
          onChange: prepareOnChange('clauAssec', (str) => str === 'true'),
          options: booleanOptions,
          disabled: formData.duracao.tpContr === 1,
          hasError: hasError('br/duracao/clauAssec'),
        }}
      >
        {formatBooleanBR(duracao.clauAssec)}
      </RecordEntry>
      <RecordEntry
        label="Objeto razão da contratação temporária"
        hide={
          (!editing &&
            (duracao.tpContr === 1 || !isNil(duracao.dtPerExpTerm))) ||
          (editing &&
            (formData.duracao.tpContr === 1 ||
              !isNil(formData.duracao.dtPerExpTerm)))
        }
        edit={{
          editing,
          type: 'text',
          onChange: prepareOnChange('objDet'),
          value: formData.duracao.objDet,
          disabled: formData.duracao.tpContr === 1,
          hasError: hasError('br/duracao/objDet'),
        }}
      >
        {duracao.objDet}
      </RecordEntry>
      <RecordEntry
        label="Data do início do contrato"
        hide={
          (!editing &&
            (duracao.tpContr === 1 || isNil(duracao.dtPerExpTerm))) ||
          (editing &&
            (formData.duracao.tpContr === 1 ||
              isNil(formData.duracao.dtPerExpTerm)))
        }
        edit={{
          editing,
          type: 'date',
          valueFormat: 'YYYY-MM-DD',
          value: regime.dtAdm,
          onChange: () => ({}),
          disabled: true,
          hasError: false,
        }}
      >
        {regime.dtAdm ? formatDateBR(regime.dtAdm) : undefined}
      </RecordEntry>
      <RecordEntry
        label="Período de experiência"
        description={
          formData.duracao.dtPerExpTerm
            ? `até ${formatDateBR(formData.duracao.dtPerExpTerm)}`
            : ' '
        }
        hide={
          (!editing &&
            (duracao.tpContr === 1 || isNil(duracao.dtPerExpTerm))) ||
          (editing &&
            (formData.duracao.tpContr === 1 ||
              isNil(formData.duracao.dtPerExpTerm)))
        }
        edit={{
          editing,
          type: 'options',
          value: formData.duracao.dtPerExpTerm,
          options: getProbationPeriodOptions(false),
          onChange: prepareOnChange('dtPerExpTerm'),
          disabled:
            calculateDaysDifferenceBetweenDates(
              regime.dtAdm,
              duracao.dtPerExpTerm,
            ) === 90,
          hasError: hasError('br/duracao/dtPerExpTerm'),
        }}
      >
        {duracao.dtPerExpTerm
          ? formatNumberOfDays(
              calculateDaysDifferenceBetweenDates(
                regime.dtAdm,
                duracao.dtPerExpTerm,
              ) + 1,
            )
          : undefined}
      </RecordEntry>
      <RecordEntry
        label="Prorrogação"
        description={
          formData.duracao.dtPerExpProrTerm
            ? `até ${formatDateBR(formData.duracao.dtPerExpProrTerm)}`
            : ' '
        }
        hide={
          (!editing &&
            (duracao.tpContr === 1 ||
              isNil(duracao.dtPerExpProrTerm) ||
              isNil(duracao.dtPerExpTerm))) ||
          (editing &&
            (formData.duracao.tpContr === 1 ||
              isNil(formData.duracao.dtPerExpTerm)))
        }
        edit={{
          editing,
          type: 'options',
          value: formData.duracao.dtPerExpProrTerm,
          options: getProbationPeriodOptions(true),
          onChange: prepareOnChange('dtPerExpProrTerm'),
          disabled:
            calculateDaysDifferenceBetweenDates(
              regime.dtAdm,
              formData.duracao.dtPerExpTerm,
            ) >= 90,
          hasError: hasError('br/duracao/dtPerExpProrTerm'),
        }}
      >
        {duracao.dtPerExpProrTerm
          ? `${formatNumberOfDays(
              calculateDaysDifferenceBetweenDates(
                duracao.dtPerExpTerm,
                duracao.dtPerExpProrTerm,
              ),
            )}`
          : undefined}
      </RecordEntry>
    </Record>
  );
}
