import { AgRichSelect } from 'ag-grid-charts-enterprise';
import type {
  FieldPickerValueSelectedEvent,
  ICellEditor,
  ICellEditorParams,
  RichCellEditorParams,
  RichSelectParams,
} from 'ag-grid-community';
import { PopupComponent, _missing, _warnOnce } from 'ag-grid-community';

import { ContractSummary } from '@octopus/api';

import { PayrollEmployeeData } from '../../[period]/[type]/inputs/types';

export class InputNameCellEditor<TData = PayrollEmployeeData, TValue = string>
  extends PopupComponent
  implements ICellEditor<TValue>
{
  private params: RichCellEditorParams<TData, TValue>;
  private focusAfterAttached: boolean;
  private richSelect: AgRichSelect<TValue>;
  private onSearch: (searchText: string) => void;
  private searchTimeout: number | null = null;
  onSearchComplete: (editor: InputNameCellEditor<TData, TValue>) => void;

  constructor() {
    super(/* html */ `<div class="ag-cell-edit-wrapper"></div>`);
  }

  private debounceSearch(searchText: string, delay: number) {
    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = window.setTimeout(() => {
      this.onSearch(searchText);
    }, delay);
  }

  private inputListener = (e: Event) => {
    const searchText = (e.target as HTMLInputElement).value;
    this.debounceSearch(searchText, this.params.searchDebounceDelay || 300);
    if (this.onSearchComplete) {
      this.onSearchComplete(this);
    }
  };

  public init(
    params: RichCellEditorParams<TData, TValue> & {
      onSearch: (searchText: string) => void;
      onSearchComplete?: (editor: InputNameCellEditor<TData, TValue>) => void;
    },
  ): void {
    this.params = params;

    const { cellStartedEdit, values, onSearch, onSearchComplete } = params;
    this.onSearch = onSearch;
    this.onSearchComplete = onSearchComplete;
    if (_missing(values)) {
      _warnOnce('missing values');
    }

    const { params: richSelectParams } = this.buildRichSelectParams();

    const richSelect = this.createManagedBean(
      new AgRichSelect<TValue>(richSelectParams),
    );
    this.richSelect = richSelect;
    richSelect.addCssClass('ag-cell-editor');
    this.appendChild(richSelect);

    const inputElement = richSelect.getFocusableElement() as HTMLInputElement;
    inputElement.addEventListener('input', this.inputListener);

    this.addManagedListeners(richSelect, {
      fieldPickerValueSelected: this.onEditorPickerValueSelected.bind(this),
    });
    this.focusAfterAttached = cellStartedEdit;
  }

  private onEditorPickerValueSelected(e: FieldPickerValueSelectedEvent): void {
    // there is an issue with focus handling when we call `stopEditing` while the
    // picker list is still collapsing, so we make this call async to guarantee that.
    setTimeout(() => this.params.stopEditing(!e.fromEnterKey));
  }

  private buildRichSelectParams(): {
    params: RichSelectParams<TValue>;
  } {
    const params = this.params;
    const {
      cellRenderer,
      cellHeight,
      value,
      values,
      formatValue,
      searchDebounceDelay = 300,
      valueListGap,
      valueListMaxHeight,
      valueListMaxWidth,
      searchType,
      highlightMatch,
      valuePlaceholder,
      multiSelect,
      suppressDeselectAll,
      suppressMultiSelectPillRenderer,
    } = params;

    const ret: RichSelectParams = {
      value: value,
      cellRenderer,
      cellRowHeight: cellHeight,
      searchDebounceDelay,
      valueFormatter: formatValue,
      pickerAriaLabelKey: 'ariaLabelRichSelectField',
      pickerAriaLabelValue: 'Rich Select Field',
      pickerType: 'virtual-list',
      pickerGap: valueListGap,
      allowTyping: true,
      filterList: false,
      searchType,
      highlightMatch,
      maxPickerHeight: valueListMaxHeight,
      maxPickerWidth: valueListMaxWidth,
      placeholder: valuePlaceholder || 'Procurar...',
      initialInputValue: value ? String(value) : '',
      multiSelect,
      suppressDeselectAll,
      suppressMultiSelectPillRenderer,
    };

    let valuesResult;

    if (typeof values === 'function') {
      valuesResult = values(params as ICellEditorParams);
    } else {
      valuesResult = values ?? [];
    }

    if (Array.isArray(valuesResult)) {
      ret.valueList = valuesResult;
    }

    return { params: ret };
  }

  // we need to have the gui attached before we can draw the virtual rows, as the
  // virtual row logic needs info about the gui state
  public afterGuiAttached(): void {
    const { focusAfterAttached } = this;

    setTimeout(() => {
      if (!this.isAlive()) {
        return;
      }

      const richSelect = this.richSelect;
      const focusableEl = richSelect.getFocusableElement() as HTMLInputElement;

      if (focusAfterAttached) {
        focusableEl.focus();
        focusableEl.select();
      }

      richSelect.showPicker();
    });
  }

  public focusIn(): void {
    this.richSelect.getFocusableElement().focus();
  }

  public getValue(): any {
    return this.richSelect.getValue();
  }

  public override isPopup(): boolean {
    return false;
  }

  public override destroy(): void {
    if (this.searchTimeout) {
      window.clearTimeout(this.searchTimeout);
    }
    super.destroy();
  }

  public updateValues(newValues: ContractSummary[]) {
    if (this.richSelect) {
      this.richSelect.setValueList({
        valueList: newValues.map((value) => value) as TValue[],
        refresh: true,
      });
    }
  }
}
