import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { VacationStatusTag } from '../Status';

import { VacationsScheduleActions } from './ScheduleActions';
import { VacationsScheduleReviews } from './ScheduleReviews';

export const scheduleReviewsColumn: GridColDef = {
  sortable: false,
  field: 'vacationsApprovals',
  headerName: 'Aprovações',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row;
  },
  renderCell: ({ value }) => {
    if (!value.approval) return null;
    return (
      <VacationsScheduleReviews
        pendingSteps={value.approval?.pendingSteps}
        status={value.approval?.status}
        steps={value.approval?.steps}
        totalSteps={value.approval?.totalSteps}
        finishedSteps={value.approval?.finishedSteps}
      />
    );
  },
};

export const scheduleActionsColumn = (
  isManager: boolean,
  refetchAll: () => Promise<void>,
) => {
  return {
    sortable: true,
    field: 'reviewActions',
    headerName: 'Aprovação',
    renderCell: ({ row }) => {
      return (
        <VacationsScheduleActions
          row={row}
          isManager={isManager}
          onApproval={refetchAll}
        />
      );
    },
  } as GridColDef;
};

export const scheduleStatusColumn: GridColDef = {
  sortable: false,
  field: 'vacationsScheduleStatus',
  headerName: 'Status férias',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row;
  },
  renderCell: ({ value }) => {
    return <VacationStatusTag summary={value} />;
  },
};
