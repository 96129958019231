import { IconAlertTriangleFilled } from '@tabler/icons-react';

import { Box, Typography, useTheme } from '@mui/material';

import { usePopover } from '../../hooks/usePopover';

export const AlertWithPopover = ({
  value,
  variant,
  popoverContent,
}: {
  value: string;
  variant: 'warning' | 'error' | null;
  popoverContent: string;
}) => {
  const theme = useTheme();
  const [WithPopover, handlePopoverOpen, handlePopoverClose] = usePopover();
  const withStyles = variant
    ? {
        color: theme.palette[variant].main,
      }
    : undefined;

  return (
    <Box
      display="flex"
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography sx={withStyles} variant="body2">
        {value || '-'}
      </Typography>
      {variant && (
        <>
          <Box ml={1} display="flex" alignItems="center">
            <IconAlertTriangleFilled
              size={20}
              style={{
                color:
                  variant === 'error'
                    ? theme.palette.error.lowEmphasis
                    : theme.palette.warning.main,
              }}
            />
          </Box>
          <WithPopover>{popoverContent}</WithPopover>
        </>
      )}
    </Box>
  );
};
