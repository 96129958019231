export function getRUMInfo():
  | {
      userId: string | undefined;
      sessionId: string | undefined;
    }
  | undefined {
  try {
    const userId = getCookie('cwr_u');
    const sessionCookie = getCookie('cwr_s');
    if (sessionCookie) {
      try {
        const { sessionId } = JSON.parse(atob(sessionCookie));
        return {
          userId,
          sessionId,
        };
      } catch (err) {
        console.error('Failed to parse RUM session id', err);
      }
    }
    return {
      userId,
      sessionId: undefined,
    };
  } catch (err) {
    console.error('Failed to parse RUM cookies', err);
  }
  return undefined;
}

function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return undefined;
}
