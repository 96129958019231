import { IconCalendarTime } from '@tabler/icons-react';

import { Box, Typography } from '@mui/material';

import { usePopover } from '../../hooks/usePopover';

export const PayrollSchedule = ({ value }: { value: string }) => {
  const [WithPopover, handlePopoverOpen, handlePopoverClose] = usePopover();

  if (!value) return null;

  return (
    <Box
      display="flex"
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      alignItems="center"
      color="#BABABF"
    >
      <Typography mb={0.5} color="#BABABF" variant="body2">
        {value || '-'}
      </Typography>
      <Box ml={2}>
        <IconCalendarTime size={20} />
      </Box>
      <WithPopover>{'Programado para esta data'}</WithPopover>
    </Box>
  );
};
