import { Submission } from '@conform-to/react';
import { Schema, z } from 'zod';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { PayrollEntry } from '@octopus/api';
import { UI_TYPE } from '@octopus/libs/forms';
import { Button } from '@octopus/ui/design-system';

import { Form } from '../../../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../../../modules/form/useFormFromDefinition';

export function ChangePaymentDateModal({
  open,
  isSubmitting,
  summary,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  isSubmitting: boolean;
  summary: PayrollEntry;
  onCancel: () => void;
  onConfirm: (_: any, formData: Submission<Schema, string[], Schema>) => void;
}) {
  const formDefinition = useFormFromDefinition(
    [
      {
        label: 'Data de pagamento',
        type: z.string(),
        name: 'paymentDate',
        value: summary.paymentDate,
        uiType: UI_TYPE.TEXT_DATE_PICKER,
      },
    ],
    {
      id: 'paymentDateForm',
      onSubmit: onConfirm,
      persistLocal: false,
    },
  );

  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      data-testid="confirm-vacations-modal"
    >
      <DialogTitle mt={3}>
        <Typography fontWeight={700} fontSize="32px">
          Data do pagamento
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Typography fontWeight={500} fontSize="16px" color="allVariants">
            Para calcular é necessário confirmar o dia do pagamento. A data
            abaixo segue a quantidade de dias de antecedência definido pela sua
            empresa, altere somente caso necessário.
          </Typography>
        </Box>
        <Box mt={3}>
          <Form
            metaForm={formDefinition.metaForm}
            payloadForm={formDefinition.payloadForm}
          >
            <Form.Field for={formDefinition.fields.paymentDate} />
          </Form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variantSemantic="secondary">
          Cancelar
        </Button>
        <Button isLoading={isSubmitting} type="submit" form={formDefinition.id}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
