export function downloadFile(filePath: string) {
  const tempAnchor = document.createElement('A');
  // @ts-expect-error this is on purpose
  tempAnchor.href = filePath;
  // @ts-expect-error this is on purpose
  tempAnchor.download = filePath.substr(filePath.lastIndexOf('/') + 1);

  document.body.appendChild(tempAnchor);
  tempAnchor.click();
  document.body.removeChild(tempAnchor);
}
