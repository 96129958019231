import { ProcessHeaderForExportParams } from 'ag-grid-community';
import { ProcessCellForExportParams } from 'ag-grid-community/dist/types/core/interfaces/exportParams';

import { Alert, InputAdornment, InputProps, Typography } from '@mui/material';

import { PayrollInputsConfig, PayrollInputsPayloadTypes } from '@octopus/api';
import { formatDecimal } from '@octopus/formatters';

import { DEPENDENT_COLUMN_ID, EMPLOYEE_COLUMN_ID } from './types';

export function copyHeader(params: ProcessHeaderForExportParams): string {
  const colId = params.column.getColId();
  if (colId === EMPLOYEE_COLUMN_ID) {
    return `Colaborador\tMatrícula`;
  }
  if (colId === DEPENDENT_COLUMN_ID) {
    return `Colaborador\tDependente\tTipo de Dependente`;
  }
  return params.column.getColDef().headerName;
}

export function copyCell(
  params: ProcessCellForExportParams,
  inputsConfig: PayrollInputsConfig,
): string {
  if (!params.value) {
    return '';
  }
  const colId = params.column.getColId();
  if (colId === EMPLOYEE_COLUMN_ID || colId === DEPENDENT_COLUMN_ID) {
    return params.value;
  }
  const payrollConfig = inputsConfig.payload[colId];
  switch (payrollConfig?.payloadType) {
    case 'currency':
      return formatDecimal(params.value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    case 'number':
      return formatDecimal(params.value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 6,
      });
    case 'percentage':
      return formatDecimal(parseFloat(params.value), {
        maximumFractionDigits: 6,
      });
    default:
      return params.value;
  }
}

export function pasteCell(
  params: ProcessCellForExportParams,
  inputsConfig: PayrollInputsConfig,
) {
  const value: string = params.value;
  if (!value) {
    return null;
  }
  const payrollConfig = inputsConfig.payload[params.column.getColId()];
  switch (payrollConfig?.payloadType) {
    case 'currency':
    case 'number':
    case 'percentage':
      return value.replace(/[^\d,]/g, '').replace(/,/g, '.');
    case 'hours': {
      const parsedValue = value.replace(/[^\d:]/g, '');
      if (!parsedValue) {
        return null;
      }
      const split = parsedValue.split(':');
      if (split.length === 1) {
        return `${split[0]}:00:00`;
      } else if (split.length === 2) {
        return `${split[0]}:${split[1]}:00`;
      } else {
        return `${split[0]}:${split[1]}:${split[2]}`;
      }
    }
    default:
      return params.value;
  }
}

export function cellValueEquals(
  val1: string | undefined,
  val2: string | undefined,
  payloadType: PayrollInputsPayloadTypes | undefined,
) {
  if (!val1 && !val2) {
    return true;
  }
  switch (payloadType) {
    case 'currency':
    case 'percentage':
    case 'number':
      return parseFloat(val1) === parseFloat(val2);
    case 'hours':
      return parseHours(val1) === parseHours(val2);
    case 'days': {
      const days1 = val1.split(',');
      const days2 = val2.split(',');
      if (days1.length !== days2.length) {
        return false;
      }
      return !days1.some((day) => !days2.includes(day));
    }
    default:
      return val1 === val2;
  }
}

export function parseHours(value: string) {
  if (!value) {
    return 0;
  }
  const [hours, minutes] = value.split(':');
  return parseInt(hours) * 60 + parseInt(minutes ?? '0');
}

const CURRENCY_REGEX = /^\d*(,\d?\d?)?$/;
const HOURS_REGEX = /^\d*(:\d?\d?)?$/;
const PERCENTAGE_REGEX = /^\d*(,\d*)?$/;
const NUMBER_REGEX = /^\d*(,\d?\d?\d?\d?\d?\d?)?$/;
const ANY_REGEX = /^.*/;

export function getRegexForPayloadType(
  payloadType: PayrollInputsPayloadTypes,
): RegExp {
  switch (payloadType) {
    case 'currency':
      return CURRENCY_REGEX;
    case 'hours':
      return HOURS_REGEX;
    case 'percentage':
      return PERCENTAGE_REGEX;
    case 'number':
      return NUMBER_REGEX;
    default:
      return ANY_REGEX;
  }
}

export function getPlaceholderForPayloadType(
  payloadType: PayrollInputsPayloadTypes,
): string {
  switch (payloadType) {
    case 'currency':
      return '0,00';
    case 'hours':
      return '0:00';
    case 'percentage':
      return '0,00';
    case 'number':
      return '0,00';
    case 'date':
      return 'dd/mm/aaaa';
    default:
      return 'Valor';
  }
}

export function getInputPropsForPayloadType(
  payloadType: PayrollInputsPayloadTypes,
): Partial<InputProps> {
  switch (payloadType) {
    case 'currency':
      return {
        startAdornment: (
          <InputAdornment position="start">
            <Typography variant="body2" color="strokes.heavy">
              R$
            </Typography>
          </InputAdornment>
        ),
        sx: {
          '& input': {
            px: 0,
            fontWeight: 500,
          },
        },
      };
    case 'hours':
      return {
        endAdornment: (
          <InputAdornment position="start">
            <Typography variant="body2" color="strokes.heavy">
              h:m
            </Typography>
          </InputAdornment>
        ),
      };
    case 'percentage':
      return {
        endAdornment: (
          <InputAdornment position="start">
            <Typography variant="body2" color="strokes.heavy">
              %
            </Typography>
          </InputAdornment>
        ),
      };
    case 'date':
      return {
        placeholder: 'dd/mm/aaaa',
      };
    default:
      return {};
  }
}

export function parseValueForPayloadType(
  value: string | undefined,
  type: PayrollInputsPayloadTypes,
) {
  if (!value) return undefined;
  switch (type) {
    case 'currency':
    case 'number': {
      const parsedValue = value.replace(/\./g, '').replace(/,/g, '.');
      if (parsedValue.endsWith('.')) {
        return parsedValue.slice(0, -1);
      }
      return parsedValue;
    }
    case 'percentage': {
      const parsedValue = value.replace(/\./g, '').replace(/,/g, '.');
      if (parsedValue.endsWith('.')) {
        return parsedValue.slice(0, -1);
      }
      return `${Number(parsedValue) / 100}`;
    }
    case 'hours': {
      if (!value.includes(':')) {
        return `${value}:00:00`;
      } else {
        if (value.endsWith(':')) {
          return `${value}00:00`;
        } else if (value.endsWith(':', value.length - 1)) {
          return `${value}0:00`;
        } else {
          return `${value}:00`;
        }
      }
    }
    case 'date': {
      const dateMatch = value.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
      if (dateMatch) {
        return `${dateMatch[3]}-${dateMatch[2]}-${dateMatch[1]}`;
      }
      return value;
    }
    default:
      return value;
  }
}

export function NoRowsOverlay() {
  return (
    <Alert severity="warning">
      Nenhum lançamento encontrado para esse período
    </Alert>
  );
}
