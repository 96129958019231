import { SvgIconComponent } from '@mui/icons-material';
import { createSvgIcon } from '@mui/material';

export function PaymentRequestIcon(classNames: string): SvgIconComponent {
  return createSvgIcon(
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames}
    >
      <g id="Glyphs/Outlined/request_payment_pj">
        <g id="vector">
          <path
            d="M30.3169 3.85352V6.18628H33.9615V9.31128H26.5657C26.4506 9.31128 26.3063 9.36025 26.175 9.50461C26.0393 9.65392 25.9407 9.88574 25.9407 10.155C25.9407 10.4243 26.0393 10.6561 26.175 10.8055C26.3063 10.9498 26.4506 10.9988 26.5657 10.9988H30.9407C31.9859 10.9988 32.9544 11.4568 33.6436 12.215C34.3284 12.9682 34.6907 13.9605 34.6907 14.9675C34.6907 15.9746 34.3284 16.9669 33.6436 17.7201C32.9544 18.4782 31.9859 18.9363 30.9407 18.9363H30.3169V21.5618H27.1919V18.9363H23.5448V15.8113H30.9407C31.0558 15.8113 31.2001 15.7623 31.3313 15.618C31.4671 15.4686 31.5657 15.2368 31.5657 14.9675C31.5657 14.6982 31.4671 14.4664 31.3313 14.3171C31.2001 14.1727 31.0558 14.1238 30.9407 14.1238H26.5657C25.5205 14.1238 24.552 13.6657 23.8627 12.9076C23.178 12.1544 22.8157 11.1621 22.8157 10.155C22.8157 9.14797 23.178 8.15571 23.8627 7.4025C24.552 6.64434 25.5205 6.18628 26.5657 6.18628H27.1919V3.85352H30.3169Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.33594 13.7327V15.7959H16.3329C17.907 15.795 19.4172 16.4192 20.5313 17.5313L23.3784 20.3784C24.2184 21.2184 24.6903 22.3577 24.6903 23.5456C24.6903 23.8867 24.6514 24.2237 24.5761 24.5508L29.6166 24.1535L29.6394 24.1518L29.6591 24.1507C30.7588 24.0941 31.8383 24.4612 32.6756 25.1763C33.5129 25.8914 34.0443 26.9003 34.1604 27.9952C34.2765 29.0902 33.9686 30.1881 33.2999 31.0629C32.633 31.9355 31.6579 32.52 30.5742 32.6971L20.1261 34.4899C19.1961 34.6503 18.2412 34.5867 17.3407 34.3044C16.4402 34.0221 15.62 33.5292 14.948 32.8665L14.9402 32.8588L9.65019 27.5688C9.52394 27.4423 9.37303 27.3412 9.20787 27.273C9.04271 27.2048 8.86568 27.17 8.68699 27.1705L8.68234 27.1705H7.33594V29.232H4.21094V13.7327H7.33594ZM7.33594 24.0455V18.9209L16.3348 18.9209C17.0803 18.9205 17.7954 19.216 18.3231 19.7425L21.1687 22.5881C21.4226 22.842 21.5653 23.1865 21.5653 23.5456C21.5653 23.9048 21.4226 24.2492 21.1687 24.5032C20.9772 24.6946 20.7343 24.8228 20.4725 24.8743L20.1613 24.8989C19.8202 24.8863 19.4958 24.7454 19.2536 24.5032L15.5707 20.8203L13.361 23.03L17.0439 26.7129C17.8839 27.5529 19.0232 28.0248 20.2111 28.0248C20.4248 28.0248 20.6369 28.0095 20.846 27.9796L29.8365 27.2708C30.1319 27.2599 30.421 27.3603 30.6461 27.5526C30.8754 27.7485 31.021 28.0248 31.0528 28.3248C31.0846 28.6248 31.0003 28.9256 30.8171 29.1652C30.6339 29.4049 30.3658 29.5652 30.068 29.6132L19.5963 31.4102L19.595 31.4104C19.1544 31.4864 18.7021 31.4562 18.2756 31.3225C17.8506 31.1893 17.4633 30.957 17.1456 30.6448L11.8608 25.3599C11.4432 24.9417 10.9469 24.6102 10.4006 24.3846C9.85512 24.1594 9.27051 24.0441 8.68037 24.0455L8.67769 24.0455L7.33594 24.0455Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>,
    'PJIcon',
  );
}
