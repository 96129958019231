import React from 'react';

import { Checklist, FilePresent, UploadFile } from '@mui/icons-material';
import { Alert, Box, Container, Paper, Stack, Typography } from '@mui/material';

import { BackButton } from '../../modules/components/BackButton';
import DirectDownloadButton from '../../modules/components/spreadsheets/DirectDownloadButton';
import LatestUploaded from '../../modules/components/spreadsheets/LatestUploaded';
import OverrideReportingDownload from '../../modules/components/spreadsheets/OverrideReportingDownload';
import PayrollDownload from '../../modules/components/spreadsheets/PayrollDownload';
import UploadSpreadsheet from '../../modules/components/spreadsheets/UploadSpreadsheet';

function Page({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  const [error, setError] = React.useState(false);

  return (
    <Container maxWidth="md">
      <Typography variant="h1">Planilhas</Typography>

      <Box pt={4} pb={4}>
        <Paper elevation={1} sx={{ backgroundColor: 'white' }}>
          <BackButton />

          <Box py={4} px={4}>
            <Typography variant="h2" pb={4}>
              <FilePresent fontSize="large" color="primary" />
              Download
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                Ocorreu um erro ao fazer o download da planilha, tente novamente
                ou se o erro persistir, entre em contato com nosso suporte.
              </Alert>
            )}

            <Stack spacing={2} direction="row">
              <DirectDownloadButton
                organizationId={organizationId}
                setError={setError}
                type="contracts"
                label="Contratos - Ativos"
              />

              <DirectDownloadButton
                organizationId={organizationId}
                setError={setError}
                type="contracts"
                label="Contratos - Todos"
                params={{ includeTerminated: 'true' }}
              />

              <DirectDownloadButton
                organizationId={organizationId}
                setError={setError}
                type="pjContracts"
                label="Contratos PJ"
              />

              <DirectDownloadButton
                organizationId={organizationId}
                setError={setError}
                type="internalTransfer"
                label="Transferências Internas"
              />
            </Stack>

            <Stack spacing={2} direction="row" mt={2}>
              <DirectDownloadButton
                organizationId={organizationId}
                setError={setError}
                type="historicalEvents"
                label="Eventos Históricos"
              />
            </Stack>

            <Stack spacing={2} direction="row" mt={2}>
              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="payroll"
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="rpa"
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="advance"
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="complementary"
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="complementaryTermination"
              />
            </Stack>

            <Stack spacing={2} direction="row" mt={2}>
              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="termination"
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="thirteenth"
              />

              <DirectDownloadButton
                organizationId={organizationId}
                setError={setError}
                type="provisionsTransfers"
                label="Provisões - Transferências"
              />
            </Stack>

            <Stack spacing={2} direction="row" mt={2}>
              <OverrideReportingDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="metadadosDeRubricas"
              />

              <DirectDownloadButton
                organizationId={organizationId}
                setError={setError}
                type="payrollRecurringEvents"
                label="Lançamentos recorrentes"
              />
            </Stack>
          </Box>
        </Paper>
      </Box>

      <Box pt={2} pb={4}>
        <Paper elevation={1} sx={{ backgroundColor: 'white' }}>
          <Box py={4} px={4}>
            <Typography variant="h2">
              <UploadFile fontSize="large" color="primary" />
              Upload
            </Typography>

            <UploadSpreadsheet organizationId={organizationId} />
          </Box>
        </Paper>
      </Box>

      <Box pt={2} pb={6}>
        <Paper elevation={1} sx={{ backgroundColor: 'white' }}>
          <Box py={4} px={4}>
            <Typography variant="h2">
              <Checklist fontSize="large" color="primary" />
              Últimos envios
            </Typography>

            <LatestUploaded organizationId={organizationId} />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default Page;
