import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  AdmissionDraftAttachmentList,
  AdmissionDraftEntry,
  useGetAdmissionDraft,
  useGetAllAdmissionDraftAttachments,
} from '@octopus/api';

import { BackButton } from '../../../modules/components/BackButton';
import { PageAlert } from '../../../modules/components/PageAlert';
import { CompanyContext } from '../../../modules/types';
import { LoadingScene } from '../components/AdmissionDraftLoadingScene';
import { AdmissionReviewDocumentsType } from '../utils/types';

import { AdmissionReviewDocuments } from './AdmissionReviewDocuments';

type Props = {
  organizationId: string;
  companyId: string;
  companyContext: CompanyContext;
};

const isValidAdmission = (admissionDraft: AdmissionDraftEntry): boolean =>
  admissionDraft?.draftStatus != null &&
  admissionDraft?.draftStatus === 'admission_submitted';

export function AdmissionDraftReviewPage({
  organizationId,
  companyId,
  companyContext,
}: Props) {
  const { draftId } = useParams<{ draftId: string }>();
  const [admissionDraft, setAdmissionDraft] = useState<AdmissionDraftEntry>();
  const [attachments, setAttachments] =
    useState<AdmissionDraftAttachmentList>();
  const [files, setFiles] = useState<AdmissionReviewDocumentsType>();

  const admissionDraftQuery = useGetAdmissionDraft(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        draftId: draftId ?? '',
      },
    },
    {
      queryHash: `admission-draft-review-${organizationId}-${draftId}`,
      staleTime: 1,
      enabled:
        !isValidAdmission(admissionDraft) ||
        (!!organizationId &&
          !!draftId &&
          (!admissionDraft || draftId !== admissionDraft?.draftId)),
      refetchInterval: () => (isValidAdmission(admissionDraft) ? null : 1000),
    },
  );
  const { error, data, isLoading, isFetching } = admissionDraftQuery;

  const attachmentsQuery = useGetAllAdmissionDraftAttachments(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        draftId: draftId ?? '',
      },
    },
    {
      enabled:
        !isValidAdmission(admissionDraft) ||
        (!!organizationId && !!draftId && !attachments),
      refetchInterval: () => (isValidAdmission(admissionDraft) ? null : 1000),
    },
  );

  const { data: attachmentsData, isLoading: isLoadingAttachments } =
    attachmentsQuery;

  useEffect(() => {
    if (data != null && admissionDraft == null && isValidAdmission(data)) {
      setAdmissionDraft(data);
    }
  }, [data, admissionDraft, draftId]);

  useEffect(() => {
    const fetchAttachmentFiles = async () => {
      if (attachmentsData != null && files == null) {
        setAttachments(attachmentsData);

        const promises = attachmentsData.map(async (attachment) =>
          fetch(attachment.downloadUrl).then((response) => {
            return response.blob().then((fileBlob) => {
              return {
                step: attachment.formStep,
                dependentId: attachment.dependentId,
                file: new File([fileBlob], attachment.fileName, {
                  type: attachment.contentType,
                }),
              };
            });
          }),
        );

        const donwloadedFiles = await Promise.all(promises);
        const fileDict = donwloadedFiles.reduce(
          (acc, { step, file, dependentId }) => {
            const stepFiles = acc[step] ?? [];
            stepFiles.push({ dependentId, file });
            return { ...acc, [step]: stepFiles };
          },
          {} as AdmissionReviewDocumentsType,
        );

        setFiles(fileDict);
      }
    };

    fetchAttachmentFiles();
  }, [attachmentsData, files]);

  if (
    isLoading ||
    isFetching ||
    isLoadingAttachments ||
    !isValidAdmission(admissionDraft)
  ) {
    return <LoadingScene />;
  }

  if (!isLoading && !isFetching && (error || admissionDraft == null)) {
    console.error(
      `Failed to load admission: ${draftId}. Error: ${JSON.stringify(error)}`,
    );
    return (
      <>
        <BackButton destination="/admissions" />
        <PageAlert
          message="Modo Revisão: Erro ao carregar dados da admissão, por favor atualize essa página para tentar novamente."
          severity="error"
          showRetryMessage={true}
        />
      </>
    );
  }

  return (
    <AdmissionReviewDocuments
      organizationId={organizationId}
      companyId={companyId}
      companyContext={companyContext}
      admissionDraftEntry={admissionDraft}
      draftId={draftId}
      files={files}
    />
  );
}
