import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { IconChevronDown } from '@tabler/icons-react';

import {
  Box,
  Button,
  Container,
  Drawer,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';

import { PayrollStatus, PayrollSummary, PayrollTypes } from '@octopus/api';
import {
  DataGridToolbar,
  FilterOptions,
  makeMoneyRangeFilter,
  makeYearMonthPickerFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { AutonomoReceiptIcon } from '../../modules/components/autonomos/icon';
import EnableMoreActionsPopover from '../../modules/components/payrolls/moreActionsPopover';
import { PayslipDetailsDrawer } from '../../modules/components/payrolls/PayslipDetails';
import { PeriodFormat } from '../../modules/types';

import { SubmissionToast } from './[period]/[type]/SubmissionToast';
import { RpaDetails } from './rpa/RpaDetails';
import { SelectPeriodDialog } from './rpa/RpaDialogs';
import { RpaTable } from './rpa/RpaTable';

const rpaGroupsConfig = {
  open: { color: 'primary', label: 'Abertos' },
  approved: {
    color: 'primary',
    label: 'Aprovados',
  },
  closed: { color: 'success', label: 'Fechados' },
};

export default function RPAPage({
  organizationId,
  companyId,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
}) {
  const RPA_TYPE = 'rpa';
  const [tab, setTab] = useState<keyof typeof rpaGroupsConfig>('open');
  const [countByStatus] = useState<{
    [key in keyof typeof rpaGroupsConfig]: number;
  }>({
    open: 0,
    approved: 0,
    closed: 0,
  });
  const [enableMoreActionsPopoverOpen, setEnableMoreActionsPopoverOpen] =
    useState(false);
  const [showSelectPeriodDialog, setShowSelectPeriodDialog] = useState(false);
  const [selectPeriodDialogMode, setSelectPeriodDialogMode] = useState<
    'create' | 'edit' | 'approve'
  >('create');
  const [popoverAnchorEl, setPopOverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const moreActionsState: {
    enableMoreActionsPopoverOpen: boolean;
    setEnableMoreActionsPopoverOpen: (value: boolean) => void;
    popoverAnchorEl: HTMLButtonElement | null;
    setPopOverAnchorEl: (value: HTMLButtonElement | null) => void;
  } = {
    enableMoreActionsPopoverOpen,
    setEnableMoreActionsPopoverOpen,
    popoverAnchorEl,
    setPopOverAnchorEl,
  };

  const moreActions = [
    {
      label: 'Editar pagamentos em aberto',
      disabled: false,
      onClick: () => {
        setShowSelectPeriodDialog(true);
        setSelectPeriodDialogMode('edit');
      },
    },
    {
      label: 'Aprovar pagamentos em aberto',
      disabled: false,
      onClick: () => {
        setShowSelectPeriodDialog(true);
        setSelectPeriodDialogMode('approve');
      },
    },
    {
      label: 'Enviar RPAs não eviados',
      disabled: true,
    },
  ];

  const filters = useFilters();
  const { filteringProps, searchProps } = useDataGrid({
    filters,
  });

  if (!organizationId || !companyId) {
    return (
      <Typography variant="h1">
        Selecione uma organização e companhia
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Toolbar />
      <Container>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="autonomo-page-header"
          mb={5}
        >
          <Box
            component={AutonomoReceiptIcon()}
            sx={{
              p: 0,
              height: '40px',
              width: '40px',
              mr: 1.5,
            }}
          />
          <Typography variant="h1">Pagamentos a autônomos</Typography>
        </Box>
        <Box pt={5}>
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            searchPlaceholder="Procurar"
          >
            <Box
              sx={{
                alignContent: 'end',
                flex: 'end',
                display: 'flex',
                gap: 1,
                justifyContent: 'end',
                width: '100%',
              }}
            >
              <Button
                color={'primaryAlt'}
                sx={{
                  display: 'flex',
                  width: 160,
                  height: 40,
                  p: 1,
                  m: 0,
                }}
                onClick={() => {
                  setSelectPeriodDialogMode('create');
                  setShowSelectPeriodDialog(true);
                }}
              >
                <Typography
                  variant={'body2'}
                  color="secondary"
                  fontWeight={400}
                >
                  Criar Pagamentos
                </Typography>
              </Button>
              <Button
                color="secondary"
                sx={{
                  display: 'flex',
                  width: 130,
                  height: 40,
                  p: 1,
                  m: 0,
                  gap: 1,
                }}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  moreActionsState.setPopOverAnchorEl(event.currentTarget);
                  moreActionsState.setEnableMoreActionsPopoverOpen(true);
                }}
              >
                <Typography
                  variant={'body2'}
                  color="primaryAlt"
                  fontWeight={400}
                >
                  Mais ações
                </Typography>
                <IconChevronDown height={'20px'} width={'20px'} />
              </Button>
            </Box>
            <EnableMoreActionsPopover
              moreActionsProps={moreActionsState}
              actions={moreActions}
            />
            <SelectPeriodDialog
              open={showSelectPeriodDialog}
              setOpen={setShowSelectPeriodDialog}
              organizationId={organizationId}
              companyId={companyId}
              mode={selectPeriodDialogMode}
            />
          </DataGridToolbar>
          <Box
            my={2}
            sx={(theme) => ({
              boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
            })}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              value={tab}
              onChange={(_, newTab) => setTab(newTab)}
              textColor="inherit"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: `${rpaGroupsConfig[tab].color}.main`,
                },
              }}
              data-testid="rpa-period-tabs"
            >
              {Object.entries(rpaGroupsConfig).map(
                ([groupName, groupConfig]) => (
                  <Tab
                    key={groupName}
                    value={groupName}
                    icon={
                      <PeriodTabLabel
                        isSelected={groupName === tab}
                        config={groupConfig}
                        count={
                          countByStatus[
                            groupName as keyof typeof rpaGroupsConfig
                          ]
                        }
                      />
                    }
                    data-testid={`rpas-tab-${groupName}`}
                  />
                ),
              )}
            </Tabs>
          </Box>
          <Payrolls
            organizationId={organizationId}
            companyId={companyId}
            type={RPA_TYPE}
            selectedTab={tab}
          />
          <SubmissionToast successMessage="Pagamentos criados com sucesso" />
        </Box>
      </Container>
    </Box>
  );
}

function useFilters(): FilterOptions {
  return [
    makeYearMonthPickerFilter({
      label: 'Competência',
      propertyToFilter: 'period',
    }),
    makeMoneyRangeFilter({
      label: 'Valor líquido',
      propertyToFilter: 'calculationTotals.netPay',
      getRangeMin: () => 0,
      getRangeMax: () => 1_000_000,
    }),
  ].filter(Boolean);
}

function Payrolls({
  organizationId,
  companyId,
  type,
  selectedTab,
}: {
  organizationId: string;
  companyId: string;
  type: PayrollTypes;
  selectedTab: PayrollStatus;
}) {
  const [selectedPayrollId, setSelectedPayrollId] = useState<
    string | undefined
  >();
  const [selectedContractId, setSelectedContractId] = useState<
    string | undefined
  >();

  const [currentPayrollIndex, setCurrentPayrollIndex] = useState<
    number | undefined
  >();
  const [payrolls, setPayrolls] = useState<Array<PayrollSummary> | undefined>();

  const [compareTo, setCompareTo] = useState<PeriodFormat | undefined>(
    undefined,
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const closeDrawer = () => setShowDrawer(false);
  const [showPayslipDrawer, setShowPayslipDrawer] = useState(false);
  const closePayslipDrawer = () => setShowPayslipDrawer(false);
  const selectPayroll = (
    payrollId: string,
    contractId: string,
    rows: Array<PayrollSummary>,
    selectedTab: PayrollStatus,
  ) => {
    if (selectedTab === 'open') {
      setSelectedPayrollId(payrollId);
      setSelectedContractId(contractId);

      const currentIndex = rows.findIndex((row) => row.payrollId === payrollId);

      setCurrentPayrollIndex(currentIndex);
      setPayrolls(rows);

      setShowDrawer(true);
    } else {
      setSelectedPayrollId(payrollId);
      setSelectedContractId(contractId);
      const currentIndex = rows.findIndex((row) => row.payrollId === payrollId);
      setCurrentPayrollIndex(currentIndex);
      setShowPayslipDrawer(true);
    }
  };

  useEffect(() => {
    setCompareTo(undefined);
  }, [organizationId, companyId, type]);

  const workerNavigation = useMemo(
    () => ({
      canGoBackwards:
        payrolls != null &&
        currentPayrollIndex > 0 &&
        currentPayrollIndex <= payrolls.length - 1,
      canGoForward:
        payrolls != null &&
        currentPayrollIndex >= 0 &&
        currentPayrollIndex < payrolls.length - 1,
      goBackwards: () => {
        if (currentPayrollIndex <= 0) {
          return;
        }
        const newIndex = currentPayrollIndex - 1;
        const payroll = payrolls[newIndex];
        setCurrentPayrollIndex(newIndex);
        setSelectedContractId(payroll.contractId);
        setSelectedPayrollId(payroll.payrollId);
      },
      goForward: () => {
        if (currentPayrollIndex >= payrolls.length - 1) {
          return;
        }
        const newIndex = currentPayrollIndex + 1;
        const payroll = payrolls[newIndex];
        setCurrentPayrollIndex(newIndex);
        setSelectedContractId(payroll.contractId);
        setSelectedPayrollId(payroll.payrollId);
      },
    }),
    [payrolls, currentPayrollIndex],
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <RpaTable
        organizationId={organizationId}
        companyId={companyId}
        type={type}
        compareTo={compareTo}
        onWorkerClick={selectPayroll}
        selectedStatus={selectedTab}
      />

      <Drawer
        anchor={'right'}
        open={showDrawer}
        onClose={closeDrawer}
        elevation={2}
      >
        {selectedPayrollId && selectedContractId && (
          <RpaDetails
            organizationId={organizationId}
            companyId={companyId}
            period={null}
            type={type}
            payrollId={selectedPayrollId}
            contractId={selectedContractId}
            compareToPeriod={compareTo}
            workerNavigation={workerNavigation}
            onExit={closeDrawer}
          />
        )}
      </Drawer>

      <PayslipDetailsDrawer
        showPayslipDrawer={showPayslipDrawer}
        closePayslipDrawer={closePayslipDrawer}
        organizationId={organizationId}
        companyId={companyId}
        payrollId={selectedPayrollId}
      />
    </Box>
  );
}

type PeriodTabLabelProps = {
  isSelected: boolean;
  config: (typeof rpaGroupsConfig)[keyof typeof rpaGroupsConfig];
  count: number;
};

function PeriodTabLabel({
  isSelected,
  config: { label, color },
  count,
}: PeriodTabLabelProps) {
  const fontWeight = isSelected ? 700 : 500;
  const textColor = isSelected ? `${color}.main` : 'text.secondary';
  const bgColor = isSelected ? `background.${color}` : 'background.default';
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography color={textColor} variant="body1" fontWeight={fontWeight}>
        {label}
      </Typography>
      {count > 0 && (
        <Typography
          bgcolor={bgColor}
          color={textColor}
          py={0.25}
          px={1}
          borderRadius={2}
          variant="caption"
          fontWeight={fontWeight}
        >
          {count}
        </Typography>
      )}
    </Box>
  );
}
