import {
  makeDateRangeFilter,
  makeElementListFilter,
} from '@octopus/ui/data-grid';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { VacationsTabs } from '../../../utils/types';

export const useFilters = (tab: string) => {
  if (tab === VacationsTabs.people) {
    return [
      makeElementListFilter({
        label: 'Férias disponíveis',
        propertyToFilter: 'hasAvailableVacations',
        elements: ['true', 'false'],
        labels: {
          true: 'Sim',
          false: 'Não',
        },
      }),
      makeElementListFilter({
        label: 'Próximas do vencimento',
        propertyToFilter: 'hasVacationsCloseToDueDate',
        elements: ['true', 'false'],
        labels: {
          true: 'Sim',
          false: 'Não',
        },
      }),
      makeElementListFilter({
        label: 'Vencidas',
        propertyToFilter: 'hasOverdueVacations',
        elements: ['true', 'false'],
        labels: {
          true: 'Sim',
          false: 'Não',
        },
      }),
    ];
  }
  return [
    makeElementListFilter({
      label: 'Status',
      propertyToFilter: 'status',
      elements: [
        ...(tab === VacationsTabs.calculations
          ? [
              vacationsScheduleStatuses.payrollCreated,
              vacationsScheduleStatuses.payrollApproved,
            ]
          : [
              vacationsScheduleStatuses.waitingApproval,
              vacationsScheduleStatuses.approved,
            ]),
      ],
      labels: {
        [vacationsScheduleStatuses.waitingApproval]: 'Aguardando aprovação',
        [vacationsScheduleStatuses.approved]: 'Aprovadas',
        [vacationsScheduleStatuses.payrollCreated]: 'Folha criada',
        [vacationsScheduleStatuses.payrollApproved]: 'Folha aprovada',
      },
    }),
    makeElementListFilter({
      label: 'Adiantamento 13º',
      propertyToFilter: 'thirteenthAdvance',
      elements: ['true', 'false'],
      labels: {
        true: 'Sim',
        false: 'Não',
      },
    }),
    makeDateRangeFilter({
      label: 'Aprovar até',
      propertyToFilter: 'approvalDeadline',
    }),
    makeDateRangeFilter({
      label: 'Data de início',
      propertyToFilter: 'startDate',
    }),
    makeElementListFilter({
      label: 'Dias vendidos',
      propertyToFilter: 'hasDaysSold',
      elements: ['true', 'false'],
      labels: {
        true: 'Sim',
        false: 'Não',
      },
    }),
  ];
};
