import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Input, Skeleton, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import {
  GroupVacationsContractInput,
  VacationsAccrualPeriodSummary,
  fetchPostGroupVacations,
  fetchSearchVacationsAccrualPeriods,
} from '@octopus/api';
import { UI_TYPE } from '@octopus/libs/forms';
import {
  CreateScheduleRule,
  scheduleErrorMessages,
} from '@octopus/vacations-types';

import { BackButton } from '../../../modules/components/BackButton';
import { FormFields } from '../../../modules/form';
import { FormFromLayout } from '../../../modules/form/FormFromLayout';
import { Form } from '../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../modules/form/useFormFromDefinition';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { ActionBar } from '../components/PageActionsBar';
import { getColumnsByTab } from '../components/table/Tabs';
import { VacationsTabs } from '../utils/types';

type NewVacationsGroupProps = {
  organizationId: string;
};

type GroupVacationsForm = {
  startDate: string;
  endDate: string;
  paymentDate: string;
  batchVacationsType: 'group' | 'individual';
  sellDays?: string;
};

export const NewVacationsGroup: React.FC<NewVacationsGroupProps> = ({
  organizationId,
}: NewVacationsGroupProps) => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<GroupVacationsForm>({
    startDate: '',
    endDate: '',
    paymentDate: '',
    batchVacationsType: 'group',
    sellDays: '',
  });
  const [selectedContracts, setSelectedContracts] = useState([]);
  const columnsByTab = getColumnsByTab(false);

  const [vacationsSellDaysByContract, setVacationsSellDaysByContract] =
    useState<Record<string, number>>({});

  const setVacationsSellDays = (id: string, days: number) => {
    setVacationsSellDaysByContract({
      ...vacationsSellDaysByContract,
      [id]: days,
    });
  };

  const searchVacationsAccrualPeriods = async () => {
    let pageParam = 0;
    let data: VacationsAccrualPeriodSummary[] = [];
    let hasNext = false;
    do {
      const result = await fetchSearchVacationsAccrualPeriods({
        pathParams: { organizationId },
        body: {
          pagination: {
            page: pageParam,
            size: 100,
          },
        },
      });
      data = [...data, ...result.data];
      pageParam++;
      hasNext = !!result.size;
    } while (hasNext);
    return data;
  };

  const vacationsAccrualPeriods = useQuery({
    queryKey: ['fetchSearchVacationsAccrualPeriods', organizationId],
    queryFn: searchVacationsAccrualPeriods,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!organizationId,
  });

  const vacationsGroupForm = useFormFromDefinition(
    [
      {
        label: 'Data de início',
        type: z.string(),
        name: 'startDate',
        uiType: UI_TYPE.TEXT_DATE_PICKER,
      },
      {
        label: 'Data de fim',
        type: z.string(),
        name: 'endDate',
        uiType: UI_TYPE.TEXT_DATE_PICKER,
      },
      {
        label: 'Data de pagamento',
        type: z.string(),
        name: 'paymentDate',
        uiType: UI_TYPE.TEXT_DATE_PICKER,
      },
      {
        label: 'Tipo de Férias',
        name: 'batchVacationsType',
        uiType: UI_TYPE.SELECT,
        type: z.string(),
        options: [
          {
            label: 'Selecione uma opção',
            value: '',
          },
          {
            label: 'Individuais',
            value: 'individual',
          },
          {
            label: 'Coletivas',
            value: 'group',
          },
        ],
      },
      {
        label: 'Vender férias',
        name: 'sellDays',
        uiType: UI_TYPE.SELECT,
        type: z.string(),
        options: [
          {
            label: 'Selecione uma opção',
            value: '',
          },
          {
            label: 'Sim',
            value: 'true',
          },
          {
            label: 'Não',
            value: 'false',
          },
        ],
      },
    ],
    {
      id: 'vacations-group-form',
      persistLocal: false,
      onSubmit: async () => {
        const {
          startDate,
          paymentDate,
          endDate,
          batchVacationsType,
          sellDays,
        } = form;

        const contracts: GroupVacationsContractInput[] = selectedContracts.map(
          (contractId) => {
            return {
              contractId,
              doesNotHaveAvailableDaysPolicy: 'paidLeave',
              daysSold:
                sellDays === 'true'
                  ? vacationsSellDaysByContract?.[contractId] || 0
                  : 0,
            };
          },
        );

        setIsLoading(true);
        await fetchPostGroupVacations({
          body: {
            startDate,
            endDate,
            paymentDate,
            contracts,
            type: batchVacationsType,
          },
          pathParams: { organizationId },
        })
          .then(() => {
            showSnackbar({
              isOpen: true,
              Message: 'Férias coletivas agendadas com sucesso',
            });
            navigate('/vacations-group');
          })
          .catch((error) => {
            const message = Object.entries(error.stack.details).map(([key]) => {
              const errorKey = key.split('/')[1] as CreateScheduleRule;
              return scheduleErrorMessages[errorKey]?.description;
            })?.[0];

            showSnackbar({
              isOpen: true,
              Message: message,
              variant: 'error',
            });
          });
        setIsLoading(false);
      },
      useNewParser: true,
    },
  );

  const { startDate, endDate, paymentDate, batchVacationsType, sellDays } =
    vacationsGroupForm.payloadValue as unknown as GroupVacationsForm;

  useEffect(() => {
    setForm({
      startDate,
      paymentDate,
      endDate,
      batchVacationsType,
      sellDays,
    });
  }, [startDate, endDate, paymentDate, batchVacationsType, sellDays]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        py: 9,
        px: 10,
      }}
    >
      <Box>
        <BackButton />
      </Box>
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="group-vacations-header"
          gap={1}
          mb={5}
        >
          <BeachAccessOutlined
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Férias Coletivas</Typography>
        </Box>

        <Box>
          {vacationsAccrualPeriods.isLoading ? (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          ) : (
            <Box>
              <Box>
                <Form
                  metaForm={vacationsGroupForm.metaForm}
                  payloadForm={vacationsGroupForm.payloadForm}
                >
                  <FormFromLayout
                    fields={FormFields({
                      fields: vacationsGroupForm.fieldsRenderOptions,
                      formId: vacationsGroupForm.id,
                    })}
                  />
                </Form>
              </Box>
              <Box
                mt={4}
                display="flex"
                sx={{ height: '100vh', width: '100%' }}
              >
                <Box flexGrow={1}>
                  <DataGrid
                    getRowId={(row) => row.contractId}
                    rows={vacationsAccrualPeriods.data}
                    columns={[
                      ...columnsByTab[VacationsTabs.people],
                      ...(form?.sellDays === 'true'
                        ? [
                            sellDaysColumn(
                              setVacationsSellDays,
                              vacationsSellDaysByContract,
                            ),
                          ]
                        : []),
                    ]}
                    checkboxSelection
                    components={{
                      Toolbar: GridToolbarQuickFilter,
                    }}
                    componentsProps={{
                      toolbar: {
                        sx: { p: 2 },
                        placeholder: 'Filtrar',
                      },
                    }}
                    onSelectionModelChange={(newSelection) => {
                      setSelectedContracts(newSelection);
                    }}
                    selectionModel={selectedContracts}
                  />
                </Box>
              </Box>

              <Box mt={5} display="flex" justifyContent="end">
                <ActionBar>
                  <ActionBar.Action
                    sx={{ minWidth: '157px' }}
                    type="submit"
                    form={vacationsGroupForm.id}
                    isLoading={isLoading}
                    disabled={selectedContracts.length === 0}
                  >
                    {'Agendar férias'}
                  </ActionBar.Action>
                </ActionBar>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

const sellDaysColumn = (
  setSellDays: (id: string, days: number) => void,
  vacationsSellDaysByContract: Record<string, number>,
): GridColDef<VacationsAccrualPeriodSummary> => ({
  field: 'sellDays',
  headerName: 'Dias a Vender',
  flex: 1,
  valueGetter: (
    params: GridValueGetterParams<VacationsAccrualPeriodSummary>,
  ) => {
    return params.row;
  },
  renderCell: (params) => {
    return SellDaysColumnInput(
      setSellDays,
      vacationsSellDaysByContract,
      params,
    );
  },
});

const SellDaysColumnInput = (
  setSellDays: (id: string, days: number) => void,
  vacationsSellDaysByContract: Record<string, number>,
  params: GridRenderCellParams<VacationsAccrualPeriodSummary>,
) => {
  const { contractId } = params.value;
  const selectedValue = vacationsSellDaysByContract?.[contractId];
  const value = selectedValue || 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (value < 0 || value > 30) {
      return;
    }
    setSellDays(contractId, value);
  };

  return <Input value={value} onChange={handleChange} />;
};
