import { useState } from 'react';

import { LocalDate } from '@js-joda/core';
import {
  IconCircleCheck,
  IconCircleDashedCheck,
  IconProgressCheck,
} from '@tabler/icons-react';

import {
  Check,
  CloseOutlined,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { VacationsScheduleEntry } from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';
import { Button as DSButton } from '@octopus/ui/design-system';

import { ActionMenu } from '../../../../modules/components/ActionMenu';
import {
  useGetRequestedRoles,
  useVacationsSchedule,
} from '../../hooks/useVacationsApprove';
import { shouldShowApprovalActions } from '../../utils/status';
import { BodyText } from '../Text';

export const ScheduleReview = (props: {
  vacationSchedule: VacationsScheduleEntry;
  onReview: () => Promise<void>;
  isManager: boolean;
}) => {
  const { vacationSchedule, onReview, isManager } = props;
  const { status } = vacationSchedule;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column">
        <ApprovalsList vacationSchedule={vacationSchedule} />
      </Box>
      <Box mt={2} display="flex" justifyContent="space-between">
        {shouldShowApprovalActions(status) && (
          <ApprovalActions
            vacationSchedule={vacationSchedule}
            onReview={onReview}
            isManager={isManager}
          />
        )}
      </Box>
    </Box>
  );
};

const ApprovalActions = (props: {
  vacationSchedule: VacationsScheduleEntry;
  onReview: () => Promise<void>;
  isManager: boolean;
}) => {
  const { vacationSchedule, onReview, isManager } = props;
  const { approvalDeadline } = vacationSchedule;
  const {
    RejectScheduleModal,
    approveVacation,
    openRejectModal,
    isSubmitting,
  } = useVacationsSchedule(vacationSchedule, onReview, isManager);

  const {
    isManagerRequested,
    shouldShowApprovalButton,
    shouldShowForceApprovalButton,
    shouldOverride,
  } = useGetRequestedRoles(vacationSchedule, isManager);

  if (isManager && !isManagerRequested) {
    return null;
  }

  const isPastApprovalDeadline =
    approvalDeadline &&
    LocalDate.parse(approvalDeadline).isBefore(LocalDate.now());

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <RejectScheduleModal
        override={shouldOverride}
        asManager={isManagerRequested}
      />
      <Box display="flex" flexDirection="column" justifyContent="center">
        {!shouldShowApprovalButton && (
          <BodyText>Aguardando aprovadores</BodyText>
        )}
        <BodyText
          color={
            isPastApprovalDeadline
              ? 'error.main'
              : !shouldShowApprovalButton
                ? '#BABABF'
                : 'text.secondary'
          }
        >
          {`Limite para aprovação: ${formatDateBR(approvalDeadline)}`}
        </BodyText>
      </Box>
      <Box alignItems="center" display="flex" justifyContent="end" gap={1}>
        {shouldShowApprovalButton && (
          <>
            <DSButton
              variantLayout="tiny"
              variantSemantic="secondary"
              onClick={openRejectModal}
              disabled={isSubmitting}
            >
              {'Recusar'}
              <CloseOutlined
                sx={{ ml: '4px', width: '16px', height: '16px' }}
              />
            </DSButton>

            <DSButton
              variantLayout="tiny"
              sx={{ minWidth: '100px', minHeight: '32px' }}
              onClick={() =>
                approveVacation(shouldOverride, isManagerRequested)
              }
              isLoading={isSubmitting}
            >
              {'Aprovar'}
              <Check sx={{ ml: '4px', width: '16px', height: '16px' }} />
            </DSButton>
          </>
        )}
        {shouldShowForceApprovalButton &&
          (!isSubmitting ? (
            <ActionMenu
              menuItems={[
                {
                  label: 'Forçar aprovação',
                  onClick: () =>
                    approveVacation(shouldOverride, isManagerRequested),
                },
                {
                  label: 'Forçar recusa',
                  onClick: () => openRejectModal(),
                  color: 'error.main',
                },
              ]}
            />
          ) : (
            <CircularProgress size={20} />
          ))}
      </Box>
    </Box>
  );
};

const ApprovalsList = ({
  vacationSchedule,
}: {
  vacationSchedule: VacationsScheduleEntry;
}) => {
  const [expandButtonState, setExpandButtonState] = useState(true);

  const { totalSteps, pendingSteps, steps } = vacationSchedule?.approval || {};
  const pendingCount = totalSteps - pendingSteps;

  const handleExpandButtonClick = () => {
    setExpandButtonState(!expandButtonState);
  };

  const nameMapper = {
    'membership:owner': 'Departamento Pessoal',
    manager: 'Gestor',
    'resource:owner': 'Gestor',
    system: 'Sistema',
  };

  const statusMapper = {
    approve: 'Aprovado',
    reject: 'Recusado',
    terminate: 'Recusado',
    cancel: 'Recusado',
    timeout: 'Recusado',
  };

  return (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '4px 8px 0px 16px',
          backgroundColor: 'background.secondary',
          borderRadius: '8px 8px 0 0',
        }}
        onClick={handleExpandButtonClick}
        style={{ cursor: 'pointer' }}
      >
        <BodyText>Aprovações</BodyText>
        <Box display="flex" alignItems="center">
          <BodyText color={pendingSteps > 0 ? 'warning.main' : 'success.main'}>
            {`${pendingCount}/${totalSteps}`}
          </BodyText>
          <IconProgressCheck
            style={{ margin: '0 8px 4px 8px' }}
            size={16}
            color={pendingSteps > 0 ? '#93500B' : '#066F4F'}
          />
          <IconButton sx={{ mb: '4px' }} size="small">
            {expandButtonState ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expandButtonState}>
        <List>
          {steps?.map((approval, idx) => {
            return approval?.requests?.map((request, index) => {
              const isPending = ['pending', 'requested'].includes(
                request.status,
              );
              const status = isPending
                ? 'Aguardando'
                : statusMapper[request.review.action];
              return (
                <ListItem
                  key={`${idx}-${index}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0 16px',
                  }}
                  disablePadding
                >
                  <ListItemText disableTypography>
                    <Typography
                      fontSize={12}
                      variant="body2"
                      fontWeight={isPending ? 500 : 700}
                      color={isPending ? '#616161' : '#25252D'}
                    >
                      {nameMapper[request.role]}
                    </Typography>
                  </ListItemText>
                  <ListItemIcon sx={{ alignItems: 'center' }}>
                    <Typography
                      fontSize={12}
                      variant="body2"
                      fontWeight={isPending ? 500 : 700}
                      color={isPending ? '#BABABF' : '#066F4F'}
                    >
                      {status}
                    </Typography>
                    <Box display="flex" ml={1}>
                      {isPending ? (
                        <IconCircleDashedCheck size={16} color="#BABABF" />
                      ) : (
                        <IconCircleCheck size={16} color="#066F4F" />
                      )}
                    </Box>
                  </ListItemIcon>
                </ListItem>
              );
            });
          })}
        </List>
      </Collapse>
    </Box>
  );
};
