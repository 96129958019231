import {
  type FieldMetadata,
  FormMetadata,
  getSelectProps,
} from '@conform-to/react';

import {
  IFieldsetBranchingDefinition,
  IFormDefinitionEntry,
  isFieldCollectionWithOptions,
  isFieldSingleWithAutoCompleteOption,
  isFieldSingleWithOptions,
  isFieldSingleWithoutOptions,
  isFieldset,
  isFieldsetBranching,
  isNamedFieldDefinition,
  parseFieldUiInfo,
} from '@octopus/libs/forms';

import { parseFieldRadio } from './parseField';
import { parseFieldCheckboxCard } from './parseField/parseFieldCheckboxCard';
import { parseFieldCheckboxCloud } from './parseField/parseFieldCheckboxCloud';
import { parseFieldSelect } from './parseField/parseFieldSelect';
import { parseFieldSelectAutoComplete } from './parseField/parseFieldSelectAutoComplete';
import { parseFieldText } from './parseField/parseFieldText';
import { parseFieldTextArea } from './parseField/parseFieldTextArea';
import { TFieldRenderProps, TFormRenderPropOptions } from './parseField/types';

function getFieldsetBranchingChoiceField(
  fieldDefinition: IFieldsetBranchingDefinition,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaFields: FieldMetadata<any>,
  fieldsetName = '',
): TFieldRenderProps {
  const selectProps = getSelectProps(metaFields);
  const defaultValue: string | number | readonly string[] =
    fieldDefinition.value ??
    selectProps.defaultValue ??
    (selectProps.multiple ? [] : '');

  const fieldRenderProps: TFieldRenderProps = {
    uiType: parseFieldUiInfo(fieldDefinition),
    fieldsetName,
    errors: fieldDefinition.errors,
    props: {
      key: selectProps.id,
    },
    label: {
      textContent: fieldDefinition.label,
      props: {
        htmlFor: selectProps.id,
      },
    },
    select: {
      props: {
        ...(fieldDefinition.type != null &&
          'isOptional' in fieldDefinition.type && {
            required: !fieldDefinition.type.isOptional(),
          }),
        ...selectProps,
        ...(defaultValue && { defaultValue }),
        placeholder: fieldDefinition.placeholder,
        info: fieldDefinition.info,
        hint: fieldDefinition.hint,
      },
      options: fieldDefinition.fieldsOptions.map((option, idx) => ({
        props: {
          value: `${idx}`,
          key: `${idx}`,
        },
        defaultSelected: option.selected,
        textContent: option.label,
      })),
    },
  };

  return fieldRenderProps;
}

function getSelectedFieldsetBranchingOption(
  fieldDefinition: IFieldsetBranchingDefinition,
  metaForm: FormMetadata<Record<string, unknown>, string[]>,
  currentLevelId: string,
) {
  const fieldName = `${fieldDefinition.name || 'unamed'}_${currentLevelId}_fieldOptions_idx`;
  const fieldMetadataValue = metaForm.value?.[fieldName];
  const currentValue =
    fieldMetadataValue !== undefined
      ? typeof fieldMetadataValue !== 'string' &&
        typeof fieldMetadataValue !== 'number'
        ? JSON.stringify(fieldMetadataValue)
        : `${fieldMetadataValue}`
      : undefined;

  let selectedOptionIdx = 0;
  if (currentValue) {
    selectedOptionIdx = parseInt(`${metaForm.value?.[fieldName] || 0}`);
  } else if (fieldDefinition.fieldsOptions) {
    selectedOptionIdx = fieldDefinition.fieldsOptions.findIndex(
      (option) => option.selected,
    );
  }

  if (selectedOptionIdx == null || selectedOptionIdx < 0) {
    selectedOptionIdx = 0;
  }

  return fieldDefinition.fieldsOptions[selectedOptionIdx];
}

export function getFieldsRenderProps({
  definition,
  payloadFields,
  metaFields,
  metaForm,
  payloadFormErrors = {},
  fieldsOptions = [],
  levelId = '',
  fieldsetName = '',
  lastFieldCount = 0,
}: {
  definition: IFormDefinitionEntry[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payloadFields: Record<string, FieldMetadata<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaFields: Record<string, FieldMetadata<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaForm: FormMetadata<Record<string, unknown>, string[]>;
  payloadFormErrors?: Record<string, string[]>;
  fieldsOptions?: TFormRenderPropOptions;
  levelId?: string;
  fieldsetName?: string;
  lastFieldCount?: number;
}): TFormRenderPropOptions {
  for (const fieldDefinition of definition) {
    const fieldProps = getFieldProps({
      fieldDefinition,
      payloadFields,
      metaFields,
      metaForm,
      payloadFormErrors,
      fieldsOptions: [],
      levelId,
      fieldsetName,
      lastFieldCount,
      getFields: getFieldsRenderProps,
    });

    if (fieldProps) {
      fieldsOptions.push(...fieldProps);
    }
  }

  if (!payloadFormErrors || Object.keys(payloadFormErrors).length === 0)
    return fieldsOptions;

  return fieldsOptions.map((field) => {
    const [fieldName, ...fieldProps] = field;
    const errors: string[] | undefined = payloadFormErrors[fieldName];

    if (errors) {
      const newFieldProps = fieldProps.map((options) => ({
        ...options,
        errors,
      }));

      return [fieldName, ...newFieldProps];
    }
    return field;
  });
}

export const getFieldProps = ({
  fieldDefinition,
  payloadFields,
  metaFields,
  metaForm,
  payloadFormErrors = {},
  fieldsOptions = [],
  levelId = '',
  fieldsetName = '',
  lastFieldCount = 0,
  getFields,
}: {
  fieldDefinition: IFormDefinitionEntry;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payloadFields: Record<string, FieldMetadata<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaFields: Record<string, FieldMetadata<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metaForm: FormMetadata<Record<string, unknown>, string[]>;
  payloadFormErrors?: Record<string, string[]>;
  fieldsOptions?: TFormRenderPropOptions;
  levelId?: string;
  fieldsetName?: string;
  lastFieldCount: number;
  getFields: (
    args: typeof getFieldsRenderProps.arguments,
  ) => TFormRenderPropOptions;
}): TFormRenderPropOptions => {
  const currentLevelId = `${levelId}${lastFieldCount++}`;

  if (isFieldset(fieldDefinition)) {
    const fieldsetPayloadFields = isNamedFieldDefinition(fieldDefinition)
      ? (payloadFields[fieldDefinition.name].getFieldset() as Record<
          string,
          FieldMetadata
        >)
      : payloadFields;

    const fieldsetName = `${fieldDefinition.name || 'unamed'}_${currentLevelId}_fields`;

    fieldsOptions.push(
      ...getFields({
        definition: fieldDefinition.fields,
        payloadFields: fieldsetPayloadFields,
        metaFields,
        metaForm,
        payloadFormErrors,
        fieldsOptions: [],
        levelId: currentLevelId,
        fieldsetName,
        lastFieldCount,
      }),
    );
    return fieldsOptions;
  } else if (isFieldsetBranching(fieldDefinition)) {
    const fieldsetName = `${fieldDefinition.name || 'unamed'}_${currentLevelId}_fieldOptions`;
    const selectBranchFieldName = `${fieldDefinition.name || 'unamed'}_${currentLevelId}_fieldOptions_idx`;
    const selectBranchField = getFieldsetBranchingChoiceField(
      fieldDefinition,
      metaFields[selectBranchFieldName],
      fieldsetName,
    );

    const selectedFieldsOption = getSelectedFieldsetBranchingOption(
      fieldDefinition,
      metaForm,
      currentLevelId,
    );

    const branchingPayloadFields = isNamedFieldDefinition(fieldDefinition)
      ? (payloadFields[fieldDefinition.name].getFieldset() as Record<
          string,
          FieldMetadata
        >)
      : payloadFields;

    fieldsOptions.push(
      ...getFields({
        definition: selectedFieldsOption.fields,
        payloadFields: branchingPayloadFields,
        metaFields,
        metaForm,
        payloadFormErrors,
        fieldsOptions: [[selectBranchFieldName, selectBranchField]],
        levelId: currentLevelId,
        fieldsetName,
        lastFieldCount,
      }),
    );
    return fieldsOptions;
  } else if (
    isFieldSingleWithOptions(fieldDefinition) &&
    !isFieldSingleWithAutoCompleteOption(fieldDefinition)
  ) {
    //TODO text+radio option with dynamic metaform getCollectionProps({options: typedOptions})
    const fieldName = fieldDefinition.name;
    const conformFieldData = payloadFields[fieldName];

    const fieldRadioRenderProps = parseFieldRadio(
      conformFieldData,
      fieldDefinition,
      fieldsetName,
    );

    const fieldSelectRenderProps = parseFieldSelect(
      conformFieldData,
      fieldDefinition,
      fieldsetName,
      payloadFormErrors,
    );

    return [[fieldName, fieldRadioRenderProps, fieldSelectRenderProps]];
  } else if (
    isFieldSingleWithOptions(fieldDefinition) &&
    isFieldSingleWithAutoCompleteOption(fieldDefinition)
  ) {
    const fieldName = fieldDefinition.name;

    const fieldsetName = `${fieldDefinition.name}_autocomplete_group`;
    const autoCompleteFieldName = `${fieldName}_autocomplete_input`;
    const autoCompleteOptionsName = `${fieldName}_autocomplete_options`;
    const autoCompleteTimestampName = `${fieldName}_autocomplete_timestamp`;

    const autoCompleteFieldRenderProps = parseFieldSelectAutoComplete(
      payloadFields[fieldName],
      metaFields[autoCompleteFieldName],
      metaFields[autoCompleteOptionsName],
      metaFields[autoCompleteTimestampName],
      fieldDefinition,
      fieldsetName,
    );

    return [[fieldName, autoCompleteFieldRenderProps]];
  } else if (isFieldCollectionWithOptions(fieldDefinition)) {
    //TODO text+checkbox option with dynamic metaform getCollectionProps({options: typedOptions})
    const fieldName = fieldDefinition.name;
    const conformFieldData = payloadFields[fieldName];

    const checkboxCloudRenderProps = parseFieldCheckboxCloud(
      conformFieldData,
      fieldDefinition,
      fieldsetName,
      payloadFormErrors,
    );

    const simpleSelectRenderProps = parseFieldSelect(
      conformFieldData,
      fieldDefinition,
      fieldsetName,
      payloadFormErrors,
    );

    return [[fieldName, checkboxCloudRenderProps, simpleSelectRenderProps]];
  } else if (isFieldSingleWithoutOptions(fieldDefinition)) {
    //TODO text+radio option with dynamic metaform getCollectionProps({options: typedOptions})
    const fieldName = fieldDefinition.name;
    const conformFieldData = payloadFields[fieldName];

    const fieldTextRenderProps = parseFieldText(
      conformFieldData,
      fieldDefinition,
      fieldsetName,
      payloadFormErrors,
    );

    const fieldTextAreaRenderProps = parseFieldTextArea(
      conformFieldData,
      fieldDefinition,
      fieldsetName,
    );

    const checkboxesSelectRenderProps = parseFieldCheckboxCard(
      conformFieldData,
      fieldDefinition,
      fieldsetName,
    );

    return [
      [
        fieldName,
        fieldTextRenderProps,
        fieldTextAreaRenderProps,
        checkboxesSelectRenderProps,
      ],
    ];
  }

  return null;
};
