import { Navigate } from 'react-router-dom';

import { membershipTypes } from '@octopus/vacations-types';

import { MembershipContext } from '../../modules/types';

export const VacationsRedirect = (props: {
  membership: MembershipContext;
  isManager: boolean;
}) => {
  const { membership, isManager } = props;

  if (membership.membershipType === membershipTypes.owner) {
    return <Navigate to="/vacations/admin" />;
  }

  if (isManager) {
    return <Navigate to="/vacations/manager" />;
  }

  return <Navigate to="/" />;
};
