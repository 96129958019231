import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Cancel, CheckCircle, ErrorOutline } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import {
  ApproveAllPayrollsError,
  ArchivePayrollError,
  useArchivePayroll,
} from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import { RPANavigation } from '../navigationUtils';

export function CancelConfirmationModal({
  organizationId,
  companyId,
  payrollId,
  close,
}: {
  organizationId: string;
  companyId: string;
  payrollId: string;
  close: () => void;
}) {
  const navigate = useNavigate();
  const { mutate, isLoading, isSuccess, isError, error } = useArchivePayroll();
  const archivePayroll = () => {
    mutate({
      pathParams: {
        organizationId: organizationId,
        companyId: companyId,
        payrollId: payrollId,
      },
    });
  };
  const showSnackbar = useSnackbar().showSnackbar;

  useEffect(() => {
    if (isSuccess) {
      showSnackbar({
        isOpen: true,
        Message: 'Pagamento cancelado',
        StartAdornment: <CheckCircle />,
        autoHideDuration: 5000,
        hasCloseAction: true,
      });
      close();
    }
  }, [isSuccess]);

  if (isLoading) {
    return <LoadingScene />;
  }

  if (isError) {
    return <ErrorScene error={error} close={close} />;
  }

  if (isSuccess) {
    navigate(RPANavigation.homePagePath());
  }

  return <InitialScene approve={archivePayroll} close={close} />;
}

function ErrorScene({
  error,
  close,
}: {
  error: ApproveAllPayrollsError;
  close: () => void;
}) {
  const getErrorMessage = (error: ArchivePayrollError) => {
    // Map error codes to user-friendly messages
    const errorMessages: { [key: string]: string } = {
      '400': 'Ocorreu um erro ao cancelar o pagamento',
      // Add other error mappings
    };
    return (
      errorMessages[error.status] || 'Ocorreu um erro ao cancelar o pagamento'
    );
  };
  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro na aprovação</Typography>
        </Box>
        <Typography variant="body1">{getErrorMessage(error)}</Typography>
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={close}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}

function LoadingScene() {
  return (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Cancelando pagamento, aguarde alguns segundos...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}

function InitialScene({
  approve,
  close,
}: {
  approve: () => void;
  close: () => void;
}) {
  return (
    <>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={4}
          alignItems="flex-start"
          pr={1}
          mb={0}
        >
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="h5" fontSize="24px" fontWeight={600}>
              Cancelar pagamento
            </Typography>

            <Box display="flex" flexDirection="column" gap={3}>
              <Typography fontSize="16px" fontWeight={450}>
                Deseja descartar os valores preenchidos e cancelar este
                pagamento?
              </Typography>
              <Alert severity="error" icon={<ErrorOutline />}>
                Atenção, esta ação não pode ser desfeita.
              </Alert>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={() => {
            close();
          }}
          data-testid="cancel-approve-payroll-button"
        >
          Cancelar
        </Button>
        <Button
          color="error"
          variant="contained"
          size="large"
          sx={{
            height: '40px',
          }}
          onClick={approve}
          data-testid="confirm-cancel-payroll-button"
        >
          Cancelar pagamento
        </Button>
      </DialogActions>
    </>
  );
}
