import React, { useState } from 'react';

import { IconUserPlus } from '@tabler/icons-react';

import { Box, Container, Typography } from '@mui/material';

import { useFFlags } from '../fflags';

import { AdmissionCount } from './AdmissionCount';
import { AdmissionTable } from './AdmissionTable';

export type AdmissionProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

export default function Admission({ organizationId }: AdmissionProps) {
  const [showAdmitted, setShowAdmitted] = useState(true);
  const { fflags } = useFFlags();
  const pjEnabled = fflags.pjAdmissionEnabled.enabled;

  return (
    <Box
      pt={9}
      height="100vh"
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="admission-header"
          gap={1}
        >
          <IconUserPlus width={40} height={40} />
          <Typography variant="h1" ml={1}>
            Admissão
          </Typography>
        </Box>

        {pjEnabled && <AdmissionCount organizationId={organizationId} />}

        <Box py={3.2}>
          <AdmissionTable
            organizationId={organizationId}
            setShowAdmitted={setShowAdmitted}
            showAdmitted={showAdmitted}
          />
        </Box>
      </Container>
    </Box>
  );
}
