import React from 'react';

import { Box } from '@mui/material';

type FormFromLayoutProps = {
  fields: [fieldsetName: string, React.ReactElement][] | undefined;
  validationErrors?: Record<string, string[]>;
  fieldsetInline?: { fieldsetName: string; fieldsetInline: boolean }[];
};

export function FormFromLayout({
  fields,
  fieldsetInline,
}: FormFromLayoutProps) {
  const fieldsets = fields.reduce(
    (fieldsets, entry) => {
      const [name, field] = entry;

      if (name !== '') {
        const oldset = fieldsets.find(([fname]) => fname === name);
        let set = oldset;
        if (!set) {
          set = [name];
          fieldsets.push(set);
        }
        set.push(field);
        return fieldsets;
      } else {
        fieldsets.push([name, field]);
        return fieldsets;
      }
    },
    [] as [fieldsetName: string, ...fields: React.ReactElement[]][],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px 0',
      }}
    >
      {fieldsets.map(([fieldsetName, ...Fields], idx) => {
        const isFieldSetInline =
          (fieldsetName !== '' &&
            fieldsetInline?.find((item) =>
              fieldsetName.startsWith(item.fieldsetName),
            )?.fieldsetInline) ||
          false;
        const sx = getSx(isFieldSetInline);

        return fieldsetName !== '' ? (
          <Box key={idx} sx={sx} id={fieldsetName}>
            {...Fields}
          </Box>
        ) : (
          <Box key={idx}>{...Fields}</Box>
        );
      })}
    </Box>
  );
}

const FIELDSET_INLINE_SX = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: 2,
  '& > *': {
    flexGrow: 1,
  },
};

const FIELDSET_SX = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyItems: 'stretch',
  width: '100%',
  gap: 2,
  '& > *:not(:first-of-type)': {
    width: '100%',
    flex: '2 0 min-content',
  },
  '& > *:first-of-type': {
    width: '100%',
    flex: '0 0 auto',
  },
};

const getSx = (isFieldSetInline: boolean) => {
  return isFieldSetInline ? FIELDSET_INLINE_SX : FIELDSET_SX;
};
