import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button as ButtonMUI,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { WorkerCategory } from '@octopus/contract-types';

import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import { UploadProfilePicture } from '../../components/UploadProfilePicture';
import { AdmissionFormState, FormStepDefinition } from '../form/types';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  organizationId: string;
  companyId: string;
  draftId: string;
  submitLabel: string;
  workerCategory: WorkerCategory;
  profilePictureId?: string;
};

export const NewProfilePicture = ({
  disabled,
  isLoading,
  goToPreviousStep,
  goToNextStep,
  organizationId,
  draftId,
  submitLabel,
  formState,
  profilePictureId,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { showSnackbar } = useSnackbar();
  const [currentProfilePictureId, setCurrentProfilePictureId] =
    useState(profilePictureId);

  const goBackButton = (
    <ButtonMUI
      fullWidth
      color="secondary"
      variant="outlined"
      disabled={disabled}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          px: 6,
          marginLeft: 'auto',
          width: 'auto',
        },
        [theme.breakpoints.down('md')]: {
          py: 1.25,
          px: 2,
          mt: 1.5,
          display: 'block',
          width: 'calc(100svw - 64px)',
        },
      })}
      onClick={goToPreviousStep}
    >
      Voltar
    </ButtonMUI>
  );

  const handleNext = () => {
    if (!currentProfilePictureId && formState.admissionType !== 'admin_fills') {
      showSnackbar({
        isOpen: true,
        Message: 'Por favor, adicione uma foto de perfil antes de continuar.',
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    goToNextStep();
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          gap: 4,
        },
        [theme.breakpoints.down('md')]: {
          gap: 2,
          width: '100%',
          height: `calc(100svh - ${theme.spacing(38)})`,
          overflow: 'scroll',
          '::-webkit-scrollbar': { display: 'none' },
        },
      })}
    >
      <UploadProfilePicture
        organizationId={organizationId}
        draftId={draftId}
        title={formState.user_name}
        key="foto_perfil"
        onPictureUploaded={setCurrentProfilePictureId}
      />
      <Box>
        <Typography variant="body1" sx={{ display: 'flex', mb: 1 }}>
          Essa foto será exibida no seu perfil e ficará visível para o time de
          DP e os membros da organização em que você faz parte.
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          width: '100%',
          [theme.breakpoints.up('md')]: {
            display: 'flex',
            paddingTop: 3,
            gap: 2,
          },
          [theme.breakpoints.down('md')]: {
            position: 'fixed',
            bottom: 0,
            paddingBottom: 3,
            left: 0,
            background: theme.palette.background.paper,
            borderColor: 'transparent',
            border: 'none',
            px: 4,
          },
        })}
      >
        {!isMobile && goBackButton}
        <LoadingButton
          fullWidth
          color="primaryAlt"
          variant="contained"
          disabled={disabled}
          loading={isLoading}
          sx={(theme) => ({
            py: 1.25,
            [theme.breakpoints.up('md')]: {
              px: 6,
              width: 'auto',
            },
            [theme.breakpoints.down('md')]: {
              px: 2,
              display: 'block',
              width: 'calc(100svw - 64px)',
            },
          })}
          onClick={handleNext}
          data-testid={`submit-foto_perfil`}
        >
          {submitLabel}
        </LoadingButton>
        {isMobile && goBackButton}
      </Box>
    </Box>
  );
};
