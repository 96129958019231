import { Box } from '@mui/material';

import { VacationsScheduleSummary } from '@octopus/api';

import { PayrollCard } from './PayrollCard';
import { DrawerEmptyState } from './DrawerEmptyState';
import { vacationsDetailsTabsConfig } from './VacationsTabs';

export const CalculationsTab = ({
  calculations,
  onSuccess,
}: {
  calculations: VacationsScheduleSummary[];
  onSuccess: () => Promise<void>;
}) => {
  return (
    <Box>
      {calculations.length > 0 ? (
        calculations.map((summary: VacationsScheduleSummary) => {
          return (
            <Box mb={3} key={summary.sequence}>
              <PayrollCard
                key={summary.sequence}
                summary={summary}
                onSuccess={onSuccess}
              />
            </Box>
          );
        })
      ) : (
        <DrawerEmptyState
          message={vacationsDetailsTabsConfig.calculations.emptyMessage}
        />
      )}
    </Box>
  );
};
