import { Box, Typography } from '@mui/material';

export const DrawerEmptyState = ({ message }: { message: string }) => (
  <Box mt={10} display="flex" justifyContent="center">
    <Typography
      fontStyle="italic"
      color="#616161"
      variant="caption"
      fontWeight={500}
    >
      {message}
    </Typography>
  </Box>
);
