import { useEffect, useState } from 'react';

import { useQueries } from '@tanstack/react-query';

import { Drawer } from '@mui/material';

import {
  VacationsScheduleSummary,
  fetchGetContract,
  fetchGetContractAccrualPeriods,
  fetchGetContractVacationsSchedule,
} from '@octopus/api';

import { Controls } from './components/drawer/DrawerHeader';
import { DrawerSkeleton } from './components/drawer/DrawerSkeleton';
import { VacationsDrawer } from './components/drawer/VacationsDrawer';
import { VacationsDetailsTabs } from './utils/types';

type VacationsDetailsProps = {
  open: boolean;
  onClose: () => void;
  row: VacationsScheduleSummary;
  controls: Controls;
  detailsTab: VacationsDetailsTabs;
  isManager: boolean;
  onUpdate: () => Promise<void>;
};

export const VacationsDrawerDetails = ({
  isManager,
  open,
  onClose,
  onUpdate,
  controls,
  row,
  detailsTab,
}: VacationsDetailsProps) => {
  const [tab, setTab] = useState(detailsTab);
  useEffect(() => setTab(detailsTab), [detailsTab]);
  const pathParams = {
    organizationId: row?.organizationId,
    contractId: row?.contractId,
  };
  const fetchContractAccrualPeriods = () =>
    fetchGetContractAccrualPeriods({ pathParams });
  const fetchContractVacationsSchedule = () =>
    fetchGetContractVacationsSchedule({ pathParams });
  const fetchContract = () => fetchGetContract({ pathParams });
  const queryParams = { enabled: !!row && open };
  const queryKey = (q: string) => [q, row?.organizationId, row?.contractId];
  const results = useQueries({
    queries: [
      {
        queryKey: queryKey('contractAccrualPeriods'),
        queryFn: fetchContractAccrualPeriods,
        ...queryParams,
      },
      {
        queryKey: queryKey('contractVacationsSchedule'),
        queryFn: fetchContractVacationsSchedule,
        ...queryParams,
      },
      {
        queryKey: queryKey('contract'),
        queryFn: fetchContract,
        ...queryParams,
      },
    ],
  });

  const [
    { data: contractAccrualPeriods, refetch: refetchContractAccrualPeriods },
    { data: vacationsSchedule, refetch: refetchVacationsSchedule },
    { data: contract },
  ] = results;
  const isLoading = results.some((result) => result.isLoading);

  const refetchData = async () => {
    await refetchContractAccrualPeriods();
    await refetchVacationsSchedule();
    await onUpdate();
  };

  return (
    <Drawer elevation={2} anchor="right" open={open} onClose={onClose}>
      {isLoading || !open ? (
        <DrawerSkeleton isManager={isManager} />
      ) : (
        <VacationsDrawer
          onSuccess={refetchData}
          controls={controls}
          contract={contract}
          isManager={isManager}
          tab={tab}
          setTab={setTab}
          row={row}
          contractAccrualPeriods={contractAccrualPeriods}
          vacationsSchedule={vacationsSchedule}
        />
      )}
    </Drawer>
  );
};
