import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IconChevronDown } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';

import {
  Box,
  Button,
  Container,
  Skeleton,
  Toolbar,
  Typography,
} from '@mui/material';

import {
  PayrollInputsConfig,
  PayrollInputsList,
  PayrollInputsPayroll,
  fetchGetPayrollInputsConfiguration,
  fetchGetPayrollInputsForPeriod,
} from '@octopus/api';
import { formatPeriodDate } from '@octopus/formatters';
import {
  DataGridToolbar,
  FilterOptions,
  makeMoneyRangeFilter,
  makeYearMonthPickerFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';

import Pencil from '../../../assets/pencil.svg';
import { TrashIcon } from '../../../modules/components/autonomos/icon';
import { PageAlert } from '../../../modules/components/PageAlert';
import EnableMoreActionsPopover from '../../../modules/components/payrolls/moreActionsPopover';
import { DataFetching } from '../../../modules/dataFetching';
import { LegalEntityContext } from '../../../modules/types';
import { AppContext } from '../../context';

import createBaseRPAInputConfiguration from './inputs/createBaseRpaInputConfiguration';
import RPASubmission from './inputs/RPASubmission';
import { PasteCPFListDialog, SelectLegalEntityDialog } from './RpaDialogs';

export default function RPALaunchPage({
  organizationId,
  companyId,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
}) {
  const { period } = useParams<{
    period: string;
  }>();
  const navigate = useNavigate();
  const { appContext } = useContext(AppContext);

  // URL params
  const isEditing =
    new URLSearchParams(window.location.search).get('editMode') === 'true';
  const urlLegalEntityId = new URLSearchParams(window.location.search).get(
    'legalEntityId',
  );

  // State
  const [showSelectLegalEntityDialog, setShowSelectLegalEntityDialog] =
    useState(false);
  const [showPasteCPFDialog, setShowPasteCPFDialog] = useState(false);
  const [enableMoreActionsPopoverOpen, setEnableMoreActionsPopoverOpen] =
    useState(false);
  const [popoverAnchorEl, setPopOverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [selectedLegalEntity, setSelectedLegalEntity] = useState<string>(
    urlLegalEntityId &&
      appContext.company.legalEntities.some((le) => le.id === urlLegalEntityId)
      ? urlLegalEntityId
      : appContext.company.legalEntities[0].id,
  );

  // More actions state
  const moreActionsState = {
    enableMoreActionsPopoverOpen,
    setEnableMoreActionsPopoverOpen,
    popoverAnchorEl,
    setPopOverAnchorEl,
  };

  // Refs
  const cpfListRef = useRef<string[]>([]);
  const selectedLegalEntityRef = useRef<string>(selectedLegalEntity);
  const rpaHandlersRef = useRef({
    search: () => console.log('Search handler not initialized'),
    legalEntityChange: () =>
      console.log('Legal entity handler not initialized'),
  });

  // Callbacks
  const handleCPFListSubmit = useCallback((newCpfList: string[]) => {
    cpfListRef.current = newCpfList;
    setShowPasteCPFDialog(false);
    rpaHandlersRef.current.search();
  }, []);

  const registerSearchHandler = useCallback((handler: () => void) => {
    rpaHandlersRef.current.search = handler;
  }, []);

  const registerLegalEntityHandler = useCallback((handler: () => void) => {
    rpaHandlersRef.current.legalEntityChange = handler;
  }, []);

  // Effects
  useEffect(() => {
    selectedLegalEntityRef.current = selectedLegalEntity;
    rpaHandlersRef.current.legalEntityChange();
  }, [selectedLegalEntity]);

  const editRPAMoreActions = [
    {
      label: 'Colar uma lista de CPFs',
      onClick: () => setShowPasteCPFDialog(true),
    },
    {
      label: '',
      divider: true,
    },
    {
      label: 'Remover todos autônomos',
      onClick: () => navigate(`/autonomos`),
      icon: TrashIcon(),
      disabled: true,
    },
  ];

  const filters = useFilters();
  const { filteringProps, searchProps } = useDataGrid({
    filters,
  });

  const inputsConfiguration = useQuery({
    queryKey: ['rpa-fetch-inputs-configuration', organizationId, companyId],
    queryFn: () => {
      return fetchGetPayrollInputsConfiguration({
        pathParams: {
          organizationId,
          companyId,
        },
      });
    },
    refetchOnWindowFocus: false,
    enabled: !!organizationId && !!companyId,
  });

  useEffect(() => {
    try {
      if (
        inputsConfiguration.isSuccess &&
        Object.values(inputsConfiguration.data.payload).filter(
          (input) => input.target === 'rpa',
        ).length === 0
      ) {
        inputsConfiguration.data.payload = createBaseRPAInputConfiguration({
          organizationId,
          companyId,
          existingPayload: inputsConfiguration.data.payload,
        });
      }
    } catch (error) {
      console.error('Error creating base RPA input configuration', error);
    }
  }, [inputsConfiguration.isSuccess]);

  const payrollInputs = useQuery({
    queryKey: ['rpa-fetch-inputs', organizationId, companyId],
    queryFn: () => {
      return fetchGetPayrollInputsForPeriod({
        pathParams: {
          organizationId,
          companyId,
          periodId: period,
          payrollType: 'rpa',
        },
      });
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!organizationId && !!companyId && isEditing,
  });

  const toolbar = useMemo(
    () => (
      <DataGridToolbar
        filters={filters}
        searchProps={searchProps}
        filteringProps={filteringProps}
        searchPlaceholder="Procurar"
      >
        <Box
          sx={{
            alignContent: 'end',
            flex: 'end',
            display: 'flex',
            gap: 1,
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Button
            color="secondary"
            sx={{ display: 'flex', width: 130, height: 40, p: 1, m: 0, gap: 1 }}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              moreActionsState.setPopOverAnchorEl(event.currentTarget);
              moreActionsState.setEnableMoreActionsPopoverOpen(true);
            }}
          >
            <Typography variant={'body2'} color="primaryAlt" fontWeight={400}>
              Mais ações
            </Typography>
            <IconChevronDown height={'20px'} width={'20px'} />
          </Button>
        </Box>
        <EnableMoreActionsPopover
          moreActionsProps={moreActionsState}
          actions={editRPAMoreActions}
        />
        <SelectLegalEntityDialog
          open={showSelectLegalEntityDialog}
          setOpen={setShowSelectLegalEntityDialog}
          selectedLegalEntity={selectedLegalEntity}
          setSelectedLegalEntity={setSelectedLegalEntity}
          legalEntities={
            appContext.company.legalEntities as LegalEntityContext[]
          }
        />
      </DataGridToolbar>
    ),
    [
      filters,
      searchProps,
      filteringProps,
      showSelectLegalEntityDialog,
      moreActionsState,
    ],
  );
  const dataFetchingContent = useMemo(
    () => (
      <DataFetching<{
        inputsConfiguration: PayrollInputsConfig;
        payrollInputs: PayrollInputsList;
      }>
        containerSx={{ height: '100%' }}
        fetchResult={{
          results: {
            inputsConfiguration: {
              ...inputsConfiguration,
              isLoading: inputsConfiguration.isLoading,
            },
            payrollInputs: isEditing
              ? {
                  ...payrollInputs,
                  isLoading: payrollInputs.isLoading,
                }
              : {
                  ...payrollInputs,
                  data: {
                    organizationId,
                    companyId,
                    period,
                    type: 'rpa',
                    payrolls: {},
                  },
                  isLoading: false,
                },
          },
        }}
        Loading={() => (
          <Skeleton
            variant="rounded"
            width="100%"
            height="calc(100vh - 245px)"
          />
        )}
        Data={({ data }) => (
          <RPASubmission
            organizationId={organizationId}
            companyId={companyId}
            period={period}
            type="rpa"
            data={{
              ...data.payrollInputs,
              payrolls: filterPayrollsByLegalEntity(
                data.payrollInputs.payrolls,
                selectedLegalEntity,
              ),
            }}
            config={data.inputsConfiguration}
            mode={'multiple'}
            cpfListRef={cpfListRef}
            onSearchTrigger={registerSearchHandler}
            selectedLegalEntityRef={selectedLegalEntityRef}
            onLegalEntityChange={registerLegalEntityHandler}
          />
        )}
        Error={() => {
          if (inputsConfiguration.error) {
            inputsConfiguration.data.payload = createBaseRPAInputConfiguration({
              organizationId,
              companyId,
            });
          }
          return <GenericError />;
        }}
      />
    ),
    [
      inputsConfiguration.status,
      inputsConfiguration.data,
      inputsConfiguration.error,
      organizationId,
      companyId,
      period,
      payrollInputs.data,
      registerSearchHandler,
    ],
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
      }}
    >
      <Toolbar sx={{ padding: 0 }}>
        <Button
          variant="text"
          color="primaryAlt"
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            padding: 0,
            height: '26px',
          }}
          onClick={() => navigate(`/autonomos`)}
        >
          Cancelar
        </Button>
      </Toolbar>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          data-testid="autonomo-page-header"
          px="40px"
        >
          <Typography variant="subtitle1" fontSize={'12px'}>
            Pagamentos para autônomos
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
              alignItems: 'baseline',
              height: '32px',
              '& img': {
                opacity: 0,
                transition: 'opacity 0.3s ease-in-out',
              },
              '&:hover img': { opacity: 1 },
            }}
            onClick={() => {
              if (appContext.company.legalEntities.length > 1) {
                setShowSelectLegalEntityDialog(true);
              }
            }}
          >
            <Typography variant="h1" fontSize={'24px'}>
              {formatPeriodDate(period)}
            </Typography>
            <Typography variant="subtitle1" fontSize={'14px'}>
              •
            </Typography>
            <Typography variant="subtitle1" fontSize={'14px'}>
              {
                appContext.company.legalEntities.find(
                  (legalEntity) => legalEntity.id === selectedLegalEntity,
                )?.name
              }
            </Typography>
            {/* We should only show the pencil icon if there are more than one legal entity */}
            {appContext.company.legalEntities.length > 1 && (
              <Box
                component="img"
                src={Pencil}
                sx={{ height: '20px', width: '20px', padding: '0px' }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            px: '40px',
            display: 'flex',
            flexDirection: 'column',
            height: '80vh',
            gap: 2,
          }}
        >
          {toolbar}
          {dataFetchingContent}
        </Box>
      </Container>
      <PasteCPFListDialog
        open={showPasteCPFDialog}
        setOpen={setShowPasteCPFDialog}
        onSubmit={handleCPFListSubmit}
      />
    </Box>
  );
}

function useFilters(): FilterOptions {
  return [
    makeYearMonthPickerFilter({
      label: 'Competência',
      propertyToFilter: 'period',
    }),
    makeMoneyRangeFilter({
      label: 'Valor líquido',
      propertyToFilter: 'calculationTotals.netPay',
      getRangeMin: () => 0,
      getRangeMax: () => 1_000_000,
    }),
  ].filter(Boolean);
}

function GenericError() {
  return (
    <Box
      border={(theme) => `1px solid ${theme.palette.strokes.light}`}
      borderRadius={2}
      width="100%"
      height="calc(100vh - 245px)"
    >
      <PageAlert
        message="Falha ao carregar tabela de lançamentos!"
        severity="error"
        showRetryMessage={true}
      />
    </Box>
  );
}

function filterPayrollsByLegalEntity(
  payrolls: { [key: string]: PayrollInputsPayroll },
  selectedLegalEntity: string,
): { [key: string]: PayrollInputsPayroll } {
  return Object.entries(payrolls)
    .filter(([_, payroll]) => payroll.legalEntityId === selectedLegalEntity)
    .reduce(
      (acc, [key, payroll]) => {
        acc[key] = payroll;
        return acc;
      },
      {} as { [key: string]: PayrollInputsPayroll },
    );
}
