import { useState } from 'react';

import {
  PayrollEntry,
  VacationsScheduleEntry,
  fetchApprovePayroll,
  fetchArchivePayroll,
} from '@octopus/api';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { ConfirmModal } from '../[contractId]/new/modal/PayrollApprovalModal';
import { RejectModal } from '../[contractId]/new/modal/PayrollRejectModal';
import { pollingSearch } from '../utils/polling';

export const useVacationsPayrollApprove = (
  vacationSchedule: VacationsScheduleEntry,
  payroll: PayrollEntry,
  onReview: () => Promise<void>,
) => {
  const { organizationId, companyId, sequence, contractId } = vacationSchedule;
  const { payrollId } = payroll || {};

  const [isOpen, setIsOpen] = useState(null);

  const { showSnackbar } = useSnackbar();
  const onClickApprove = () => setIsOpen('approve');
  const onClickReject = () => setIsOpen('reject');

  const pathParams = {
    companyId,
    organizationId,
    payrollId,
  };

  const waitingStatusChange = (status: string) =>
    pollingSearch({
      contractId,
      organizationId,
      id: `${organizationId}|${contractId}|${sequence}`,
      vacationsScheduleStatusesList: [status],
    });

  const showSnackbarMessage = (
    isError: boolean,
    action: 'approve' | 'reject',
  ) => {
    const successAction = action === 'approve' ? 'aprovada' : 'reprovada';
    const errorAction = action === 'approve' ? 'aprovar' : 'reprovar';
    showSnackbar({
      isOpen: true,
      variant: isError ? 'error' : 'default',
      Message: isError
        ? `Erro ao ${errorAction} a folha de férias`
        : `Folha de férias ${successAction} com sucesso`,
      hasCloseAction: true,
    });
  };

  const onFinish = () => {
    setIsOpen(null);
  };

  const onSuccess = (action: 'approve' | 'reject') => {
    onFinish();
    showSnackbarMessage(false, action);
  };

  const onError = (action: 'approve' | 'reject') => {
    onFinish();
    showSnackbarMessage(true, action);
  };

  const onArchivePayroll = async () => {
    await fetchArchivePayroll({
      pathParams,
    })
      .then(() =>
        waitingStatusChange(vacationsScheduleStatuses.payrollArchived),
      )
      .then(() => onSuccess('reject'))
      .catch(() => onError('reject'));
    await onReview();
  };

  const onApprovePayroll = async () => {
    await fetchApprovePayroll({
      pathParams,
    })
      .then(() =>
        waitingStatusChange(vacationsScheduleStatuses.payrollApproved),
      )
      .then(() => onSuccess('approve'))
      .catch(() => onError('approve'));
    await onReview();
  };

  const ConfirmPayroll = () => {
    return (
      <ConfirmModal
        open={isOpen === 'approve'}
        onCancel={() => setIsOpen(null)}
        onConfirm={() => onApprovePayroll()}
      />
    );
  };

  const RejectPayroll = () => {
    return (
      <RejectModal
        open={isOpen === 'reject'}
        onCancel={() => setIsOpen(null)}
        onConfirm={() => onArchivePayroll()}
      />
    );
  };

  return {
    ConfirmPayroll,
    RejectPayroll,
    onClickApprove,
    onClickReject,
  };
};
