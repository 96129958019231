import {
  IconCircleCheckFilled,
  IconCircleDashedCheck,
} from '@tabler/icons-react';

import { Box, Typography } from '@mui/material';

import { VacationsScheduleApprovalSummary } from '@octopus/api';

export function VacationsScheduleReviews({
  pendingSteps,
  totalSteps,
}: VacationsScheduleApprovalSummary) {
  const approvedSteps = totalSteps - pendingSteps;

  const getProgressColor = () => {
    if (approvedSteps < totalSteps) {
      return {
        color: '#C77F31',
        bgcolor: '#FEF7F1',
        Icon: IconCircleDashedCheck,
      };
    }
    return {
      color: '#2E7D32',
      bgcolor: '#F4FBF6',
      Icon: IconCircleCheckFilled,
    };
  };

  const { color, bgcolor, Icon } = getProgressColor();

  return (
    <Box
      display="flex"
      flexDirection="row"
      borderRadius="32px"
      alignItems="center"
      justifyContent="center"
      bgcolor={bgcolor}
      width="fit-content"
      pl={2.5}
      pr={2}
      py={0.5}
    >
      <Typography whiteSpace="nowrap" mr={1} color={color} variant="body2">
        {`${approvedSteps} / ${totalSteps}`}
      </Typography>
      <Icon color={color} />
    </Box>
  );
}
