import { ReactElement } from 'react';

import { Alert, Box, Typography } from '@mui/material';

import { getRUMInfo } from '../../utils/getRUMInfo';

export type PageAlertProps = {
  message: string | ReactElement;
  severity: 'error' | 'warning' | 'info' | 'success';
  showRetryMessage: boolean;
  retryMessage?: string | undefined;
};

export function PageAlert({
  message,
  severity,
  showRetryMessage,
  retryMessage,
}: PageAlertProps) {
  const extraMessage =
    retryMessage ||
    'Por favor, tente novamente em alguns instantes. Se o problema persistir, entre em contato com o suporte da Tako.';
  const rumInfo = getRUMInfo();
  return (
    <Box
      position="fixed"
      top="50%"
      left="50%"
      sx={{
        transform: 'translate(-50%, -50%)',
      }}
      minWidth="280px"
    >
      <Box data-testid="page-alert">
        <Alert
          severity={severity}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            p: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Typography variant="body1" fontWeight="700" component="span">
              {message}
            </Typography>
            {showRetryMessage && (
              <>
                <Typography
                  variant="body2"
                  fontWeight="400"
                  sx={{ whiteSpace: 'pre-line' }}
                  component="span"
                >
                  {extraMessage}
                </Typography>
                {rumInfo && rumInfo.sessionId && (
                  <Typography variant="body2" fontWeight={700}>
                    SessionId: {rumInfo.sessionId}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Alert>
      </Box>
    </Box>
  );
}
