/**
 * Generated by @openapi-codegen
 *
 * @version 0.0.1
 */
import * as reactQuery from '@tanstack/react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';

export type GetUserIdentityError = Fetcher.ErrorWrapper<undefined>;

export type GetUserIdentityVariables = ApiContext['fetcherOptions'];

/**
 * Retrieves user information from authentication token
 */
export const fetchGetUserIdentity = (
  variables: GetUserIdentityVariables,
  signal?: AbortSignal,
) =>
  apiFetch<Schemas.User, GetUserIdentityError, undefined, {}, {}, {}>({
    url: '/api/user-auth/identity',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Retrieves user information from authentication token
 */
export const useGetUserIdentity = <TData = Schemas.User,>(
  variables: GetUserIdentityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.User, GetUserIdentityError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.User, GetUserIdentityError, TData>({
    queryKey: queryKeyFn({
      path: '/api/user-auth/identity',
      operationId: 'getUserIdentity',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserIdentity({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type LoginError = Fetcher.ErrorWrapper<undefined>;

export type LoginVariables = {
  body: Schemas.Credentials;
} & ApiContext['fetcherOptions'];

/**
 * Can respond either with bearer tokens and setting a refresh cookie or with an authentication challenge.
 */
export const fetchLogin = (variables: LoginVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.Challenge | Schemas.Token,
    LoginError,
    Schemas.Credentials,
    {},
    {},
    {}
  >({ url: '/api/user-auth/login', method: 'post', ...variables, signal });

/**
 * Can respond either with bearer tokens and setting a refresh cookie or with an authentication challenge.
 */
export const useLogin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Challenge | Schemas.Token,
      LoginError,
      LoginVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Challenge | Schemas.Token,
    LoginError,
    LoginVariables
  >({
    mutationFn: (variables: LoginVariables) =>
      fetchLogin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RespondToChallengeError = Fetcher.ErrorWrapper<undefined>;

export type RespondToChallengeVariables = {
  body: Schemas.Answer;
} & ApiContext['fetcherOptions'];

/**
 * Can respond either with bearer tokens and setting a refresh cookie or with another authentication challenge.
 */
export const fetchRespondToChallenge = (
  variables: RespondToChallengeVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.Challenge | Schemas.Token,
    RespondToChallengeError,
    Schemas.Answer,
    {},
    {},
    {}
  >({
    url: '/api/user-auth/login/challenge',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Can respond either with bearer tokens and setting a refresh cookie or with another authentication challenge.
 */
export const useRespondToChallenge = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.Challenge | Schemas.Token,
      RespondToChallengeError,
      RespondToChallengeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.Challenge | Schemas.Token,
    RespondToChallengeError,
    RespondToChallengeVariables
  >({
    mutationFn: (variables: RespondToChallengeVariables) =>
      fetchRespondToChallenge({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RefreshTokenError = Fetcher.ErrorWrapper<undefined>;

export type RefreshTokenVariables = ApiContext['fetcherOptions'];

/**
 * Uses refresh cookie to retrieve a new bearer token, redirects to login in case of failure
 */
export const fetchRefreshToken = (
  variables: RefreshTokenVariables,
  signal?: AbortSignal,
) =>
  apiFetch<Schemas.Token, RefreshTokenError, undefined, {}, {}, {}>({
    url: '/api/user-auth/login/token',
    method: 'get',
    ...variables,
    signal,
  });

/**
 * Uses refresh cookie to retrieve a new bearer token, redirects to login in case of failure
 */
export const useRefreshToken = <TData = Schemas.Token,>(
  variables: RefreshTokenVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Token, RefreshTokenError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Token, RefreshTokenError, TData>({
    queryKey: queryKeyFn({
      path: '/api/user-auth/login/token',
      operationId: 'refreshToken',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchRefreshToken({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type LogoutError = Fetcher.ErrorWrapper<undefined>;

export type LogoutVariables = ApiContext['fetcherOptions'];

/**
 * Invalidates refresh token and clears user cookies
 */
export const fetchLogout = (variables: LogoutVariables, signal?: AbortSignal) =>
  apiFetch<undefined, LogoutError, undefined, {}, {}, {}>({
    url: '/api/user-auth/logout',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Invalidates refresh token and clears user cookies
 */
export const useLogout = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, LogoutError, LogoutVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, LogoutError, LogoutVariables>({
    mutationFn: (variables: LogoutVariables) =>
      fetchLogout({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangePasswordError = Fetcher.ErrorWrapper<undefined>;

export type ChangePasswordVariables = {
  body: Schemas.ChangePasswordInput;
} & ApiContext['fetcherOptions'];

/**
 * Updates a user password
 */
export const fetchChangePassword = (
  variables: ChangePasswordVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ChangePasswordError,
    Schemas.ChangePasswordInput,
    {},
    {},
    {}
  >({ url: '/api/user-auth/password', method: 'post', ...variables, signal });

/**
 * Updates a user password
 */
export const useChangePassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ChangePasswordError,
      ChangePasswordVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ChangePasswordError,
    ChangePasswordVariables
  >({
    mutationFn: (variables: ChangePasswordVariables) =>
      fetchChangePassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type StartPasswordRecoveryError = Fetcher.ErrorWrapper<undefined>;

export type StartPasswordRecoveryVariables = {
  body: Schemas.StartPasswordRecoveryInput;
} & ApiContext['fetcherOptions'];

/**
 * Triggers a password recovery flow, which sends an email to the user that can be used to set a new password
 */
export const fetchStartPasswordRecovery = (
  variables: StartPasswordRecoveryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    StartPasswordRecoveryError,
    Schemas.StartPasswordRecoveryInput,
    {},
    {},
    {}
  >({
    url: '/api/user-auth/password/recovery',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Triggers a password recovery flow, which sends an email to the user that can be used to set a new password
 */
export const useStartPasswordRecovery = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      StartPasswordRecoveryError,
      StartPasswordRecoveryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    StartPasswordRecoveryError,
    StartPasswordRecoveryVariables
  >({
    mutationFn: (variables: StartPasswordRecoveryVariables) =>
      fetchStartPasswordRecovery({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ConfirmPasswordRecoveryError = Fetcher.ErrorWrapper<undefined>;

export type ConfirmPasswordRecoveryVariables = {
  body: Schemas.ConfirmPasswordRecoveryInput;
} & ApiContext['fetcherOptions'];

/**
 * Sets a new password for user using a recovery code received by email
 */
export const fetchConfirmPasswordRecovery = (
  variables: ConfirmPasswordRecoveryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ConfirmPasswordRecoveryError,
    Schemas.ConfirmPasswordRecoveryInput,
    {},
    {},
    {}
  >({
    url: '/api/user-auth/password/recovery/code',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Sets a new password for user using a recovery code received by email
 */
export const useConfirmPasswordRecovery = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ConfirmPasswordRecoveryError,
      ConfirmPasswordRecoveryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ConfirmPasswordRecoveryError,
    ConfirmPasswordRecoveryVariables
  >({
    mutationFn: (variables: ConfirmPasswordRecoveryVariables) =>
      fetchConfirmPasswordRecovery({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateUserError = Fetcher.ErrorWrapper<undefined>;

export type CreateUserVariables = {
  body: Schemas.UserInput;
} & ApiContext['fetcherOptions'];

export const fetchCreateUser = (
  variables: CreateUserVariables,
  signal?: AbortSignal,
) =>
  apiFetch<Schemas.UserEntry, CreateUserError, Schemas.UserInput, {}, {}, {}>({
    url: '/api/users',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserEntry,
      CreateUserError,
      CreateUserVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.UserEntry,
    CreateUserError,
    CreateUserVariables
  >({
    mutationFn: (variables: CreateUserVariables) =>
      fetchCreateUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUsersByIdsError = Fetcher.ErrorWrapper<undefined>;

export type GetUsersByIdsResponse = Schemas.UserData[];

export type GetUsersByIdsRequestBody = {
  required?: void;
  /**
   * @minItems 1
   * @maxItems 50
   */
  userIds?: string[];
};

export type GetUsersByIdsVariables = {
  body?: GetUsersByIdsRequestBody;
} & ApiContext['fetcherOptions'];

export const fetchGetUsersByIds = (
  variables: GetUsersByIdsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetUsersByIdsResponse,
    GetUsersByIdsError,
    GetUsersByIdsRequestBody,
    {},
    {},
    {}
  >({ url: '/api/users/data', method: 'post', ...variables, signal });

export const useGetUsersByIds = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      GetUsersByIdsResponse,
      GetUsersByIdsError,
      GetUsersByIdsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    GetUsersByIdsResponse,
    GetUsersByIdsError,
    GetUsersByIdsVariables
  >({
    mutationFn: (variables: GetUsersByIdsVariables) =>
      fetchGetUsersByIds({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserByIdPathParams = {
  /**
   * @format uuid
   */
  userId: string;
};

export type GetUserByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetUserByIdVariables = {
  pathParams: GetUserByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetUserById = (
  variables: GetUserByIdVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.UserData,
    GetUserByIdError,
    undefined,
    {},
    {},
    GetUserByIdPathParams
  >({ url: '/api/users/{userId}', method: 'get', ...variables, signal });

export const useGetUserById = <TData = Schemas.UserData,>(
  variables: GetUserByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.UserData, GetUserByIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.UserData, GetUserByIdError, TData>({
    queryKey: queryKeyFn({
      path: '/api/users/{userId}',
      operationId: 'getUserById',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserById({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateUserPathParams = {
  /**
   * @format uuid
   */
  userId: string;
};

export type UpdateUserError = Fetcher.ErrorWrapper<undefined>;

export type UpdateUserVariables = {
  body?: Schemas.UserUpdate;
  pathParams: UpdateUserPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateUser = (
  variables: UpdateUserVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.UserEntry,
    UpdateUserError,
    Schemas.UserUpdate,
    {},
    {},
    UpdateUserPathParams
  >({ url: '/api/users/{userId}', method: 'put', ...variables, signal });

export const useUpdateUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserEntry,
      UpdateUserError,
      UpdateUserVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.UserEntry,
    UpdateUserError,
    UpdateUserVariables
  >({
    mutationFn: (variables: UpdateUserVariables) =>
      fetchUpdateUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResendUserCreationEmailError = Fetcher.ErrorWrapper<undefined>;

export type ResendUserCreationEmailVariables = {
  body: Schemas.Email;
} & ApiContext['fetcherOptions'];

export const fetchResendUserCreationEmail = (
  variables: ResendUserCreationEmailVariables,
  signal?: AbortSignal,
) =>
  apiFetch<undefined, ResendUserCreationEmailError, Schemas.Email, {}, {}, {}>({
    url: '/api/users/resend-creation-email',
    method: 'post',
    ...variables,
    signal,
  });

export const useResendUserCreationEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ResendUserCreationEmailError,
      ResendUserCreationEmailVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ResendUserCreationEmailError,
    ResendUserCreationEmailVariables
  >({
    mutationFn: (variables: ResendUserCreationEmailVariables) =>
      fetchResendUserCreationEmail({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DisableUserAccessError = Fetcher.ErrorWrapper<undefined>;

export type DisableUserAccessVariables = {
  body: Schemas.Email;
} & ApiContext['fetcherOptions'];

export const fetchDisableUserAccess = (
  variables: DisableUserAccessVariables,
  signal?: AbortSignal,
) =>
  apiFetch<undefined, DisableUserAccessError, Schemas.Email, {}, {}, {}>({
    url: '/api/users/disable-user-access',
    method: 'post',
    ...variables,
    signal,
  });

export const useDisableUserAccess = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DisableUserAccessError,
      DisableUserAccessVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DisableUserAccessError,
    DisableUserAccessVariables
  >({
    mutationFn: (variables: DisableUserAccessVariables) =>
      fetchDisableUserAccess({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EnableUserAccessError = Fetcher.ErrorWrapper<undefined>;

export type EnableUserAccessVariables = {
  body: Schemas.Email;
} & ApiContext['fetcherOptions'];

export const fetchEnableUserAccess = (
  variables: EnableUserAccessVariables,
  signal?: AbortSignal,
) =>
  apiFetch<undefined, EnableUserAccessError, Schemas.Email, {}, {}, {}>({
    url: '/api/users/enable-user-access',
    method: 'post',
    ...variables,
    signal,
  });

export const useEnableUserAccess = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      EnableUserAccessError,
      EnableUserAccessVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    EnableUserAccessError,
    EnableUserAccessVariables
  >({
    mutationFn: (variables: EnableUserAccessVariables) =>
      fetchEnableUserAccess({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserMembershipPathParams = {
  /**
   * @format uuid
   */
  userId: string;
};

export type GetUserMembershipQueryParams = {
  query?: string;
  size?: string;
  page?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  rangeFilters?: string;
  elementFilters?: string;
};

export type GetUserMembershipError = Fetcher.ErrorWrapper<undefined>;

export type GetUserMembershipVariables = {
  pathParams: GetUserMembershipPathParams;
  queryParams?: GetUserMembershipQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetUserMembership = (
  variables: GetUserMembershipVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.MembershipList,
    GetUserMembershipError,
    undefined,
    {},
    GetUserMembershipQueryParams,
    GetUserMembershipPathParams
  >({
    url: '/api/users/{userId}/membership',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetUserMembership = <TData = Schemas.MembershipList,>(
  variables: GetUserMembershipVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MembershipList,
      GetUserMembershipError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.MembershipList,
    GetUserMembershipError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/users/{userId}/membership',
      operationId: 'getUserMembership',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserMembership({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type LookupUserError = Fetcher.ErrorWrapper<undefined>;

export type LookupUserVariables = {
  body: Schemas.UserLookupInput;
} & ApiContext['fetcherOptions'];

export const fetchLookupUser = (
  variables: LookupUserVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.UserLookupEntry,
    LookupUserError,
    Schemas.UserLookupInput,
    {},
    {},
    {}
  >({ url: '/api/users/lookup', method: 'post', ...variables, signal });

export const useLookupUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserLookupEntry,
      LookupUserError,
      LookupUserVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.UserLookupEntry,
    LookupUserError,
    LookupUserVariables
  >({
    mutationFn: (variables: LookupUserVariables) =>
      fetchLookupUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserByPersonIdPathParams = {
  /**
   * A unique identifier for a person
   *
   * @format uuid
   */
  personId: string;
};

export type GetUserByPersonIdError = Fetcher.ErrorWrapper<undefined>;

export type GetUserByPersonIdVariables = {
  pathParams: GetUserByPersonIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetUserByPersonId = (
  variables: GetUserByPersonIdVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.UserLookupEntry,
    GetUserByPersonIdError,
    undefined,
    {},
    {},
    GetUserByPersonIdPathParams
  >({
    url: '/api/users/people/{personId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetUserByPersonId = <TData = Schemas.UserLookupEntry,>(
  variables: GetUserByPersonIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.UserLookupEntry,
      GetUserByPersonIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.UserLookupEntry,
    GetUserByPersonIdError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/users/people/{personId}',
      operationId: 'getUserByPersonId',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetUserByPersonId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetOrganizationsQueryParams = {
  query?: string;
  size?: string;
  page?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  elementFilters?: string;
};

export type GetOrganizationsError = Fetcher.ErrorWrapper<undefined>;

export type GetOrganizationsVariables = {
  queryParams?: GetOrganizationsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetOrganizations = (
  variables: GetOrganizationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.OrganizationList,
    GetOrganizationsError,
    undefined,
    {},
    GetOrganizationsQueryParams,
    {}
  >({ url: '/api/organizations/', method: 'get', ...variables, signal });

export const useGetOrganizations = <TData = Schemas.OrganizationList,>(
  variables: GetOrganizationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrganizationList,
      GetOrganizationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrganizationList,
    GetOrganizationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/',
      operationId: 'getOrganizations',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetOrganizations({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostOrganizationsError = Fetcher.ErrorWrapper<undefined>;

export type PostOrganizationsVariables = {
  body: Schemas.OrganizationInput;
} & ApiContext['fetcherOptions'];

export const fetchPostOrganizations = (
  variables: PostOrganizationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.OrganizationEntry,
    PostOrganizationsError,
    Schemas.OrganizationInput,
    {},
    {},
    {}
  >({ url: '/api/organizations/', method: 'post', ...variables, signal });

export const usePostOrganizations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrganizationEntry,
      PostOrganizationsError,
      PostOrganizationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrganizationEntry,
    PostOrganizationsError,
    PostOrganizationsVariables
  >({
    mutationFn: (variables: PostOrganizationsVariables) =>
      fetchPostOrganizations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetOrganizationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetOrganizationEntryError = Fetcher.ErrorWrapper<undefined>;

export type GetOrganizationEntryVariables = {
  pathParams: GetOrganizationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetOrganizationEntry = (
  variables: GetOrganizationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.OrganizationEntry,
    GetOrganizationEntryError,
    undefined,
    {},
    {},
    GetOrganizationEntryPathParams
  >({
    url: '/api/organizations/{organizationId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetOrganizationEntry = <TData = Schemas.OrganizationEntry,>(
  variables: GetOrganizationEntryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrganizationEntry,
      GetOrganizationEntryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrganizationEntry,
    GetOrganizationEntryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}',
      operationId: 'getOrganizationEntry',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetOrganizationEntry({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutOrganizationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PutOrganizationEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutOrganizationEntryVariables = {
  body?: Schemas.OrganizationUpdateInput;
  pathParams: PutOrganizationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutOrganizationEntry = (
  variables: PutOrganizationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.OrganizationEntry,
    PutOrganizationEntryError,
    Schemas.OrganizationUpdateInput,
    {},
    {},
    PutOrganizationEntryPathParams
  >({
    url: '/api/organizations/{organizationId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutOrganizationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrganizationEntry,
      PutOrganizationEntryError,
      PutOrganizationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrganizationEntry,
    PutOrganizationEntryError,
    PutOrganizationEntryVariables
  >({
    mutationFn: (variables: PutOrganizationEntryVariables) =>
      fetchPutOrganizationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteOrganizationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type DeleteOrganizationEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteOrganizationEntryVariables = {
  pathParams: DeleteOrganizationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteOrganizationEntry = (
  variables: DeleteOrganizationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteOrganizationEntryError,
    undefined,
    {},
    {},
    DeleteOrganizationEntryPathParams
  >({
    url: '/api/organizations/{organizationId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteOrganizationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteOrganizationEntryError,
      DeleteOrganizationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteOrganizationEntryError,
    DeleteOrganizationEntryVariables
  >({
    mutationFn: (variables: DeleteOrganizationEntryVariables) =>
      fetchDeleteOrganizationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ActivateOrganizationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type ActivateOrganizationEntryError = Fetcher.ErrorWrapper<undefined>;

export type ActivateOrganizationEntryVariables = {
  pathParams: ActivateOrganizationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchActivateOrganizationEntry = (
  variables: ActivateOrganizationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ActivateOrganizationEntryError,
    undefined,
    {},
    {},
    ActivateOrganizationEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/activate',
    method: 'post',
    ...variables,
    signal,
  });

export const useActivateOrganizationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ActivateOrganizationEntryError,
      ActivateOrganizationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ActivateOrganizationEntryError,
    ActivateOrganizationEntryVariables
  >({
    mutationFn: (variables: ActivateOrganizationEntryVariables) =>
      fetchActivateOrganizationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeactivateOrganizationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type DeactivateOrganizationEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeactivateOrganizationEntryVariables = {
  pathParams: DeactivateOrganizationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeactivateOrganizationEntry = (
  variables: DeactivateOrganizationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeactivateOrganizationEntryError,
    undefined,
    {},
    {},
    DeactivateOrganizationEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/deactivate',
    method: 'post',
    ...variables,
    signal,
  });

export const useDeactivateOrganizationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeactivateOrganizationEntryError,
      DeactivateOrganizationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeactivateOrganizationEntryError,
    DeactivateOrganizationEntryVariables
  >({
    mutationFn: (variables: DeactivateOrganizationEntryVariables) =>
      fetchDeactivateOrganizationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllMembershipPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetAllMembershipQueryParams = {
  query?: string;
  size?: string;
  page?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  rangeFilters?: string;
  elementFilters?: string;
};

export type GetAllMembershipError = Fetcher.ErrorWrapper<undefined>;

export type GetAllMembershipVariables = {
  pathParams: GetAllMembershipPathParams;
  queryParams?: GetAllMembershipQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAllMembership = (
  variables: GetAllMembershipVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.MembershipList,
    GetAllMembershipError,
    undefined,
    {},
    GetAllMembershipQueryParams,
    GetAllMembershipPathParams
  >({
    url: '/api/organizations/{organizationId}/membership',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllMembership = <TData = Schemas.MembershipList,>(
  variables: GetAllMembershipVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MembershipList,
      GetAllMembershipError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.MembershipList,
    GetAllMembershipError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/membership',
      operationId: 'getAllMembership',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllMembership({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostMembershipPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PostMembershipError = Fetcher.ErrorWrapper<undefined>;

export type PostMembershipVariables = {
  body: Schemas.MembershipInput;
  pathParams: PostMembershipPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostMembership = (
  variables: PostMembershipVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.MembershipEntry,
    PostMembershipError,
    Schemas.MembershipInput,
    {},
    {},
    PostMembershipPathParams
  >({
    url: '/api/organizations/{organizationId}/membership',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostMembership = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MembershipEntry,
      PostMembershipError,
      PostMembershipVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.MembershipEntry,
    PostMembershipError,
    PostMembershipVariables
  >({
    mutationFn: (variables: PostMembershipVariables) =>
      fetchPostMembership({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetMembershipByUserIdPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * @format uuid
   */
  userId: string;
};

export type GetMembershipByUserIdError = Fetcher.ErrorWrapper<undefined>;

export type GetMembershipByUserIdVariables = {
  pathParams: GetMembershipByUserIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetMembershipByUserId = (
  variables: GetMembershipByUserIdVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.MembershipEntry,
    GetMembershipByUserIdError,
    undefined,
    {},
    {},
    GetMembershipByUserIdPathParams
  >({
    url: '/api/organizations/{organizationId}/membership/user/{userId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetMembershipByUserId = <TData = Schemas.MembershipEntry,>(
  variables: GetMembershipByUserIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MembershipEntry,
      GetMembershipByUserIdError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.MembershipEntry,
    GetMembershipByUserIdError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/membership/user/{userId}',
      operationId: 'getMembershipByUserId',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMembershipByUserId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetMembershipEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the membership to retrieve
   *
   * @format uuid
   */
  membershipId: string;
};

export type GetMembershipEntryError = Fetcher.ErrorWrapper<undefined>;

export type GetMembershipEntryVariables = {
  pathParams: GetMembershipEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetMembershipEntry = (
  variables: GetMembershipEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.MembershipEntry,
    GetMembershipEntryError,
    undefined,
    {},
    {},
    GetMembershipEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/membership/{membershipId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetMembershipEntry = <TData = Schemas.MembershipEntry,>(
  variables: GetMembershipEntryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.MembershipEntry,
      GetMembershipEntryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.MembershipEntry,
    GetMembershipEntryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/membership/{membershipId}',
      operationId: 'getMembershipEntry',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMembershipEntry({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteMembershipEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the membership to retrieve
   *
   * @format uuid
   */
  membershipId: string;
};

export type DeleteMembershipEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteMembershipEntryVariables = {
  pathParams: DeleteMembershipEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteMembershipEntry = (
  variables: DeleteMembershipEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteMembershipEntryError,
    undefined,
    {},
    {},
    DeleteMembershipEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/membership/{membershipId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteMembershipEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteMembershipEntryError,
      DeleteMembershipEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteMembershipEntryError,
    DeleteMembershipEntryVariables
  >({
    mutationFn: (variables: DeleteMembershipEntryVariables) =>
      fetchDeleteMembershipEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateMembershipEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the membership to retrieve
   *
   * @format uuid
   */
  membershipId: string;
};

export type UpdateMembershipEntryError = Fetcher.ErrorWrapper<undefined>;

export type UpdateMembershipEntryVariables = {
  body: Schemas.MembershipUpdateInput;
  pathParams: UpdateMembershipEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateMembershipEntry = (
  variables: UpdateMembershipEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.MembershipEntry,
    UpdateMembershipEntryError,
    Schemas.MembershipUpdateInput,
    {},
    {},
    UpdateMembershipEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/membership/{membershipId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateMembershipEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.MembershipEntry,
      UpdateMembershipEntryError,
      UpdateMembershipEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.MembershipEntry,
    UpdateMembershipEntryError,
    UpdateMembershipEntryVariables
  >({
    mutationFn: (variables: UpdateMembershipEntryVariables) =>
      fetchUpdateMembershipEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ActivateMembershipEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the membership to retrieve
   *
   * @format uuid
   */
  membershipId: string;
};

export type ActivateMembershipEntryError = Fetcher.ErrorWrapper<undefined>;

export type ActivateMembershipEntryVariables = {
  pathParams: ActivateMembershipEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchActivateMembershipEntry = (
  variables: ActivateMembershipEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ActivateMembershipEntryError,
    undefined,
    {},
    {},
    ActivateMembershipEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/membership/{membershipId}/activate',
    method: 'post',
    ...variables,
    signal,
  });

export const useActivateMembershipEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ActivateMembershipEntryError,
      ActivateMembershipEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ActivateMembershipEntryError,
    ActivateMembershipEntryVariables
  >({
    mutationFn: (variables: ActivateMembershipEntryVariables) =>
      fetchActivateMembershipEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeactivateMembershipEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the membership to retrieve
   *
   * @format uuid
   */
  membershipId: string;
};

export type DeactivateMembershipEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeactivateMembershipEntryVariables = {
  pathParams: DeactivateMembershipEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeactivateMembershipEntry = (
  variables: DeactivateMembershipEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeactivateMembershipEntryError,
    undefined,
    {},
    {},
    DeactivateMembershipEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/membership/{membershipId}/deactivate',
    method: 'post',
    ...variables,
    signal,
  });

export const useDeactivateMembershipEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeactivateMembershipEntryError,
      DeactivateMembershipEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeactivateMembershipEntryError,
    DeactivateMembershipEntryVariables
  >({
    mutationFn: (variables: DeactivateMembershipEntryVariables) =>
      fetchDeactivateMembershipEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type HandlePostPublicAssetsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type HandlePostPublicAssetsError = Fetcher.ErrorWrapper<undefined>;

export type HandlePostPublicAssetsVariables = {
  body: Schemas.OrganizationPublicAssetsInput;
  pathParams: HandlePostPublicAssetsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchHandlePostPublicAssets = (
  variables: HandlePostPublicAssetsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.OrganizationPublicAssetsEntry,
    HandlePostPublicAssetsError,
    Schemas.OrganizationPublicAssetsInput,
    {},
    {},
    HandlePostPublicAssetsPathParams
  >({
    url: '/api/organizations/{organizationId}/public-assets/upload',
    method: 'post',
    ...variables,
    signal,
  });

export const useHandlePostPublicAssets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrganizationPublicAssetsEntry,
      HandlePostPublicAssetsError,
      HandlePostPublicAssetsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.OrganizationPublicAssetsEntry,
    HandlePostPublicAssetsError,
    HandlePostPublicAssetsVariables
  >({
    mutationFn: (variables: HandlePostPublicAssetsVariables) =>
      fetchHandlePostPublicAssets({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllCompaniesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetAllCompaniesQueryParams = {
  query?: string;
  size?: string;
  page?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  rangeFilters?: string;
  elementFilters?: string;
};

export type GetAllCompaniesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllCompaniesVariables = {
  pathParams: GetAllCompaniesPathParams;
  queryParams?: GetAllCompaniesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAllCompanies = (
  variables: GetAllCompaniesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CompanyList,
    GetAllCompaniesError,
    undefined,
    {},
    GetAllCompaniesQueryParams,
    GetAllCompaniesPathParams
  >({
    url: '/api/organizations/{organizationId}/companies',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllCompanies = <TData = Schemas.CompanyList,>(
  variables: GetAllCompaniesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CompanyList,
      GetAllCompaniesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.CompanyList, GetAllCompaniesError, TData>({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies',
      operationId: 'getAllCompanies',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllCompanies({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostCompanyPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PostCompanyError = Fetcher.ErrorWrapper<undefined>;

export type PostCompanyVariables = {
  body: Schemas.CompanyInput;
  pathParams: PostCompanyPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostCompany = (
  variables: PostCompanyVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CompanyEntry,
    PostCompanyError,
    Schemas.CompanyInput,
    {},
    {},
    PostCompanyPathParams
  >({
    url: '/api/organizations/{organizationId}/companies',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostCompany = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CompanyEntry,
      PostCompanyError,
      PostCompanyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CompanyEntry,
    PostCompanyError,
    PostCompanyVariables
  >({
    mutationFn: (variables: PostCompanyVariables) =>
      fetchPostCompany({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCompanyEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type GetCompanyEntryError = Fetcher.ErrorWrapper<undefined>;

export type GetCompanyEntryVariables = {
  pathParams: GetCompanyEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetCompanyEntry = (
  variables: GetCompanyEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CompanyEntry,
    GetCompanyEntryError,
    undefined,
    {},
    {},
    GetCompanyEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetCompanyEntry = <TData = Schemas.CompanyEntry,>(
  variables: GetCompanyEntryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CompanyEntry,
      GetCompanyEntryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.CompanyEntry, GetCompanyEntryError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/api/organizations/{organizationId}/companies/{companyId}',
        operationId: 'getCompanyEntry',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchGetCompanyEntry({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    },
  );
};

export type PutCompanyEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PutCompanyEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutCompanyEntryVariables = {
  body: Schemas.CompanyInput;
  pathParams: PutCompanyEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutCompanyEntry = (
  variables: PutCompanyEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CompanyEntry,
    PutCompanyEntryError,
    Schemas.CompanyInput,
    {},
    {},
    PutCompanyEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutCompanyEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CompanyEntry,
      PutCompanyEntryError,
      PutCompanyEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CompanyEntry,
    PutCompanyEntryError,
    PutCompanyEntryVariables
  >({
    mutationFn: (variables: PutCompanyEntryVariables) =>
      fetchPutCompanyEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteCompanyEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type DeleteCompanyEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteCompanyEntryVariables = {
  pathParams: DeleteCompanyEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteCompanyEntry = (
  variables: DeleteCompanyEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteCompanyEntryError,
    undefined,
    {},
    {},
    DeleteCompanyEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteCompanyEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteCompanyEntryError,
      DeleteCompanyEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteCompanyEntryError,
    DeleteCompanyEntryVariables
  >({
    mutationFn: (variables: DeleteCompanyEntryVariables) =>
      fetchDeleteCompanyEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ActivateCompanyEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type ActivateCompanyEntryError = Fetcher.ErrorWrapper<undefined>;

export type ActivateCompanyEntryVariables = {
  pathParams: ActivateCompanyEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchActivateCompanyEntry = (
  variables: ActivateCompanyEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ActivateCompanyEntryError,
    undefined,
    {},
    {},
    ActivateCompanyEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/activate',
    method: 'post',
    ...variables,
    signal,
  });

export const useActivateCompanyEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ActivateCompanyEntryError,
      ActivateCompanyEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ActivateCompanyEntryError,
    ActivateCompanyEntryVariables
  >({
    mutationFn: (variables: ActivateCompanyEntryVariables) =>
      fetchActivateCompanyEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeactivateCompanyEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type DeactivateCompanyEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeactivateCompanyEntryVariables = {
  pathParams: DeactivateCompanyEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeactivateCompanyEntry = (
  variables: DeactivateCompanyEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeactivateCompanyEntryError,
    undefined,
    {},
    {},
    DeactivateCompanyEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/deactivate',
    method: 'post',
    ...variables,
    signal,
  });

export const useDeactivateCompanyEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeactivateCompanyEntryError,
      DeactivateCompanyEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeactivateCompanyEntryError,
    DeactivateCompanyEntryVariables
  >({
    mutationFn: (variables: DeactivateCompanyEntryVariables) =>
      fetchDeactivateCompanyEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostCertificatePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PostCertificateError = Fetcher.ErrorWrapper<undefined>;

export type PostCertificateVariables = {
  body: Schemas.CertificateInput;
  pathParams: PostCertificatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostCertificate = (
  variables: PostCertificateVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CertificateEntry,
    PostCertificateError,
    Schemas.CertificateInput,
    {},
    {},
    PostCertificatePathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/certificates',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostCertificate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CertificateEntry,
      PostCertificateError,
      PostCertificateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CertificateEntry,
    PostCertificateError,
    PostCertificateVariables
  >({
    mutationFn: (variables: PostCertificateVariables) =>
      fetchPostCertificate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchAllPayrollsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type SearchAllPayrollsError = Fetcher.ErrorWrapper<undefined>;

export type SearchAllPayrollsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAllPayrollsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAllPayrolls = (
  variables: SearchAllPayrollsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollList,
    SearchAllPayrollsError,
    Schemas.SearchInput,
    {},
    {},
    SearchAllPayrollsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAllPayrolls = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollList,
      SearchAllPayrollsError,
      SearchAllPayrollsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollList,
    SearchAllPayrollsError,
    SearchAllPayrollsVariables
  >({
    mutationFn: (variables: SearchAllPayrollsVariables) =>
      fetchSearchAllPayrolls({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllCompanyPayrollConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type GetAllCompanyPayrollConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAllCompanyPayrollConfigurationResponse = {
  data: Schemas.PayrollConfigurationEntry[];
};

export type GetAllCompanyPayrollConfigurationVariables = {
  pathParams: GetAllCompanyPayrollConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAllCompanyPayrollConfiguration = (
  variables: GetAllCompanyPayrollConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetAllCompanyPayrollConfigurationResponse,
    GetAllCompanyPayrollConfigurationError,
    undefined,
    {},
    {},
    GetAllCompanyPayrollConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/configurations',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllCompanyPayrollConfiguration = <
  TData = GetAllCompanyPayrollConfigurationResponse,
>(
  variables: GetAllCompanyPayrollConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllCompanyPayrollConfigurationResponse,
      GetAllCompanyPayrollConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    GetAllCompanyPayrollConfigurationResponse,
    GetAllCompanyPayrollConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/configurations',
      operationId: 'getAllCompanyPayrollConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllCompanyPayrollConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostCompanyPayrollConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PostCompanyPayrollConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type PostCompanyPayrollConfigurationVariables = {
  body: Schemas.PayrollConfigurationInput;
  pathParams: PostCompanyPayrollConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostCompanyPayrollConfiguration = (
  variables: PostCompanyPayrollConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollConfigurationEntry,
    PostCompanyPayrollConfigurationError,
    Schemas.PayrollConfigurationInput,
    {},
    {},
    PostCompanyPayrollConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostCompanyPayrollConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollConfigurationEntry,
      PostCompanyPayrollConfigurationError,
      PostCompanyPayrollConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollConfigurationEntry,
    PostCompanyPayrollConfigurationError,
    PostCompanyPayrollConfigurationVariables
  >({
    mutationFn: (variables: PostCompanyPayrollConfigurationVariables) =>
      fetchPostCompanyPayrollConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCompanyPayrollConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * @format date
   */
  effectiveDate?: string;
};

export type GetCompanyPayrollConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetCompanyPayrollConfigurationVariables = {
  pathParams: GetCompanyPayrollConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetCompanyPayrollConfiguration = (
  variables: GetCompanyPayrollConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollConfigurationEntry,
    GetCompanyPayrollConfigurationError,
    undefined,
    {},
    {},
    GetCompanyPayrollConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/configurations/{effectiveDate}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetCompanyPayrollConfiguration = <
  TData = Schemas.PayrollConfigurationEntry,
>(
  variables: GetCompanyPayrollConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollConfigurationEntry,
      GetCompanyPayrollConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollConfigurationEntry,
    GetCompanyPayrollConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/configurations/{effectiveDate}',
      operationId: 'getCompanyPayrollConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCompanyPayrollConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PutCompanyPayrollConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * @format date
   */
  effectiveDate?: string;
};

export type PutCompanyPayrollConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type PutCompanyPayrollConfigurationVariables = {
  body: Schemas.PayrollConfigurationUpdate;
  pathParams: PutCompanyPayrollConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutCompanyPayrollConfiguration = (
  variables: PutCompanyPayrollConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PutCompanyPayrollConfigurationError,
    Schemas.PayrollConfigurationUpdate,
    {},
    {},
    PutCompanyPayrollConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/configurations/{effectiveDate}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutCompanyPayrollConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PutCompanyPayrollConfigurationError,
      PutCompanyPayrollConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PutCompanyPayrollConfigurationError,
    PutCompanyPayrollConfigurationVariables
  >({
    mutationFn: (variables: PutCompanyPayrollConfigurationVariables) =>
      fetchPutCompanyPayrollConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostPayrollsHistoricalEventsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PostPayrollsHistoricalEventsError = Fetcher.ErrorWrapper<undefined>;

export type PostPayrollsHistoricalEventsVariables = {
  body: Schemas.PayrollHistoricalEventInput;
  pathParams: PostPayrollsHistoricalEventsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPayrollsHistoricalEvents = (
  variables: PostPayrollsHistoricalEventsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PostPayrollsHistoricalEventsError,
    Schemas.PayrollHistoricalEventInput,
    {},
    {},
    PostPayrollsHistoricalEventsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/historical-events',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPayrollsHistoricalEvents = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostPayrollsHistoricalEventsError,
      PostPayrollsHistoricalEventsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PostPayrollsHistoricalEventsError,
    PostPayrollsHistoricalEventsVariables
  >({
    mutationFn: (variables: PostPayrollsHistoricalEventsVariables) =>
      fetchPostPayrollsHistoricalEvents({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePayrollsHistoricalEventsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type DeletePayrollsHistoricalEventsError =
  Fetcher.ErrorWrapper<undefined>;

export type DeletePayrollsHistoricalEventsVariables = {
  body: Schemas.PayrollHistoricalEventDelete;
  pathParams: DeletePayrollsHistoricalEventsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeletePayrollsHistoricalEvents = (
  variables: DeletePayrollsHistoricalEventsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeletePayrollsHistoricalEventsError,
    Schemas.PayrollHistoricalEventDelete,
    {},
    {},
    DeletePayrollsHistoricalEventsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/historical-events',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePayrollsHistoricalEvents = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePayrollsHistoricalEventsError,
      DeletePayrollsHistoricalEventsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeletePayrollsHistoricalEventsError,
    DeletePayrollsHistoricalEventsVariables
  >({
    mutationFn: (variables: DeletePayrollsHistoricalEventsVariables) =>
      fetchDeletePayrollsHistoricalEvents({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPayrollsHistoricalEventsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type GetPayrollsHistoricalEventsQueryParams = {
  /**
   * @format uuid
   */
  contractId?: string;
};

export type GetPayrollsHistoricalEventsError = Fetcher.ErrorWrapper<undefined>;

export type GetPayrollsHistoricalEventsResponse = void[];

export type GetPayrollsHistoricalEventsVariables = {
  pathParams: GetPayrollsHistoricalEventsPathParams;
  queryParams?: GetPayrollsHistoricalEventsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayrollsHistoricalEvents = (
  variables: GetPayrollsHistoricalEventsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetPayrollsHistoricalEventsResponse,
    GetPayrollsHistoricalEventsError,
    undefined,
    {},
    GetPayrollsHistoricalEventsQueryParams,
    GetPayrollsHistoricalEventsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/historical-events',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayrollsHistoricalEvents = <
  TData = GetPayrollsHistoricalEventsResponse,
>(
  variables: GetPayrollsHistoricalEventsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPayrollsHistoricalEventsResponse,
      GetPayrollsHistoricalEventsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    GetPayrollsHistoricalEventsResponse,
    GetPayrollsHistoricalEventsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/historical-events',
      operationId: 'getPayrollsHistoricalEvents',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayrollsHistoricalEvents(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type GetPeriodError = Fetcher.ErrorWrapper<undefined>;

export type GetPeriodVariables = {
  pathParams: GetPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPeriod = (
  variables: GetPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPeriodEntry,
    GetPeriodError,
    undefined,
    {},
    {},
    GetPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{period}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPeriod = <TData = Schemas.PayrollPeriodEntry,>(
  variables: GetPeriodVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollPeriodEntry,
      GetPeriodError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.PayrollPeriodEntry, GetPeriodError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{period}',
        operationId: 'getPeriod',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchGetPeriod({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    },
  );
};

export type PostPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type PostPeriodError = Fetcher.ErrorWrapper<undefined>;

export type PostPeriodVariables = {
  pathParams: PostPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPeriod = (
  variables: PostPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPeriodEntry,
    PostPeriodError,
    undefined,
    {},
    {},
    PostPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{period}',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollPeriodEntry,
      PostPeriodError,
      PostPeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollPeriodEntry,
    PostPeriodError,
    PostPeriodVariables
  >({
    mutationFn: (variables: PostPeriodVariables) =>
      fetchPostPeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PatchPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type PatchPeriodError = Fetcher.ErrorWrapper<undefined>;

export type PatchPeriodRequestBody = {
  br?: Schemas.PayrollPeriodBr;
};

export type PatchPeriodVariables = {
  body?: PatchPeriodRequestBody;
  pathParams: PatchPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPatchPeriod = (
  variables: PatchPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPeriodEntry,
    PatchPeriodError,
    PatchPeriodRequestBody,
    {},
    {},
    PatchPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{period}',
    method: 'patch',
    ...variables,
    signal,
  });

export const usePatchPeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollPeriodEntry,
      PatchPeriodError,
      PatchPeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollPeriodEntry,
    PatchPeriodError,
    PatchPeriodVariables
  >({
    mutationFn: (variables: PatchPeriodVariables) =>
      fetchPatchPeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApprovePeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type ApprovePeriodError = Fetcher.ErrorWrapper<undefined>;

export type ApprovePeriodVariables = {
  pathParams: ApprovePeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApprovePeriod = (
  variables: ApprovePeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ApprovePeriodError,
    undefined,
    {},
    {},
    ApprovePeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/approve',
    method: 'post',
    ...variables,
    signal,
  });

export const useApprovePeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ApprovePeriodError,
      ApprovePeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ApprovePeriodError,
    ApprovePeriodVariables
  >({
    mutationFn: (variables: ApprovePeriodVariables) =>
      fetchApprovePeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RecalculatePeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type RecalculatePeriodError = Fetcher.ErrorWrapper<undefined>;

export type RecalculatePeriodVariables = {
  pathParams: RecalculatePeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecalculatePeriod = (
  variables: RecalculatePeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    RecalculatePeriodError,
    undefined,
    {},
    {},
    RecalculatePeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/recalculate',
    method: 'post',
    ...variables,
    signal,
  });

export const useRecalculatePeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RecalculatePeriodError,
      RecalculatePeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RecalculatePeriodError,
    RecalculatePeriodVariables
  >({
    mutationFn: (variables: RecalculatePeriodVariables) =>
      fetchRecalculatePeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ReopenPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type ReopenPeriodError = Fetcher.ErrorWrapper<undefined>;

export type ReopenPeriodVariables = {
  pathParams: ReopenPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReopenPeriod = (
  variables: ReopenPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ReopenPeriodError,
    undefined,
    {},
    {},
    ReopenPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/reopen',
    method: 'post',
    ...variables,
    signal,
  });

export const useReopenPeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReopenPeriodError,
      ReopenPeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ReopenPeriodError,
    ReopenPeriodVariables
  >({
    mutationFn: (variables: ReopenPeriodVariables) =>
      fetchReopenPeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ClosePeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type ClosePeriodError = Fetcher.ErrorWrapper<undefined>;

export type ClosePeriodVariables = {
  pathParams: ClosePeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchClosePeriod = (
  variables: ClosePeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ClosePeriodError,
    undefined,
    {},
    {},
    ClosePeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/close',
    method: 'post',
    ...variables,
    signal,
  });

export const useClosePeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ClosePeriodError,
      ClosePeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ClosePeriodError,
    ClosePeriodVariables
  >({
    mutationFn: (variables: ClosePeriodVariables) =>
      fetchClosePeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type HandleErrorPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type HandleErrorPeriodError = Fetcher.ErrorWrapper<undefined>;

export type HandleErrorPeriodVariables = {
  pathParams: HandleErrorPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchHandleErrorPeriod = (
  variables: HandleErrorPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    HandleErrorPeriodError,
    undefined,
    {},
    {},
    HandleErrorPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/handleError',
    method: 'post',
    ...variables,
    signal,
  });

export const useHandleErrorPeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      HandleErrorPeriodError,
      HandleErrorPeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    HandleErrorPeriodError,
    HandleErrorPeriodVariables
  >({
    mutationFn: (variables: HandleErrorPeriodVariables) =>
      fetchHandleErrorPeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostPayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PostPayrollError = Fetcher.ErrorWrapper<undefined>;

export type PostPayrollVariables = {
  body: Schemas.PayrollInput;
  pathParams: PostPayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPayroll = (
  variables: PostPayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollEntry,
    PostPayrollError,
    Schemas.PayrollInput,
    {},
    {},
    PostPayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollEntry,
      PostPayrollError,
      PostPayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollEntry,
    PostPayrollError,
    PostPayrollVariables
  >({
    mutationFn: (variables: PostPayrollVariables) =>
      fetchPostPayroll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPayrollOrderPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the payroll order to retrieve
   */
  orderId: string;
};

export type GetPayrollOrderQueryParams = {
  /**
   * @default false
   */
  withExecutions?: boolean;
  /**
   * @default false
   */
  withResults?: boolean;
};

export type GetPayrollOrderError = Fetcher.ErrorWrapper<undefined>;

export type GetPayrollOrderVariables = {
  pathParams: GetPayrollOrderPathParams;
  queryParams?: GetPayrollOrderQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayrollOrder = (
  variables: GetPayrollOrderVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollOrderOutput,
    GetPayrollOrderError,
    undefined,
    {},
    GetPayrollOrderQueryParams,
    GetPayrollOrderPathParams
  >({
    url: '/api/organizations/{organizationId}/payrolls/orders/{orderId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayrollOrder = <TData = Schemas.PayrollOrderOutput,>(
  variables: GetPayrollOrderVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollOrderOutput,
      GetPayrollOrderError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollOrderOutput,
    GetPayrollOrderError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/payrolls/orders/{orderId}',
      operationId: 'getPayrollOrder',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayrollOrder({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPayrollOrdersPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetPayrollOrdersQueryParams = {
  withExecutions?: boolean;
  withResults?: boolean;
};

export type GetPayrollOrdersError = Fetcher.ErrorWrapper<undefined>;

export type GetPayrollOrdersResponse = Schemas.PayrollOrderOutput[];

export type GetPayrollOrdersVariables = {
  pathParams: GetPayrollOrdersPathParams;
  queryParams?: GetPayrollOrdersQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayrollOrders = (
  variables: GetPayrollOrdersVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetPayrollOrdersResponse,
    GetPayrollOrdersError,
    undefined,
    {},
    GetPayrollOrdersQueryParams,
    GetPayrollOrdersPathParams
  >({
    url: '/api/organizations/{organizationId}/payrolls/orders',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayrollOrders = <TData = GetPayrollOrdersResponse,>(
  variables: GetPayrollOrdersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetPayrollOrdersResponse,
      GetPayrollOrdersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    GetPayrollOrdersResponse,
    GetPayrollOrdersError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/payrolls/orders',
      operationId: 'getPayrollOrders',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayrollOrders({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostPayrollOrderPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PostPayrollOrderQueryParams = {
  retry?: boolean;
};

export type PostPayrollOrderError = Fetcher.ErrorWrapper<undefined>;

export type PostPayrollOrderVariables = {
  body: Schemas.PayrollOrderInput;
  pathParams: PostPayrollOrderPathParams;
  queryParams?: PostPayrollOrderQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPayrollOrder = (
  variables: PostPayrollOrderVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollOrderOutput,
    PostPayrollOrderError,
    Schemas.PayrollOrderInput,
    {},
    PostPayrollOrderQueryParams,
    PostPayrollOrderPathParams
  >({
    url: '/api/organizations/{organizationId}/payrolls/orders',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPayrollOrder = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollOrderOutput,
      PostPayrollOrderError,
      PostPayrollOrderVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollOrderOutput,
    PostPayrollOrderError,
    PostPayrollOrderVariables
  >({
    mutationFn: (variables: PostPayrollOrderVariables) =>
      fetchPostPayrollOrder({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RecalculatePayrollsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type RecalculatePayrollsError = Fetcher.ErrorWrapper<undefined>;

export type RecalculatePayrollsRequestBody = {
  /**
   * Tolerance for comparing results
   *
   * @default 0
   */
  tolerance?: number;
  period?: {
    start?: Schemas.DatePeriodFormat;
    end?: Schemas.DatePeriodFormat;
  };
};

export type RecalculatePayrollsVariables = {
  body?: RecalculatePayrollsRequestBody;
  pathParams: RecalculatePayrollsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecalculatePayrolls = (
  variables: RecalculatePayrollsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    RecalculatePayrollsError,
    RecalculatePayrollsRequestBody,
    {},
    {},
    RecalculatePayrollsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/recalculate',
    method: 'post',
    ...variables,
    signal,
  });

export const useRecalculatePayrolls = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RecalculatePayrollsError,
      RecalculatePayrollsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RecalculatePayrollsError,
    RecalculatePayrollsVariables
  >({
    mutationFn: (variables: RecalculatePayrollsVariables) =>
      fetchRecalculatePayrolls({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostRubricTableEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PostRubricTableEntryError = Fetcher.ErrorWrapper<undefined>;

export type PostRubricTableEntryVariables = {
  pathParams: PostRubricTableEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostRubricTableEntry = (
  variables: PostRubricTableEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.RubricsTableEntry,
    PostRubricTableEntryError,
    undefined,
    {},
    {},
    PostRubricTableEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/rubrics-tables',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostRubricTableEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RubricsTableEntry,
      PostRubricTableEntryError,
      PostRubricTableEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.RubricsTableEntry,
    PostRubricTableEntryError,
    PostRubricTableEntryVariables
  >({
    mutationFn: (variables: PostRubricTableEntryVariables) =>
      fetchPostRubricTableEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllRubricTablesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type GetAllRubricTablesQueryParams = {
  /**
   * @format date
   */
  effectiveDate?: string;
};

export type GetAllRubricTablesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllRubricTablesVariables = {
  pathParams: GetAllRubricTablesPathParams;
  queryParams?: GetAllRubricTablesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAllRubricTables = (
  variables: GetAllRubricTablesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.RubricsTableMap,
    GetAllRubricTablesError,
    undefined,
    {},
    GetAllRubricTablesQueryParams,
    GetAllRubricTablesPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/rubrics-tables',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllRubricTables = <TData = Schemas.RubricsTableMap,>(
  variables: GetAllRubricTablesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.RubricsTableMap,
      GetAllRubricTablesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.RubricsTableMap,
    GetAllRubricTablesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/rubrics-tables',
      operationId: 'getAllRubricTables',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllRubricTables({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostRubricEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the table to retrieve
   *
   * @minLength 1
   * @maxLength 8
   */
  tableId: string;
};

export type PostRubricEntryHeaders = {
  /**
   * The idempotency key for this request
   */
  ['x-idempotency-key']?: string;
};

export type PostRubricEntryError = Fetcher.ErrorWrapper<undefined>;

export type PostRubricEntryVariables = {
  body: Schemas.RubricInput;
  headers?: PostRubricEntryHeaders;
  pathParams: PostRubricEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostRubricEntry = (
  variables: PostRubricEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.RubricEntry,
    PostRubricEntryError,
    Schemas.RubricInput,
    PostRubricEntryHeaders,
    {},
    PostRubricEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/rubrics-tables/{tableId}/rubrics',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostRubricEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RubricEntry,
      PostRubricEntryError,
      PostRubricEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.RubricEntry,
    PostRubricEntryError,
    PostRubricEntryVariables
  >({
    mutationFn: (variables: PostRubricEntryVariables) =>
      fetchPostRubricEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutRubricEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the rubric to update
   */
  rubricId: string;
  /**
   * The id of the table to retrieve
   *
   * @minLength 1
   * @maxLength 8
   */
  tableId: string;
};

export type PutRubricEntryHeaders = {
  /**
   * The idempotency key for this request
   */
  ['x-idempotency-key']?: string;
};

export type PutRubricEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutRubricEntryVariables = {
  body: Schemas.RubricInput;
  headers?: PutRubricEntryHeaders;
  pathParams: PutRubricEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutRubricEntry = (
  variables: PutRubricEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.RubricEntry,
    PutRubricEntryError,
    Schemas.RubricInput,
    PutRubricEntryHeaders,
    {},
    PutRubricEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/rubrics-tables/{tableId}/rubrics/{rubricId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutRubricEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RubricEntry,
      PutRubricEntryError,
      PutRubricEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.RubricEntry,
    PutRubricEntryError,
    PutRubricEntryVariables
  >({
    mutationFn: (variables: PutRubricEntryVariables) =>
      fetchPutRubricEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PatchRubricEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the rubric to update
   */
  rubricId: string;
  /**
   * The id of the table to retrieve
   *
   * @minLength 1
   * @maxLength 8
   */
  tableId: string;
};

export type PatchRubricEntryHeaders = {
  /**
   * The idempotency key for this request
   */
  ['x-idempotency-key']?: string;
};

export type PatchRubricEntryError = Fetcher.ErrorWrapper<undefined>;

export type PatchRubricEntryVariables = {
  body: Schemas.RubricPatch;
  headers?: PatchRubricEntryHeaders;
  pathParams: PatchRubricEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPatchRubricEntry = (
  variables: PatchRubricEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.RubricEntry,
    PatchRubricEntryError,
    Schemas.RubricPatch,
    PatchRubricEntryHeaders,
    {},
    PatchRubricEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/rubrics-tables/{tableId}/rubrics/{rubricId}',
    method: 'patch',
    ...variables,
    signal,
  });

export const usePatchRubricEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.RubricEntry,
      PatchRubricEntryError,
      PatchRubricEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.RubricEntry,
    PatchRubricEntryError,
    PatchRubricEntryVariables
  >({
    mutationFn: (variables: PatchRubricEntryVariables) =>
      fetchPatchRubricEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type GetPayrollError = Fetcher.ErrorWrapper<undefined>;

export type GetPayrollVariables = {
  pathParams: GetPayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayroll = (
  variables: GetPayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollEntry,
    GetPayrollError,
    undefined,
    {},
    {},
    GetPayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayroll = <TData = Schemas.PayrollEntry,>(
  variables: GetPayrollVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PayrollEntry, GetPayrollError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.PayrollEntry, GetPayrollError, TData>({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}',
      operationId: 'getPayroll',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayroll({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutPayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type PutPayrollError = Fetcher.ErrorWrapper<undefined>;

export type PutPayrollVariables = {
  body: Schemas.PayrollUpdate;
  pathParams: PutPayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutPayroll = (
  variables: PutPayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PutPayrollError,
    Schemas.PayrollUpdate,
    {},
    {},
    PutPayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutPayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PutPayrollError,
      PutPayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PutPayrollError,
    PutPayrollVariables
  >({
    mutationFn: (variables: PutPayrollVariables) =>
      fetchPutPayroll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type DeletePayrollError = Fetcher.ErrorWrapper<undefined>;

export type DeletePayrollVariables = {
  pathParams: DeletePayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeletePayroll = (
  variables: DeletePayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeletePayrollError,
    undefined,
    {},
    {},
    DeletePayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePayrollError,
      DeletePayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeletePayrollError,
    DeletePayrollVariables
  >({
    mutationFn: (variables: DeletePayrollVariables) =>
      fetchDeletePayroll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApprovePayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type ApprovePayrollError = Fetcher.ErrorWrapper<undefined>;

export type ApprovePayrollVariables = {
  pathParams: ApprovePayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApprovePayroll = (
  variables: ApprovePayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ApprovePayrollError,
    undefined,
    {},
    {},
    ApprovePayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/approve',
    method: 'post',
    ...variables,
    signal,
  });

export const useApprovePayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ApprovePayrollError,
      ApprovePayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ApprovePayrollError,
    ApprovePayrollVariables
  >({
    mutationFn: (variables: ApprovePayrollVariables) =>
      fetchApprovePayroll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ArchivePayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type ArchivePayrollError = Fetcher.ErrorWrapper<undefined>;

export type ArchivePayrollVariables = {
  pathParams: ArchivePayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchArchivePayroll = (
  variables: ArchivePayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ArchivePayrollError,
    undefined,
    {},
    {},
    ArchivePayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/archive',
    method: 'post',
    ...variables,
    signal,
  });

export const useArchivePayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ArchivePayrollError,
      ArchivePayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ArchivePayrollError,
    ArchivePayrollVariables
  >({
    mutationFn: (variables: ArchivePayrollVariables) =>
      fetchArchivePayroll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RestorePayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type RestorePayrollError = Fetcher.ErrorWrapper<undefined>;

export type RestorePayrollVariables = {
  body: Schemas.PayrollUpdate;
  pathParams: RestorePayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRestorePayroll = (
  variables: RestorePayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    RestorePayrollError,
    Schemas.PayrollUpdate,
    {},
    {},
    RestorePayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/restore',
    method: 'post',
    ...variables,
    signal,
  });

export const useRestorePayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RestorePayrollError,
      RestorePayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RestorePayrollError,
    RestorePayrollVariables
  >({
    mutationFn: (variables: RestorePayrollVariables) =>
      fetchRestorePayroll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangePayrollPaymentDatePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type ChangePayrollPaymentDateError = Fetcher.ErrorWrapper<undefined>;

export type ChangePayrollPaymentDateVariables = {
  body: Schemas.PayrollChangePaymentDateInput;
  pathParams: ChangePayrollPaymentDatePathParams;
} & ApiContext['fetcherOptions'];

export const fetchChangePayrollPaymentDate = (
  variables: ChangePayrollPaymentDateVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ChangePayrollPaymentDateError,
    Schemas.PayrollChangePaymentDateInput,
    {},
    {},
    ChangePayrollPaymentDatePathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/change-payment-date',
    method: 'post',
    ...variables,
    signal,
  });

export const useChangePayrollPaymentDate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ChangePayrollPaymentDateError,
      ChangePayrollPaymentDateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ChangePayrollPaymentDateError,
    ChangePayrollPaymentDateVariables
  >({
    mutationFn: (variables: ChangePayrollPaymentDateVariables) =>
      fetchChangePayrollPaymentDate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ReopenPayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type ReopenPayrollError = Fetcher.ErrorWrapper<undefined>;

export type ReopenPayrollVariables = {
  body: Schemas.PayrollUpdate;
  pathParams: ReopenPayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReopenPayroll = (
  variables: ReopenPayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ReopenPayrollError,
    Schemas.PayrollUpdate,
    {},
    {},
    ReopenPayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/reopen',
    method: 'post',
    ...variables,
    signal,
  });

export const useReopenPayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReopenPayrollError,
      ReopenPayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ReopenPayrollError,
    ReopenPayrollVariables
  >({
    mutationFn: (variables: ReopenPayrollVariables) =>
      fetchReopenPayroll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RecalculatePayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type RecalculatePayrollError = Fetcher.ErrorWrapper<undefined>;

export type RecalculatePayrollRequestBody = {
  /**
   * Skip the safeguard that prevents saving recalculation if payroll (public) results are different.
   *
   * @default false
   */
  dangerouslySkipSafeguard?: boolean;
  /**
   * Tolerance for comparing results
   *
   * @default 0
   */
  tolerance?: number;
};

export type RecalculatePayrollVariables = {
  body?: RecalculatePayrollRequestBody;
  pathParams: RecalculatePayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecalculatePayroll = (
  variables: RecalculatePayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    RecalculatePayrollError,
    RecalculatePayrollRequestBody,
    {},
    {},
    RecalculatePayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/recalculate',
    method: 'post',
    ...variables,
    signal,
  });

export const useRecalculatePayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RecalculatePayrollError,
      RecalculatePayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RecalculatePayrollError,
    RecalculatePayrollVariables
  >({
    mutationFn: (variables: RecalculatePayrollVariables) =>
      fetchRecalculatePayroll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RollbackToApprovedPayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type RollbackToApprovedPayrollError = Fetcher.ErrorWrapper<undefined>;

export type RollbackToApprovedPayrollVariables = {
  pathParams: RollbackToApprovedPayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRollbackToApprovedPayroll = (
  variables: RollbackToApprovedPayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    RollbackToApprovedPayrollError,
    undefined,
    {},
    {},
    RollbackToApprovedPayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/rollback-to-approved',
    method: 'post',
    ...variables,
    signal,
  });

export const useRollbackToApprovedPayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RollbackToApprovedPayrollError,
      RollbackToApprovedPayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RollbackToApprovedPayrollError,
    RollbackToApprovedPayrollVariables
  >({
    mutationFn: (variables: RollbackToApprovedPayrollVariables) =>
      fetchRollbackToApprovedPayroll({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPayrollPayslipPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type GetPayrollPayslipError = Fetcher.ErrorWrapper<undefined>;

export type GetPayrollPayslipVariables = {
  pathParams: GetPayrollPayslipPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayrollPayslip = (
  variables: GetPayrollPayslipVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPayslipEntry,
    GetPayrollPayslipError,
    undefined,
    {},
    {},
    GetPayrollPayslipPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/payslips',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayrollPayslip = <TData = Schemas.PayrollPayslipEntry,>(
  variables: GetPayrollPayslipVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollPayslipEntry,
      GetPayrollPayslipError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollPayslipEntry,
    GetPayrollPayslipError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/payslips',
      operationId: 'getPayrollPayslip',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayrollPayslip({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SendPayrollPayslipPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type SendPayrollPayslipError = Fetcher.ErrorWrapper<undefined>;

export type SendPayrollPayslipVariables = {
  pathParams: SendPayrollPayslipPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSendPayrollPayslip = (
  variables: SendPayrollPayslipVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    SendPayrollPayslipError,
    undefined,
    {},
    {},
    SendPayrollPayslipPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/payslips/send',
    method: 'post',
    ...variables,
    signal,
  });

export const useSendPayrollPayslip = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SendPayrollPayslipError,
      SendPayrollPayslipVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SendPayrollPayslipError,
    SendPayrollPayslipVariables
  >({
    mutationFn: (variables: SendPayrollPayslipVariables) =>
      fetchSendPayrollPayslip({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPayrollPayslipPdfPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type GetPayrollPayslipPdfError = Fetcher.ErrorWrapper<undefined>;

export type GetPayrollPayslipPdfVariables = {
  pathParams: GetPayrollPayslipPdfPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayrollPayslipPdf = (
  variables: GetPayrollPayslipPdfVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPayslipPdf,
    GetPayrollPayslipPdfError,
    undefined,
    {},
    {},
    GetPayrollPayslipPdfPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/contracts/{contractId}/payrolls/{payrollId}/payslips/pdf',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayrollPayslipPdf = <TData = Schemas.PayrollPayslipPdf,>(
  variables: GetPayrollPayslipPdfVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollPayslipPdf,
      GetPayrollPayslipPdfError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollPayslipPdf,
    GetPayrollPayslipPdfError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/contracts/{contractId}/payrolls/{payrollId}/payslips/pdf',
      operationId: 'getPayrollPayslipPdf',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayrollPayslipPdf({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SearchAllPeriodsSummariesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type SearchAllPeriodsSummariesError = Fetcher.ErrorWrapper<undefined>;

export type SearchAllPeriodsSummariesResponse = {
  total: number;
  size: number;
  page: number;
  data: Schemas.PayrollPeriodSummary[];
  metadata: {
    countByStatus: {
      [key: string]: number;
    };
    payrolls?: {
      [key: string]: number;
    };
  };
};

export type SearchAllPeriodsSummariesVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAllPeriodsSummariesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAllPeriodsSummaries = (
  variables: SearchAllPeriodsSummariesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    SearchAllPeriodsSummariesResponse,
    SearchAllPeriodsSummariesError,
    Schemas.SearchInput,
    {},
    {},
    SearchAllPeriodsSummariesPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/summaries/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAllPeriodsSummaries = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      SearchAllPeriodsSummariesResponse,
      SearchAllPeriodsSummariesError,
      SearchAllPeriodsSummariesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    SearchAllPeriodsSummariesResponse,
    SearchAllPeriodsSummariesError,
    SearchAllPeriodsSummariesVariables
  >({
    mutationFn: (variables: SearchAllPeriodsSummariesVariables) =>
      fetchSearchAllPeriodsSummaries({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchAllPayslipsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type SearchAllPayslipsError = Fetcher.ErrorWrapper<undefined>;

export type SearchAllPayslipsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAllPayslipsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAllPayslips = (
  variables: SearchAllPayslipsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPayslipList,
    SearchAllPayslipsError,
    Schemas.SearchInput,
    {},
    {},
    SearchAllPayslipsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/payslips/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAllPayslips = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollPayslipList,
      SearchAllPayslipsError,
      SearchAllPayslipsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollPayslipList,
    SearchAllPayslipsError,
    SearchAllPayslipsVariables
  >({
    mutationFn: (variables: SearchAllPayslipsVariables) =>
      fetchSearchAllPayslips({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApproveAllPayrollsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * The type of a payroll resource
   */
  payrollType: Schemas.PayrollTypes;
};

export type ApproveAllPayrollsError = Fetcher.ErrorWrapper<undefined>;

export type ApproveAllPayrollsVariables = {
  pathParams: ApproveAllPayrollsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApproveAllPayrolls = (
  variables: ApproveAllPayrollsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ApproveAllPayrollsError,
    undefined,
    {},
    {},
    ApproveAllPayrollsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/types/{payrollType}/approve',
    method: 'post',
    ...variables,
    signal,
  });

export const useApproveAllPayrolls = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ApproveAllPayrollsError,
      ApproveAllPayrollsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ApproveAllPayrollsError,
    ApproveAllPayrollsVariables
  >({
    mutationFn: (variables: ApproveAllPayrollsVariables) =>
      fetchApproveAllPayrolls({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPeriodSummaryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * The type of a payroll resource
   */
  payrollType: Schemas.PayrollTypes;
};

export type GetPeriodSummaryError = Fetcher.ErrorWrapper<undefined>;

export type GetPeriodSummaryVariables = {
  pathParams: GetPeriodSummaryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPeriodSummary = (
  variables: GetPeriodSummaryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPeriodSummaryEntry,
    GetPeriodSummaryError,
    undefined,
    {},
    {},
    GetPeriodSummaryPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/types/{payrollType}/summary',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPeriodSummary = <TData = Schemas.PayrollPeriodSummaryEntry,>(
  variables: GetPeriodSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollPeriodSummaryEntry,
      GetPeriodSummaryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollPeriodSummaryEntry,
    GetPeriodSummaryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/types/{payrollType}/summary',
      operationId: 'getPeriodSummary',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPeriodSummary({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SendAllPayrollPayslipPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * The type of a payroll resource
   */
  payrollType: Schemas.PayrollTypes;
};

export type SendAllPayrollPayslipError = Fetcher.ErrorWrapper<undefined>;

export type SendAllPayrollPayslipVariables = {
  pathParams: SendAllPayrollPayslipPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSendAllPayrollPayslip = (
  variables: SendAllPayrollPayslipVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    SendAllPayrollPayslipError,
    undefined,
    {},
    {},
    SendAllPayrollPayslipPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/types/{payrollType}/payslips/send',
    method: 'post',
    ...variables,
    signal,
  });

export const useSendAllPayrollPayslip = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SendAllPayrollPayslipError,
      SendAllPayrollPayslipVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SendAllPayrollPayslipError,
    SendAllPayrollPayslipVariables
  >({
    mutationFn: (variables: SendAllPayrollPayslipVariables) =>
      fetchSendAllPayrollPayslip({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllPayrollPayslipPdfPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * The type of a payroll resource
   */
  payrollType: Schemas.PayrollTypes;
};

export type GetAllPayrollPayslipPdfError = Fetcher.ErrorWrapper<undefined>;

export type GetAllPayrollPayslipPdfVariables = {
  pathParams: GetAllPayrollPayslipPdfPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAllPayrollPayslipPdf = (
  variables: GetAllPayrollPayslipPdfVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPayslipPdf,
    GetAllPayrollPayslipPdfError,
    undefined,
    {},
    {},
    GetAllPayrollPayslipPdfPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/types/{payrollType}/payslips/pdf',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllPayrollPayslipPdf = <TData = Schemas.PayrollPayslipPdf,>(
  variables: GetAllPayrollPayslipPdfVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollPayslipPdf,
      GetAllPayrollPayslipPdfError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollPayslipPdf,
    GetAllPayrollPayslipPdfError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/types/{payrollType}/payslips/pdf',
      operationId: 'getAllPayrollPayslipPdf',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllPayrollPayslipPdf({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPayrollInputsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type GetPayrollInputsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetPayrollInputsConfigurationVariables = {
  pathParams: GetPayrollInputsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayrollInputsConfiguration = (
  variables: GetPayrollInputsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollInputsConfig,
    GetPayrollInputsConfigurationError,
    undefined,
    {},
    {},
    GetPayrollInputsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayrollInputsConfiguration = <
  TData = Schemas.PayrollInputsConfig,
>(
  variables: GetPayrollInputsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollInputsConfig,
      GetPayrollInputsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollInputsConfig,
    GetPayrollInputsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs',
      operationId: 'getPayrollInputsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayrollInputsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostPayrollInputsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PostPayrollInputsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type PostPayrollInputsConfigurationVariables = {
  body?: Schemas.PayrollInputsPayload;
  pathParams: PostPayrollInputsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPayrollInputsConfiguration = (
  variables: PostPayrollInputsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollInputsConfig,
    PostPayrollInputsConfigurationError,
    Schemas.PayrollInputsPayload,
    {},
    {},
    PostPayrollInputsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPayrollInputsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollInputsConfig,
      PostPayrollInputsConfigurationError,
      PostPayrollInputsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollInputsConfig,
    PostPayrollInputsConfigurationError,
    PostPayrollInputsConfigurationVariables
  >({
    mutationFn: (variables: PostPayrollInputsConfigurationVariables) =>
      fetchPostPayrollInputsConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutPayrollInputsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PutPayrollInputsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type PutPayrollInputsConfigurationVariables = {
  body?: Schemas.PayrollInputsPayload;
  pathParams: PutPayrollInputsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutPayrollInputsConfiguration = (
  variables: PutPayrollInputsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollInputsConfig,
    PutPayrollInputsConfigurationError,
    Schemas.PayrollInputsPayload,
    {},
    {},
    PutPayrollInputsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutPayrollInputsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollInputsConfig,
      PutPayrollInputsConfigurationError,
      PutPayrollInputsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollInputsConfig,
    PutPayrollInputsConfigurationError,
    PutPayrollInputsConfigurationVariables
  >({
    mutationFn: (variables: PutPayrollInputsConfigurationVariables) =>
      fetchPutPayrollInputsConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PatchPayrollInputsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PatchPayrollInputsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type PatchPayrollInputsConfigurationVariables = {
  body: Schemas.PayrollInputsUpdate;
  pathParams: PatchPayrollInputsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPatchPayrollInputsConfiguration = (
  variables: PatchPayrollInputsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollInputsConfig,
    PatchPayrollInputsConfigurationError,
    Schemas.PayrollInputsUpdate,
    {},
    {},
    PatchPayrollInputsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs',
    method: 'patch',
    ...variables,
    signal,
  });

export const usePatchPayrollInputsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollInputsConfig,
      PatchPayrollInputsConfigurationError,
      PatchPayrollInputsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollInputsConfig,
    PatchPayrollInputsConfigurationError,
    PatchPayrollInputsConfigurationVariables
  >({
    mutationFn: (variables: PatchPayrollInputsConfigurationVariables) =>
      fetchPatchPayrollInputsConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPayrollInputsForPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * The type of a payroll resource
   */
  payrollType: Schemas.PayrollTypes;
};

export type GetPayrollInputsForPeriodError = Fetcher.ErrorWrapper<undefined>;

export type GetPayrollInputsForPeriodVariables = {
  pathParams: GetPayrollInputsForPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayrollInputsForPeriod = (
  variables: GetPayrollInputsForPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollInputsList,
    GetPayrollInputsForPeriodError,
    undefined,
    {},
    {},
    GetPayrollInputsForPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs/periods/{periodId}/types/{payrollType}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayrollInputsForPeriod = <
  TData = Schemas.PayrollInputsList,
>(
  variables: GetPayrollInputsForPeriodVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollInputsList,
      GetPayrollInputsForPeriodError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollInputsList,
    GetPayrollInputsForPeriodError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs/periods/{periodId}/types/{payrollType}',
      operationId: 'getPayrollInputsForPeriod',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayrollInputsForPeriod(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostPayrollInputsForPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * The type of a payroll resource
   */
  payrollType: Schemas.PayrollTypes;
};

export type PostPayrollInputsForPeriodError = Fetcher.ErrorWrapper<undefined>;

export type PostPayrollInputsForPeriodVariables = {
  body?: Schemas.PayrollInputsSubmissionInput;
  pathParams: PostPayrollInputsForPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPayrollInputsForPeriod = (
  variables: PostPayrollInputsForPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollInputsSubmissionEntry,
    PostPayrollInputsForPeriodError,
    Schemas.PayrollInputsSubmissionInput,
    {},
    {},
    PostPayrollInputsForPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs/periods/{periodId}/types/{payrollType}',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPayrollInputsForPeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollInputsSubmissionEntry,
      PostPayrollInputsForPeriodError,
      PostPayrollInputsForPeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollInputsSubmissionEntry,
    PostPayrollInputsForPeriodError,
    PostPayrollInputsForPeriodVariables
  >({
    mutationFn: (variables: PostPayrollInputsForPeriodVariables) =>
      fetchPostPayrollInputsForPeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPayrollInputsSubmissionDetailsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * The type of a payroll resource
   */
  payrollType: Schemas.PayrollTypes;
  /**
   * The id of a submission
   *
   * @format uuid
   */
  submissionId: string;
};

export type GetPayrollInputsSubmissionDetailsError =
  Fetcher.ErrorWrapper<undefined>;

export type GetPayrollInputsSubmissionDetailsVariables = {
  pathParams: GetPayrollInputsSubmissionDetailsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayrollInputsSubmissionDetails = (
  variables: GetPayrollInputsSubmissionDetailsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollInputsSubmissionDetails,
    GetPayrollInputsSubmissionDetailsError,
    undefined,
    {},
    {},
    GetPayrollInputsSubmissionDetailsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs/periods/{periodId}/types/{payrollType}/submissions/{submissionId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayrollInputsSubmissionDetails = <
  TData = Schemas.PayrollInputsSubmissionDetails,
>(
  variables: GetPayrollInputsSubmissionDetailsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollInputsSubmissionDetails,
      GetPayrollInputsSubmissionDetailsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollInputsSubmissionDetails,
    GetPayrollInputsSubmissionDetailsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs/periods/{periodId}/types/{payrollType}/submissions/{submissionId}',
      operationId: 'getPayrollInputsSubmissionDetails',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayrollInputsSubmissionDetails(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostPjPaymentsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type PostPjPaymentsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type PostPjPaymentsNotificationsConfigurationVariables = {
  body: Schemas.NotificationConfigurationInput;
  pathParams: PostPjPaymentsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPjPaymentsNotificationsConfiguration = (
  variables: PostPjPaymentsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfiguration,
    PostPjPaymentsNotificationsConfigurationError,
    Schemas.NotificationConfigurationInput,
    {},
    {},
    PostPjPaymentsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPjPaymentsNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationConfiguration,
      PostPjPaymentsNotificationsConfigurationError,
      PostPjPaymentsNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationConfiguration,
    PostPjPaymentsNotificationsConfigurationError,
    PostPjPaymentsNotificationsConfigurationVariables
  >({
    mutationFn: (
      variables: PostPjPaymentsNotificationsConfigurationVariables,
    ) =>
      fetchPostPjPaymentsNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ListPjPaymentsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type ListPjPaymentsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type ListPjPaymentsNotificationsConfigurationVariables = {
  pathParams: ListPjPaymentsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListPjPaymentsNotificationsConfiguration = (
  variables: ListPjPaymentsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfigurationsList,
    ListPjPaymentsNotificationsConfigurationError,
    undefined,
    {},
    {},
    ListPjPaymentsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations',
    method: 'get',
    ...variables,
    signal,
  });

export const useListPjPaymentsNotificationsConfiguration = <
  TData = Schemas.NotificationConfigurationsList,
>(
  variables: ListPjPaymentsNotificationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.NotificationConfigurationsList,
      ListPjPaymentsNotificationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.NotificationConfigurationsList,
    ListPjPaymentsNotificationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations',
      operationId: 'listPjPaymentsNotificationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListPjPaymentsNotificationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type TestPjPaymentsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type TestPjPaymentsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type TestPjPaymentsNotificationsConfigurationRequestBody = {
  [key: string]: any;
};

export type TestPjPaymentsNotificationsConfigurationVariables = {
  body?: TestPjPaymentsNotificationsConfigurationRequestBody;
  pathParams: TestPjPaymentsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTestPjPaymentsNotificationsConfiguration = (
  variables: TestPjPaymentsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationTest,
    TestPjPaymentsNotificationsConfigurationError,
    TestPjPaymentsNotificationsConfigurationRequestBody,
    {},
    {},
    TestPjPaymentsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations/{resourceType}/testConfig',
    method: 'post',
    ...variables,
    signal,
  });

export const useTestPjPaymentsNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationTest,
      TestPjPaymentsNotificationsConfigurationError,
      TestPjPaymentsNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationTest,
    TestPjPaymentsNotificationsConfigurationError,
    TestPjPaymentsNotificationsConfigurationVariables
  >({
    mutationFn: (
      variables: TestPjPaymentsNotificationsConfigurationVariables,
    ) =>
      fetchTestPjPaymentsNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetPjPaymentsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the
   */
  ruleId: void;
};

export type GetPjPaymentsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetPjPaymentsNotificationsConfigurationVariables = {
  pathParams: GetPjPaymentsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPjPaymentsNotificationsConfiguration = (
  variables: GetPjPaymentsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    GetPjPaymentsNotificationsConfigurationError,
    undefined,
    {},
    {},
    GetPjPaymentsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPjPaymentsNotificationsConfiguration = <TData = undefined,>(
  variables: GetPjPaymentsNotificationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      GetPjPaymentsNotificationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    undefined,
    GetPjPaymentsNotificationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations/{resourceType}/rules/{ruleId}',
      operationId: 'getPjPaymentsNotificationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPjPaymentsNotificationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type UpdatePjPaymentsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the
   */
  ruleId: void;
};

export type UpdatePjPaymentsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type UpdatePjPaymentsNotificationsConfigurationVariables = {
  body: Schemas.NotificationConfiguration;
  pathParams: UpdatePjPaymentsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdatePjPaymentsNotificationsConfiguration = (
  variables: UpdatePjPaymentsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfiguration,
    UpdatePjPaymentsNotificationsConfigurationError,
    Schemas.NotificationConfiguration,
    {},
    {},
    UpdatePjPaymentsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpdatePjPaymentsNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationConfiguration,
      UpdatePjPaymentsNotificationsConfigurationError,
      UpdatePjPaymentsNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationConfiguration,
    UpdatePjPaymentsNotificationsConfigurationError,
    UpdatePjPaymentsNotificationsConfigurationVariables
  >({
    mutationFn: (
      variables: UpdatePjPaymentsNotificationsConfigurationVariables,
    ) =>
      fetchUpdatePjPaymentsNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DeletePjPaymentsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the
   */
  ruleId: void;
};

export type DeletePjPaymentsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type DeletePjPaymentsNotificationsConfigurationVariables = {
  pathParams: DeletePjPaymentsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeletePjPaymentsNotificationsConfiguration = (
  variables: DeletePjPaymentsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeletePjPaymentsNotificationsConfigurationError,
    undefined,
    {},
    {},
    DeletePjPaymentsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePjPaymentsNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePjPaymentsNotificationsConfigurationError,
      DeletePjPaymentsNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeletePjPaymentsNotificationsConfigurationError,
    DeletePjPaymentsNotificationsConfigurationVariables
  >({
    mutationFn: (
      variables: DeletePjPaymentsNotificationsConfigurationVariables,
    ) =>
      fetchDeletePjPaymentsNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PostPaymentRequestsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PostPaymentRequestsError = Fetcher.ErrorWrapper<undefined>;

export type PostPaymentRequestsVariables = {
  body: Schemas.PjPaymentRequestInput;
  pathParams: PostPaymentRequestsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPaymentRequests = (
  variables: PostPaymentRequestsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestEntry,
    PostPaymentRequestsError,
    Schemas.PjPaymentRequestInput,
    {},
    {},
    PostPaymentRequestsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPaymentRequests = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentRequestEntry,
      PostPaymentRequestsError,
      PostPaymentRequestsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentRequestEntry,
    PostPaymentRequestsError,
    PostPaymentRequestsVariables
  >({
    mutationFn: (variables: PostPaymentRequestsVariables) =>
      fetchPostPaymentRequests({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchAllPjPaymentRequestsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type SearchAllPjPaymentRequestsError = Fetcher.ErrorWrapper<undefined>;

export type SearchAllPjPaymentRequestsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAllPjPaymentRequestsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAllPjPaymentRequests = (
  variables: SearchAllPjPaymentRequestsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestList,
    SearchAllPjPaymentRequestsError,
    Schemas.SearchInput,
    {},
    {},
    SearchAllPjPaymentRequestsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAllPjPaymentRequests = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentRequestList,
      SearchAllPjPaymentRequestsError,
      SearchAllPjPaymentRequestsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentRequestList,
    SearchAllPjPaymentRequestsError,
    SearchAllPjPaymentRequestsVariables
  >({
    mutationFn: (variables: SearchAllPjPaymentRequestsVariables) =>
      fetchSearchAllPjPaymentRequests({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostContractPaymentRequestsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostContractPaymentRequestsError = Fetcher.ErrorWrapper<undefined>;

export type PostContractPaymentRequestsVariables = {
  body: Schemas.PjPaymentRequestInput;
  pathParams: PostContractPaymentRequestsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostContractPaymentRequests = (
  variables: PostContractPaymentRequestsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestEntry,
    PostContractPaymentRequestsError,
    Schemas.PjPaymentRequestInput,
    {},
    {},
    PostContractPaymentRequestsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostContractPaymentRequests = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentRequestEntry,
      PostContractPaymentRequestsError,
      PostContractPaymentRequestsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentRequestEntry,
    PostContractPaymentRequestsError,
    PostContractPaymentRequestsVariables
  >({
    mutationFn: (variables: PostContractPaymentRequestsVariables) =>
      fetchPostContractPaymentRequests({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetContractPaymentRequestPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type GetContractPaymentRequestError = Fetcher.ErrorWrapper<undefined>;

export type GetContractPaymentRequestVariables = {
  pathParams: GetContractPaymentRequestPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractPaymentRequest = (
  variables: GetContractPaymentRequestVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestEntry,
    GetContractPaymentRequestError,
    undefined,
    {},
    {},
    GetContractPaymentRequestPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractPaymentRequest = <
  TData = Schemas.PjPaymentRequestEntry,
>(
  variables: GetContractPaymentRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PjPaymentRequestEntry,
      GetContractPaymentRequestError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PjPaymentRequestEntry,
    GetContractPaymentRequestError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}',
      operationId: 'getContractPaymentRequest',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractPaymentRequest(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DeleteContractPaymentRequestPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type DeleteContractPaymentRequestError = Fetcher.ErrorWrapper<undefined>;

export type DeleteContractPaymentRequestRequestBody = {
  version: number;
};

export type DeleteContractPaymentRequestVariables = {
  body: DeleteContractPaymentRequestRequestBody;
  pathParams: DeleteContractPaymentRequestPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteContractPaymentRequest = (
  variables: DeleteContractPaymentRequestVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteContractPaymentRequestError,
    DeleteContractPaymentRequestRequestBody,
    {},
    {},
    DeleteContractPaymentRequestPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteContractPaymentRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteContractPaymentRequestError,
      DeleteContractPaymentRequestVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteContractPaymentRequestError,
    DeleteContractPaymentRequestVariables
  >({
    mutationFn: (variables: DeleteContractPaymentRequestVariables) =>
      fetchDeleteContractPaymentRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutContractPaymentRequestPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type PutContractPaymentRequestError = Fetcher.ErrorWrapper<undefined>;

export type PutContractPaymentRequestVariables = {
  body: Schemas.PjPaymentRequestUpdate;
  pathParams: PutContractPaymentRequestPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutContractPaymentRequest = (
  variables: PutContractPaymentRequestVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestEntry,
    PutContractPaymentRequestError,
    Schemas.PjPaymentRequestUpdate,
    {},
    {},
    PutContractPaymentRequestPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutContractPaymentRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentRequestEntry,
      PutContractPaymentRequestError,
      PutContractPaymentRequestVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentRequestEntry,
    PutContractPaymentRequestError,
    PutContractPaymentRequestVariables
  >({
    mutationFn: (variables: PutContractPaymentRequestVariables) =>
      fetchPutContractPaymentRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetContractAllPaymentRequestsAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type GetContractAllPaymentRequestsAttachmentError =
  Fetcher.ErrorWrapper<undefined>;

export type GetContractAllPaymentRequestsAttachmentVariables = {
  pathParams: GetContractAllPaymentRequestsAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractAllPaymentRequestsAttachment = (
  variables: GetContractAllPaymentRequestsAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentAttachmentList,
    GetContractAllPaymentRequestsAttachmentError,
    undefined,
    {},
    {},
    GetContractAllPaymentRequestsAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}/attachments',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractAllPaymentRequestsAttachment = <
  TData = Schemas.PjPaymentAttachmentList,
>(
  variables: GetContractAllPaymentRequestsAttachmentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PjPaymentAttachmentList,
      GetContractAllPaymentRequestsAttachmentError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PjPaymentAttachmentList,
    GetContractAllPaymentRequestsAttachmentError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}/attachments',
      operationId: 'getContractAllPaymentRequestsAttachment',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractAllPaymentRequestsAttachment(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostContractPaymentRequestsAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type PostContractPaymentRequestsAttachmentError =
  Fetcher.ErrorWrapper<undefined>;

export type PostContractPaymentRequestsAttachmentVariables = {
  body: Schemas.PjPaymentAttachmentInput;
  pathParams: PostContractPaymentRequestsAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostContractPaymentRequestsAttachment = (
  variables: PostContractPaymentRequestsAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentAttachmentEntry,
    PostContractPaymentRequestsAttachmentError,
    Schemas.PjPaymentAttachmentInput,
    {},
    {},
    PostContractPaymentRequestsAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}/attachments',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostContractPaymentRequestsAttachment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentAttachmentEntry,
      PostContractPaymentRequestsAttachmentError,
      PostContractPaymentRequestsAttachmentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentAttachmentEntry,
    PostContractPaymentRequestsAttachmentError,
    PostContractPaymentRequestsAttachmentVariables
  >({
    mutationFn: (variables: PostContractPaymentRequestsAttachmentVariables) =>
      fetchPostContractPaymentRequestsAttachment({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetContractPaymentRequestAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
  /**
   * The id of the payment request attachment to retrieve
   *
   * @format uuid
   */
  attachmentId: string;
};

export type GetContractPaymentRequestAttachmentError =
  Fetcher.ErrorWrapper<undefined>;

export type GetContractPaymentRequestAttachmentVariables = {
  pathParams: GetContractPaymentRequestAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractPaymentRequestAttachment = (
  variables: GetContractPaymentRequestAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentAttachmentEntry,
    GetContractPaymentRequestAttachmentError,
    undefined,
    {},
    {},
    GetContractPaymentRequestAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}/attachments/{attachmentId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractPaymentRequestAttachment = <
  TData = Schemas.PjPaymentAttachmentEntry,
>(
  variables: GetContractPaymentRequestAttachmentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PjPaymentAttachmentEntry,
      GetContractPaymentRequestAttachmentError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PjPaymentAttachmentEntry,
    GetContractPaymentRequestAttachmentError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}/attachments/{attachmentId}',
      operationId: 'getContractPaymentRequestAttachment',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractPaymentRequestAttachment(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DeleteContractPaymentRequestAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
  /**
   * The id of the payment request attachment to retrieve
   *
   * @format uuid
   */
  attachmentId: string;
};

export type DeleteContractPaymentRequestAttachmentError =
  Fetcher.ErrorWrapper<undefined>;

export type DeleteContractPaymentRequestAttachmentVariables = {
  pathParams: DeleteContractPaymentRequestAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteContractPaymentRequestAttachment = (
  variables: DeleteContractPaymentRequestAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteContractPaymentRequestAttachmentError,
    undefined,
    {},
    {},
    DeleteContractPaymentRequestAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}/attachments/{attachmentId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteContractPaymentRequestAttachment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteContractPaymentRequestAttachmentError,
      DeleteContractPaymentRequestAttachmentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteContractPaymentRequestAttachmentError,
    DeleteContractPaymentRequestAttachmentVariables
  >({
    mutationFn: (variables: DeleteContractPaymentRequestAttachmentVariables) =>
      fetchDeleteContractPaymentRequestAttachment({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type SearchByContractPjPaymentRequestsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type SearchByContractPjPaymentRequestsError =
  Fetcher.ErrorWrapper<undefined>;

export type SearchByContractPjPaymentRequestsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchByContractPjPaymentRequestsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchByContractPjPaymentRequests = (
  variables: SearchByContractPjPaymentRequestsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestList,
    SearchByContractPjPaymentRequestsError,
    Schemas.SearchInput,
    {},
    {},
    SearchByContractPjPaymentRequestsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchByContractPjPaymentRequests = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentRequestList,
      SearchByContractPjPaymentRequestsError,
      SearchByContractPjPaymentRequestsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentRequestList,
    SearchByContractPjPaymentRequestsError,
    SearchByContractPjPaymentRequestsVariables
  >({
    mutationFn: (variables: SearchByContractPjPaymentRequestsVariables) =>
      fetchSearchByContractPjPaymentRequests({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetContractManagerPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract whose manager we are fetching
   *
   * @format uuid
   */
  contractId: string;
};

export type GetContractManagerError = Fetcher.ErrorWrapper<undefined>;

export type GetContractManagerVariables = {
  pathParams: GetContractManagerPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractManager = (
  variables: GetContractManagerVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.OrgChartContract,
    GetContractManagerError,
    undefined,
    {},
    {},
    GetContractManagerPathParams
  >({
    url: '/api/organizations/{organizationId}/org-chart/contracts/{contractId}/managers',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractManager = <TData = Schemas.OrgChartContract,>(
  variables: GetContractManagerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.OrgChartContract,
      GetContractManagerError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.OrgChartContract,
    GetContractManagerError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/org-chart/contracts/{contractId}/managers',
      operationId: 'getContractManager',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractManager({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetContractReportsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract whose direct reports we are fetching
   *
   * @format uuid
   */
  contractId: string;
};

export type GetContractReportsQueryParams = {
  /**
   * Whether or not to return direct reports only
   *
   * @default true
   */
  directReports?: 'true' | 'false';
};

export type GetContractReportsError = Fetcher.ErrorWrapper<undefined>;

export type GetContractReportsResponse = Schemas.OrgChartContract[];

export type GetContractReportsVariables = {
  pathParams: GetContractReportsPathParams;
  queryParams?: GetContractReportsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractReports = (
  variables: GetContractReportsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetContractReportsResponse,
    GetContractReportsError,
    undefined,
    {},
    GetContractReportsQueryParams,
    GetContractReportsPathParams
  >({
    url: '/api/organizations/{organizationId}/org-chart/contracts/{contractId}/reports',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractReports = <TData = GetContractReportsResponse,>(
  variables: GetContractReportsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetContractReportsResponse,
      GetContractReportsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    GetContractReportsResponse,
    GetContractReportsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/org-chart/contracts/{contractId}/reports',
      operationId: 'getContractReports',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractReports({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CheckCycleInOrgChartPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract we checking if adding the manager
   * would create a cycle in the org chart
   *
   * @format uuid
   */
  contractId: string;
};

export type CheckCycleInOrgChartError = Fetcher.ErrorWrapper<undefined>;

export type CheckCycleInOrgChartResponse = {
  cycle: boolean;
};

export type CheckCycleInOrgChartVariables = {
  body: Schemas.OrgChartContract;
  pathParams: CheckCycleInOrgChartPathParams;
} & ApiContext['fetcherOptions'];

/**
 * Checks whether or not adding the manager to the contract would create a cycle in the org chart.
 * Notice that this operation WILL NOT add/replace the manager, it simply checks.
 */
export const fetchCheckCycleInOrgChart = (
  variables: CheckCycleInOrgChartVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    CheckCycleInOrgChartResponse,
    CheckCycleInOrgChartError,
    Schemas.OrgChartContract,
    {},
    {},
    CheckCycleInOrgChartPathParams
  >({
    url: '/api/organizations/{organizationId}/org-chart/contracts/{contractId}/cycle',
    method: 'post',
    ...variables,
    signal,
  });

/**
 * Checks whether or not adding the manager to the contract would create a cycle in the org chart.
 * Notice that this operation WILL NOT add/replace the manager, it simply checks.
 */
export const useCheckCycleInOrgChart = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      CheckCycleInOrgChartResponse,
      CheckCycleInOrgChartError,
      CheckCycleInOrgChartVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    CheckCycleInOrgChartResponse,
    CheckCycleInOrgChartError,
    CheckCycleInOrgChartVariables
  >({
    mutationFn: (variables: CheckCycleInOrgChartVariables) =>
      fetchCheckCycleInOrgChart({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListCustomFieldsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type ListCustomFieldsQueryParams = {
  ['contract-types']?: ('pj' | 'clt') | string;
  ['app-visibility']?: 'contract' | 'admission' | 'customer-api';
  ['user-visibility']?: 'admin' | 'employee';
  ['admission-registered-by']?: 'admin' | 'worker';
};

export type ListCustomFieldsError = Fetcher.ErrorWrapper<undefined>;

export type ListCustomFieldsResponse = Schemas.CustomFieldEntry[];

export type ListCustomFieldsVariables = {
  pathParams: ListCustomFieldsPathParams;
  queryParams?: ListCustomFieldsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchListCustomFields = (
  variables: ListCustomFieldsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    ListCustomFieldsResponse,
    ListCustomFieldsError,
    undefined,
    {},
    ListCustomFieldsQueryParams,
    ListCustomFieldsPathParams
  >({
    url: '/api/organizations/{organizationId}/custom-fields',
    method: 'get',
    ...variables,
    signal,
  });

export const useListCustomFields = <TData = ListCustomFieldsResponse,>(
  variables: ListCustomFieldsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListCustomFieldsResponse,
      ListCustomFieldsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ListCustomFieldsResponse,
    ListCustomFieldsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/custom-fields',
      operationId: 'listCustomFields',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListCustomFields({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateCustomFieldPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type CreateCustomFieldError = Fetcher.ErrorWrapper<undefined>;

export type CreateCustomFieldVariables = {
  body?: Schemas.CustomFieldDefinition;
  pathParams: CreateCustomFieldPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCreateCustomField = (
  variables: CreateCustomFieldVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CustomFieldEntry,
    CreateCustomFieldError,
    Schemas.CustomFieldDefinition,
    {},
    {},
    CreateCustomFieldPathParams
  >({
    url: '/api/organizations/{organizationId}/custom-fields',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateCustomField = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CustomFieldEntry,
      CreateCustomFieldError,
      CreateCustomFieldVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CustomFieldEntry,
    CreateCustomFieldError,
    CreateCustomFieldVariables
  >({
    mutationFn: (variables: CreateCustomFieldVariables) =>
      fetchCreateCustomField({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateCustomFieldPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * Name of the custom field
   */
  fieldName: string;
};

export type UpdateCustomFieldError = Fetcher.ErrorWrapper<undefined>;

export type UpdateCustomFieldVariables = {
  body?: Schemas.CustomFieldUpdateInput;
  pathParams: UpdateCustomFieldPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateCustomField = (
  variables: UpdateCustomFieldVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CustomFieldEntry,
    UpdateCustomFieldError,
    Schemas.CustomFieldUpdateInput,
    {},
    {},
    UpdateCustomFieldPathParams
  >({
    url: '/api/organizations/{organizationId}/custom-fields/{fieldName}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateCustomField = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CustomFieldEntry,
      UpdateCustomFieldError,
      UpdateCustomFieldVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CustomFieldEntry,
    UpdateCustomFieldError,
    UpdateCustomFieldVariables
  >({
    mutationFn: (variables: UpdateCustomFieldVariables) =>
      fetchUpdateCustomField({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteCustomFieldPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * Name of the custom field
   */
  fieldName: string;
};

export type DeleteCustomFieldError = Fetcher.ErrorWrapper<undefined>;

export type DeleteCustomFieldVariables = {
  pathParams: DeleteCustomFieldPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteCustomField = (
  variables: DeleteCustomFieldVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteCustomFieldError,
    undefined,
    {},
    {},
    DeleteCustomFieldPathParams
  >({
    url: '/api/organizations/{organizationId}/custom-fields/{fieldName}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteCustomField = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteCustomFieldError,
      DeleteCustomFieldVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteCustomFieldError,
    DeleteCustomFieldVariables
  >({
    mutationFn: (variables: DeleteCustomFieldVariables) =>
      fetchDeleteCustomField({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutProvisionsPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type PutProvisionsPeriodError = Fetcher.ErrorWrapper<undefined>;

export type PutProvisionsPeriodVariables = {
  body: Schemas.WorkerProvisionsInput;
  pathParams: PutProvisionsPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutProvisionsPeriod = (
  variables: PutProvisionsPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PutProvisionsPeriodError,
    Schemas.WorkerProvisionsInput,
    {},
    {},
    PutProvisionsPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/provisions/contracts/{contractId}/periods/{periodId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutProvisionsPeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PutProvisionsPeriodError,
      PutProvisionsPeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PutProvisionsPeriodError,
    PutProvisionsPeriodVariables
  >({
    mutationFn: (variables: PutProvisionsPeriodVariables) =>
      fetchPutProvisionsPeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetProvisionsPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type GetProvisionsPeriodError = Fetcher.ErrorWrapper<undefined>;

export type GetProvisionsPeriodResponse = {
  sharedElements: {
    [key: string]: Schemas.PayrollElement;
  };
  provisions: {
    [key: string]: {
      provisionedInPeriod: string;
      deposits: string;
      withdrawals: string;
      paymentsForFruition: string;
      paymentsForIndemnification: string;
      paymentsReversal: string;
      writeOffs: string;
      adjustments: string;
      oldProvisionedTotal: string;
      newProvisionedTotal: string;
      elements: {
        [key: string]: Schemas.PayrollElement;
      };
    };
  };
};

export type GetProvisionsPeriodVariables = {
  pathParams: GetProvisionsPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetProvisionsPeriod = (
  variables: GetProvisionsPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetProvisionsPeriodResponse,
    GetProvisionsPeriodError,
    undefined,
    {},
    {},
    GetProvisionsPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/provisions/contracts/{contractId}/periods/{periodId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetProvisionsPeriod = <TData = GetProvisionsPeriodResponse,>(
  variables: GetProvisionsPeriodVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetProvisionsPeriodResponse,
      GetProvisionsPeriodError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    GetProvisionsPeriodResponse,
    GetProvisionsPeriodError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/provisions/contracts/{contractId}/periods/{periodId}',
      operationId: 'getProvisionsPeriod',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetProvisionsPeriod({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CloseWorkerProvisionsPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type CloseWorkerProvisionsPeriodError = Fetcher.ErrorWrapper<undefined>;

export type CloseWorkerProvisionsPeriodVariables = {
  pathParams: CloseWorkerProvisionsPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCloseWorkerProvisionsPeriod = (
  variables: CloseWorkerProvisionsPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    CloseWorkerProvisionsPeriodError,
    undefined,
    {},
    {},
    CloseWorkerProvisionsPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/provisions/contracts/{contractId}/periods/{periodId}/close',
    method: 'post',
    ...variables,
    signal,
  });

export const useCloseWorkerProvisionsPeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CloseWorkerProvisionsPeriodError,
      CloseWorkerProvisionsPeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CloseWorkerProvisionsPeriodError,
    CloseWorkerProvisionsPeriodVariables
  >({
    mutationFn: (variables: CloseWorkerProvisionsPeriodVariables) =>
      fetchCloseWorkerProvisionsPeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CloseProvisionsPeriodPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the period resource
   */
  periodId: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type CloseProvisionsPeriodError = Fetcher.ErrorWrapper<undefined>;

export type CloseProvisionsPeriodVariables = {
  pathParams: CloseProvisionsPeriodPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCloseProvisionsPeriod = (
  variables: CloseProvisionsPeriodVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    CloseProvisionsPeriodError,
    undefined,
    {},
    {},
    CloseProvisionsPeriodPathParams
  >({
    url: '/api/organizations/{organizationId}/provisions/periods/{periodId}/close',
    method: 'post',
    ...variables,
    signal,
  });

export const useCloseProvisionsPeriod = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CloseProvisionsPeriodError,
      CloseProvisionsPeriodVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CloseProvisionsPeriodError,
    CloseProvisionsPeriodVariables
  >({
    mutationFn: (variables: CloseProvisionsPeriodVariables) =>
      fetchCloseProvisionsPeriod({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPaymentRequestPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type GetPaymentRequestError = Fetcher.ErrorWrapper<undefined>;

export type GetPaymentRequestVariables = {
  pathParams: GetPaymentRequestPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPaymentRequest = (
  variables: GetPaymentRequestVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestEntry,
    GetPaymentRequestError,
    undefined,
    {},
    {},
    GetPaymentRequestPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPaymentRequest = <TData = Schemas.PjPaymentRequestEntry,>(
  variables: GetPaymentRequestVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PjPaymentRequestEntry,
      GetPaymentRequestError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PjPaymentRequestEntry,
    GetPaymentRequestError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}',
      operationId: 'getPaymentRequest',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPaymentRequest({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeletePaymentRequestPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type DeletePaymentRequestError = Fetcher.ErrorWrapper<undefined>;

export type DeletePaymentRequestRequestBody = {
  version: number;
};

export type DeletePaymentRequestVariables = {
  body: DeletePaymentRequestRequestBody;
  pathParams: DeletePaymentRequestPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeletePaymentRequest = (
  variables: DeletePaymentRequestVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeletePaymentRequestError,
    DeletePaymentRequestRequestBody,
    {},
    {},
    DeletePaymentRequestPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePaymentRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePaymentRequestError,
      DeletePaymentRequestVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeletePaymentRequestError,
    DeletePaymentRequestVariables
  >({
    mutationFn: (variables: DeletePaymentRequestVariables) =>
      fetchDeletePaymentRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutPaymentRequestPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type PutPaymentRequestError = Fetcher.ErrorWrapper<undefined>;

export type PutPaymentRequestVariables = {
  body: Schemas.PjPaymentRequestUpdate;
  pathParams: PutPaymentRequestPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutPaymentRequest = (
  variables: PutPaymentRequestVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestEntry,
    PutPaymentRequestError,
    Schemas.PjPaymentRequestUpdate,
    {},
    {},
    PutPaymentRequestPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutPaymentRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentRequestEntry,
      PutPaymentRequestError,
      PutPaymentRequestVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentRequestEntry,
    PutPaymentRequestError,
    PutPaymentRequestVariables
  >({
    mutationFn: (variables: PutPaymentRequestVariables) =>
      fetchPutPaymentRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllPaymentRequestsAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type GetAllPaymentRequestsAttachmentError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAllPaymentRequestsAttachmentVariables = {
  pathParams: GetAllPaymentRequestsAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAllPaymentRequestsAttachment = (
  variables: GetAllPaymentRequestsAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentAttachmentList,
    GetAllPaymentRequestsAttachmentError,
    undefined,
    {},
    {},
    GetAllPaymentRequestsAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/attachments',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllPaymentRequestsAttachment = <
  TData = Schemas.PjPaymentAttachmentList,
>(
  variables: GetAllPaymentRequestsAttachmentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PjPaymentAttachmentList,
      GetAllPaymentRequestsAttachmentError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PjPaymentAttachmentList,
    GetAllPaymentRequestsAttachmentError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/attachments',
      operationId: 'getAllPaymentRequestsAttachment',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllPaymentRequestsAttachment(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostPaymentRequestsAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type PostPaymentRequestsAttachmentError =
  Fetcher.ErrorWrapper<undefined>;

export type PostPaymentRequestsAttachmentVariables = {
  body: Schemas.PjPaymentAttachmentInput;
  pathParams: PostPaymentRequestsAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPaymentRequestsAttachment = (
  variables: PostPaymentRequestsAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentAttachmentEntry,
    PostPaymentRequestsAttachmentError,
    Schemas.PjPaymentAttachmentInput,
    {},
    {},
    PostPaymentRequestsAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/attachments',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPaymentRequestsAttachment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentAttachmentEntry,
      PostPaymentRequestsAttachmentError,
      PostPaymentRequestsAttachmentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentAttachmentEntry,
    PostPaymentRequestsAttachmentError,
    PostPaymentRequestsAttachmentVariables
  >({
    mutationFn: (variables: PostPaymentRequestsAttachmentVariables) =>
      fetchPostPaymentRequestsAttachment({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPaymentRequestAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
  /**
   * The id of the payment request attachment to retrieve
   *
   * @format uuid
   */
  attachmentId: string;
};

export type GetPaymentRequestAttachmentError = Fetcher.ErrorWrapper<undefined>;

export type GetPaymentRequestAttachmentVariables = {
  pathParams: GetPaymentRequestAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPaymentRequestAttachment = (
  variables: GetPaymentRequestAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentAttachmentEntry,
    GetPaymentRequestAttachmentError,
    undefined,
    {},
    {},
    GetPaymentRequestAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/attachments/{attachmentId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPaymentRequestAttachment = <
  TData = Schemas.PjPaymentAttachmentEntry,
>(
  variables: GetPaymentRequestAttachmentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PjPaymentAttachmentEntry,
      GetPaymentRequestAttachmentError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PjPaymentAttachmentEntry,
    GetPaymentRequestAttachmentError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/attachments/{attachmentId}',
      operationId: 'getPaymentRequestAttachment',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPaymentRequestAttachment(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DeletePaymentRequestAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
  /**
   * The id of the payment request attachment to retrieve
   *
   * @format uuid
   */
  attachmentId: string;
};

export type DeletePaymentRequestAttachmentError =
  Fetcher.ErrorWrapper<undefined>;

export type DeletePaymentRequestAttachmentVariables = {
  pathParams: DeletePaymentRequestAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeletePaymentRequestAttachment = (
  variables: DeletePaymentRequestAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeletePaymentRequestAttachmentError,
    undefined,
    {},
    {},
    DeletePaymentRequestAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/attachments/{attachmentId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePaymentRequestAttachment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePaymentRequestAttachmentError,
      DeletePaymentRequestAttachmentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeletePaymentRequestAttachmentError,
    DeletePaymentRequestAttachmentVariables
  >({
    mutationFn: (variables: DeletePaymentRequestAttachmentVariables) =>
      fetchDeletePaymentRequestAttachment({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApprovePaymentRequestPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type ApprovePaymentRequestError = Fetcher.ErrorWrapper<undefined>;

export type ApprovePaymentRequestVariables = {
  body: Schemas.PjPaymentRequestApprove;
  pathParams: ApprovePaymentRequestPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApprovePaymentRequest = (
  variables: ApprovePaymentRequestVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestEntry,
    ApprovePaymentRequestError,
    Schemas.PjPaymentRequestApprove,
    {},
    {},
    ApprovePaymentRequestPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/approve',
    method: 'post',
    ...variables,
    signal,
  });

export const useApprovePaymentRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentRequestEntry,
      ApprovePaymentRequestError,
      ApprovePaymentRequestVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentRequestEntry,
    ApprovePaymentRequestError,
    ApprovePaymentRequestVariables
  >({
    mutationFn: (variables: ApprovePaymentRequestVariables) =>
      fetchApprovePaymentRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveApprovalPaymentRequestPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type RemoveApprovalPaymentRequestError = Fetcher.ErrorWrapper<undefined>;

export type RemoveApprovalPaymentRequestVariables = {
  body: Schemas.PjPaymentRequestApprove;
  pathParams: RemoveApprovalPaymentRequestPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRemoveApprovalPaymentRequest = (
  variables: RemoveApprovalPaymentRequestVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    RemoveApprovalPaymentRequestError,
    Schemas.PjPaymentRequestApprove,
    {},
    {},
    RemoveApprovalPaymentRequestPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/approve',
    method: 'delete',
    ...variables,
    signal,
  });

export const useRemoveApprovalPaymentRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RemoveApprovalPaymentRequestError,
      RemoveApprovalPaymentRequestVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RemoveApprovalPaymentRequestError,
    RemoveApprovalPaymentRequestVariables
  >({
    mutationFn: (variables: RemoveApprovalPaymentRequestVariables) =>
      fetchRemoveApprovalPaymentRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RejectPaymentRequestPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payment request to retrieve
   *
   * @format uuid
   */
  paymentRequestId: string;
};

export type RejectPaymentRequestError = Fetcher.ErrorWrapper<undefined>;

export type RejectPaymentRequestVariables = {
  body: Schemas.PjPaymentRequestRejection;
  pathParams: RejectPaymentRequestPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRejectPaymentRequest = (
  variables: RejectPaymentRequestVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PjPaymentRequestEntry,
    RejectPaymentRequestError,
    Schemas.PjPaymentRequestRejection,
    {},
    {},
    RejectPaymentRequestPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/reject',
    method: 'post',
    ...variables,
    signal,
  });

export const useRejectPaymentRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PjPaymentRequestEntry,
      RejectPaymentRequestError,
      RejectPaymentRequestVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PjPaymentRequestEntry,
    RejectPaymentRequestError,
    RejectPaymentRequestVariables
  >({
    mutationFn: (variables: RejectPaymentRequestVariables) =>
      fetchRejectPaymentRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CreateContractPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type CreateContractError = Fetcher.ErrorWrapper<undefined>;

export type CreateContractVariables = {
  body?: Schemas.ContractInput;
  pathParams: CreateContractPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCreateContract = (
  variables: CreateContractVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractEntry,
    CreateContractError,
    Schemas.ContractInput,
    {},
    {},
    CreateContractPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateContract = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractEntry,
      CreateContractError,
      CreateContractVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractEntry,
    CreateContractError,
    CreateContractVariables
  >({
    mutationFn: (variables: CreateContractVariables) =>
      fetchCreateContract({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchAllContractsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type SearchAllContractsError = Fetcher.ErrorWrapper<undefined>;

export type SearchAllContractsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAllContractsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAllContracts = (
  variables: SearchAllContractsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractList,
    SearchAllContractsError,
    Schemas.SearchInput,
    {},
    {},
    SearchAllContractsPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAllContracts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractList,
      SearchAllContractsError,
      SearchAllContractsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractList,
    SearchAllContractsError,
    SearchAllContractsVariables
  >({
    mutationFn: (variables: SearchAllContractsVariables) =>
      fetchSearchAllContracts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ContractEsocialIssuesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type ContractEsocialIssuesError = Fetcher.ErrorWrapper<undefined>;

export type ContractEsocialIssuesVariables = {
  body?: Schemas.SearchInput;
  pathParams: ContractEsocialIssuesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchContractEsocialIssues = (
  variables: ContractEsocialIssuesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractList,
    ContractEsocialIssuesError,
    Schemas.SearchInput,
    {},
    {},
    ContractEsocialIssuesPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/br/clt/esocialIssues',
    method: 'post',
    ...variables,
    signal,
  });

export const useContractEsocialIssues = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractList,
      ContractEsocialIssuesError,
      ContractEsocialIssuesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractList,
    ContractEsocialIssuesError,
    ContractEsocialIssuesVariables
  >({
    mutationFn: (variables: ContractEsocialIssuesVariables) =>
      fetchContractEsocialIssues({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetContractPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type GetContractQueryParams = {
  /**
   * @format date
   */
  effectiveDate?: string;
  sequenceId?: string;
  includeDeleted?: string;
};

export type GetContractError = Fetcher.ErrorWrapper<undefined>;

export type GetContractVariables = {
  pathParams: GetContractPathParams;
  queryParams?: GetContractQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContract = (
  variables: GetContractVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractEntry,
    GetContractError,
    undefined,
    {},
    GetContractQueryParams,
    GetContractPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContract = <TData = Schemas.ContractEntry,>(
  variables: GetContractVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ContractEntry, GetContractError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ContractEntry, GetContractError, TData>({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/{contractId}',
      operationId: 'getContract',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContract({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutContractPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PutContractHeaders = {
  /**
   * The idempotency key for this request
   */
  ['x-idempotency-key']?: string;
};

export type PutContractError = Fetcher.ErrorWrapper<undefined>;

export type PutContractVariables = {
  body?: Schemas.ContractUpdate;
  headers?: PutContractHeaders;
  pathParams: PutContractPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutContract = (
  variables: PutContractVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PutContractError,
    Schemas.ContractUpdate,
    PutContractHeaders,
    {},
    PutContractPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutContract = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PutContractError,
      PutContractVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PutContractError,
    PutContractVariables
  >({
    mutationFn: (variables: PutContractVariables) =>
      fetchPutContract({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteContractPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type DeleteContractError = Fetcher.ErrorWrapper<undefined>;

export type DeleteContractVariables = {
  pathParams: DeleteContractPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteContract = (
  variables: DeleteContractVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteContractError,
    undefined,
    {},
    {},
    DeleteContractPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteContract = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteContractError,
      DeleteContractVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteContractError,
    DeleteContractVariables
  >({
    mutationFn: (variables: DeleteContractVariables) =>
      fetchDeleteContract({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetContractEventsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type GetContractEventsError = Fetcher.ErrorWrapper<undefined>;

export type GetContractEventsResponse = Schemas.EventSourcingEvent[];

export type GetContractEventsVariables = {
  pathParams: GetContractEventsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractEvents = (
  variables: GetContractEventsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetContractEventsResponse,
    GetContractEventsError,
    undefined,
    {},
    {},
    GetContractEventsPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/events',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractEvents = <TData = GetContractEventsResponse,>(
  variables: GetContractEventsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetContractEventsResponse,
      GetContractEventsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    GetContractEventsResponse,
    GetContractEventsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/{contractId}/events',
      operationId: 'getContractEvents',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractEvents({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetContractEventsPagePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type GetContractEventsPageQueryParams = {
  cursor?: string;
  /**
   * Fetches events in pages of size `limit`
   *
   * @pattern ^\d+$
   * @default 50
   */
  limit?: string;
  /**
   * @default desc
   */
  sort?: 'asc' | 'desc';
};

export type GetContractEventsPageError = Fetcher.ErrorWrapper<undefined>;

export type GetContractEventsPageResponse = {
  data?: Schemas.EventSourcingEventWithAuthor[];
  nextCursor?: string;
};

export type GetContractEventsPageVariables = {
  pathParams: GetContractEventsPagePathParams;
  queryParams?: GetContractEventsPageQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractEventsPage = (
  variables: GetContractEventsPageVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetContractEventsPageResponse,
    GetContractEventsPageError,
    undefined,
    {},
    GetContractEventsPageQueryParams,
    GetContractEventsPagePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/events-page',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractEventsPage = <
  TData = GetContractEventsPageResponse,
>(
  variables: GetContractEventsPageVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetContractEventsPageResponse,
      GetContractEventsPageError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    GetContractEventsPageResponse,
    GetContractEventsPageError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/{contractId}/events-page',
      operationId: 'getContractEventsPage',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractEventsPage({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteContractEventPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the event
   */
  eventId: string;
};

export type DeleteContractEventError = Fetcher.ErrorWrapper<undefined>;

export type DeleteContractEventVariables = {
  pathParams: DeleteContractEventPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteContractEvent = (
  variables: DeleteContractEventVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteContractEventError,
    undefined,
    {},
    {},
    DeleteContractEventPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/events/{eventId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteContractEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteContractEventError,
      DeleteContractEventVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteContractEventError,
    DeleteContractEventVariables
  >({
    mutationFn: (variables: DeleteContractEventVariables) =>
      fetchDeleteContractEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetContractEventPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the event
   */
  eventId: string;
};

export type GetContractEventError = Fetcher.ErrorWrapper<undefined>;

export type GetContractEventVariables = {
  pathParams: GetContractEventPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractEvent = (
  variables: GetContractEventVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.EventSourcingEvent,
    GetContractEventError,
    undefined,
    {},
    {},
    GetContractEventPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/events/{eventId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractEvent = <TData = Schemas.EventSourcingEvent,>(
  variables: GetContractEventVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.EventSourcingEvent,
      GetContractEventError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.EventSourcingEvent,
    GetContractEventError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/{contractId}/events/{eventId}',
      operationId: 'getContractEvent',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractEvent({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type TerminateContractPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type TerminateContractError = Fetcher.ErrorWrapper<undefined>;

export type TerminateContractVariables = {
  body?: Schemas.ContractTermination;
  pathParams: TerminateContractPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTerminateContract = (
  variables: TerminateContractVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    TerminateContractError,
    Schemas.ContractTermination,
    {},
    {},
    TerminateContractPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/terminate',
    method: 'post',
    ...variables,
    signal,
  });

export const useTerminateContract = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TerminateContractError,
      TerminateContractVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TerminateContractError,
    TerminateContractVariables
  >({
    mutationFn: (variables: TerminateContractVariables) =>
      fetchTerminateContract({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type InternalTransferContractPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type InternalTransferContractError = Fetcher.ErrorWrapper<undefined>;

export type InternalTransferContractVariables = {
  body?: Schemas.ContractInternalTransfer;
  pathParams: InternalTransferContractPathParams;
} & ApiContext['fetcherOptions'];

export const fetchInternalTransferContract = (
  variables: InternalTransferContractVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    InternalTransferContractError,
    Schemas.ContractInternalTransfer,
    {},
    {},
    InternalTransferContractPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/internalTransfer',
    method: 'post',
    ...variables,
    signal,
  });

export const useInternalTransferContract = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      InternalTransferContractError,
      InternalTransferContractVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    InternalTransferContractError,
    InternalTransferContractVariables
  >({
    mutationFn: (variables: InternalTransferContractVariables) =>
      fetchInternalTransferContract({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CompanyTransferContractPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type CompanyTransferContractError = Fetcher.ErrorWrapper<undefined>;

export type CompanyTransferContractRequestBody = Schemas.EffectiveDateBody & {
  /**
   * @format uuid
   */
  newCompanyId: string;
  /**
   * @format uuid
   */
  newLegalEntityId: string;
  newWorkerId?: string;
  br?: {
    novaMatricula?: string;
  };
};

export type CompanyTransferContractVariables = {
  body?: CompanyTransferContractRequestBody;
  pathParams: CompanyTransferContractPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCompanyTransferContract = (
  variables: CompanyTransferContractVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    CompanyTransferContractError,
    CompanyTransferContractRequestBody,
    {},
    {},
    CompanyTransferContractPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/companyTransfer',
    method: 'post',
    ...variables,
    signal,
  });

export const useCompanyTransferContract = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CompanyTransferContractError,
      CompanyTransferContractVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CompanyTransferContractError,
    CompanyTransferContractVariables
  >({
    mutationFn: (variables: CompanyTransferContractVariables) =>
      fetchCompanyTransferContract({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RegisterContractVacationsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type RegisterContractVacationsError = Fetcher.ErrorWrapper<undefined>;

export type RegisterContractVacationsVariables = {
  body?: Schemas.ContractVacations;
  pathParams: RegisterContractVacationsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRegisterContractVacations = (
  variables: RegisterContractVacationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    RegisterContractVacationsError,
    Schemas.ContractVacations,
    {},
    {},
    RegisterContractVacationsPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/vacations',
    method: 'post',
    ...variables,
    signal,
  });

export const useRegisterContractVacations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RegisterContractVacationsError,
      RegisterContractVacationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RegisterContractVacationsError,
    RegisterContractVacationsVariables
  >({
    mutationFn: (variables: RegisterContractVacationsVariables) =>
      fetchRegisterContractVacations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UploadContractProfilePicturePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type UploadContractProfilePictureError = Fetcher.ErrorWrapper<undefined>;

export type UploadContractProfilePictureVariables = {
  body: Schemas.ContractProfilePictureInput;
  pathParams: UploadContractProfilePicturePathParams;
} & ApiContext['fetcherOptions'];

export const fetchUploadContractProfilePicture = (
  variables: UploadContractProfilePictureVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractProfilePictureResponse,
    UploadContractProfilePictureError,
    Schemas.ContractProfilePictureInput,
    {},
    {},
    UploadContractProfilePicturePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/profile-pictures',
    method: 'post',
    ...variables,
    signal,
  });

export const useUploadContractProfilePicture = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractProfilePictureResponse,
      UploadContractProfilePictureError,
      UploadContractProfilePictureVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractProfilePictureResponse,
    UploadContractProfilePictureError,
    UploadContractProfilePictureVariables
  >({
    mutationFn: (variables: UploadContractProfilePictureVariables) =>
      fetchUploadContractProfilePicture({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ContractEsocialStatusPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type ContractEsocialStatusError = Fetcher.ErrorWrapper<undefined>;

export type ContractEsocialStatusVariables = {
  body: Schemas.ContractBRCltEsocialStatusInput;
  pathParams: ContractEsocialStatusPathParams;
} & ApiContext['fetcherOptions'];

export const fetchContractEsocialStatus = (
  variables: ContractEsocialStatusVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ContractEsocialStatusError,
    Schemas.ContractBRCltEsocialStatusInput,
    {},
    {},
    ContractEsocialStatusPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/br/clt/esocial',
    method: 'post',
    ...variables,
    signal,
  });

export const useContractEsocialStatus = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ContractEsocialStatusError,
      ContractEsocialStatusVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ContractEsocialStatusError,
    ContractEsocialStatusVariables
  >({
    mutationFn: (variables: ContractEsocialStatusVariables) =>
      fetchContractEsocialStatus({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostContractBrPensaoAlimenticiaPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostContractBrPensaoAlimenticiaQueryParams = {
  ignoreCheckIfEditablePayrolls?: string;
};

export type PostContractBrPensaoAlimenticiaError =
  Fetcher.ErrorWrapper<undefined>;

export type PostContractBrPensaoAlimenticiaVariables = {
  body: Schemas.ContractBRCltPensaoAlimenticiaInput;
  pathParams: PostContractBrPensaoAlimenticiaPathParams;
  queryParams?: PostContractBrPensaoAlimenticiaQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPostContractBrPensaoAlimenticia = (
  variables: PostContractBrPensaoAlimenticiaVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractBRCltPensaoAlimenticiaEntry,
    PostContractBrPensaoAlimenticiaError,
    Schemas.ContractBRCltPensaoAlimenticiaInput,
    {},
    PostContractBrPensaoAlimenticiaQueryParams,
    PostContractBrPensaoAlimenticiaPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/br/pensoes-alimenticias',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostContractBrPensaoAlimenticia = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractBRCltPensaoAlimenticiaEntry,
      PostContractBrPensaoAlimenticiaError,
      PostContractBrPensaoAlimenticiaVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractBRCltPensaoAlimenticiaEntry,
    PostContractBrPensaoAlimenticiaError,
    PostContractBrPensaoAlimenticiaVariables
  >({
    mutationFn: (variables: PostContractBrPensaoAlimenticiaVariables) =>
      fetchPostContractBrPensaoAlimenticia({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchContractBrPensaoAlimenticiaPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type SearchContractBrPensaoAlimenticiaError =
  Fetcher.ErrorWrapper<undefined>;

export type SearchContractBrPensaoAlimenticiaVariables = {
  body: Schemas.ContractBRCltPensaoAlimenticiaSearchInput;
  pathParams: SearchContractBrPensaoAlimenticiaPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchContractBrPensaoAlimenticia = (
  variables: SearchContractBrPensaoAlimenticiaVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractBRCltPensaoAlimenticiaSearchOutput,
    SearchContractBrPensaoAlimenticiaError,
    Schemas.ContractBRCltPensaoAlimenticiaSearchInput,
    {},
    {},
    SearchContractBrPensaoAlimenticiaPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/br/pensoes-alimenticias/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchContractBrPensaoAlimenticia = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractBRCltPensaoAlimenticiaSearchOutput,
      SearchContractBrPensaoAlimenticiaError,
      SearchContractBrPensaoAlimenticiaVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractBRCltPensaoAlimenticiaSearchOutput,
    SearchContractBrPensaoAlimenticiaError,
    SearchContractBrPensaoAlimenticiaVariables
  >({
    mutationFn: (variables: SearchContractBrPensaoAlimenticiaVariables) =>
      fetchSearchContractBrPensaoAlimenticia({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PutContractBrPensaoAlimenticiaPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the event
   */
  eventId: string;
};

export type PutContractBrPensaoAlimenticiaQueryParams = {
  versionId?: string;
  ignoreCheckIfEditablePayrolls?: string;
};

export type PutContractBrPensaoAlimenticiaError =
  Fetcher.ErrorWrapper<undefined>;

export type PutContractBrPensaoAlimenticiaVariables = {
  body: Schemas.ContractBRCltPensaoAlimenticiaInput;
  pathParams: PutContractBrPensaoAlimenticiaPathParams;
  queryParams?: PutContractBrPensaoAlimenticiaQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPutContractBrPensaoAlimenticia = (
  variables: PutContractBrPensaoAlimenticiaVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PutContractBrPensaoAlimenticiaError,
    Schemas.ContractBRCltPensaoAlimenticiaInput,
    {},
    PutContractBrPensaoAlimenticiaQueryParams,
    PutContractBrPensaoAlimenticiaPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/br/pensoes-alimenticias/{idPensaoAlimenticia}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutContractBrPensaoAlimenticia = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PutContractBrPensaoAlimenticiaError,
      PutContractBrPensaoAlimenticiaVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PutContractBrPensaoAlimenticiaError,
    PutContractBrPensaoAlimenticiaVariables
  >({
    mutationFn: (variables: PutContractBrPensaoAlimenticiaVariables) =>
      fetchPutContractBrPensaoAlimenticia({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteContractBrPensaoAlimenticiaPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the event
   */
  eventId: string;
};

export type DeleteContractBrPensaoAlimenticiaQueryParams = {
  versionId?: string;
  ignoreCheckIfEditablePayrolls?: string;
};

export type DeleteContractBrPensaoAlimenticiaError =
  Fetcher.ErrorWrapper<undefined>;

export type DeleteContractBrPensaoAlimenticiaVariables = {
  pathParams: DeleteContractBrPensaoAlimenticiaPathParams;
  queryParams?: DeleteContractBrPensaoAlimenticiaQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteContractBrPensaoAlimenticia = (
  variables: DeleteContractBrPensaoAlimenticiaVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteContractBrPensaoAlimenticiaError,
    undefined,
    {},
    DeleteContractBrPensaoAlimenticiaQueryParams,
    DeleteContractBrPensaoAlimenticiaPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/br/pensoes-alimenticias/{idPensaoAlimenticia}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteContractBrPensaoAlimenticia = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteContractBrPensaoAlimenticiaError,
      DeleteContractBrPensaoAlimenticiaVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteContractBrPensaoAlimenticiaError,
    DeleteContractBrPensaoAlimenticiaVariables
  >({
    mutationFn: (variables: DeleteContractBrPensaoAlimenticiaVariables) =>
      fetchDeleteContractBrPensaoAlimenticia({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PostContractBrInsalubridadePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostContractBrInsalubridadeQueryParams = {
  ignoreCheckIfEditablePayrolls?: string;
};

export type PostContractBrInsalubridadeError = Fetcher.ErrorWrapper<undefined>;

export type PostContractBrInsalubridadeVariables = {
  body?: Schemas.ContractBRCltInsalubridadeInput;
  pathParams: PostContractBrInsalubridadePathParams;
  queryParams?: PostContractBrInsalubridadeQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPostContractBrInsalubridade = (
  variables: PostContractBrInsalubridadeVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractBRCltInsalubridadeEntry,
    PostContractBrInsalubridadeError,
    Schemas.ContractBRCltInsalubridadeInput,
    {},
    PostContractBrInsalubridadeQueryParams,
    PostContractBrInsalubridadePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/br/insalubridades',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostContractBrInsalubridade = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractBRCltInsalubridadeEntry,
      PostContractBrInsalubridadeError,
      PostContractBrInsalubridadeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractBRCltInsalubridadeEntry,
    PostContractBrInsalubridadeError,
    PostContractBrInsalubridadeVariables
  >({
    mutationFn: (variables: PostContractBrInsalubridadeVariables) =>
      fetchPostContractBrInsalubridade({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchContractBrInsalubridadePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type SearchContractBrInsalubridadeError =
  Fetcher.ErrorWrapper<undefined>;

export type SearchContractBrInsalubridadeVariables = {
  body: Schemas.ContractBRCltInsalubridadeSearchInput;
  pathParams: SearchContractBrInsalubridadePathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchContractBrInsalubridade = (
  variables: SearchContractBrInsalubridadeVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractBRCltInsalubridadeSearchOutput,
    SearchContractBrInsalubridadeError,
    Schemas.ContractBRCltInsalubridadeSearchInput,
    {},
    {},
    SearchContractBrInsalubridadePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/br/insalubridades/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchContractBrInsalubridade = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractBRCltInsalubridadeSearchOutput,
      SearchContractBrInsalubridadeError,
      SearchContractBrInsalubridadeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractBRCltInsalubridadeSearchOutput,
    SearchContractBrInsalubridadeError,
    SearchContractBrInsalubridadeVariables
  >({
    mutationFn: (variables: SearchContractBrInsalubridadeVariables) =>
      fetchSearchContractBrInsalubridade({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutContractBrInsalubridadePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * Id da insalubridade
   *
   * @format uuid
   */
  insalubridadeId: string;
};

export type PutContractBrInsalubridadeQueryParams = {
  versionId?: string;
  ignoreCheckIfEditablePayrolls?: string;
};

export type PutContractBrInsalubridadeError = Fetcher.ErrorWrapper<undefined>;

export type PutContractBrInsalubridadeVariables = {
  body?: Schemas.ContractBRCltInsalubridadeInput;
  pathParams: PutContractBrInsalubridadePathParams;
  queryParams?: PutContractBrInsalubridadeQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPutContractBrInsalubridade = (
  variables: PutContractBrInsalubridadeVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PutContractBrInsalubridadeError,
    Schemas.ContractBRCltInsalubridadeInput,
    {},
    PutContractBrInsalubridadeQueryParams,
    PutContractBrInsalubridadePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/br/insalubridades/{insalubridadeId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutContractBrInsalubridade = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PutContractBrInsalubridadeError,
      PutContractBrInsalubridadeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PutContractBrInsalubridadeError,
    PutContractBrInsalubridadeVariables
  >({
    mutationFn: (variables: PutContractBrInsalubridadeVariables) =>
      fetchPutContractBrInsalubridade({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteContractBrInsalubridadePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * Id da insalubridade
   *
   * @format uuid
   */
  insalubridadeId: string;
};

export type DeleteContractBrInsalubridadeQueryParams = {
  ignoreCheckIfEditablePayrolls?: string;
};

export type DeleteContractBrInsalubridadeError =
  Fetcher.ErrorWrapper<undefined>;

export type DeleteContractBrInsalubridadeVariables = {
  pathParams: DeleteContractBrInsalubridadePathParams;
  queryParams?: DeleteContractBrInsalubridadeQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteContractBrInsalubridade = (
  variables: DeleteContractBrInsalubridadeVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteContractBrInsalubridadeError,
    undefined,
    {},
    DeleteContractBrInsalubridadeQueryParams,
    DeleteContractBrInsalubridadePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/br/insalubridades/{insalubridadeId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteContractBrInsalubridade = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteContractBrInsalubridadeError,
      DeleteContractBrInsalubridadeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteContractBrInsalubridadeError,
    DeleteContractBrInsalubridadeVariables
  >({
    mutationFn: (variables: DeleteContractBrInsalubridadeVariables) =>
      fetchDeleteContractBrInsalubridade({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ContractsPersonPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * A unique identifier for a person
   *
   * @format uuid
   */
  personId: string;
};

export type ContractsPersonQueryParams = {
  size?: string;
  page?: string;
};

export type ContractsPersonError = Fetcher.ErrorWrapper<undefined>;

export type ContractsPersonVariables = {
  pathParams: ContractsPersonPathParams;
  queryParams?: ContractsPersonQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchContractsPerson = (
  variables: ContractsPersonVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractSummaryList,
    ContractsPersonError,
    undefined,
    {},
    ContractsPersonQueryParams,
    ContractsPersonPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/people/{personId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useContractsPerson = <TData = Schemas.ContractSummaryList,>(
  variables: ContractsPersonVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ContractSummaryList,
      ContractsPersonError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ContractSummaryList,
    ContractsPersonError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/people/{personId}',
      operationId: 'contractsPerson',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchContractsPerson({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type RegisterContractLeavesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type RegisterContractLeavesError = Fetcher.ErrorWrapper<undefined>;

export type RegisterContractLeavesVariables = {
  body?: Schemas.ContractLeavesInput;
  pathParams: RegisterContractLeavesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRegisterContractLeaves = (
  variables: RegisterContractLeavesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractLeavesCreationOutput,
    RegisterContractLeavesError,
    Schemas.ContractLeavesInput,
    {},
    {},
    RegisterContractLeavesPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/leaves',
    method: 'post',
    ...variables,
    signal,
  });

export const useRegisterContractLeaves = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractLeavesCreationOutput,
      RegisterContractLeavesError,
      RegisterContractLeavesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractLeavesCreationOutput,
    RegisterContractLeavesError,
    RegisterContractLeavesVariables
  >({
    mutationFn: (variables: RegisterContractLeavesVariables) =>
      fetchRegisterContractLeaves({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetContractLeavePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the leave to retrieve
   */
  leaveId: string;
};

export type GetContractLeaveQueryParams = {
  /**
   * @format date
   */
  effectiveDate?: string;
  sequenceId?: string;
};

export type GetContractLeaveError = Fetcher.ErrorWrapper<undefined>;

export type GetContractLeaveVariables = {
  pathParams: GetContractLeavePathParams;
  queryParams?: GetContractLeaveQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractLeave = (
  variables: GetContractLeaveVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractLeavesEntry,
    GetContractLeaveError,
    undefined,
    {},
    GetContractLeaveQueryParams,
    GetContractLeavePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/leaves/{leaveId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractLeave = <TData = Schemas.ContractLeavesEntry,>(
  variables: GetContractLeaveVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ContractLeavesEntry,
      GetContractLeaveError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ContractLeavesEntry,
    GetContractLeaveError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/{contractId}/leaves/{leaveId}',
      operationId: 'getContractLeave',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractLeave({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PatchContractLeavePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the leave to retrieve
   */
  leaveId: string;
};

export type PatchContractLeaveError = Fetcher.ErrorWrapper<undefined>;

export type PatchContractLeaveVariables = {
  body?: Schemas.ContractLeavesPatch;
  pathParams: PatchContractLeavePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPatchContractLeave = (
  variables: PatchContractLeaveVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PatchContractLeaveError,
    Schemas.ContractLeavesPatch,
    {},
    {},
    PatchContractLeavePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/leaves/{leaveId}',
    method: 'patch',
    ...variables,
    signal,
  });

export const usePatchContractLeave = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PatchContractLeaveError,
      PatchContractLeaveVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PatchContractLeaveError,
    PatchContractLeaveVariables
  >({
    mutationFn: (variables: PatchContractLeaveVariables) =>
      fetchPatchContractLeave({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteContractLeavePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the leave to retrieve
   */
  leaveId: string;
};

export type DeleteContractLeaveError = Fetcher.ErrorWrapper<undefined>;

export type DeleteContractLeaveVariables = {
  body?: Schemas.ContractLeavesDelete;
  pathParams: DeleteContractLeavePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteContractLeave = (
  variables: DeleteContractLeaveVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteContractLeaveError,
    Schemas.ContractLeavesDelete,
    {},
    {},
    DeleteContractLeavePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/leaves/{leaveId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteContractLeave = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteContractLeaveError,
      DeleteContractLeaveVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteContractLeaveError,
    DeleteContractLeaveVariables
  >({
    mutationFn: (variables: DeleteContractLeaveVariables) =>
      fetchDeleteContractLeave({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchAllLeavesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type SearchAllLeavesError = Fetcher.ErrorWrapper<undefined>;

export type SearchAllLeavesVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAllLeavesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAllLeaves = (
  variables: SearchAllLeavesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractLeavesList,
    SearchAllLeavesError,
    Schemas.SearchInput,
    {},
    {},
    SearchAllLeavesPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/leaves/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAllLeaves = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractLeavesList,
      SearchAllLeavesError,
      SearchAllLeavesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractLeavesList,
    SearchAllLeavesError,
    SearchAllLeavesVariables
  >({
    mutationFn: (variables: SearchAllLeavesVariables) =>
      fetchSearchAllLeaves({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostPayrollsCheckIfEditablePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostPayrollsCheckIfEditableError = Fetcher.ErrorWrapper<undefined>;

export type PostPayrollsCheckIfEditableVariables = {
  body: Schemas.PayrollCheckIfEditableInput;
  pathParams: PostPayrollsCheckIfEditablePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPayrollsCheckIfEditable = (
  variables: PostPayrollsCheckIfEditableVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollCheckIfEditableOutput,
    PostPayrollsCheckIfEditableError,
    Schemas.PayrollCheckIfEditableInput,
    {},
    {},
    PostPayrollsCheckIfEditablePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/payrolls/check-if-editable',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPayrollsCheckIfEditable = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollCheckIfEditableOutput,
      PostPayrollsCheckIfEditableError,
      PostPayrollsCheckIfEditableVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollCheckIfEditableOutput,
    PostPayrollsCheckIfEditableError,
    PostPayrollsCheckIfEditableVariables
  >({
    mutationFn: (variables: PostPayrollsCheckIfEditableVariables) =>
      fetchPostPayrollsCheckIfEditable({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostPayrollRecurringEventPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostPayrollRecurringEventError = Fetcher.ErrorWrapper<undefined>;

export type PostPayrollRecurringEventVariables = {
  body?: Schemas.PayrollRecurringEventInput;
  pathParams: PostPayrollRecurringEventPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostPayrollRecurringEvent = (
  variables: PostPayrollRecurringEventVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollRecurringEventEntry,
    PostPayrollRecurringEventError,
    Schemas.PayrollRecurringEventInput,
    {},
    {},
    PostPayrollRecurringEventPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/payrolls/recurring-events',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPayrollRecurringEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollRecurringEventEntry,
      PostPayrollRecurringEventError,
      PostPayrollRecurringEventVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollRecurringEventEntry,
    PostPayrollRecurringEventError,
    PostPayrollRecurringEventVariables
  >({
    mutationFn: (variables: PostPayrollRecurringEventVariables) =>
      fetchPostPayrollRecurringEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchRecurringEventsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type SearchRecurringEventsError = Fetcher.ErrorWrapper<undefined>;

export type SearchRecurringEventsVariables = {
  body?: Schemas.PayrollRecurringEventSearchInput;
  pathParams: SearchRecurringEventsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchRecurringEvents = (
  variables: SearchRecurringEventsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollRecurringEventList,
    SearchRecurringEventsError,
    Schemas.PayrollRecurringEventSearchInput,
    {},
    {},
    SearchRecurringEventsPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/payrolls/recurring-events/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchRecurringEvents = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PayrollRecurringEventList,
      SearchRecurringEventsError,
      SearchRecurringEventsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PayrollRecurringEventList,
    SearchRecurringEventsError,
    SearchRecurringEventsVariables
  >({
    mutationFn: (variables: SearchRecurringEventsVariables) =>
      fetchSearchRecurringEvents({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPayrollRecurringEventPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the recurring event
   *
   * @format uuid
   */
  recurringEventId: string;
};

export type GetPayrollRecurringEventError = Fetcher.ErrorWrapper<undefined>;

export type GetPayrollRecurringEventVariables = {
  pathParams: GetPayrollRecurringEventPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetPayrollRecurringEvent = (
  variables: GetPayrollRecurringEventVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollRecurringEventEntry,
    GetPayrollRecurringEventError,
    undefined,
    {},
    {},
    GetPayrollRecurringEventPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/payrolls/recurring-events/{recurringEventId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPayrollRecurringEvent = <
  TData = Schemas.PayrollRecurringEventEntry,
>(
  variables: GetPayrollRecurringEventVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollRecurringEventEntry,
      GetPayrollRecurringEventError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollRecurringEventEntry,
    GetPayrollRecurringEventError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/{contractId}/payrolls/recurring-events/{recurringEventId}',
      operationId: 'getPayrollRecurringEvent',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPayrollRecurringEvent(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PutPayrollRecurringEventPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the recurring event
   *
   * @format uuid
   */
  recurringEventId: string;
};

export type PutPayrollRecurringEventQueryParams = {
  versionId?: string;
};

export type PutPayrollRecurringEventError = Fetcher.ErrorWrapper<undefined>;

export type PutPayrollRecurringEventVariables = {
  body?: Schemas.PayrollRecurringEventInput;
  pathParams: PutPayrollRecurringEventPathParams;
  queryParams?: PutPayrollRecurringEventQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPutPayrollRecurringEvent = (
  variables: PutPayrollRecurringEventVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PutPayrollRecurringEventError,
    Schemas.PayrollRecurringEventInput,
    {},
    PutPayrollRecurringEventQueryParams,
    PutPayrollRecurringEventPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/payrolls/recurring-events/{recurringEventId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutPayrollRecurringEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PutPayrollRecurringEventError,
      PutPayrollRecurringEventVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PutPayrollRecurringEventError,
    PutPayrollRecurringEventVariables
  >({
    mutationFn: (variables: PutPayrollRecurringEventVariables) =>
      fetchPutPayrollRecurringEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePayrollRecurringEventPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the recurring event
   *
   * @format uuid
   */
  recurringEventId: string;
};

export type DeletePayrollRecurringEventQueryParams = {
  versionId?: string;
};

export type DeletePayrollRecurringEventError = Fetcher.ErrorWrapper<undefined>;

export type DeletePayrollRecurringEventVariables = {
  pathParams: DeletePayrollRecurringEventPathParams;
  queryParams?: DeletePayrollRecurringEventQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDeletePayrollRecurringEvent = (
  variables: DeletePayrollRecurringEventVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeletePayrollRecurringEventError,
    undefined,
    {},
    DeletePayrollRecurringEventQueryParams,
    DeletePayrollRecurringEventPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/payrolls/recurring-events/{recurringEventId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePayrollRecurringEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePayrollRecurringEventError,
      DeletePayrollRecurringEventVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeletePayrollRecurringEventError,
    DeletePayrollRecurringEventVariables
  >({
    mutationFn: (variables: DeletePayrollRecurringEventVariables) =>
      fetchDeletePayrollRecurringEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListSentPayslipsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type ListSentPayslipsQueryParams = {
  page?: string;
};

export type ListSentPayslipsError = Fetcher.ErrorWrapper<undefined>;

export type ListSentPayslipsVariables = {
  pathParams: ListSentPayslipsPathParams;
  queryParams?: ListSentPayslipsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchListSentPayslips = (
  variables: ListSentPayslipsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPayslipList,
    ListSentPayslipsError,
    undefined,
    {},
    ListSentPayslipsQueryParams,
    ListSentPayslipsPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/payslips',
    method: 'get',
    ...variables,
    signal,
  });

export const useListSentPayslips = <TData = Schemas.PayrollPayslipList,>(
  variables: ListSentPayslipsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollPayslipList,
      ListSentPayslipsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollPayslipList,
    ListSentPayslipsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/{contractId}/payslips',
      operationId: 'listSentPayslips',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListSentPayslips({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetSentPayslipPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type GetSentPayslipError = Fetcher.ErrorWrapper<undefined>;

export type GetSentPayslipVariables = {
  pathParams: GetSentPayslipPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetSentPayslip = (
  variables: GetSentPayslipVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PayrollPayslipEntry,
    GetSentPayslipError,
    undefined,
    {},
    {},
    GetSentPayslipPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/payslips/{payrollId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetSentPayslip = <TData = Schemas.PayrollPayslipEntry,>(
  variables: GetSentPayslipVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PayrollPayslipEntry,
      GetSentPayslipError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PayrollPayslipEntry,
    GetSentPayslipError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/{contractId}/payslips/{payrollId}',
      operationId: 'getSentPayslip',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSentPayslip({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ContractsUpdateManagerPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type ContractsUpdateManagerHeaders = {
  /**
   * The idempotency key for this request
   */
  ['x-idempotency-key']?: string;
};

export type ContractsUpdateManagerError = Fetcher.ErrorWrapper<undefined>;

export type ContractsUpdateManagerVariables = {
  body?: Schemas.ContractOrgStructureUpdateManager;
  headers?: ContractsUpdateManagerHeaders;
  pathParams: ContractsUpdateManagerPathParams;
} & ApiContext['fetcherOptions'];

export const fetchContractsUpdateManager = (
  variables: ContractsUpdateManagerVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ContractsUpdateManagerError,
    Schemas.ContractOrgStructureUpdateManager,
    ContractsUpdateManagerHeaders,
    {},
    ContractsUpdateManagerPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/managers',
    method: 'post',
    ...variables,
    signal,
  });

export const useContractsUpdateManager = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ContractsUpdateManagerError,
      ContractsUpdateManagerVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ContractsUpdateManagerError,
    ContractsUpdateManagerVariables
  >({
    mutationFn: (variables: ContractsUpdateManagerVariables) =>
      fetchContractsUpdateManager({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostContractsotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type PostContractsotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type PostContractsotificationsConfigurationVariables = {
  body: Schemas.NotificationConfigurationInput;
  pathParams: PostContractsotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostContractsotificationsConfiguration = (
  variables: PostContractsotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfiguration,
    PostContractsotificationsConfigurationError,
    Schemas.NotificationConfigurationInput,
    {},
    {},
    PostContractsotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/notification-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostContractsotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationConfiguration,
      PostContractsotificationsConfigurationError,
      PostContractsotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationConfiguration,
    PostContractsotificationsConfigurationError,
    PostContractsotificationsConfigurationVariables
  >({
    mutationFn: (variables: PostContractsotificationsConfigurationVariables) =>
      fetchPostContractsotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ListContractsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type ListContractsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type ListContractsNotificationsConfigurationVariables = {
  pathParams: ListContractsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListContractsNotificationsConfiguration = (
  variables: ListContractsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfigurationsList,
    ListContractsNotificationsConfigurationError,
    undefined,
    {},
    {},
    ListContractsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/notification-configurations',
    method: 'get',
    ...variables,
    signal,
  });

export const useListContractsNotificationsConfiguration = <
  TData = Schemas.NotificationConfigurationsList,
>(
  variables: ListContractsNotificationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.NotificationConfigurationsList,
      ListContractsNotificationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.NotificationConfigurationsList,
    ListContractsNotificationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/notification-configurations',
      operationId: 'listContractsNotificationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListContractsNotificationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type TestContractsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type TestContractsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type TestContractsNotificationsConfigurationRequestBody = {
  [key: string]: any;
};

export type TestContractsNotificationsConfigurationVariables = {
  body?: TestContractsNotificationsConfigurationRequestBody;
  pathParams: TestContractsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTestContractsNotificationsConfiguration = (
  variables: TestContractsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationTest,
    TestContractsNotificationsConfigurationError,
    TestContractsNotificationsConfigurationRequestBody,
    {},
    {},
    TestContractsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/notification-configurations/{resourceType}/testConfig',
    method: 'post',
    ...variables,
    signal,
  });

export const useTestContractsNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationTest,
      TestContractsNotificationsConfigurationError,
      TestContractsNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationTest,
    TestContractsNotificationsConfigurationError,
    TestContractsNotificationsConfigurationVariables
  >({
    mutationFn: (variables: TestContractsNotificationsConfigurationVariables) =>
      fetchTestContractsNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetContractsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the
   */
  ruleId: void;
};

export type GetContractsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetContractsNotificationsConfigurationVariables = {
  pathParams: GetContractsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractsNotificationsConfiguration = (
  variables: GetContractsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    GetContractsNotificationsConfigurationError,
    undefined,
    {},
    {},
    GetContractsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractsNotificationsConfiguration = <TData = undefined,>(
  variables: GetContractsNotificationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      GetContractsNotificationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    undefined,
    GetContractsNotificationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/contracts/notification-configurations/{resourceType}/rules/{ruleId}',
      operationId: 'getContractsNotificationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractsNotificationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type UpdateContractsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the
   */
  ruleId: void;
};

export type UpdateContractsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type UpdateContractsNotificationsConfigurationVariables = {
  body: Schemas.NotificationConfiguration;
  pathParams: UpdateContractsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateContractsNotificationsConfiguration = (
  variables: UpdateContractsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfiguration,
    UpdateContractsNotificationsConfigurationError,
    Schemas.NotificationConfiguration,
    {},
    {},
    UpdateContractsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateContractsNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationConfiguration,
      UpdateContractsNotificationsConfigurationError,
      UpdateContractsNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationConfiguration,
    UpdateContractsNotificationsConfigurationError,
    UpdateContractsNotificationsConfigurationVariables
  >({
    mutationFn: (
      variables: UpdateContractsNotificationsConfigurationVariables,
    ) =>
      fetchUpdateContractsNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PostSalaryChangePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostSalaryChangeError = Fetcher.ErrorWrapper<undefined>;

export type PostSalaryChangeResponse =
  Schemas.ContractSalaryChangeEventReference[];

export type PostSalaryChangeVariables = {
  body?: Schemas.ContractSalaryChange;
  pathParams: PostSalaryChangePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostSalaryChange = (
  variables: PostSalaryChangeVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    PostSalaryChangeResponse,
    PostSalaryChangeError,
    Schemas.ContractSalaryChange,
    {},
    {},
    PostSalaryChangePathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/salary-changes',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostSalaryChange = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PostSalaryChangeResponse,
      PostSalaryChangeError,
      PostSalaryChangeVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    PostSalaryChangeResponse,
    PostSalaryChangeError,
    PostSalaryChangeVariables
  >({
    mutationFn: (variables: PostSalaryChangeVariables) =>
      fetchPostSalaryChange({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchContractDocumentsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type SearchContractDocumentsError = Fetcher.ErrorWrapper<undefined>;

export type SearchContractDocumentsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchContractDocumentsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchContractDocuments = (
  variables: SearchContractDocumentsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractDocumentSearch,
    SearchContractDocumentsError,
    Schemas.SearchInput,
    {},
    {},
    SearchContractDocumentsPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/documents/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchContractDocuments = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractDocumentSearch,
      SearchContractDocumentsError,
      SearchContractDocumentsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractDocumentSearch,
    SearchContractDocumentsError,
    SearchContractDocumentsVariables
  >({
    mutationFn: (variables: SearchContractDocumentsVariables) =>
      fetchSearchContractDocuments({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DownloadContractDocumentsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type DownloadContractDocumentsError = Fetcher.ErrorWrapper<undefined>;

export type DownloadContractDocumentsResponse = {
  /**
   * URL para download do arquivo
   *
   * @format uri
   */
  downloadUrl: string;
};

export type DownloadContractDocumentsRequestBody = {
  /**
   * @minItems 1
   * @maxItems 100
   */
  fileIds: string[];
};

export type DownloadContractDocumentsVariables = {
  body: DownloadContractDocumentsRequestBody;
  pathParams: DownloadContractDocumentsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadContractDocuments = (
  variables: DownloadContractDocumentsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    DownloadContractDocumentsResponse,
    DownloadContractDocumentsError,
    DownloadContractDocumentsRequestBody,
    {},
    {},
    DownloadContractDocumentsPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/documents/download',
    method: 'post',
    ...variables,
    signal,
  });

export const useDownloadContractDocuments = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      DownloadContractDocumentsResponse,
      DownloadContractDocumentsError,
      DownloadContractDocumentsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    DownloadContractDocumentsResponse,
    DownloadContractDocumentsError,
    DownloadContractDocumentsVariables
  >({
    mutationFn: (variables: DownloadContractDocumentsVariables) =>
      fetchDownloadContractDocuments({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteContractDocumentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The id of the document resource
   *
   * @format uuid
   */
  documentId: string;
};

export type DeleteContractDocumentError = Fetcher.ErrorWrapper<undefined>;

export type DeleteContractDocumentVariables = {
  pathParams: DeleteContractDocumentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteContractDocument = (
  variables: DeleteContractDocumentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteContractDocumentError,
    undefined,
    {},
    {},
    DeleteContractDocumentPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/documents/{documentId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteContractDocument = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteContractDocumentError,
      DeleteContractDocumentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteContractDocumentError,
    DeleteContractDocumentVariables
  >({
    mutationFn: (variables: DeleteContractDocumentVariables) =>
      fetchDeleteContractDocument({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UploadContractDocumentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type UploadContractDocumentError = Fetcher.ErrorWrapper<undefined>;

export type UploadContractDocumentVariables = {
  body: Schemas.ContractDocumentInput;
  pathParams: UploadContractDocumentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUploadContractDocument = (
  variables: UploadContractDocumentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractDocumentEntry,
    UploadContractDocumentError,
    Schemas.ContractDocumentInput,
    {},
    {},
    UploadContractDocumentPathParams
  >({
    url: '/api/organizations/{organizationId}/contracts/{contractId}/documents',
    method: 'post',
    ...variables,
    signal,
  });

export const useUploadContractDocument = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractDocumentEntry,
      UploadContractDocumentError,
      UploadContractDocumentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractDocumentEntry,
    UploadContractDocumentError,
    UploadContractDocumentVariables
  >({
    mutationFn: (variables: UploadContractDocumentVariables) =>
      fetchUploadContractDocument({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllJobTitlesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetAllJobTitlesQueryParams = {
  query?: string;
  size?: string;
  page?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  rangeFilters?: string;
  elementFilters?: string;
};

export type GetAllJobTitlesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllJobTitlesVariables = {
  pathParams: GetAllJobTitlesPathParams;
  queryParams?: GetAllJobTitlesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAllJobTitles = (
  variables: GetAllJobTitlesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.JobTitleList,
    GetAllJobTitlesError,
    undefined,
    {},
    GetAllJobTitlesQueryParams,
    GetAllJobTitlesPathParams
  >({
    url: '/api/organizations/{organizationId}/job-titles',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllJobTitles = <TData = Schemas.JobTitleList,>(
  variables: GetAllJobTitlesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.JobTitleList,
      GetAllJobTitlesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.JobTitleList, GetAllJobTitlesError, TData>(
    {
      queryKey: queryKeyFn({
        path: '/api/organizations/{organizationId}/job-titles',
        operationId: 'getAllJobTitles',
        variables,
      }),
      queryFn: ({ signal }) =>
        fetchGetAllJobTitles({ ...fetcherOptions, ...variables }, signal),
      ...options,
      ...queryOptions,
    },
  );
};

export type PostJobTitlePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PostJobTitleError = Fetcher.ErrorWrapper<undefined>;

export type PostJobTitleVariables = {
  body: Schemas.JobTitleInput;
  pathParams: PostJobTitlePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostJobTitle = (
  variables: PostJobTitleVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.JobTitleEntry,
    PostJobTitleError,
    Schemas.JobTitleInput,
    {},
    {},
    PostJobTitlePathParams
  >({
    url: '/api/organizations/{organizationId}/job-titles',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostJobTitle = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.JobTitleEntry,
      PostJobTitleError,
      PostJobTitleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.JobTitleEntry,
    PostJobTitleError,
    PostJobTitleVariables
  >({
    mutationFn: (variables: PostJobTitleVariables) =>
      fetchPostJobTitle({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetJobTitleEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the job title to retrieve
   *
   * @format uuid
   */
  jobTitleId: string;
};

export type GetJobTitleEntryError = Fetcher.ErrorWrapper<undefined>;

export type GetJobTitleEntryVariables = {
  pathParams: GetJobTitleEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetJobTitleEntry = (
  variables: GetJobTitleEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.JobTitleEntry,
    GetJobTitleEntryError,
    undefined,
    {},
    {},
    GetJobTitleEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/job-titles/{jobTitleId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetJobTitleEntry = <TData = Schemas.JobTitleEntry,>(
  variables: GetJobTitleEntryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.JobTitleEntry,
      GetJobTitleEntryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.JobTitleEntry,
    GetJobTitleEntryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/job-titles/{jobTitleId}',
      operationId: 'getJobTitleEntry',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetJobTitleEntry({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutJobTitleEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the job title to retrieve
   *
   * @format uuid
   */
  jobTitleId: string;
};

export type PutJobTitleEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutJobTitleEntryVariables = {
  body: Schemas.JobTitleInput;
  pathParams: PutJobTitleEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutJobTitleEntry = (
  variables: PutJobTitleEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.JobTitleEntry,
    PutJobTitleEntryError,
    Schemas.JobTitleInput,
    {},
    {},
    PutJobTitleEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/job-titles/{jobTitleId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutJobTitleEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.JobTitleEntry,
      PutJobTitleEntryError,
      PutJobTitleEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.JobTitleEntry,
    PutJobTitleEntryError,
    PutJobTitleEntryVariables
  >({
    mutationFn: (variables: PutJobTitleEntryVariables) =>
      fetchPutJobTitleEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteJobTitleEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the job title to retrieve
   *
   * @format uuid
   */
  jobTitleId: string;
};

export type DeleteJobTitleEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteJobTitleEntryVariables = {
  pathParams: DeleteJobTitleEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteJobTitleEntry = (
  variables: DeleteJobTitleEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteJobTitleEntryError,
    undefined,
    {},
    {},
    DeleteJobTitleEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/job-titles/{jobTitleId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteJobTitleEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteJobTitleEntryError,
      DeleteJobTitleEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteJobTitleEntryError,
    DeleteJobTitleEntryVariables
  >({
    mutationFn: (variables: DeleteJobTitleEntryVariables) =>
      fetchDeleteJobTitleEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ActivateJobTitleEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the job title to retrieve
   *
   * @format uuid
   */
  jobTitleId: string;
};

export type ActivateJobTitleEntryError = Fetcher.ErrorWrapper<undefined>;

export type ActivateJobTitleEntryVariables = {
  pathParams: ActivateJobTitleEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchActivateJobTitleEntry = (
  variables: ActivateJobTitleEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ActivateJobTitleEntryError,
    undefined,
    {},
    {},
    ActivateJobTitleEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/job-titles/{jobTitleId}/activate',
    method: 'post',
    ...variables,
    signal,
  });

export const useActivateJobTitleEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ActivateJobTitleEntryError,
      ActivateJobTitleEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ActivateJobTitleEntryError,
    ActivateJobTitleEntryVariables
  >({
    mutationFn: (variables: ActivateJobTitleEntryVariables) =>
      fetchActivateJobTitleEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeactivateJobTitleEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the job title to retrieve
   *
   * @format uuid
   */
  jobTitleId: string;
};

export type DeactivateJobTitleEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeactivateJobTitleEntryVariables = {
  pathParams: DeactivateJobTitleEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeactivateJobTitleEntry = (
  variables: DeactivateJobTitleEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeactivateJobTitleEntryError,
    undefined,
    {},
    {},
    DeactivateJobTitleEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/job-titles/{jobTitleId}/deactivate',
    method: 'post',
    ...variables,
    signal,
  });

export const useDeactivateJobTitleEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeactivateJobTitleEntryError,
      DeactivateJobTitleEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeactivateJobTitleEntryError,
    DeactivateJobTitleEntryVariables
  >({
    mutationFn: (variables: DeactivateJobTitleEntryVariables) =>
      fetchDeactivateJobTitleEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchAllCostCentersPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type SearchAllCostCentersError = Fetcher.ErrorWrapper<undefined>;

export type SearchAllCostCentersVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAllCostCentersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAllCostCenters = (
  variables: SearchAllCostCentersVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CostCenterList,
    SearchAllCostCentersError,
    Schemas.SearchInput,
    {},
    {},
    SearchAllCostCentersPathParams
  >({
    url: '/api/organizations/{organizationId}/cost-centers/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAllCostCenters = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostCenterList,
      SearchAllCostCentersError,
      SearchAllCostCentersVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CostCenterList,
    SearchAllCostCentersError,
    SearchAllCostCentersVariables
  >({
    mutationFn: (variables: SearchAllCostCentersVariables) =>
      fetchSearchAllCostCenters({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostCostCenterPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PostCostCenterError = Fetcher.ErrorWrapper<undefined>;

export type PostCostCenterVariables = {
  body: Schemas.CostCenterInput;
  pathParams: PostCostCenterPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostCostCenter = (
  variables: PostCostCenterVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CostCenterEntry,
    PostCostCenterError,
    Schemas.CostCenterInput,
    {},
    {},
    PostCostCenterPathParams
  >({
    url: '/api/organizations/{organizationId}/cost-centers',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostCostCenter = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostCenterEntry,
      PostCostCenterError,
      PostCostCenterVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CostCenterEntry,
    PostCostCenterError,
    PostCostCenterVariables
  >({
    mutationFn: (variables: PostCostCenterVariables) =>
      fetchPostCostCenter({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCostCenterEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * Cost Center Id
   */
  costCenterId: string;
};

export type GetCostCenterEntryError = Fetcher.ErrorWrapper<undefined>;

export type GetCostCenterEntryVariables = {
  pathParams: GetCostCenterEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetCostCenterEntry = (
  variables: GetCostCenterEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CostCenterEntry,
    GetCostCenterEntryError,
    undefined,
    {},
    {},
    GetCostCenterEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/cost-centers/{costCenterId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetCostCenterEntry = <TData = Schemas.CostCenterEntry,>(
  variables: GetCostCenterEntryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.CostCenterEntry,
      GetCostCenterEntryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.CostCenterEntry,
    GetCostCenterEntryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/cost-centers/{costCenterId}',
      operationId: 'getCostCenterEntry',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCostCenterEntry({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutCostCenterEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * Cost Center Id
   */
  costCenterId: string;
};

export type PutCostCenterEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutCostCenterEntryVariables = {
  body: Schemas.CostCenterInput;
  pathParams: PutCostCenterEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutCostCenterEntry = (
  variables: PutCostCenterEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.CostCenterEntry,
    PutCostCenterEntryError,
    Schemas.CostCenterInput,
    {},
    {},
    PutCostCenterEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/cost-centers/{costCenterId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutCostCenterEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.CostCenterEntry,
      PutCostCenterEntryError,
      PutCostCenterEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.CostCenterEntry,
    PutCostCenterEntryError,
    PutCostCenterEntryVariables
  >({
    mutationFn: (variables: PutCostCenterEntryVariables) =>
      fetchPutCostCenterEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteCostCenterEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * Cost Center Id
   */
  costCenterId: string;
};

export type DeleteCostCenterEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteCostCenterEntryVariables = {
  pathParams: DeleteCostCenterEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteCostCenterEntry = (
  variables: DeleteCostCenterEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteCostCenterEntryError,
    undefined,
    {},
    {},
    DeleteCostCenterEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/cost-centers/{costCenterId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteCostCenterEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteCostCenterEntryError,
      DeleteCostCenterEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteCostCenterEntryError,
    DeleteCostCenterEntryVariables
  >({
    mutationFn: (variables: DeleteCostCenterEntryVariables) =>
      fetchDeleteCostCenterEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ActivateCostCenterEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * Cost Center Id
   */
  costCenterId: string;
};

export type ActivateCostCenterEntryError = Fetcher.ErrorWrapper<undefined>;

export type ActivateCostCenterEntryVariables = {
  pathParams: ActivateCostCenterEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchActivateCostCenterEntry = (
  variables: ActivateCostCenterEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ActivateCostCenterEntryError,
    undefined,
    {},
    {},
    ActivateCostCenterEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/cost-centers/{costCenterId}/activate',
    method: 'post',
    ...variables,
    signal,
  });

export const useActivateCostCenterEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ActivateCostCenterEntryError,
      ActivateCostCenterEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ActivateCostCenterEntryError,
    ActivateCostCenterEntryVariables
  >({
    mutationFn: (variables: ActivateCostCenterEntryVariables) =>
      fetchActivateCostCenterEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeactivateCostCenterEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * Cost Center Id
   */
  costCenterId: string;
};

export type DeactivateCostCenterEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeactivateCostCenterEntryVariables = {
  pathParams: DeactivateCostCenterEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeactivateCostCenterEntry = (
  variables: DeactivateCostCenterEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeactivateCostCenterEntryError,
    undefined,
    {},
    {},
    DeactivateCostCenterEntryPathParams
  >({
    url: '/api/organizations/{organizationId}/cost-centers/{costCenterId}/deactivate',
    method: 'post',
    ...variables,
    signal,
  });

export const useDeactivateCostCenterEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeactivateCostCenterEntryError,
      DeactivateCostCenterEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeactivateCostCenterEntryError,
    DeactivateCostCenterEntryVariables
  >({
    mutationFn: (variables: DeactivateCostCenterEntryVariables) =>
      fetchDeactivateCostCenterEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetReportDefinitionsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetReportDefinitionsError = Fetcher.ErrorWrapper<undefined>;

export type GetReportDefinitionsVariables = {
  pathParams: GetReportDefinitionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetReportDefinitions = (
  variables: GetReportDefinitionsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ReportsDefinitionsList,
    GetReportDefinitionsError,
    undefined,
    {},
    {},
    GetReportDefinitionsPathParams
  >({
    url: '/api/organizations/{organizationId}/reports',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetReportDefinitions = <
  TData = Schemas.ReportsDefinitionsList,
>(
  variables: GetReportDefinitionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ReportsDefinitionsList,
      GetReportDefinitionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ReportsDefinitionsList,
    GetReportDefinitionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/reports',
      operationId: 'getReportDefinitions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetReportDefinitions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GenerateReportPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GenerateReportError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ReportsError;
}>;

export type GenerateReportVariables = {
  body: Schemas.ReportsInput;
  pathParams: GenerateReportPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGenerateReport = (
  variables: GenerateReportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ReportsOutput,
    GenerateReportError,
    Schemas.ReportsInput,
    {},
    {},
    GenerateReportPathParams
  >({
    url: '/api/organizations/{organizationId}/reports',
    method: 'post',
    ...variables,
    signal,
  });

export const useGenerateReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ReportsOutput,
      GenerateReportError,
      GenerateReportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ReportsOutput,
    GenerateReportError,
    GenerateReportVariables
  >({
    mutationFn: (variables: GenerateReportVariables) =>
      fetchGenerateReport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GeneratePdfReportPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GeneratePdfReportError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ReportsError;
}>;

export type GeneratePdfReportVariables = {
  body: Schemas.ReportsInput;
  pathParams: GeneratePdfReportPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGeneratePdfReport = (
  variables: GeneratePdfReportVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ReportsOutput,
    GeneratePdfReportError,
    Schemas.ReportsInput,
    {},
    {},
    GeneratePdfReportPathParams
  >({
    url: '/api/organizations/{organizationId}/reports/pdf',
    method: 'post',
    ...variables,
    signal,
  });

export const useGeneratePdfReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ReportsOutput,
      GeneratePdfReportError,
      GeneratePdfReportVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ReportsOutput,
    GeneratePdfReportError,
    GeneratePdfReportVariables
  >({
    mutationFn: (variables: GeneratePdfReportVariables) =>
      fetchGeneratePdfReport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetReportDefinitionPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the report
   */
  reportId: string;
};

export type GetReportDefinitionError = Fetcher.ErrorWrapper<undefined>;

export type GetReportDefinitionVariables = {
  pathParams: GetReportDefinitionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetReportDefinition = (
  variables: GetReportDefinitionVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ReportsDefinitionsEntry,
    GetReportDefinitionError,
    undefined,
    {},
    {},
    GetReportDefinitionPathParams
  >({
    url: '/api/organizations/{organizationId}/reports/{reportId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetReportDefinition = <
  TData = Schemas.ReportsDefinitionsEntry,
>(
  variables: GetReportDefinitionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ReportsDefinitionsEntry,
      GetReportDefinitionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ReportsDefinitionsEntry,
    GetReportDefinitionError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/reports/{reportId}',
      operationId: 'getReportDefinition',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetReportDefinition({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutPayrollEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type PutPayrollEntryQueryParams = {
  /**
   * Flag to ignore payroll period validation
   */
  ignorePayrollPeriod?: string;
};

export type PutPayrollEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutPayrollEntryVariables = {
  body: Schemas.WorkerReconciliationPayrollInput;
  pathParams: PutPayrollEntryPathParams;
  queryParams?: PutPayrollEntryQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPutPayrollEntry = (
  variables: PutPayrollEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PutPayrollEntryError,
    Schemas.WorkerReconciliationPayrollInput,
    {},
    PutPayrollEntryQueryParams,
    PutPayrollEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutPayrollEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PutPayrollEntryError,
      PutPayrollEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PutPayrollEntryError,
    PutPayrollEntryVariables
  >({
    mutationFn: (variables: PutPayrollEntryVariables) =>
      fetchPutPayrollEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePayrollEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type DeletePayrollEntryQueryParams = {
  /**
   * Flag to ignore payroll period validation
   */
  ignorePayrollPeriod?: string;
};

export type DeletePayrollEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeletePayrollEntryVariables = {
  pathParams: DeletePayrollEntryPathParams;
  queryParams?: DeletePayrollEntryQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDeletePayrollEntry = (
  variables: DeletePayrollEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeletePayrollEntryError,
    undefined,
    {},
    DeletePayrollEntryQueryParams,
    DeletePayrollEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePayrollEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeletePayrollEntryError,
      DeletePayrollEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeletePayrollEntryError,
    DeletePayrollEntryVariables
  >({
    mutationFn: (variables: DeletePayrollEntryVariables) =>
      fetchDeletePayrollEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateWorkerReconciliationReportingOverridePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type UpdateWorkerReconciliationReportingOverrideError =
  Fetcher.ErrorWrapper<undefined>;

export type UpdateWorkerReconciliationReportingOverrideVariables = {
  body: Schemas.WorkerReconciliationInputCustomInformationReportingOverride;
  pathParams: UpdateWorkerReconciliationReportingOverridePathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateWorkerReconciliationReportingOverride = (
  variables: UpdateWorkerReconciliationReportingOverrideVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    UpdateWorkerReconciliationReportingOverrideError,
    Schemas.WorkerReconciliationInputCustomInformationReportingOverride,
    {},
    {},
    UpdateWorkerReconciliationReportingOverridePathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/override-reporting',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateWorkerReconciliationReportingOverride = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpdateWorkerReconciliationReportingOverrideError,
      UpdateWorkerReconciliationReportingOverrideVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    UpdateWorkerReconciliationReportingOverrideError,
    UpdateWorkerReconciliationReportingOverrideVariables
  >({
    mutationFn: (
      variables: UpdateWorkerReconciliationReportingOverrideVariables,
    ) =>
      fetchUpdateWorkerReconciliationReportingOverride({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DeleteWorkerReconciliationReportingOverridePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type DeleteWorkerReconciliationReportingOverrideError =
  Fetcher.ErrorWrapper<undefined>;

export type DeleteWorkerReconciliationReportingOverrideVariables = {
  pathParams: DeleteWorkerReconciliationReportingOverridePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteWorkerReconciliationReportingOverride = (
  variables: DeleteWorkerReconciliationReportingOverrideVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteWorkerReconciliationReportingOverrideError,
    undefined,
    {},
    {},
    DeleteWorkerReconciliationReportingOverridePathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/override-reporting',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteWorkerReconciliationReportingOverride = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteWorkerReconciliationReportingOverrideError,
      DeleteWorkerReconciliationReportingOverrideVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteWorkerReconciliationReportingOverrideError,
    DeleteWorkerReconciliationReportingOverrideVariables
  >({
    mutationFn: (
      variables: DeleteWorkerReconciliationReportingOverrideVariables,
    ) =>
      fetchDeleteWorkerReconciliationReportingOverride({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetWorkerReconciliationReportingOverridePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the payroll to retrieve
   */
  payrollId: string;
};

export type GetWorkerReconciliationReportingOverrideError =
  Fetcher.ErrorWrapper<undefined>;

export type GetWorkerReconciliationReportingOverrideVariables = {
  pathParams: GetWorkerReconciliationReportingOverridePathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkerReconciliationReportingOverride = (
  variables: GetWorkerReconciliationReportingOverrideVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.WorkerReconciliationInputCustomInformationReportingOverride,
    GetWorkerReconciliationReportingOverrideError,
    undefined,
    {},
    {},
    GetWorkerReconciliationReportingOverridePathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/override-reporting',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkerReconciliationReportingOverride = <
  TData = Schemas.WorkerReconciliationInputCustomInformationReportingOverride,
>(
  variables: GetWorkerReconciliationReportingOverrideVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.WorkerReconciliationInputCustomInformationReportingOverride,
      GetWorkerReconciliationReportingOverrideError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.WorkerReconciliationInputCustomInformationReportingOverride,
    GetWorkerReconciliationReportingOverrideError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/esocial/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/override-reporting',
      operationId: 'getWorkerReconciliationReportingOverride',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkerReconciliationReportingOverride(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostCustomEsocialEventPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type PostCustomEsocialEventError = Fetcher.ErrorWrapper<undefined>;

export type PostCustomEsocialEventResponse = {
  eventId: string;
};

export type PostCustomEsocialEventVariables = {
  body: Schemas.CustomEventInput;
  pathParams: PostCustomEsocialEventPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostCustomEsocialEvent = (
  variables: PostCustomEsocialEventVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    PostCustomEsocialEventResponse,
    PostCustomEsocialEventError,
    Schemas.CustomEventInput,
    {},
    {},
    PostCustomEsocialEventPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/custom-events',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostCustomEsocialEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PostCustomEsocialEventResponse,
      PostCustomEsocialEventError,
      PostCustomEsocialEventVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    PostCustomEsocialEventResponse,
    PostCustomEsocialEventError,
    PostCustomEsocialEventVariables
  >({
    mutationFn: (variables: PostCustomEsocialEventVariables) =>
      fetchPostCustomEsocialEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetEsocialEventPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the event
   */
  eventId: string;
};

export type GetEsocialEventError = Fetcher.ErrorWrapper<undefined>;

export type GetEsocialEventVariables = {
  pathParams: GetEsocialEventPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetEsocialEvent = (
  variables: GetEsocialEventVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.EsocialEventEntry,
    GetEsocialEventError,
    undefined,
    {},
    {},
    GetEsocialEventPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/events/{eventId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetEsocialEvent = <TData = Schemas.EsocialEventEntry,>(
  variables: GetEsocialEventVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.EsocialEventEntry,
      GetEsocialEventError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.EsocialEventEntry,
    GetEsocialEventError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/esocial/organizations/{organizationId}/companies/{companyId}/events/{eventId}',
      operationId: 'getEsocialEvent',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetEsocialEvent({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteEsocialEventPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the event
   */
  eventId: string;
};

export type DeleteEsocialEventError = Fetcher.ErrorWrapper<undefined>;

export type DeleteEsocialEventVariables = {
  body?: Schemas.EsocialEventDeleteInput;
  pathParams: DeleteEsocialEventPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteEsocialEvent = (
  variables: DeleteEsocialEventVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteEsocialEventError,
    Schemas.EsocialEventDeleteInput,
    {},
    {},
    DeleteEsocialEventPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/events/{eventId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteEsocialEvent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteEsocialEventError,
      DeleteEsocialEventVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteEsocialEventError,
    DeleteEsocialEventVariables
  >({
    mutationFn: (variables: DeleteEsocialEventVariables) =>
      fetchDeleteEsocialEvent({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchEsocialEventsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the event
   */
  eventId: string;
};

export type SearchEsocialEventsError = Fetcher.ErrorWrapper<undefined>;

export type SearchEsocialEventsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchEsocialEventsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchEsocialEvents = (
  variables: SearchEsocialEventsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.EsocialEventList,
    SearchEsocialEventsError,
    Schemas.SearchInput,
    {},
    {},
    SearchEsocialEventsPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/events/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchEsocialEvents = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.EsocialEventList,
      SearchEsocialEventsError,
      SearchEsocialEventsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.EsocialEventList,
    SearchEsocialEventsError,
    SearchEsocialEventsVariables
  >({
    mutationFn: (variables: SearchEsocialEventsVariables) =>
      fetchSearchEsocialEvents({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EsocialRubricsProprietaryPostPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type EsocialRubricsProprietaryPostError =
  Fetcher.ErrorWrapper<undefined>;

export type EsocialRubricsProprietaryPostVariables = {
  pathParams: EsocialRubricsProprietaryPostPathParams;
} & ApiContext['fetcherOptions'];

export const fetchEsocialRubricsProprietaryPost = (
  variables: EsocialRubricsProprietaryPostVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    EsocialRubricsProprietaryPostError,
    undefined,
    {},
    {},
    EsocialRubricsProprietaryPostPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/rubrics-tables/proprietary',
    method: 'post',
    ...variables,
    signal,
  });

export const useEsocialRubricsProprietaryPost = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      EsocialRubricsProprietaryPostError,
      EsocialRubricsProprietaryPostVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    EsocialRubricsProprietaryPostError,
    EsocialRubricsProprietaryPostVariables
  >({
    mutationFn: (variables: EsocialRubricsProprietaryPostVariables) =>
      fetchEsocialRubricsProprietaryPost({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type EsocialPostRubricEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the table to retrieve
   *
   * @minLength 1
   * @maxLength 8
   */
  tableId: string;
  /**
   * The id of the rubric to update
   */
  rubricId: string;
};

export type EsocialPostRubricEntryError = Fetcher.ErrorWrapper<undefined>;

export type EsocialPostRubricEntryRequestBody = {
  validFrom: Schemas.DateYYYYMM;
  validTo?: Schemas.DateYYYYMM;
  /**
   * @minLength 1
   * @maxLength 100
   */
  description: string;
  action: 'create' | 'update';
  br: Schemas.BRRubric;
  newValidInterval?: {
    validFrom: Schemas.DateYYYYMM;
    validTo?: Schemas.DateYYYYMM;
  };
};

export type EsocialPostRubricEntryVariables = {
  body: EsocialPostRubricEntryRequestBody;
  pathParams: EsocialPostRubricEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchEsocialPostRubricEntry = (
  variables: EsocialPostRubricEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    EsocialPostRubricEntryError,
    EsocialPostRubricEntryRequestBody,
    {},
    {},
    EsocialPostRubricEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/rubrics-tables/{tableId}/rubrics/{rubricId}',
    method: 'post',
    ...variables,
    signal,
  });

export const useEsocialPostRubricEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      EsocialPostRubricEntryError,
      EsocialPostRubricEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    EsocialPostRubricEntryError,
    EsocialPostRubricEntryVariables
  >({
    mutationFn: (variables: EsocialPostRubricEntryVariables) =>
      fetchEsocialPostRubricEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type GetReconciliationEntryError = Fetcher.ErrorWrapper<undefined>;

export type GetReconciliationEntryVariables = {
  pathParams: GetReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetReconciliationEntry = (
  variables: GetReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ReconciliationEntry,
    GetReconciliationEntryError,
    undefined,
    {},
    {},
    GetReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetReconciliationEntry = <TData = Schemas.ReconciliationEntry,>(
  variables: GetReconciliationEntryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ReconciliationEntry,
      GetReconciliationEntryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ReconciliationEntry,
    GetReconciliationEntryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}',
      operationId: 'getReconciliationEntry',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetReconciliationEntry({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type PutReconciliationEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutReconciliationEntryVariables = {
  body?: Schemas.ReconciliationInput;
  pathParams: PutReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutReconciliationEntry = (
  variables: PutReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ReconciliationEntry,
    PutReconciliationEntryError,
    Schemas.ReconciliationInput,
    {},
    {},
    PutReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutReconciliationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ReconciliationEntry,
      PutReconciliationEntryError,
      PutReconciliationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ReconciliationEntry,
    PutReconciliationEntryError,
    PutReconciliationEntryVariables
  >({
    mutationFn: (variables: PutReconciliationEntryVariables) =>
      fetchPutReconciliationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type DeleteReconciliationEntryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteReconciliationEntryVariables = {
  pathParams: DeleteReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteReconciliationEntry = (
  variables: DeleteReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteReconciliationEntryError,
    undefined,
    {},
    {},
    DeleteReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteReconciliationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteReconciliationEntryError,
      DeleteReconciliationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteReconciliationEntryError,
    DeleteReconciliationEntryVariables
  >({
    mutationFn: (variables: DeleteReconciliationEntryVariables) =>
      fetchDeleteReconciliationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostReconciliationComplementaryInfoPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type PostReconciliationComplementaryInfoError =
  Fetcher.ErrorWrapper<undefined>;

export type PostReconciliationComplementaryInfoRequestBody = {
  /**
   * Percentual não substituído pela CPRB (ou seja, percentual da receita não desonerada), no formato XXX.YY
   */
  percentualNaoDesoneradoDeCP: string;
};

export type PostReconciliationComplementaryInfoVariables = {
  body: PostReconciliationComplementaryInfoRequestBody;
  pathParams: PostReconciliationComplementaryInfoPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostReconciliationComplementaryInfo = (
  variables: PostReconciliationComplementaryInfoVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PostReconciliationComplementaryInfoError,
    PostReconciliationComplementaryInfoRequestBody,
    {},
    {},
    PostReconciliationComplementaryInfoPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/complementaryInfo',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostReconciliationComplementaryInfo = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostReconciliationComplementaryInfoError,
      PostReconciliationComplementaryInfoVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PostReconciliationComplementaryInfoError,
    PostReconciliationComplementaryInfoVariables
  >({
    mutationFn: (variables: PostReconciliationComplementaryInfoVariables) =>
      fetchPostReconciliationComplementaryInfo({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type OpenReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type OpenReconciliationEntryError = Fetcher.ErrorWrapper<undefined>;

export type OpenReconciliationEntryVariables = {
  pathParams: OpenReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchOpenReconciliationEntry = (
  variables: OpenReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    OpenReconciliationEntryError,
    undefined,
    {},
    {},
    OpenReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/open',
    method: 'post',
    ...variables,
    signal,
  });

export const useOpenReconciliationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OpenReconciliationEntryError,
      OpenReconciliationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    OpenReconciliationEntryError,
    OpenReconciliationEntryVariables
  >({
    mutationFn: (variables: OpenReconciliationEntryVariables) =>
      fetchOpenReconciliationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ReconcileReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type ReconcileReconciliationEntryError = Fetcher.ErrorWrapper<undefined>;

export type ReconcileReconciliationEntryVariables = {
  pathParams: ReconcileReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReconcileReconciliationEntry = (
  variables: ReconcileReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ReconcileReconciliationEntryError,
    undefined,
    {},
    {},
    ReconcileReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/reconcile',
    method: 'post',
    ...variables,
    signal,
  });

export const useReconcileReconciliationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReconcileReconciliationEntryError,
      ReconcileReconciliationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ReconcileReconciliationEntryError,
    ReconcileReconciliationEntryVariables
  >({
    mutationFn: (variables: ReconcileReconciliationEntryVariables) =>
      fetchReconcileReconciliationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CloseReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type CloseReconciliationEntryError = Fetcher.ErrorWrapper<undefined>;

export type CloseReconciliationEntryVariables = {
  pathParams: CloseReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCloseReconciliationEntry = (
  variables: CloseReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    CloseReconciliationEntryError,
    undefined,
    {},
    {},
    CloseReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/close',
    method: 'post',
    ...variables,
    signal,
  });

export const useCloseReconciliationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CloseReconciliationEntryError,
      CloseReconciliationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CloseReconciliationEntryError,
    CloseReconciliationEntryVariables
  >({
    mutationFn: (variables: CloseReconciliationEntryVariables) =>
      fetchCloseReconciliationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CleanUpReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type CleanUpReconciliationEntryError = Fetcher.ErrorWrapper<undefined>;

export type CleanUpReconciliationEntryVariables = {
  pathParams: CleanUpReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCleanUpReconciliationEntry = (
  variables: CleanUpReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    CleanUpReconciliationEntryError,
    undefined,
    {},
    {},
    CleanUpReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/cleanup',
    method: 'post',
    ...variables,
    signal,
  });

export const useCleanUpReconciliationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CleanUpReconciliationEntryError,
      CleanUpReconciliationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CleanUpReconciliationEntryError,
    CleanUpReconciliationEntryVariables
  >({
    mutationFn: (variables: CleanUpReconciliationEntryVariables) =>
      fetchCleanUpReconciliationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type HandleErrorReconciliationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type HandleErrorReconciliationError = Fetcher.ErrorWrapper<undefined>;

export type HandleErrorReconciliationVariables = {
  pathParams: HandleErrorReconciliationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchHandleErrorReconciliation = (
  variables: HandleErrorReconciliationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    HandleErrorReconciliationError,
    undefined,
    {},
    {},
    HandleErrorReconciliationPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/handleError',
    method: 'post',
    ...variables,
    signal,
  });

export const useHandleErrorReconciliation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      HandleErrorReconciliationError,
      HandleErrorReconciliationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    HandleErrorReconciliationError,
    HandleErrorReconciliationVariables
  >({
    mutationFn: (variables: HandleErrorReconciliationVariables) =>
      fetchHandleErrorReconciliation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RecalculateReconciliationSummaryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type RecalculateReconciliationSummaryError =
  Fetcher.ErrorWrapper<undefined>;

export type RecalculateReconciliationSummaryVariables = {
  pathParams: RecalculateReconciliationSummaryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRecalculateReconciliationSummary = (
  variables: RecalculateReconciliationSummaryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    RecalculateReconciliationSummaryError,
    undefined,
    {},
    {},
    RecalculateReconciliationSummaryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/recalculate',
    method: 'post',
    ...variables,
    signal,
  });

export const useRecalculateReconciliationSummary = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      RecalculateReconciliationSummaryError,
      RecalculateReconciliationSummaryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    RecalculateReconciliationSummaryError,
    RecalculateReconciliationSummaryVariables
  >({
    mutationFn: (variables: RecalculateReconciliationSummaryVariables) =>
      fetchRecalculateReconciliationSummary({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type SearchWorkerReconciliationsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
};

export type SearchWorkerReconciliationsError = Fetcher.ErrorWrapper<undefined>;

export type SearchWorkerReconciliationsVariables = {
  body?: Schemas.WorkerReconciliationSearchInput;
  pathParams: SearchWorkerReconciliationsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchWorkerReconciliations = (
  variables: SearchWorkerReconciliationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.WorkerReconciliationList,
    SearchWorkerReconciliationsError,
    Schemas.WorkerReconciliationSearchInput,
    {},
    {},
    SearchWorkerReconciliationsPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/workers/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchWorkerReconciliations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.WorkerReconciliationList,
      SearchWorkerReconciliationsError,
      SearchWorkerReconciliationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.WorkerReconciliationList,
    SearchWorkerReconciliationsError,
    SearchWorkerReconciliationsVariables
  >({
    mutationFn: (variables: SearchWorkerReconciliationsVariables) =>
      fetchSearchWorkerReconciliations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetWorkerReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * A unique identifier for a person
   *
   * @format uuid
   */
  personId: string;
};

export type GetWorkerReconciliationEntryError = Fetcher.ErrorWrapper<undefined>;

export type GetWorkerReconciliationEntryVariables = {
  pathParams: GetWorkerReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetWorkerReconciliationEntry = (
  variables: GetWorkerReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.WorkerReconciliationEntry,
    GetWorkerReconciliationEntryError,
    undefined,
    {},
    {},
    GetWorkerReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/workers/{personId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetWorkerReconciliationEntry = <
  TData = Schemas.WorkerReconciliationEntry,
>(
  variables: GetWorkerReconciliationEntryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.WorkerReconciliationEntry,
      GetWorkerReconciliationEntryError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.WorkerReconciliationEntry,
    GetWorkerReconciliationEntryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/workers/{personId}',
      operationId: 'getWorkerReconciliationEntry',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetWorkerReconciliationEntry(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PutWorkerReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * A unique identifier for a person
   *
   * @format uuid
   */
  personId: string;
};

export type PutWorkerReconciliationEntryError = Fetcher.ErrorWrapper<undefined>;

export type PutWorkerReconciliationEntryVariables = {
  pathParams: PutWorkerReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutWorkerReconciliationEntry = (
  variables: PutWorkerReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.WorkerReconciliationEntry,
    PutWorkerReconciliationEntryError,
    undefined,
    {},
    {},
    PutWorkerReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/workers/{personId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutWorkerReconciliationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.WorkerReconciliationEntry,
      PutWorkerReconciliationEntryError,
      PutWorkerReconciliationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.WorkerReconciliationEntry,
    PutWorkerReconciliationEntryError,
    PutWorkerReconciliationEntryVariables
  >({
    mutationFn: (variables: PutWorkerReconciliationEntryVariables) =>
      fetchPutWorkerReconciliationEntry({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CleanUpWorkerReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * A unique identifier for a person
   *
   * @format uuid
   */
  personId: string;
};

export type CleanUpWorkerReconciliationEntryError =
  Fetcher.ErrorWrapper<undefined>;

export type CleanUpWorkerReconciliationEntryVariables = {
  pathParams: CleanUpWorkerReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCleanUpWorkerReconciliationEntry = (
  variables: CleanUpWorkerReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    CleanUpWorkerReconciliationEntryError,
    undefined,
    {},
    {},
    CleanUpWorkerReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/workers/{personId}/cleanup',
    method: 'post',
    ...variables,
    signal,
  });

export const useCleanUpWorkerReconciliationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CleanUpWorkerReconciliationEntryError,
      CleanUpWorkerReconciliationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CleanUpWorkerReconciliationEntryError,
    CleanUpWorkerReconciliationEntryVariables
  >({
    mutationFn: (variables: CleanUpWorkerReconciliationEntryVariables) =>
      fetchCleanUpWorkerReconciliationEntry({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type HandleErrorWorkerReconciliationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * A unique identifier for a person
   *
   * @format uuid
   */
  personId: string;
};

export type HandleErrorWorkerReconciliationError =
  Fetcher.ErrorWrapper<undefined>;

export type HandleErrorWorkerReconciliationVariables = {
  pathParams: HandleErrorWorkerReconciliationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchHandleErrorWorkerReconciliation = (
  variables: HandleErrorWorkerReconciliationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    HandleErrorWorkerReconciliationError,
    undefined,
    {},
    {},
    HandleErrorWorkerReconciliationPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/workers/{personId}/handleError',
    method: 'post',
    ...variables,
    signal,
  });

export const useHandleErrorWorkerReconciliation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      HandleErrorWorkerReconciliationError,
      HandleErrorWorkerReconciliationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    HandleErrorWorkerReconciliationError,
    HandleErrorWorkerReconciliationVariables
  >({
    mutationFn: (variables: HandleErrorWorkerReconciliationVariables) =>
      fetchHandleErrorWorkerReconciliation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ReopenWorkerReconciliationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * A unique identifier for a person
   *
   * @format uuid
   */
  personId: string;
};

export type ReopenWorkerReconciliationError = Fetcher.ErrorWrapper<undefined>;

export type ReopenWorkerReconciliationVariables = {
  pathParams: ReopenWorkerReconciliationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReopenWorkerReconciliation = (
  variables: ReopenWorkerReconciliationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ReopenWorkerReconciliationError,
    undefined,
    {},
    {},
    ReopenWorkerReconciliationPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/workers/{personId}/reopen',
    method: 'post',
    ...variables,
    signal,
  });

export const useReopenWorkerReconciliation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReopenWorkerReconciliationError,
      ReopenWorkerReconciliationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ReopenWorkerReconciliationError,
    ReopenWorkerReconciliationVariables
  >({
    mutationFn: (variables: ReopenWorkerReconciliationVariables) =>
      fetchReopenWorkerReconciliation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ReconcileWorkerReconciliationEntryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The period of the data
   */
  period: Schemas.DateYYYYMM | Schemas.DateYYYY;
  /**
   * A unique identifier for a person
   *
   * @format uuid
   */
  personId: string;
};

export type ReconcileWorkerReconciliationEntryError =
  Fetcher.ErrorWrapper<undefined>;

export type ReconcileWorkerReconciliationEntryVariables = {
  pathParams: ReconcileWorkerReconciliationEntryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReconcileWorkerReconciliationEntry = (
  variables: ReconcileWorkerReconciliationEntryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ReconcileWorkerReconciliationEntryError,
    undefined,
    {},
    {},
    ReconcileWorkerReconciliationEntryPathParams
  >({
    url: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/workers/{personId}/reconcile',
    method: 'post',
    ...variables,
    signal,
  });

export const useReconcileWorkerReconciliationEntry = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReconcileWorkerReconciliationEntryError,
      ReconcileWorkerReconciliationEntryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ReconcileWorkerReconciliationEntryError,
    ReconcileWorkerReconciliationEntryVariables
  >({
    mutationFn: (variables: ReconcileWorkerReconciliationEntryVariables) =>
      fetchReconcileWorkerReconciliationEntry({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetSpreadsheetsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetSpreadsheetsQueryParams = {
  query?: string;
  size?: string;
  page?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  elementFilters?: string;
};

export type GetSpreadsheetsError = Fetcher.ErrorWrapper<undefined>;

export type GetSpreadsheetsResponse = Schemas.SpreadsheetFileEntry[];

export type GetSpreadsheetsVariables = {
  pathParams: GetSpreadsheetsPathParams;
  queryParams?: GetSpreadsheetsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetSpreadsheets = (
  variables: GetSpreadsheetsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetSpreadsheetsResponse,
    GetSpreadsheetsError,
    undefined,
    {},
    GetSpreadsheetsQueryParams,
    GetSpreadsheetsPathParams
  >({
    url: '/api/organizations/{organizationId}/spreadsheets',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetSpreadsheets = <TData = GetSpreadsheetsResponse,>(
  variables: GetSpreadsheetsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetSpreadsheetsResponse,
      GetSpreadsheetsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    GetSpreadsheetsResponse,
    GetSpreadsheetsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/spreadsheets',
      operationId: 'getSpreadsheets',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSpreadsheets({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetLegalEntitiesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type GetLegalEntitiesQueryParams = {
  query?: string;
  size?: string;
  page?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  rangeFilters?: string;
  elementFilters?: string;
};

export type GetLegalEntitiesError = Fetcher.ErrorWrapper<undefined>;

export type GetLegalEntitiesVariables = {
  pathParams: GetLegalEntitiesPathParams;
  queryParams?: GetLegalEntitiesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetLegalEntities = (
  variables: GetLegalEntitiesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.LegalEntityList,
    GetLegalEntitiesError,
    undefined,
    {},
    GetLegalEntitiesQueryParams,
    GetLegalEntitiesPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/legal-entities',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetLegalEntities = <TData = Schemas.LegalEntityList,>(
  variables: GetLegalEntitiesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.LegalEntityList,
      GetLegalEntitiesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.LegalEntityList,
    GetLegalEntitiesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/legal-entities',
      operationId: 'getLegalEntities',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLegalEntities({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateLegalEntityPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type CreateLegalEntityError = Fetcher.ErrorWrapper<undefined>;

export type CreateLegalEntityVariables = {
  body?: Schemas.LegalEntityPayload;
  pathParams: CreateLegalEntityPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCreateLegalEntity = (
  variables: CreateLegalEntityVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.LegalEntityEntry,
    CreateLegalEntityError,
    Schemas.LegalEntityPayload,
    {},
    {},
    CreateLegalEntityPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/legal-entities',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateLegalEntity = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.LegalEntityEntry,
      CreateLegalEntityError,
      CreateLegalEntityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.LegalEntityEntry,
    CreateLegalEntityError,
    CreateLegalEntityVariables
  >({
    mutationFn: (variables: CreateLegalEntityVariables) =>
      fetchCreateLegalEntity({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetLegalEntityPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * Legal Entity ID
   *
   * @format uuid
   */
  legalEntityId: string;
};

export type GetLegalEntityQueryParams = {
  /**
   * @format date
   */
  effectiveDate?: string;
  sequenceId?: string;
};

export type GetLegalEntityError = Fetcher.ErrorWrapper<undefined>;

export type GetLegalEntityVariables = {
  pathParams: GetLegalEntityPathParams;
  queryParams?: GetLegalEntityQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetLegalEntity = (
  variables: GetLegalEntityVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.LegalEntityEntry,
    GetLegalEntityError,
    undefined,
    {},
    GetLegalEntityQueryParams,
    GetLegalEntityPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/legal-entities/{legalEntityId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetLegalEntity = <TData = Schemas.LegalEntityEntry,>(
  variables: GetLegalEntityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.LegalEntityEntry,
      GetLegalEntityError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.LegalEntityEntry,
    GetLegalEntityError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/legal-entities/{legalEntityId}',
      operationId: 'getLegalEntity',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetLegalEntity({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutLegalEntityPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * Legal Entity ID
   *
   * @format uuid
   */
  legalEntityId: string;
};

export type PutLegalEntityError = Fetcher.ErrorWrapper<undefined>;

export type PutLegalEntityVariables = {
  body?: Schemas.LegalEntityEntry;
  pathParams: PutLegalEntityPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutLegalEntity = (
  variables: PutLegalEntityVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.LegalEntityEntry,
    PutLegalEntityError,
    Schemas.LegalEntityEntry,
    {},
    {},
    PutLegalEntityPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/legal-entities/{legalEntityId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutLegalEntity = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.LegalEntityEntry,
      PutLegalEntityError,
      PutLegalEntityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.LegalEntityEntry,
    PutLegalEntityError,
    PutLegalEntityVariables
  >({
    mutationFn: (variables: PutLegalEntityVariables) =>
      fetchPutLegalEntity({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteLegalEntityPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * Legal Entity ID
   *
   * @format uuid
   */
  legalEntityId: string;
};

export type DeleteLegalEntityError = Fetcher.ErrorWrapper<undefined>;

export type DeleteLegalEntityVariables = {
  pathParams: DeleteLegalEntityPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteLegalEntity = (
  variables: DeleteLegalEntityVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteLegalEntityError,
    undefined,
    {},
    {},
    DeleteLegalEntityPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/legal-entities/{legalEntityId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteLegalEntity = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteLegalEntityError,
      DeleteLegalEntityVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteLegalEntityError,
    DeleteLegalEntityVariables
  >({
    mutationFn: (variables: DeleteLegalEntityVariables) =>
      fetchDeleteLegalEntity({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryPayrollCalculationOutputsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type QueryPayrollCalculationOutputsError =
  Fetcher.ErrorWrapper<undefined>;

export type QueryPayrollCalculationOutputsResponse =
  Schemas.PayrollOutputsOutputs & Schemas.PayrollCalculationOutputsByType;

export type QueryPayrollCalculationOutputsRequestBody = Schemas.PayrollInput & {
  outputsType?: Schemas.PayrollCalculationOutputsTypeName;
};

export type QueryPayrollCalculationOutputsVariables = {
  body?: QueryPayrollCalculationOutputsRequestBody;
  pathParams: QueryPayrollCalculationOutputsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchQueryPayrollCalculationOutputs = (
  variables: QueryPayrollCalculationOutputsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    QueryPayrollCalculationOutputsResponse,
    QueryPayrollCalculationOutputsError,
    QueryPayrollCalculationOutputsRequestBody,
    {},
    {},
    QueryPayrollCalculationOutputsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/calculation-outputs',
    method: 'post',
    ...variables,
    signal,
  });

export const useQueryPayrollCalculationOutputs = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      QueryPayrollCalculationOutputsResponse,
      QueryPayrollCalculationOutputsError,
      QueryPayrollCalculationOutputsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    QueryPayrollCalculationOutputsResponse,
    QueryPayrollCalculationOutputsError,
    QueryPayrollCalculationOutputsVariables
  >({
    mutationFn: (variables: QueryPayrollCalculationOutputsVariables) =>
      fetchQueryPayrollCalculationOutputs({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchAdmissionDraftsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type SearchAdmissionDraftsError = Fetcher.ErrorWrapper<undefined>;

export type SearchAdmissionDraftsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAdmissionDraftsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAdmissionDrafts = (
  variables: SearchAdmissionDraftsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AdmissionDraftList,
    SearchAdmissionDraftsError,
    Schemas.SearchInput,
    {},
    {},
    SearchAdmissionDraftsPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAdmissionDrafts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AdmissionDraftList,
      SearchAdmissionDraftsError,
      SearchAdmissionDraftsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AdmissionDraftList,
    SearchAdmissionDraftsError,
    SearchAdmissionDraftsVariables
  >({
    mutationFn: (variables: SearchAdmissionDraftsVariables) =>
      fetchSearchAdmissionDrafts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type AdmissionDraftPostPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type AdmissionDraftPostHeaders = {
  /**
   * The idempotency key for this request
   */
  ['x-idempotency-key']?: string;
};

export type AdmissionDraftPostError = Fetcher.ErrorWrapper<undefined>;

export type AdmissionDraftPostVariables = {
  body?: Schemas.AdmissionDraftInput;
  headers?: AdmissionDraftPostHeaders;
  pathParams: AdmissionDraftPostPathParams;
} & ApiContext['fetcherOptions'];

export const fetchAdmissionDraftPost = (
  variables: AdmissionDraftPostVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AdmissionDraftEntry,
    AdmissionDraftPostError,
    Schemas.AdmissionDraftInput,
    AdmissionDraftPostHeaders,
    {},
    AdmissionDraftPostPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft',
    method: 'post',
    ...variables,
    signal,
  });

export const useAdmissionDraftPost = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AdmissionDraftEntry,
      AdmissionDraftPostError,
      AdmissionDraftPostVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AdmissionDraftEntry,
    AdmissionDraftPostError,
    AdmissionDraftPostVariables
  >({
    mutationFn: (variables: AdmissionDraftPostVariables) =>
      fetchAdmissionDraftPost({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAdmissionDraftPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
};

export type GetAdmissionDraftError = Fetcher.ErrorWrapper<undefined>;

export type GetAdmissionDraftVariables = {
  pathParams: GetAdmissionDraftPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAdmissionDraft = (
  variables: GetAdmissionDraftVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AdmissionDraftEntry,
    GetAdmissionDraftError,
    undefined,
    {},
    {},
    GetAdmissionDraftPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAdmissionDraft = <TData = Schemas.AdmissionDraftEntry,>(
  variables: GetAdmissionDraftVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AdmissionDraftEntry,
      GetAdmissionDraftError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AdmissionDraftEntry,
    GetAdmissionDraftError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}',
      operationId: 'getAdmissionDraft',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAdmissionDraft({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutAdmissionDraftPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
};

export type PutAdmissionDraftHeaders = {
  /**
   * The idempotency key for this request
   */
  ['x-idempotency-key']?: string;
};

export type PutAdmissionDraftError = Fetcher.ErrorWrapper<undefined>;

export type PutAdmissionDraftVariables = {
  body?: Schemas.AdmissionDraftUpdateInput;
  headers?: PutAdmissionDraftHeaders;
  pathParams: PutAdmissionDraftPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutAdmissionDraft = (
  variables: PutAdmissionDraftVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AdmissionDraftEntry,
    PutAdmissionDraftError,
    Schemas.AdmissionDraftUpdateInput,
    PutAdmissionDraftHeaders,
    {},
    PutAdmissionDraftPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutAdmissionDraft = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AdmissionDraftEntry,
      PutAdmissionDraftError,
      PutAdmissionDraftVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AdmissionDraftEntry,
    PutAdmissionDraftError,
    PutAdmissionDraftVariables
  >({
    mutationFn: (variables: PutAdmissionDraftVariables) =>
      fetchPutAdmissionDraft({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SubmitAdmissionDraftReviewPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
};

export type SubmitAdmissionDraftReviewError = Fetcher.ErrorWrapper<undefined>;

export type SubmitAdmissionDraftReviewVariables = {
  body: Schemas.AdmissionDraftReviewInput;
  pathParams: SubmitAdmissionDraftReviewPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSubmitAdmissionDraftReview = (
  variables: SubmitAdmissionDraftReviewVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    SubmitAdmissionDraftReviewError,
    Schemas.AdmissionDraftReviewInput,
    {},
    {},
    SubmitAdmissionDraftReviewPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/submit',
    method: 'post',
    ...variables,
    signal,
  });

export const useSubmitAdmissionDraftReview = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SubmitAdmissionDraftReviewError,
      SubmitAdmissionDraftReviewVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SubmitAdmissionDraftReviewError,
    SubmitAdmissionDraftReviewVariables
  >({
    mutationFn: (variables: SubmitAdmissionDraftReviewVariables) =>
      fetchSubmitAdmissionDraftReview({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApproveAdmissionDraftReviewPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
};

export type ApproveAdmissionDraftReviewError = Fetcher.ErrorWrapper<undefined>;

export type ApproveAdmissionDraftReviewVariables = {
  body: Schemas.AdmissionDraftReviewInput;
  pathParams: ApproveAdmissionDraftReviewPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApproveAdmissionDraftReview = (
  variables: ApproveAdmissionDraftReviewVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ApproveAdmissionDraftReviewError,
    Schemas.AdmissionDraftReviewInput,
    {},
    {},
    ApproveAdmissionDraftReviewPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/approve',
    method: 'post',
    ...variables,
    signal,
  });

export const useApproveAdmissionDraftReview = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ApproveAdmissionDraftReviewError,
      ApproveAdmissionDraftReviewVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ApproveAdmissionDraftReviewError,
    ApproveAdmissionDraftReviewVariables
  >({
    mutationFn: (variables: ApproveAdmissionDraftReviewVariables) =>
      fetchApproveAdmissionDraftReview({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ArchiveAdmissionDraftPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
};

export type ArchiveAdmissionDraftError = Fetcher.ErrorWrapper<undefined>;

export type ArchiveAdmissionDraftVariables = {
  pathParams: ArchiveAdmissionDraftPathParams;
} & ApiContext['fetcherOptions'];

export const fetchArchiveAdmissionDraft = (
  variables: ArchiveAdmissionDraftVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    ArchiveAdmissionDraftError,
    undefined,
    {},
    {},
    ArchiveAdmissionDraftPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/archive',
    method: 'post',
    ...variables,
    signal,
  });

export const useArchiveAdmissionDraft = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ArchiveAdmissionDraftError,
      ArchiveAdmissionDraftVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ArchiveAdmissionDraftError,
    ArchiveAdmissionDraftVariables
  >({
    mutationFn: (variables: ArchiveAdmissionDraftVariables) =>
      fetchArchiveAdmissionDraft({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAdmissionDraftByUserPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * @format uuid
   */
  userId: string;
};

export type GetAdmissionDraftByUserError = Fetcher.ErrorWrapper<undefined>;

export type GetAdmissionDraftByUserVariables = {
  pathParams: GetAdmissionDraftByUserPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAdmissionDraftByUser = (
  variables: GetAdmissionDraftByUserVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AdmissionDraftEntry,
    GetAdmissionDraftByUserError,
    undefined,
    {},
    {},
    GetAdmissionDraftByUserPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/users/{userId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAdmissionDraftByUser = <
  TData = Schemas.AdmissionDraftEntry,
>(
  variables: GetAdmissionDraftByUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AdmissionDraftEntry,
      GetAdmissionDraftByUserError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AdmissionDraftEntry,
    GetAdmissionDraftByUserError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/onboarding/admission/draft/users/{userId}',
      operationId: 'getAdmissionDraftByUser',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAdmissionDraftByUser({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostAdmissionInvitePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PostAdmissionInviteError = Fetcher.ErrorWrapper<undefined>;

export type PostAdmissionInviteVariables = {
  body: Schemas.AdmissionInviteInput;
  pathParams: PostAdmissionInvitePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostAdmissionInvite = (
  variables: PostAdmissionInviteVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PostAdmissionInviteError,
    Schemas.AdmissionInviteInput,
    {},
    {},
    PostAdmissionInvitePathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/invite',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostAdmissionInvite = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostAdmissionInviteError,
      PostAdmissionInviteVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PostAdmissionInviteError,
    PostAdmissionInviteVariables
  >({
    mutationFn: (variables: PostAdmissionInviteVariables) =>
      fetchPostAdmissionInvite({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostAdmissionDraftAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
};

export type PostAdmissionDraftAttachmentError = Fetcher.ErrorWrapper<undefined>;

export type PostAdmissionDraftAttachmentVariables = {
  body: Schemas.AdmissionDraftAttachmentInput;
  pathParams: PostAdmissionDraftAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostAdmissionDraftAttachment = (
  variables: PostAdmissionDraftAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AdmissionDraftAttachmentEntry,
    PostAdmissionDraftAttachmentError,
    Schemas.AdmissionDraftAttachmentInput,
    {},
    {},
    PostAdmissionDraftAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/attachments',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostAdmissionDraftAttachment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AdmissionDraftAttachmentEntry,
      PostAdmissionDraftAttachmentError,
      PostAdmissionDraftAttachmentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AdmissionDraftAttachmentEntry,
    PostAdmissionDraftAttachmentError,
    PostAdmissionDraftAttachmentVariables
  >({
    mutationFn: (variables: PostAdmissionDraftAttachmentVariables) =>
      fetchPostAdmissionDraftAttachment({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllAdmissionDraftAttachmentsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
};

export type GetAllAdmissionDraftAttachmentsError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAllAdmissionDraftAttachmentsVariables = {
  pathParams: GetAllAdmissionDraftAttachmentsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAllAdmissionDraftAttachments = (
  variables: GetAllAdmissionDraftAttachmentsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AdmissionDraftAttachmentList,
    GetAllAdmissionDraftAttachmentsError,
    undefined,
    {},
    {},
    GetAllAdmissionDraftAttachmentsPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/attachments',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllAdmissionDraftAttachments = <
  TData = Schemas.AdmissionDraftAttachmentList,
>(
  variables: GetAllAdmissionDraftAttachmentsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AdmissionDraftAttachmentList,
      GetAllAdmissionDraftAttachmentsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AdmissionDraftAttachmentList,
    GetAllAdmissionDraftAttachmentsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/attachments',
      operationId: 'getAllAdmissionDraftAttachments',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllAdmissionDraftAttachments(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetAdmissionDraftAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
  /**
   * The id of the payment request attachment to retrieve
   *
   * @format uuid
   */
  attachmentId: string;
};

export type GetAdmissionDraftAttachmentError = Fetcher.ErrorWrapper<undefined>;

export type GetAdmissionDraftAttachmentVariables = {
  pathParams: GetAdmissionDraftAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAdmissionDraftAttachment = (
  variables: GetAdmissionDraftAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AdmissionDraftAttachmentEntry,
    GetAdmissionDraftAttachmentError,
    undefined,
    {},
    {},
    GetAdmissionDraftAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/attachments/{attachmentId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAdmissionDraftAttachment = <
  TData = Schemas.AdmissionDraftAttachmentEntry,
>(
  variables: GetAdmissionDraftAttachmentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AdmissionDraftAttachmentEntry,
      GetAdmissionDraftAttachmentError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AdmissionDraftAttachmentEntry,
    GetAdmissionDraftAttachmentError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/attachments/{attachmentId}',
      operationId: 'getAdmissionDraftAttachment',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAdmissionDraftAttachment(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type DeleteAdmissionDraftAttachmentPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
  /**
   * The id of the payment request attachment to retrieve
   *
   * @format uuid
   */
  attachmentId: string;
};

export type DeleteAdmissionDraftAttachmentError =
  Fetcher.ErrorWrapper<undefined>;

export type DeleteAdmissionDraftAttachmentVariables = {
  pathParams: DeleteAdmissionDraftAttachmentPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteAdmissionDraftAttachment = (
  variables: DeleteAdmissionDraftAttachmentVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteAdmissionDraftAttachmentError,
    undefined,
    {},
    {},
    DeleteAdmissionDraftAttachmentPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/attachments/{attachmentId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteAdmissionDraftAttachment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteAdmissionDraftAttachmentError,
      DeleteAdmissionDraftAttachmentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteAdmissionDraftAttachmentError,
    DeleteAdmissionDraftAttachmentVariables
  >({
    mutationFn: (variables: DeleteAdmissionDraftAttachmentVariables) =>
      fetchDeleteAdmissionDraftAttachment({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostAdmissionNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type PostAdmissionNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type PostAdmissionNotificationsConfigurationVariables = {
  body: Schemas.NotificationConfigurationInput;
  pathParams: PostAdmissionNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostAdmissionNotificationsConfiguration = (
  variables: PostAdmissionNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfiguration,
    PostAdmissionNotificationsConfigurationError,
    Schemas.NotificationConfigurationInput,
    {},
    {},
    PostAdmissionNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/notification-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostAdmissionNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationConfiguration,
      PostAdmissionNotificationsConfigurationError,
      PostAdmissionNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationConfiguration,
    PostAdmissionNotificationsConfigurationError,
    PostAdmissionNotificationsConfigurationVariables
  >({
    mutationFn: (variables: PostAdmissionNotificationsConfigurationVariables) =>
      fetchPostAdmissionNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ListAdmissionNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type ListAdmissionNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type ListAdmissionNotificationsConfigurationVariables = {
  pathParams: ListAdmissionNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListAdmissionNotificationsConfiguration = (
  variables: ListAdmissionNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfigurationsList,
    ListAdmissionNotificationsConfigurationError,
    undefined,
    {},
    {},
    ListAdmissionNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/notification-configurations',
    method: 'get',
    ...variables,
    signal,
  });

export const useListAdmissionNotificationsConfiguration = <
  TData = Schemas.NotificationConfigurationsList,
>(
  variables: ListAdmissionNotificationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.NotificationConfigurationsList,
      ListAdmissionNotificationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.NotificationConfigurationsList,
    ListAdmissionNotificationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/onboarding/notification-configurations',
      operationId: 'listAdmissionNotificationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListAdmissionNotificationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type TestAdmissionNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type TestAdmissionNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type TestAdmissionNotificationsConfigurationRequestBody = {
  [key: string]: any;
};

export type TestAdmissionNotificationsConfigurationVariables = {
  body?: TestAdmissionNotificationsConfigurationRequestBody;
  pathParams: TestAdmissionNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTestAdmissionNotificationsConfiguration = (
  variables: TestAdmissionNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationTest,
    TestAdmissionNotificationsConfigurationError,
    TestAdmissionNotificationsConfigurationRequestBody,
    {},
    {},
    TestAdmissionNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/notification-configurations/{resourceType}/testConfig',
    method: 'post',
    ...variables,
    signal,
  });

export const useTestAdmissionNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationTest,
      TestAdmissionNotificationsConfigurationError,
      TestAdmissionNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationTest,
    TestAdmissionNotificationsConfigurationError,
    TestAdmissionNotificationsConfigurationVariables
  >({
    mutationFn: (variables: TestAdmissionNotificationsConfigurationVariables) =>
      fetchTestAdmissionNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetAdmissionNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the
   */
  ruleId: void;
};

export type GetAdmissionNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAdmissionNotificationsConfigurationVariables = {
  pathParams: GetAdmissionNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAdmissionNotificationsConfiguration = (
  variables: GetAdmissionNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    GetAdmissionNotificationsConfigurationError,
    undefined,
    {},
    {},
    GetAdmissionNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAdmissionNotificationsConfiguration = <TData = undefined,>(
  variables: GetAdmissionNotificationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      GetAdmissionNotificationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    undefined,
    GetAdmissionNotificationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/onboarding/notification-configurations/{resourceType}/rules/{ruleId}',
      operationId: 'getAdmissionNotificationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAdmissionNotificationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type UpdateAdmissionNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the
   */
  ruleId: void;
};

export type UpdateAdmissionNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type UpdateAdmissionNotificationsConfigurationVariables = {
  body: Schemas.NotificationConfiguration;
  pathParams: UpdateAdmissionNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateAdmissionNotificationsConfiguration = (
  variables: UpdateAdmissionNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfiguration,
    UpdateAdmissionNotificationsConfigurationError,
    Schemas.NotificationConfiguration,
    {},
    {},
    UpdateAdmissionNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateAdmissionNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationConfiguration,
      UpdateAdmissionNotificationsConfigurationError,
      UpdateAdmissionNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationConfiguration,
    UpdateAdmissionNotificationsConfigurationError,
    UpdateAdmissionNotificationsConfigurationVariables
  >({
    mutationFn: (
      variables: UpdateAdmissionNotificationsConfigurationVariables,
    ) =>
      fetchUpdateAdmissionNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type UploadAdmissionProfilePicturePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the admission draft to retrieve
   *
   * @format uuid
   */
  draftId: string;
};

export type UploadAdmissionProfilePictureError =
  Fetcher.ErrorWrapper<undefined>;

export type UploadAdmissionProfilePictureVariables = {
  body: Schemas.ContractProfilePictureInput;
  pathParams: UploadAdmissionProfilePicturePathParams;
} & ApiContext['fetcherOptions'];

export const fetchUploadAdmissionProfilePicture = (
  variables: UploadAdmissionProfilePictureVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractProfilePictureResponse,
    UploadAdmissionProfilePictureError,
    Schemas.ContractProfilePictureInput,
    {},
    {},
    UploadAdmissionProfilePicturePathParams
  >({
    url: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/profile-pictures',
    method: 'post',
    ...variables,
    signal,
  });

export const useUploadAdmissionProfilePicture = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ContractProfilePictureResponse,
      UploadAdmissionProfilePictureError,
      UploadAdmissionProfilePictureVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.ContractProfilePictureResponse,
    UploadAdmissionProfilePictureError,
    UploadAdmissionProfilePictureVariables
  >({
    mutationFn: (variables: UploadAdmissionProfilePictureVariables) =>
      fetchUploadAdmissionProfilePicture({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetVacationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetVacationsConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type GetVacationsConfigurationVariables = {
  pathParams: GetVacationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetVacationsConfiguration = (
  variables: GetVacationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsConfigurationEntry,
    GetVacationsConfigurationError,
    undefined,
    {},
    {},
    GetVacationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/configuration',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetVacationsConfiguration = <
  TData = Schemas.VacationsConfigurationEntry,
>(
  variables: GetVacationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VacationsConfigurationEntry,
      GetVacationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VacationsConfigurationEntry,
    GetVacationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/configuration',
      operationId: 'getVacationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetVacationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PutVacationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PutVacationsConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type PutVacationsConfigurationVariables = {
  body: Schemas.VacationsConfigurationInput;
  pathParams: PutVacationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutVacationsConfiguration = (
  variables: PutVacationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsConfigurationEntry,
    PutVacationsConfigurationError,
    Schemas.VacationsConfigurationInput,
    {},
    {},
    PutVacationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/configuration',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutVacationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsConfigurationEntry,
      PutVacationsConfigurationError,
      PutVacationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsConfigurationEntry,
    PutVacationsConfigurationError,
    PutVacationsConfigurationVariables
  >({
    mutationFn: (variables: PutVacationsConfigurationVariables) =>
      fetchPutVacationsConfiguration({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostVacationsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PostVacationsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type PostVacationsNotificationsConfigurationVariables = {
  body: Schemas.NotificationConfigurationInput;
  pathParams: PostVacationsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostVacationsNotificationsConfiguration = (
  variables: PostVacationsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfiguration,
    PostVacationsNotificationsConfigurationError,
    Schemas.NotificationConfigurationInput,
    {},
    {},
    PostVacationsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/notification-configurations',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostVacationsNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationConfiguration,
      PostVacationsNotificationsConfigurationError,
      PostVacationsNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationConfiguration,
    PostVacationsNotificationsConfigurationError,
    PostVacationsNotificationsConfigurationVariables
  >({
    mutationFn: (variables: PostVacationsNotificationsConfigurationVariables) =>
      fetchPostVacationsNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ListVacationsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type ListVacationsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type ListVacationsNotificationsConfigurationVariables = {
  pathParams: ListVacationsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListVacationsNotificationsConfiguration = (
  variables: ListVacationsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfigurationsList,
    ListVacationsNotificationsConfigurationError,
    undefined,
    {},
    {},
    ListVacationsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/notification-configurations',
    method: 'get',
    ...variables,
    signal,
  });

export const useListVacationsNotificationsConfiguration = <
  TData = Schemas.NotificationConfigurationsList,
>(
  variables: ListVacationsNotificationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.NotificationConfigurationsList,
      ListVacationsNotificationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.NotificationConfigurationsList,
    ListVacationsNotificationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/notification-configurations',
      operationId: 'listVacationsNotificationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListVacationsNotificationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type TestVacationsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
};

export type TestVacationsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type TestVacationsNotificationsConfigurationRequestBody = {
  [key: string]: any;
};

export type TestVacationsNotificationsConfigurationVariables = {
  body?: TestVacationsNotificationsConfigurationRequestBody;
  pathParams: TestVacationsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTestVacationsNotificationsConfiguration = (
  variables: TestVacationsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationTest,
    TestVacationsNotificationsConfigurationError,
    TestVacationsNotificationsConfigurationRequestBody,
    {},
    {},
    TestVacationsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/notification-configurations/{resourceType}/testConfig',
    method: 'post',
    ...variables,
    signal,
  });

export const useTestVacationsNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationTest,
      TestVacationsNotificationsConfigurationError,
      TestVacationsNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationTest,
    TestVacationsNotificationsConfigurationError,
    TestVacationsNotificationsConfigurationVariables
  >({
    mutationFn: (variables: TestVacationsNotificationsConfigurationVariables) =>
      fetchTestVacationsNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetVacationsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the rule
   */
  ruleId: string;
};

export type GetVacationsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type GetVacationsNotificationsConfigurationVariables = {
  pathParams: GetVacationsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetVacationsNotificationsConfiguration = (
  variables: GetVacationsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    GetVacationsNotificationsConfigurationError,
    undefined,
    {},
    {},
    GetVacationsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetVacationsNotificationsConfiguration = <TData = undefined,>(
  variables: GetVacationsNotificationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      undefined,
      GetVacationsNotificationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    undefined,
    GetVacationsNotificationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/notification-configurations/{resourceType}/rules/{ruleId}',
      operationId: 'getVacationsNotificationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetVacationsNotificationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type UpdateNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the rule
   */
  ruleId: string;
};

export type UpdateNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type UpdateNotificationsConfigurationVariables = {
  body: Schemas.NotificationConfiguration;
  pathParams: UpdateNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUpdateNotificationsConfiguration = (
  variables: UpdateNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.NotificationConfiguration,
    UpdateNotificationsConfigurationError,
    Schemas.NotificationConfiguration,
    {},
    {},
    UpdateNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpdateNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.NotificationConfiguration,
      UpdateNotificationsConfigurationError,
      UpdateNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.NotificationConfiguration,
    UpdateNotificationsConfigurationError,
    UpdateNotificationsConfigurationVariables
  >({
    mutationFn: (variables: UpdateNotificationsConfigurationVariables) =>
      fetchUpdateNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DeleteVacationsNotificationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The type of the resource to retrieve
   */
  resourceType: string;
  /**
   * The ID of the rule
   */
  ruleId: string;
};

export type DeleteVacationsNotificationsConfigurationError =
  Fetcher.ErrorWrapper<undefined>;

export type DeleteVacationsNotificationsConfigurationVariables = {
  pathParams: DeleteVacationsNotificationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteVacationsNotificationsConfiguration = (
  variables: DeleteVacationsNotificationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteVacationsNotificationsConfigurationError,
    undefined,
    {},
    {},
    DeleteVacationsNotificationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/notification-configurations/{resourceType}/rules/{ruleId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteVacationsNotificationsConfiguration = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteVacationsNotificationsConfigurationError,
      DeleteVacationsNotificationsConfigurationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteVacationsNotificationsConfigurationError,
    DeleteVacationsNotificationsConfigurationVariables
  >({
    mutationFn: (
      variables: DeleteVacationsNotificationsConfigurationVariables,
    ) =>
      fetchDeleteVacationsNotificationsConfiguration({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ListVacationsConfigurationPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type ListVacationsConfigurationError = Fetcher.ErrorWrapper<undefined>;

export type ListVacationsConfigurationVariables = {
  pathParams: ListVacationsConfigurationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListVacationsConfiguration = (
  variables: ListVacationsConfigurationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsConfigurationList,
    ListVacationsConfigurationError,
    undefined,
    {},
    {},
    ListVacationsConfigurationPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/configuration/list',
    method: 'get',
    ...variables,
    signal,
  });

export const useListVacationsConfiguration = <
  TData = Schemas.VacationsConfigurationList,
>(
  variables: ListVacationsConfigurationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VacationsConfigurationList,
      ListVacationsConfigurationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VacationsConfigurationList,
    ListVacationsConfigurationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/configuration/list',
      operationId: 'listVacationsConfiguration',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListVacationsConfiguration(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostGroupVacationsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PostGroupVacationsError = Fetcher.ErrorWrapper<undefined>;

export type PostGroupVacationsVariables = {
  body: Schemas.GroupVacationsInput;
  pathParams: PostGroupVacationsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostGroupVacations = (
  variables: PostGroupVacationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GroupVacationsEntry,
    PostGroupVacationsError,
    Schemas.GroupVacationsInput,
    {},
    {},
    PostGroupVacationsPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/group',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostGroupVacations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.GroupVacationsEntry,
      PostGroupVacationsError,
      PostGroupVacationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.GroupVacationsEntry,
    PostGroupVacationsError,
    PostGroupVacationsVariables
  >({
    mutationFn: (variables: PostGroupVacationsVariables) =>
      fetchPostGroupVacations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetGroupVacationsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetGroupVacationsError = Fetcher.ErrorWrapper<undefined>;

export type GetGroupVacationsVariables = {
  pathParams: GetGroupVacationsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetGroupVacations = (
  variables: GetGroupVacationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.GroupVacationsList,
    GetGroupVacationsError,
    undefined,
    {},
    {},
    GetGroupVacationsPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/group',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetGroupVacations = <TData = Schemas.GroupVacationsList,>(
  variables: GetGroupVacationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.GroupVacationsList,
      GetGroupVacationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.GroupVacationsList,
    GetGroupVacationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/group',
      operationId: 'getGroupVacations',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetGroupVacations({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetGroupVacationSchedulesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The ID of the group vacation
   *
   * @format uuid
   */
  groupId: string;
};

export type GetGroupVacationSchedulesError = Fetcher.ErrorWrapper<undefined>;

export type GetGroupVacationSchedulesResponse =
  Schemas.VacationsScheduleEntry[];

export type GetGroupVacationSchedulesVariables = {
  pathParams: GetGroupVacationSchedulesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetGroupVacationSchedules = (
  variables: GetGroupVacationSchedulesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    GetGroupVacationSchedulesResponse,
    GetGroupVacationSchedulesError,
    undefined,
    {},
    {},
    GetGroupVacationSchedulesPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/group/{groupId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetGroupVacationSchedules = <
  TData = GetGroupVacationSchedulesResponse,
>(
  variables: GetGroupVacationSchedulesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetGroupVacationSchedulesResponse,
      GetGroupVacationSchedulesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    GetGroupVacationSchedulesResponse,
    GetGroupVacationSchedulesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/group/{groupId}',
      operationId: 'getGroupVacationSchedules',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetGroupVacationSchedules(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type SearchVacationsAccrualPeriodsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type SearchVacationsAccrualPeriodsError =
  Fetcher.ErrorWrapper<undefined>;

export type SearchVacationsAccrualPeriodsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchVacationsAccrualPeriodsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchVacationsAccrualPeriods = (
  variables: SearchVacationsAccrualPeriodsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsAccrualPeriodList,
    SearchVacationsAccrualPeriodsError,
    Schemas.SearchInput,
    {},
    {},
    SearchVacationsAccrualPeriodsPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/accrual-periods/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchVacationsAccrualPeriods = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsAccrualPeriodList,
      SearchVacationsAccrualPeriodsError,
      SearchVacationsAccrualPeriodsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsAccrualPeriodList,
    SearchVacationsAccrualPeriodsError,
    SearchVacationsAccrualPeriodsVariables
  >({
    mutationFn: (variables: SearchVacationsAccrualPeriodsVariables) =>
      fetchSearchVacationsAccrualPeriods({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchAllScheduledVacationsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type SearchAllScheduledVacationsError = Fetcher.ErrorWrapper<undefined>;

export type SearchAllScheduledVacationsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAllScheduledVacationsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAllScheduledVacations = (
  variables: SearchAllScheduledVacationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsScheduleList,
    SearchAllScheduledVacationsError,
    Schemas.SearchInput,
    {},
    {},
    SearchAllScheduledVacationsPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/schedules/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAllScheduledVacations = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsScheduleList,
      SearchAllScheduledVacationsError,
      SearchAllScheduledVacationsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsScheduleList,
    SearchAllScheduledVacationsError,
    SearchAllScheduledVacationsVariables
  >({
    mutationFn: (variables: SearchAllScheduledVacationsVariables) =>
      fetchSearchAllScheduledVacations({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchManagedByVacationsSchedulesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type SearchManagedByVacationsSchedulesQueryParams = {
  /**
   * Whether or not to return direct reports only
   *
   * @default true
   */
  directReports?: 'true' | 'false';
};

export type SearchManagedByVacationsSchedulesError =
  Fetcher.ErrorWrapper<undefined>;

export type SearchManagedByVacationsSchedulesVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchManagedByVacationsSchedulesPathParams;
  queryParams?: SearchManagedByVacationsSchedulesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchManagedByVacationsSchedules = (
  variables: SearchManagedByVacationsSchedulesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsScheduleList,
    SearchManagedByVacationsSchedulesError,
    Schemas.SearchInput,
    {},
    SearchManagedByVacationsSchedulesQueryParams,
    SearchManagedByVacationsSchedulesPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/managers/{contractId}/schedules/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchManagedByVacationsSchedules = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsScheduleList,
      SearchManagedByVacationsSchedulesError,
      SearchManagedByVacationsSchedulesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsScheduleList,
    SearchManagedByVacationsSchedulesError,
    SearchManagedByVacationsSchedulesVariables
  >({
    mutationFn: (variables: SearchManagedByVacationsSchedulesVariables) =>
      fetchSearchManagedByVacationsSchedules({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type SearchManagedByVacationsAccrualPeriodsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type SearchManagedByVacationsAccrualPeriodsQueryParams = {
  /**
   * Whether or not to return direct reports only
   *
   * @default true
   */
  directReports?: 'true' | 'false';
};

export type SearchManagedByVacationsAccrualPeriodsError =
  Fetcher.ErrorWrapper<undefined>;

export type SearchManagedByVacationsAccrualPeriodsVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchManagedByVacationsAccrualPeriodsPathParams;
  queryParams?: SearchManagedByVacationsAccrualPeriodsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchManagedByVacationsAccrualPeriods = (
  variables: SearchManagedByVacationsAccrualPeriodsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsAccrualPeriodList,
    SearchManagedByVacationsAccrualPeriodsError,
    Schemas.SearchInput,
    {},
    SearchManagedByVacationsAccrualPeriodsQueryParams,
    SearchManagedByVacationsAccrualPeriodsPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/managers/{contractId}/accrual-periods/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchManagedByVacationsAccrualPeriods = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsAccrualPeriodList,
      SearchManagedByVacationsAccrualPeriodsError,
      SearchManagedByVacationsAccrualPeriodsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsAccrualPeriodList,
    SearchManagedByVacationsAccrualPeriodsError,
    SearchManagedByVacationsAccrualPeriodsVariables
  >({
    mutationFn: (variables: SearchManagedByVacationsAccrualPeriodsVariables) =>
      fetchSearchManagedByVacationsAccrualPeriods({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetVacationsConfigurationByContractPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type GetVacationsConfigurationByContractError =
  Fetcher.ErrorWrapper<undefined>;

export type GetVacationsConfigurationByContractVariables = {
  pathParams: GetVacationsConfigurationByContractPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetVacationsConfigurationByContract = (
  variables: GetVacationsConfigurationByContractVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsConfigurationEntry,
    GetVacationsConfigurationByContractError,
    undefined,
    {},
    {},
    GetVacationsConfigurationByContractPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/configuration',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetVacationsConfigurationByContract = <
  TData = Schemas.VacationsConfigurationEntry,
>(
  variables: GetVacationsConfigurationByContractVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VacationsConfigurationEntry,
      GetVacationsConfigurationByContractError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VacationsConfigurationEntry,
    GetVacationsConfigurationByContractError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/configuration',
      operationId: 'getVacationsConfigurationByContract',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetVacationsConfigurationByContract(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetContractAccrualPeriodsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type GetContractAccrualPeriodsQueryParams = {
  /**
   * @format date
   */
  effectiveDate?: string;
};

export type GetContractAccrualPeriodsError = Fetcher.ErrorWrapper<undefined>;

export type GetContractAccrualPeriodsVariables = {
  pathParams: GetContractAccrualPeriodsPathParams;
  queryParams?: GetContractAccrualPeriodsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractAccrualPeriods = (
  variables: GetContractAccrualPeriodsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.ContractVacationsAccrualPeriodsEntry,
    GetContractAccrualPeriodsError,
    undefined,
    {},
    GetContractAccrualPeriodsQueryParams,
    GetContractAccrualPeriodsPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/accrual-periods',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractAccrualPeriods = <
  TData = Schemas.ContractVacationsAccrualPeriodsEntry,
>(
  variables: GetContractAccrualPeriodsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.ContractVacationsAccrualPeriodsEntry,
      GetContractAccrualPeriodsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.ContractVacationsAccrualPeriodsEntry,
    GetContractAccrualPeriodsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/accrual-periods',
      operationId: 'getContractAccrualPeriods',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractAccrualPeriods(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetContractAccrualPeriodsScheduledVacationsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The startDate of accrualPeriod
   *
   * @format ../data-types/date/YYYY-MM-DD.yaml
   */
  startDate: string;
};

export type GetContractAccrualPeriodsScheduledVacationsError =
  Fetcher.ErrorWrapper<undefined>;

export type GetContractAccrualPeriodsScheduledVacationsVariables = {
  pathParams: GetContractAccrualPeriodsScheduledVacationsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractAccrualPeriodsScheduledVacations = (
  variables: GetContractAccrualPeriodsScheduledVacationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsAccrualPeriodScheduledVacations,
    GetContractAccrualPeriodsScheduledVacationsError,
    undefined,
    {},
    {},
    GetContractAccrualPeriodsScheduledVacationsPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/accrual-periods/{startDate}/schedules',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractAccrualPeriodsScheduledVacations = <
  TData = Schemas.VacationsAccrualPeriodScheduledVacations,
>(
  variables: GetContractAccrualPeriodsScheduledVacationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VacationsAccrualPeriodScheduledVacations,
      GetContractAccrualPeriodsScheduledVacationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VacationsAccrualPeriodScheduledVacations,
    GetContractAccrualPeriodsScheduledVacationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/accrual-periods/{startDate}/schedules',
      operationId: 'getContractAccrualPeriodsScheduledVacations',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractAccrualPeriodsScheduledVacations(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostVacationsSchedulePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostVacationsScheduleError = Fetcher.ErrorWrapper<undefined>;

export type PostVacationsScheduleVariables = {
  body: Schemas.VacationsScheduleInput;
  pathParams: PostVacationsSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostVacationsSchedule = (
  variables: PostVacationsScheduleVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsScheduleEntry,
    PostVacationsScheduleError,
    Schemas.VacationsScheduleInput,
    {},
    {},
    PostVacationsSchedulePathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostVacationsSchedule = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsScheduleEntry,
      PostVacationsScheduleError,
      PostVacationsScheduleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsScheduleEntry,
    PostVacationsScheduleError,
    PostVacationsScheduleVariables
  >({
    mutationFn: (variables: PostVacationsScheduleVariables) =>
      fetchPostVacationsSchedule({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetContractVacationsSchedulePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type GetContractVacationsScheduleError = Fetcher.ErrorWrapper<undefined>;

export type GetContractVacationsScheduleVariables = {
  pathParams: GetContractVacationsSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetContractVacationsSchedule = (
  variables: GetContractVacationsScheduleVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsScheduleList,
    GetContractVacationsScheduleError,
    undefined,
    {},
    {},
    GetContractVacationsSchedulePathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetContractVacationsSchedule = <
  TData = Schemas.VacationsScheduleList,
>(
  variables: GetContractVacationsScheduleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VacationsScheduleList,
      GetContractVacationsScheduleError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VacationsScheduleList,
    GetContractVacationsScheduleError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules',
      operationId: 'getContractVacationsSchedule',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetContractVacationsSchedule(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PostValidateVacationsSchedulePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostValidateVacationsScheduleError =
  Fetcher.ErrorWrapper<undefined>;

export type PostValidateVacationsScheduleVariables = {
  body: Schemas.VacationsScheduleInput;
  pathParams: PostValidateVacationsSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostValidateVacationsSchedule = (
  variables: PostValidateVacationsScheduleVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PostValidateVacationsScheduleError,
    Schemas.VacationsScheduleInput,
    {},
    {},
    PostValidateVacationsSchedulePathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules/validate',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostValidateVacationsSchedule = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostValidateVacationsScheduleError,
      PostValidateVacationsScheduleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PostValidateVacationsScheduleError,
    PostValidateVacationsScheduleVariables
  >({
    mutationFn: (variables: PostValidateVacationsScheduleVariables) =>
      fetchPostValidateVacationsSchedule({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetVacationsSchedulePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The sequence of the schedule
   */
  sequence: string;
};

export type GetVacationsScheduleError = Fetcher.ErrorWrapper<undefined>;

export type GetVacationsScheduleVariables = {
  pathParams: GetVacationsSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetVacationsSchedule = (
  variables: GetVacationsScheduleVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsScheduleEntry,
    GetVacationsScheduleError,
    undefined,
    {},
    {},
    GetVacationsSchedulePathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules/{sequence}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetVacationsSchedule = <
  TData = Schemas.VacationsScheduleEntry,
>(
  variables: GetVacationsScheduleVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.VacationsScheduleEntry,
      GetVacationsScheduleError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.VacationsScheduleEntry,
    GetVacationsScheduleError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules/{sequence}',
      operationId: 'getVacationsSchedule',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetVacationsSchedule({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutVacationsSchedulePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The sequence of the schedule
   */
  sequence: string;
};

export type PutVacationsScheduleError = Fetcher.ErrorWrapper<undefined>;

export type PutVacationsScheduleVariables = {
  pathParams: PutVacationsSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutVacationsSchedule = (
  variables: PutVacationsScheduleVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsScheduleUpdate,
    PutVacationsScheduleError,
    undefined,
    {},
    {},
    PutVacationsSchedulePathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules/{sequence}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutVacationsSchedule = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsScheduleUpdate,
      PutVacationsScheduleError,
      PutVacationsScheduleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsScheduleUpdate,
    PutVacationsScheduleError,
    PutVacationsScheduleVariables
  >({
    mutationFn: (variables: PutVacationsScheduleVariables) =>
      fetchPutVacationsSchedule({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteVacationsSchedulePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The sequence of the schedule
   */
  sequence: string;
};

export type DeleteVacationsScheduleError = Fetcher.ErrorWrapper<undefined>;

export type DeleteVacationsScheduleVariables = {
  body: Schemas.VacationsScheduleCancelInput;
  pathParams: DeleteVacationsSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteVacationsSchedule = (
  variables: DeleteVacationsScheduleVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsScheduleEntry,
    DeleteVacationsScheduleError,
    Schemas.VacationsScheduleCancelInput,
    {},
    {},
    DeleteVacationsSchedulePathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules/{sequence}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteVacationsSchedule = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsScheduleEntry,
      DeleteVacationsScheduleError,
      DeleteVacationsScheduleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsScheduleEntry,
    DeleteVacationsScheduleError,
    DeleteVacationsScheduleVariables
  >({
    mutationFn: (variables: DeleteVacationsScheduleVariables) =>
      fetchDeleteVacationsSchedule({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostReviewVacationsSchedulePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The sequence of the schedule
   */
  sequence: string;
};

export type PostReviewVacationsScheduleError = Fetcher.ErrorWrapper<undefined>;

export type PostReviewVacationsScheduleVariables = {
  body: Schemas.VacationsScheduleReviewInput;
  pathParams: PostReviewVacationsSchedulePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostReviewVacationsSchedule = (
  variables: PostReviewVacationsScheduleVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsScheduleEntry,
    PostReviewVacationsScheduleError,
    Schemas.VacationsScheduleReviewInput,
    {},
    {},
    PostReviewVacationsSchedulePathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules/{sequence}/review',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostReviewVacationsSchedule = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsScheduleEntry,
      PostReviewVacationsScheduleError,
      PostReviewVacationsScheduleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsScheduleEntry,
    PostReviewVacationsScheduleError,
    PostReviewVacationsScheduleVariables
  >({
    mutationFn: (variables: PostReviewVacationsScheduleVariables) =>
      fetchPostReviewVacationsSchedule({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostVacationsScheduleCreatePayrollPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
  /**
   * The sequence of the schedule
   */
  sequence: string;
};

export type PostVacationsScheduleCreatePayrollError =
  Fetcher.ErrorWrapper<undefined>;

export type PostVacationsScheduleCreatePayrollVariables = {
  pathParams: PostVacationsScheduleCreatePayrollPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostVacationsScheduleCreatePayroll = (
  variables: PostVacationsScheduleCreatePayrollVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.VacationsScheduleEntry,
    PostVacationsScheduleCreatePayrollError,
    undefined,
    {},
    {},
    PostVacationsScheduleCreatePayrollPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules/{sequence}/create-payroll',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostVacationsScheduleCreatePayroll = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.VacationsScheduleEntry,
      PostVacationsScheduleCreatePayrollError,
      PostVacationsScheduleCreatePayrollVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.VacationsScheduleEntry,
    PostVacationsScheduleCreatePayrollError,
    PostVacationsScheduleCreatePayrollVariables
  >({
    mutationFn: (variables: PostVacationsScheduleCreatePayrollVariables) =>
      fetchPostVacationsScheduleCreatePayroll({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PostVacationsIngestContractHistoryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostVacationsIngestContractHistoryError =
  Fetcher.ErrorWrapper<undefined>;

export type PostVacationsIngestContractHistoryVariables = {
  body: Schemas.VacationsIngestContractHistoryInput;
  pathParams: PostVacationsIngestContractHistoryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostVacationsIngestContractHistory = (
  variables: PostVacationsIngestContractHistoryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PostVacationsIngestContractHistoryError,
    Schemas.VacationsIngestContractHistoryInput,
    {},
    {},
    PostVacationsIngestContractHistoryPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/history/ingest-contract-history',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostVacationsIngestContractHistory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostVacationsIngestContractHistoryError,
      PostVacationsIngestContractHistoryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PostVacationsIngestContractHistoryError,
    PostVacationsIngestContractHistoryVariables
  >({
    mutationFn: (variables: PostVacationsIngestContractHistoryVariables) =>
      fetchPostVacationsIngestContractHistory({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PostVacationsIngestHistoryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type PostVacationsIngestHistoryError = Fetcher.ErrorWrapper<undefined>;

export type PostVacationsIngestHistoryVariables = {
  body: Schemas.VacationsIngestHistoryInput;
  pathParams: PostVacationsIngestHistoryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostVacationsIngestHistory = (
  variables: PostVacationsIngestHistoryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PostVacationsIngestHistoryError,
    Schemas.VacationsIngestHistoryInput,
    {},
    {},
    PostVacationsIngestHistoryPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/history/ingest-historical-events',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostVacationsIngestHistory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostVacationsIngestHistoryError,
      PostVacationsIngestHistoryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PostVacationsIngestHistoryError,
    PostVacationsIngestHistoryVariables
  >({
    mutationFn: (variables: PostVacationsIngestHistoryVariables) =>
      fetchPostVacationsIngestHistory({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteVacationsHistoryPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the contract resource
   *
   * @format uuid
   */
  contractId: string;
};

export type DeleteVacationsHistoryError = Fetcher.ErrorWrapper<undefined>;

export type DeleteVacationsHistoryVariables = {
  body: Schemas.VacationsHistoryDeleteInput;
  pathParams: DeleteVacationsHistoryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteVacationsHistory = (
  variables: DeleteVacationsHistoryVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteVacationsHistoryError,
    Schemas.VacationsHistoryDeleteInput,
    {},
    {},
    DeleteVacationsHistoryPathParams
  >({
    url: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/history/ingest-historical-events',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteVacationsHistory = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteVacationsHistoryError,
      DeleteVacationsHistoryVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteVacationsHistoryError,
    DeleteVacationsHistoryVariables
  >({
    mutationFn: (variables: DeleteVacationsHistoryVariables) =>
      fetchDeleteVacationsHistory({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type LookupPersonError = Fetcher.ErrorWrapper<undefined>;

export type LookupPersonVariables = {
  body: Schemas.PeopleLookupInput;
} & ApiContext['fetcherOptions'];

export const fetchLookupPerson = (
  variables: LookupPersonVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.PeopleLookupEntry,
    LookupPersonError,
    Schemas.PeopleLookupInput,
    {},
    {},
    {}
  >({ url: '/api/people/lookup', method: 'post', ...variables, signal });

export const useLookupPerson = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PeopleLookupEntry,
      LookupPersonError,
      LookupPersonVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PeopleLookupEntry,
    LookupPersonError,
    LookupPersonVariables
  >({
    mutationFn: (variables: LookupPersonVariables) =>
      fetchLookupPerson({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutEntriesMappingRulesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * YYYY-MM of the accrualPeriod
   */
  periodId: Schemas.DateYYYYMM;
};

export type PutEntriesMappingRulesError = Fetcher.ErrorWrapper<undefined>;

export type PutEntriesMappingRulesVariables = {
  body: Schemas.AccountingEntriesMappingRules;
  pathParams: PutEntriesMappingRulesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutEntriesMappingRules = (
  variables: PutEntriesMappingRulesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AccountingEntriesMappingRules,
    PutEntriesMappingRulesError,
    Schemas.AccountingEntriesMappingRules,
    {},
    {},
    PutEntriesMappingRulesPathParams
  >({
    url: '/api/organizations/{organizationId}/accounting/entriesMappingRules/{periodId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutEntriesMappingRules = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AccountingEntriesMappingRules,
      PutEntriesMappingRulesError,
      PutEntriesMappingRulesVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AccountingEntriesMappingRules,
    PutEntriesMappingRulesError,
    PutEntriesMappingRulesVariables
  >({
    mutationFn: (variables: PutEntriesMappingRulesVariables) =>
      fetchPutEntriesMappingRules({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetEntriesMappingRulesPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * YYYY-MM of the accrualPeriod
   */
  periodId: Schemas.DateYYYYMM;
};

export type GetEntriesMappingRulesError = Fetcher.ErrorWrapper<undefined>;

export type GetEntriesMappingRulesVariables = {
  pathParams: GetEntriesMappingRulesPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetEntriesMappingRules = (
  variables: GetEntriesMappingRulesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AccountingEntriesMappingRules,
    GetEntriesMappingRulesError,
    undefined,
    {},
    {},
    GetEntriesMappingRulesPathParams
  >({
    url: '/api/organizations/{organizationId}/accounting/entriesMappingRules/{periodId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetEntriesMappingRules = <
  TData = Schemas.AccountingEntriesMappingRules,
>(
  variables: GetEntriesMappingRulesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AccountingEntriesMappingRules,
      GetEntriesMappingRulesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AccountingEntriesMappingRules,
    GetEntriesMappingRulesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/accounting/entriesMappingRules/{periodId}',
      operationId: 'getEntriesMappingRules',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetEntriesMappingRules({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostEntriesMappingRulesCompilePathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * YYYY-MM of the accrualPeriod
   */
  periodId: Schemas.DateYYYYMM;
};

export type PostEntriesMappingRulesCompileError =
  Fetcher.ErrorWrapper<undefined>;

export type PostEntriesMappingRulesCompileVariables = {
  pathParams: PostEntriesMappingRulesCompilePathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostEntriesMappingRulesCompile = (
  variables: PostEntriesMappingRulesCompileVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    PostEntriesMappingRulesCompileError,
    undefined,
    {},
    {},
    PostEntriesMappingRulesCompilePathParams
  >({
    url: '/api/organizations/{organizationId}/accounting/entriesMappingRules/{periodId}/compile',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostEntriesMappingRulesCompile = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostEntriesMappingRulesCompileError,
      PostEntriesMappingRulesCompileVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    PostEntriesMappingRulesCompileError,
    PostEntriesMappingRulesCompileVariables
  >({
    mutationFn: (variables: PostEntriesMappingRulesCompileVariables) =>
      fetchPostEntriesMappingRulesCompile({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutChartsOfAccountsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type PutChartsOfAccountsError = Fetcher.ErrorWrapper<undefined>;

export type PutChartsOfAccountsVariables = {
  body: Schemas.AccountingChartsOfAccounts;
  pathParams: PutChartsOfAccountsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutChartsOfAccounts = (
  variables: PutChartsOfAccountsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AccountingChartsOfAccounts,
    PutChartsOfAccountsError,
    Schemas.AccountingChartsOfAccounts,
    {},
    {},
    PutChartsOfAccountsPathParams
  >({
    url: '/api/organizations/{organizationId}/accounting/chartsOfAccounts',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutChartsOfAccounts = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AccountingChartsOfAccounts,
      PutChartsOfAccountsError,
      PutChartsOfAccountsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AccountingChartsOfAccounts,
    PutChartsOfAccountsError,
    PutChartsOfAccountsVariables
  >({
    mutationFn: (variables: PutChartsOfAccountsVariables) =>
      fetchPutChartsOfAccounts({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetChartsOfAccountsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type GetChartsOfAccountsError = Fetcher.ErrorWrapper<undefined>;

export type GetChartsOfAccountsVariables = {
  pathParams: GetChartsOfAccountsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetChartsOfAccounts = (
  variables: GetChartsOfAccountsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AccountingChartsOfAccounts,
    GetChartsOfAccountsError,
    undefined,
    {},
    {},
    GetChartsOfAccountsPathParams
  >({
    url: '/api/organizations/{organizationId}/accounting/chartsOfAccounts',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetChartsOfAccounts = <
  TData = Schemas.AccountingChartsOfAccounts,
>(
  variables: GetChartsOfAccountsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AccountingChartsOfAccounts,
      GetChartsOfAccountsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AccountingChartsOfAccounts,
    GetChartsOfAccountsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/accounting/chartsOfAccounts',
      operationId: 'getChartsOfAccounts',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetChartsOfAccounts({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostAssociationError = Fetcher.ErrorWrapper<undefined>;

export type PostAssociationVariables = {
  body: Schemas.AssociationCreate;
} & ApiContext['fetcherOptions'];

export const fetchPostAssociation = (
  variables: PostAssociationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AssociationEntry,
    PostAssociationError,
    Schemas.AssociationCreate,
    {},
    {},
    {}
  >({ url: '/api/associations', method: 'post', ...variables, signal });

export const usePostAssociation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssociationEntry,
      PostAssociationError,
      PostAssociationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AssociationEntry,
    PostAssociationError,
    PostAssociationVariables
  >({
    mutationFn: (variables: PostAssociationVariables) =>
      fetchPostAssociation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListAssociationsQueryParams = {
  document?: string;
  type?: Schemas.AssociationTypes;
};

export type ListAssociationsError = Fetcher.ErrorWrapper<undefined>;

export type ListAssociationsResponse = Schemas.AssociationEntry[];

export type ListAssociationsVariables = {
  queryParams?: ListAssociationsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchListAssociations = (
  variables: ListAssociationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    ListAssociationsResponse,
    ListAssociationsError,
    undefined,
    {},
    ListAssociationsQueryParams,
    {}
  >({ url: '/api/associations', method: 'get', ...variables, signal });

export const useListAssociations = <TData = ListAssociationsResponse,>(
  variables: ListAssociationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListAssociationsResponse,
      ListAssociationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ListAssociationsResponse,
    ListAssociationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/associations',
      operationId: 'listAssociations',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListAssociations({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAssociationPathParams = {
  /**
   * The id of the association
   *
   * @format uuid
   */
  associationId: string;
};

export type GetAssociationError = Fetcher.ErrorWrapper<undefined>;

export type GetAssociationVariables = {
  pathParams: GetAssociationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAssociation = (
  variables: GetAssociationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AssociationEntry,
    GetAssociationError,
    undefined,
    {},
    {},
    GetAssociationPathParams
  >({
    url: '/api/associations/{associationId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAssociation = <TData = Schemas.AssociationEntry,>(
  variables: GetAssociationVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AssociationEntry,
      GetAssociationError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AssociationEntry,
    GetAssociationError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/associations/{associationId}',
      operationId: 'getAssociation',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAssociation({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutAssociationPathParams = {
  /**
   * The id of the association
   *
   * @format uuid
   */
  associationId: string;
};

export type PutAssociationError = Fetcher.ErrorWrapper<undefined>;

export type PutAssociationVariables = {
  body: Schemas.AssociationUpdate;
  pathParams: PutAssociationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutAssociation = (
  variables: PutAssociationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AssociationEntry,
    PutAssociationError,
    Schemas.AssociationUpdate,
    {},
    {},
    PutAssociationPathParams
  >({
    url: '/api/associations/{associationId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutAssociation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssociationEntry,
      PutAssociationError,
      PutAssociationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AssociationEntry,
    PutAssociationError,
    PutAssociationVariables
  >({
    mutationFn: (variables: PutAssociationVariables) =>
      fetchPutAssociation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteAssociationPathParams = {
  /**
   * The id of the association
   *
   * @format uuid
   */
  associationId: string;
};

export type DeleteAssociationError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAssociationVariables = {
  pathParams: DeleteAssociationPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteAssociation = (
  variables: DeleteAssociationVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteAssociationError,
    undefined,
    {},
    {},
    DeleteAssociationPathParams
  >({
    url: '/api/associations/{associationId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteAssociation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteAssociationError,
      DeleteAssociationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteAssociationError,
    DeleteAssociationVariables
  >({
    mutationFn: (variables: DeleteAssociationVariables) =>
      fetchDeleteAssociation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListAssociationMembersPathParams = {
  /**
   * The id of the association
   *
   * @format uuid
   */
  associationId: string;
};

export type ListAssociationMembersError = Fetcher.ErrorWrapper<undefined>;

export type ListAssociationMembersResponse = Schemas.AssociationMemberEntry[];

export type ListAssociationMembersVariables = {
  pathParams: ListAssociationMembersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListAssociationMembers = (
  variables: ListAssociationMembersVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    ListAssociationMembersResponse,
    ListAssociationMembersError,
    undefined,
    {},
    {},
    ListAssociationMembersPathParams
  >({
    url: '/api/associations/{associationId}/members',
    method: 'get',
    ...variables,
    signal,
  });

export const useListAssociationMembers = <
  TData = ListAssociationMembersResponse,
>(
  variables: ListAssociationMembersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListAssociationMembersResponse,
      ListAssociationMembersError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ListAssociationMembersResponse,
    ListAssociationMembersError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/associations/{associationId}/members',
      operationId: 'listAssociationMembers',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListAssociationMembers({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostCompanyAssociationMemberPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the association
   *
   * @format uuid
   */
  associationId: string;
};

export type PostCompanyAssociationMemberError = Fetcher.ErrorWrapper<undefined>;

export type PostCompanyAssociationMemberVariables = {
  pathParams: PostCompanyAssociationMemberPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostCompanyAssociationMember = (
  variables: PostCompanyAssociationMemberVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AssociationMemberEntry,
    PostCompanyAssociationMemberError,
    undefined,
    {},
    {},
    PostCompanyAssociationMemberPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/associations/{associationId}',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostCompanyAssociationMember = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssociationMemberEntry,
      PostCompanyAssociationMemberError,
      PostCompanyAssociationMemberVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AssociationMemberEntry,
    PostCompanyAssociationMemberError,
    PostCompanyAssociationMemberVariables
  >({
    mutationFn: (variables: PostCompanyAssociationMemberVariables) =>
      fetchPostCompanyAssociationMember({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteCompanyAssociationMemberPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
  /**
   * The id of the association
   *
   * @format uuid
   */
  associationId: string;
};

export type DeleteCompanyAssociationMemberError =
  Fetcher.ErrorWrapper<undefined>;

export type DeleteCompanyAssociationMemberVariables = {
  pathParams: DeleteCompanyAssociationMemberPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteCompanyAssociationMember = (
  variables: DeleteCompanyAssociationMemberVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AssociationMemberEntry,
    DeleteCompanyAssociationMemberError,
    undefined,
    {},
    {},
    DeleteCompanyAssociationMemberPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/associations/{associationId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteCompanyAssociationMember = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssociationMemberEntry,
      DeleteCompanyAssociationMemberError,
      DeleteCompanyAssociationMemberVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AssociationMemberEntry,
    DeleteCompanyAssociationMemberError,
    DeleteCompanyAssociationMemberVariables
  >({
    mutationFn: (variables: DeleteCompanyAssociationMemberVariables) =>
      fetchDeleteCompanyAssociationMember({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostOrganizationAssociationMemberPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the association
   *
   * @format uuid
   */
  associationId: string;
};

export type PostOrganizationAssociationMemberError =
  Fetcher.ErrorWrapper<undefined>;

export type PostOrganizationAssociationMemberVariables = {
  pathParams: PostOrganizationAssociationMemberPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostOrganizationAssociationMember = (
  variables: PostOrganizationAssociationMemberVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AssociationMemberEntry,
    PostOrganizationAssociationMemberError,
    undefined,
    {},
    {},
    PostOrganizationAssociationMemberPathParams
  >({
    url: '/api/organizations/{organizationId}/associations/{associationId}',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostOrganizationAssociationMember = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssociationMemberEntry,
      PostOrganizationAssociationMemberError,
      PostOrganizationAssociationMemberVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AssociationMemberEntry,
    PostOrganizationAssociationMemberError,
    PostOrganizationAssociationMemberVariables
  >({
    mutationFn: (variables: PostOrganizationAssociationMemberVariables) =>
      fetchPostOrganizationAssociationMember({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type DeleteOrganizationAssociationMemberPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the association
   *
   * @format uuid
   */
  associationId: string;
};

export type DeleteOrganizationAssociationMemberError =
  Fetcher.ErrorWrapper<undefined>;

export type DeleteOrganizationAssociationMemberVariables = {
  pathParams: DeleteOrganizationAssociationMemberPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteOrganizationAssociationMember = (
  variables: DeleteOrganizationAssociationMemberVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AssociationMemberEntry,
    DeleteOrganizationAssociationMemberError,
    undefined,
    {},
    {},
    DeleteOrganizationAssociationMemberPathParams
  >({
    url: '/api/organizations/{organizationId}/associations/{associationId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteOrganizationAssociationMember = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AssociationMemberEntry,
      DeleteOrganizationAssociationMemberError,
      DeleteOrganizationAssociationMemberVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AssociationMemberEntry,
    DeleteOrganizationAssociationMemberError,
    DeleteOrganizationAssociationMemberVariables
  >({
    mutationFn: (variables: DeleteOrganizationAssociationMemberVariables) =>
      fetchDeleteOrganizationAssociationMember({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type ListCompanyMemberAssociationsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the company to retrieve
   *
   * @format uuid
   */
  companyId: string;
};

export type ListCompanyMemberAssociationsError =
  Fetcher.ErrorWrapper<undefined>;

export type ListCompanyMemberAssociationsResponse =
  Schemas.AssociationMemberEntry[];

export type ListCompanyMemberAssociationsVariables = {
  pathParams: ListCompanyMemberAssociationsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListCompanyMemberAssociations = (
  variables: ListCompanyMemberAssociationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    ListCompanyMemberAssociationsResponse,
    ListCompanyMemberAssociationsError,
    undefined,
    {},
    {},
    ListCompanyMemberAssociationsPathParams
  >({
    url: '/api/organizations/{organizationId}/companies/{companyId}/associations',
    method: 'get',
    ...variables,
    signal,
  });

export const useListCompanyMemberAssociations = <
  TData = ListCompanyMemberAssociationsResponse,
>(
  variables: ListCompanyMemberAssociationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListCompanyMemberAssociationsResponse,
      ListCompanyMemberAssociationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ListCompanyMemberAssociationsResponse,
    ListCompanyMemberAssociationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/companies/{companyId}/associations',
      operationId: 'listCompanyMemberAssociations',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListCompanyMemberAssociations(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ListOrganizationMemberAssociationsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type ListOrganizationMemberAssociationsError =
  Fetcher.ErrorWrapper<undefined>;

export type ListOrganizationMemberAssociationsResponse =
  Schemas.AssociationMemberEntry[];

export type ListOrganizationMemberAssociationsVariables = {
  pathParams: ListOrganizationMemberAssociationsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListOrganizationMemberAssociations = (
  variables: ListOrganizationMemberAssociationsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    ListOrganizationMemberAssociationsResponse,
    ListOrganizationMemberAssociationsError,
    undefined,
    {},
    {},
    ListOrganizationMemberAssociationsPathParams
  >({
    url: '/api/organizations/{organizationId}/associations',
    method: 'get',
    ...variables,
    signal,
  });

export const useListOrganizationMemberAssociations = <
  TData = ListOrganizationMemberAssociationsResponse,
>(
  variables: ListOrganizationMemberAssociationsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListOrganizationMemberAssociationsResponse,
      ListOrganizationMemberAssociationsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ListOrganizationMemberAssociationsResponse,
    ListOrganizationMemberAssociationsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/associations',
      operationId: 'listOrganizationMemberAssociations',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListOrganizationMemberAssociations(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type ListRuleAttributesError = Fetcher.ErrorWrapper<undefined>;

export type ListRuleAttributesResponse = Schemas.AgreementRulesAttribute[];

export type ListRuleAttributesVariables = ApiContext['fetcherOptions'];

export const fetchListRuleAttributes = (
  variables: ListRuleAttributesVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    ListRuleAttributesResponse,
    ListRuleAttributesError,
    undefined,
    {},
    {},
    {}
  >({
    url: '/api/agreements/rules/attributes',
    method: 'get',
    ...variables,
    signal,
  });

export const useListRuleAttributes = <TData = ListRuleAttributesResponse,>(
  variables: ListRuleAttributesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListRuleAttributesResponse,
      ListRuleAttributesError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ListRuleAttributesResponse,
    ListRuleAttributesError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/agreements/rules/attributes',
      operationId: 'listRuleAttributes',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListRuleAttributes({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostAgreementError = Fetcher.ErrorWrapper<undefined>;

export type PostAgreementVariables = {
  body: Schemas.AgreementCreate;
} & ApiContext['fetcherOptions'];

export const fetchPostAgreement = (
  variables: PostAgreementVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementEntry,
    PostAgreementError,
    Schemas.AgreementCreate,
    {},
    {},
    {}
  >({ url: '/api/agreements', method: 'post', ...variables, signal });

export const usePostAgreement = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementEntry,
      PostAgreementError,
      PostAgreementVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementEntry,
    PostAgreementError,
    PostAgreementVariables
  >({
    mutationFn: (variables: PostAgreementVariables) =>
      fetchPostAgreement({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListAgreementsError = Fetcher.ErrorWrapper<undefined>;

export type ListAgreementsResponse = Schemas.AgreementEntry[];

export type ListAgreementsVariables = ApiContext['fetcherOptions'];

export const fetchListAgreements = (
  variables: ListAgreementsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<ListAgreementsResponse, ListAgreementsError, undefined, {}, {}, {}>({
    url: '/api/agreements',
    method: 'get',
    ...variables,
    signal,
  });

export const useListAgreements = <TData = ListAgreementsResponse,>(
  variables: ListAgreementsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListAgreementsResponse,
      ListAgreementsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ListAgreementsResponse,
    ListAgreementsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/agreements',
      operationId: 'listAgreements',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListAgreements({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAgreementPathParams = {
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
};

export type GetAgreementError = Fetcher.ErrorWrapper<undefined>;

export type GetAgreementVariables = {
  pathParams: GetAgreementPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAgreement = (
  variables: GetAgreementVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementEntry,
    GetAgreementError,
    undefined,
    {},
    {},
    GetAgreementPathParams
  >({
    url: '/api/agreements/{agreementId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAgreement = <TData = Schemas.AgreementEntry,>(
  variables: GetAgreementVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AgreementEntry,
      GetAgreementError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.AgreementEntry, GetAgreementError, TData>({
    queryKey: queryKeyFn({
      path: '/api/agreements/{agreementId}',
      operationId: 'getAgreement',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAgreement({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutAgreementPathParams = {
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
};

export type PutAgreementError = Fetcher.ErrorWrapper<undefined>;

export type PutAgreementVariables = {
  body: Schemas.AgreementUpdate;
  pathParams: PutAgreementPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutAgreement = (
  variables: PutAgreementVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementEntry,
    PutAgreementError,
    Schemas.AgreementUpdate,
    {},
    {},
    PutAgreementPathParams
  >({
    url: '/api/agreements/{agreementId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutAgreement = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementEntry,
      PutAgreementError,
      PutAgreementVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementEntry,
    PutAgreementError,
    PutAgreementVariables
  >({
    mutationFn: (variables: PutAgreementVariables) =>
      fetchPutAgreement({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteAgreementPathParams = {
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
};

export type DeleteAgreementError = Fetcher.ErrorWrapper<undefined>;

export type DeleteAgreementVariables = {
  pathParams: DeleteAgreementPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDeleteAgreement = (
  variables: DeleteAgreementVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    undefined,
    DeleteAgreementError,
    undefined,
    {},
    {},
    DeleteAgreementPathParams
  >({
    url: '/api/agreements/{agreementId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteAgreement = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      DeleteAgreementError,
      DeleteAgreementVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    DeleteAgreementError,
    DeleteAgreementVariables
  >({
    mutationFn: (variables: DeleteAgreementVariables) =>
      fetchDeleteAgreement({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListAgreementExecutionsPathParams = {
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
};

export type ListAgreementExecutionsError = Fetcher.ErrorWrapper<undefined>;

export type ListAgreementExecutionsResponse = Schemas.AgreementExecutionEntry[];

export type ListAgreementExecutionsVariables = {
  pathParams: ListAgreementExecutionsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListAgreementExecutions = (
  variables: ListAgreementExecutionsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    ListAgreementExecutionsResponse,
    ListAgreementExecutionsError,
    undefined,
    {},
    {},
    ListAgreementExecutionsPathParams
  >({
    url: '/api/agreements/{agreementId}/executions',
    method: 'get',
    ...variables,
    signal,
  });

export const useListAgreementExecutions = <
  TData = ListAgreementExecutionsResponse,
>(
  variables: ListAgreementExecutionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListAgreementExecutionsResponse,
      ListAgreementExecutionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ListAgreementExecutionsResponse,
    ListAgreementExecutionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/agreements/{agreementId}/executions',
      operationId: 'listAgreementExecutions',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListAgreementExecutions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostAgreementExecutionPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
};

export type PostAgreementExecutionError = Fetcher.ErrorWrapper<undefined>;

export type PostAgreementExecutionVariables = {
  body?: Schemas.AgreementExecutionCreate;
  pathParams: PostAgreementExecutionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPostAgreementExecution = (
  variables: PostAgreementExecutionVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionEntry,
    PostAgreementExecutionError,
    Schemas.AgreementExecutionCreate,
    {},
    {},
    PostAgreementExecutionPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostAgreementExecution = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementExecutionEntry,
      PostAgreementExecutionError,
      PostAgreementExecutionVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementExecutionEntry,
    PostAgreementExecutionError,
    PostAgreementExecutionVariables
  >({
    mutationFn: (variables: PostAgreementExecutionVariables) =>
      fetchPostAgreementExecution({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RemoveAgreementExecutionItemPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
  /**
   * The id of the agreement execution item
   *
   * @format uuid
   */
  agreementExecutionItemId: string;
};

export type RemoveAgreementExecutionItemError = Fetcher.ErrorWrapper<undefined>;

export type RemoveAgreementExecutionItemVariables = {
  pathParams: RemoveAgreementExecutionItemPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRemoveAgreementExecutionItem = (
  variables: RemoveAgreementExecutionItemVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionItemEntry,
    RemoveAgreementExecutionItemError,
    undefined,
    {},
    {},
    RemoveAgreementExecutionItemPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/items/{agreementExecutionItemId}/remove',
    method: 'put',
    ...variables,
    signal,
  });

export const useRemoveAgreementExecutionItem = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementExecutionItemEntry,
      RemoveAgreementExecutionItemError,
      RemoveAgreementExecutionItemVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementExecutionItemEntry,
    RemoveAgreementExecutionItemError,
    RemoveAgreementExecutionItemVariables
  >({
    mutationFn: (variables: RemoveAgreementExecutionItemVariables) =>
      fetchRemoveAgreementExecutionItem({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RevertAgreementExecutionItemPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
  /**
   * The id of the agreement execution item
   *
   * @format uuid
   */
  agreementExecutionItemId: string;
};

export type RevertAgreementExecutionItemError = Fetcher.ErrorWrapper<undefined>;

export type RevertAgreementExecutionItemVariables = {
  pathParams: RevertAgreementExecutionItemPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRevertAgreementExecutionItem = (
  variables: RevertAgreementExecutionItemVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionItemEntry,
    RevertAgreementExecutionItemError,
    undefined,
    {},
    {},
    RevertAgreementExecutionItemPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/items/{agreementExecutionItemId}/revert',
    method: 'put',
    ...variables,
    signal,
  });

export const useRevertAgreementExecutionItem = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementExecutionItemEntry,
      RevertAgreementExecutionItemError,
      RevertAgreementExecutionItemVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementExecutionItemEntry,
    RevertAgreementExecutionItemError,
    RevertAgreementExecutionItemVariables
  >({
    mutationFn: (variables: RevertAgreementExecutionItemVariables) =>
      fetchRevertAgreementExecutionItem({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAgreementExecutionItemPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
  /**
   * The id of the agreement execution item
   *
   * @format uuid
   */
  agreementExecutionItemId: string;
};

export type GetAgreementExecutionItemError = Fetcher.ErrorWrapper<undefined>;

export type GetAgreementExecutionItemVariables = {
  pathParams: GetAgreementExecutionItemPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAgreementExecutionItem = (
  variables: GetAgreementExecutionItemVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionItemEntry,
    GetAgreementExecutionItemError,
    undefined,
    {},
    {},
    GetAgreementExecutionItemPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/items/{agreementExecutionItemId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAgreementExecutionItem = <
  TData = Schemas.AgreementExecutionItemEntry,
>(
  variables: GetAgreementExecutionItemVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AgreementExecutionItemEntry,
      GetAgreementExecutionItemError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AgreementExecutionItemEntry,
    GetAgreementExecutionItemError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/items/{agreementExecutionItemId}',
      operationId: 'getAgreementExecutionItem',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAgreementExecutionItem(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type PutAgreementExecutionItemPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
  /**
   * The id of the agreement execution item
   *
   * @format uuid
   */
  agreementExecutionItemId: string;
};

export type PutAgreementExecutionItemError = Fetcher.ErrorWrapper<undefined>;

export type PutAgreementExecutionItemVariables = {
  body: Schemas.AgreementExecutionItemUpdate;
  pathParams: PutAgreementExecutionItemPathParams;
} & ApiContext['fetcherOptions'];

export const fetchPutAgreementExecutionItem = (
  variables: PutAgreementExecutionItemVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionItemEntry,
    PutAgreementExecutionItemError,
    Schemas.AgreementExecutionItemUpdate,
    {},
    {},
    PutAgreementExecutionItemPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/items/{agreementExecutionItemId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutAgreementExecutionItem = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementExecutionItemEntry,
      PutAgreementExecutionItemError,
      PutAgreementExecutionItemVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementExecutionItemEntry,
    PutAgreementExecutionItemError,
    PutAgreementExecutionItemVariables
  >({
    mutationFn: (variables: PutAgreementExecutionItemVariables) =>
      fetchPutAgreementExecutionItem({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ListAgreementExecutionItemsPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
};

export type ListAgreementExecutionItemsError = Fetcher.ErrorWrapper<undefined>;

export type ListAgreementExecutionItemsResponse =
  Schemas.AgreementExecutionItemEntry[];

export type ListAgreementExecutionItemsVariables = {
  pathParams: ListAgreementExecutionItemsPathParams;
} & ApiContext['fetcherOptions'];

export const fetchListAgreementExecutionItems = (
  variables: ListAgreementExecutionItemsVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    ListAgreementExecutionItemsResponse,
    ListAgreementExecutionItemsError,
    undefined,
    {},
    {},
    ListAgreementExecutionItemsPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/items',
    method: 'get',
    ...variables,
    signal,
  });

export const useListAgreementExecutionItems = <
  TData = ListAgreementExecutionItemsResponse,
>(
  variables: ListAgreementExecutionItemsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ListAgreementExecutionItemsResponse,
      ListAgreementExecutionItemsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ListAgreementExecutionItemsResponse,
    ListAgreementExecutionItemsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/items',
      operationId: 'listAgreementExecutionItems',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchListAgreementExecutionItems(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetAgreementExecutionPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
};

export type GetAgreementExecutionError = Fetcher.ErrorWrapper<undefined>;

export type GetAgreementExecutionVariables = {
  pathParams: GetAgreementExecutionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchGetAgreementExecution = (
  variables: GetAgreementExecutionVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionEntry,
    GetAgreementExecutionError,
    undefined,
    {},
    {},
    GetAgreementExecutionPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/{agreementExecutionId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAgreementExecution = <
  TData = Schemas.AgreementExecutionEntry,
>(
  variables: GetAgreementExecutionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.AgreementExecutionEntry,
      GetAgreementExecutionError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.AgreementExecutionEntry,
    GetAgreementExecutionError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/{agreementExecutionId}',
      operationId: 'getAgreementExecution',
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAgreementExecution({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ApplyAgreementExecutionPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
};

export type ApplyAgreementExecutionError = Fetcher.ErrorWrapper<undefined>;

export type ApplyAgreementExecutionVariables = {
  pathParams: ApplyAgreementExecutionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchApplyAgreementExecution = (
  variables: ApplyAgreementExecutionVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionEntry,
    ApplyAgreementExecutionError,
    undefined,
    {},
    {},
    ApplyAgreementExecutionPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/{agreementExecutionId}/apply',
    method: 'put',
    ...variables,
    signal,
  });

export const useApplyAgreementExecution = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementExecutionEntry,
      ApplyAgreementExecutionError,
      ApplyAgreementExecutionVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementExecutionEntry,
    ApplyAgreementExecutionError,
    ApplyAgreementExecutionVariables
  >({
    mutationFn: (variables: ApplyAgreementExecutionVariables) =>
      fetchApplyAgreementExecution({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CancelAgreementExecutionPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
};

export type CancelAgreementExecutionError = Fetcher.ErrorWrapper<undefined>;

export type CancelAgreementExecutionVariables = {
  pathParams: CancelAgreementExecutionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCancelAgreementExecution = (
  variables: CancelAgreementExecutionVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionEntry,
    CancelAgreementExecutionError,
    undefined,
    {},
    {},
    CancelAgreementExecutionPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/{agreementExecutionId}/cancel',
    method: 'put',
    ...variables,
    signal,
  });

export const useCancelAgreementExecution = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementExecutionEntry,
      CancelAgreementExecutionError,
      CancelAgreementExecutionVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementExecutionEntry,
    CancelAgreementExecutionError,
    CancelAgreementExecutionVariables
  >({
    mutationFn: (variables: CancelAgreementExecutionVariables) =>
      fetchCancelAgreementExecution({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type RevertAgreementExecutionPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
};

export type RevertAgreementExecutionError = Fetcher.ErrorWrapper<undefined>;

export type RevertAgreementExecutionVariables = {
  pathParams: RevertAgreementExecutionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRevertAgreementExecution = (
  variables: RevertAgreementExecutionVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionEntry,
    RevertAgreementExecutionError,
    undefined,
    {},
    {},
    RevertAgreementExecutionPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/{agreementExecutionId}/revert',
    method: 'put',
    ...variables,
    signal,
  });

export const useRevertAgreementExecution = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementExecutionEntry,
      RevertAgreementExecutionError,
      RevertAgreementExecutionVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementExecutionEntry,
    RevertAgreementExecutionError,
    RevertAgreementExecutionVariables
  >({
    mutationFn: (variables: RevertAgreementExecutionVariables) =>
      fetchRevertAgreementExecution({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DryRunAgreementExecutionPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
  /**
   * The id of the agreement
   *
   * @format uuid
   */
  agreementId: string;
  /**
   * The id of the agreement execution
   *
   * @format uuid
   */
  agreementExecutionId: string;
};

export type DryRunAgreementExecutionError = Fetcher.ErrorWrapper<undefined>;

export type DryRunAgreementExecutionVariables = {
  pathParams: DryRunAgreementExecutionPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDryRunAgreementExecution = (
  variables: DryRunAgreementExecutionVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.AgreementExecutionEntry,
    DryRunAgreementExecutionError,
    undefined,
    {},
    {},
    DryRunAgreementExecutionPathParams
  >({
    url: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/{agreementExecutionId}/dryrun',
    method: 'put',
    ...variables,
    signal,
  });

export const useDryRunAgreementExecution = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.AgreementExecutionEntry,
      DryRunAgreementExecutionError,
      DryRunAgreementExecutionVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.AgreementExecutionEntry,
    DryRunAgreementExecutionError,
    DryRunAgreementExecutionVariables
  >({
    mutationFn: (variables: DryRunAgreementExecutionVariables) =>
      fetchDryRunAgreementExecution({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SearchAllUsersPathParams = {
  /**
   * The id of the organization to retrieve
   *
   * @format uuid
   */
  organizationId: string;
};

export type SearchAllUsersError = Fetcher.ErrorWrapper<undefined>;

export type SearchAllUsersVariables = {
  body?: Schemas.SearchInput;
  pathParams: SearchAllUsersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSearchAllUsers = (
  variables: SearchAllUsersVariables,
  signal?: AbortSignal,
) =>
  apiFetch<
    Schemas.UserSearchList,
    SearchAllUsersError,
    Schemas.SearchInput,
    {},
    {},
    SearchAllUsersPathParams
  >({
    url: '/api/organizations/{organizationId}/users/search',
    method: 'post',
    ...variables,
    signal,
  });

export const useSearchAllUsers = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserSearchList,
      SearchAllUsersError,
      SearchAllUsersVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.UserSearchList,
    SearchAllUsersError,
    SearchAllUsersVariables
  >({
    mutationFn: (variables: SearchAllUsersVariables) =>
      fetchSearchAllUsers({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: '/api/user-auth/identity';
      operationId: 'getUserIdentity';
      variables: GetUserIdentityVariables;
    }
  | {
      path: '/api/user-auth/login/token';
      operationId: 'refreshToken';
      variables: RefreshTokenVariables;
    }
  | {
      path: '/api/users/{userId}';
      operationId: 'getUserById';
      variables: GetUserByIdVariables;
    }
  | {
      path: '/api/users/{userId}/membership';
      operationId: 'getUserMembership';
      variables: GetUserMembershipVariables;
    }
  | {
      path: '/api/users/people/{personId}';
      operationId: 'getUserByPersonId';
      variables: GetUserByPersonIdVariables;
    }
  | {
      path: '/api/organizations/';
      operationId: 'getOrganizations';
      variables: GetOrganizationsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}';
      operationId: 'getOrganizationEntry';
      variables: GetOrganizationEntryVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/membership';
      operationId: 'getAllMembership';
      variables: GetAllMembershipVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/membership/user/{userId}';
      operationId: 'getMembershipByUserId';
      variables: GetMembershipByUserIdVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/membership/{membershipId}';
      operationId: 'getMembershipEntry';
      variables: GetMembershipEntryVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies';
      operationId: 'getAllCompanies';
      variables: GetAllCompaniesVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}';
      operationId: 'getCompanyEntry';
      variables: GetCompanyEntryVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/configurations';
      operationId: 'getAllCompanyPayrollConfiguration';
      variables: GetAllCompanyPayrollConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/configurations/{effectiveDate}';
      operationId: 'getCompanyPayrollConfiguration';
      variables: GetCompanyPayrollConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/historical-events';
      operationId: 'getPayrollsHistoricalEvents';
      variables: GetPayrollsHistoricalEventsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{period}';
      operationId: 'getPeriod';
      variables: GetPeriodVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/payrolls/orders/{orderId}';
      operationId: 'getPayrollOrder';
      variables: GetPayrollOrderVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/payrolls/orders';
      operationId: 'getPayrollOrders';
      variables: GetPayrollOrdersVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/rubrics-tables';
      operationId: 'getAllRubricTables';
      variables: GetAllRubricTablesVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}';
      operationId: 'getPayroll';
      variables: GetPayrollVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/payslips';
      operationId: 'getPayrollPayslip';
      variables: GetPayrollPayslipVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/contracts/{contractId}/payrolls/{payrollId}/payslips/pdf';
      operationId: 'getPayrollPayslipPdf';
      variables: GetPayrollPayslipPdfVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/types/{payrollType}/summary';
      operationId: 'getPeriodSummary';
      variables: GetPeriodSummaryVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/periods/{periodId}/types/{payrollType}/payslips/pdf';
      operationId: 'getAllPayrollPayslipPdf';
      variables: GetAllPayrollPayslipPdfVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs';
      operationId: 'getPayrollInputsConfiguration';
      variables: GetPayrollInputsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs/periods/{periodId}/types/{payrollType}';
      operationId: 'getPayrollInputsForPeriod';
      variables: GetPayrollInputsForPeriodVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/payrolls/inputs/periods/{periodId}/types/{payrollType}/submissions/{submissionId}';
      operationId: 'getPayrollInputsSubmissionDetails';
      variables: GetPayrollInputsSubmissionDetailsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations';
      operationId: 'listPjPaymentsNotificationsConfiguration';
      variables: ListPjPaymentsNotificationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/notification-configurations/{resourceType}/rules/{ruleId}';
      operationId: 'getPjPaymentsNotificationsConfiguration';
      variables: GetPjPaymentsNotificationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}';
      operationId: 'getContractPaymentRequest';
      variables: GetContractPaymentRequestVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}/attachments';
      operationId: 'getContractAllPaymentRequestsAttachment';
      variables: GetContractAllPaymentRequestsAttachmentVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/contracts/{contractId}/payments/{paymentRequestId}/attachments/{attachmentId}';
      operationId: 'getContractPaymentRequestAttachment';
      variables: GetContractPaymentRequestAttachmentVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/org-chart/contracts/{contractId}/managers';
      operationId: 'getContractManager';
      variables: GetContractManagerVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/org-chart/contracts/{contractId}/reports';
      operationId: 'getContractReports';
      variables: GetContractReportsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/custom-fields';
      operationId: 'listCustomFields';
      variables: ListCustomFieldsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/provisions/contracts/{contractId}/periods/{periodId}';
      operationId: 'getProvisionsPeriod';
      variables: GetProvisionsPeriodVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}';
      operationId: 'getPaymentRequest';
      variables: GetPaymentRequestVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/attachments';
      operationId: 'getAllPaymentRequestsAttachment';
      variables: GetAllPaymentRequestsAttachmentVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/pj-payments/payments/{paymentRequestId}/attachments/{attachmentId}';
      operationId: 'getPaymentRequestAttachment';
      variables: GetPaymentRequestAttachmentVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/{contractId}';
      operationId: 'getContract';
      variables: GetContractVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/{contractId}/events';
      operationId: 'getContractEvents';
      variables: GetContractEventsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/{contractId}/events-page';
      operationId: 'getContractEventsPage';
      variables: GetContractEventsPageVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/{contractId}/events/{eventId}';
      operationId: 'getContractEvent';
      variables: GetContractEventVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/people/{personId}';
      operationId: 'contractsPerson';
      variables: ContractsPersonVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/{contractId}/leaves/{leaveId}';
      operationId: 'getContractLeave';
      variables: GetContractLeaveVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/{contractId}/payrolls/recurring-events/{recurringEventId}';
      operationId: 'getPayrollRecurringEvent';
      variables: GetPayrollRecurringEventVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/{contractId}/payslips';
      operationId: 'listSentPayslips';
      variables: ListSentPayslipsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/{contractId}/payslips/{payrollId}';
      operationId: 'getSentPayslip';
      variables: GetSentPayslipVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/notification-configurations';
      operationId: 'listContractsNotificationsConfiguration';
      variables: ListContractsNotificationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/contracts/notification-configurations/{resourceType}/rules/{ruleId}';
      operationId: 'getContractsNotificationsConfiguration';
      variables: GetContractsNotificationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/job-titles';
      operationId: 'getAllJobTitles';
      variables: GetAllJobTitlesVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/job-titles/{jobTitleId}';
      operationId: 'getJobTitleEntry';
      variables: GetJobTitleEntryVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/cost-centers/{costCenterId}';
      operationId: 'getCostCenterEntry';
      variables: GetCostCenterEntryVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/reports';
      operationId: 'getReportDefinitions';
      variables: GetReportDefinitionsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/reports/{reportId}';
      operationId: 'getReportDefinition';
      variables: GetReportDefinitionVariables;
    }
  | {
      path: '/api/esocial/organizations/{organizationId}/companies/{companyId}/payrolls/{payrollId}/override-reporting';
      operationId: 'getWorkerReconciliationReportingOverride';
      variables: GetWorkerReconciliationReportingOverrideVariables;
    }
  | {
      path: '/api/esocial/organizations/{organizationId}/companies/{companyId}/events/{eventId}';
      operationId: 'getEsocialEvent';
      variables: GetEsocialEventVariables;
    }
  | {
      path: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}';
      operationId: 'getReconciliationEntry';
      variables: GetReconciliationEntryVariables;
    }
  | {
      path: '/api/esocial/organizations/{organizationId}/companies/{companyId}/reconciliations/{period}/workers/{personId}';
      operationId: 'getWorkerReconciliationEntry';
      variables: GetWorkerReconciliationEntryVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/spreadsheets';
      operationId: 'getSpreadsheets';
      variables: GetSpreadsheetsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/legal-entities';
      operationId: 'getLegalEntities';
      variables: GetLegalEntitiesVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/legal-entities/{legalEntityId}';
      operationId: 'getLegalEntity';
      variables: GetLegalEntityVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}';
      operationId: 'getAdmissionDraft';
      variables: GetAdmissionDraftVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/onboarding/admission/draft/users/{userId}';
      operationId: 'getAdmissionDraftByUser';
      variables: GetAdmissionDraftByUserVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/attachments';
      operationId: 'getAllAdmissionDraftAttachments';
      variables: GetAllAdmissionDraftAttachmentsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/onboarding/admission/draft/{draftId}/attachments/{attachmentId}';
      operationId: 'getAdmissionDraftAttachment';
      variables: GetAdmissionDraftAttachmentVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/onboarding/notification-configurations';
      operationId: 'listAdmissionNotificationsConfiguration';
      variables: ListAdmissionNotificationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/onboarding/notification-configurations/{resourceType}/rules/{ruleId}';
      operationId: 'getAdmissionNotificationsConfiguration';
      variables: GetAdmissionNotificationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/configuration';
      operationId: 'getVacationsConfiguration';
      variables: GetVacationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/notification-configurations';
      operationId: 'listVacationsNotificationsConfiguration';
      variables: ListVacationsNotificationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/notification-configurations/{resourceType}/rules/{ruleId}';
      operationId: 'getVacationsNotificationsConfiguration';
      variables: GetVacationsNotificationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/configuration/list';
      operationId: 'listVacationsConfiguration';
      variables: ListVacationsConfigurationVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/group';
      operationId: 'getGroupVacations';
      variables: GetGroupVacationsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/group/{groupId}';
      operationId: 'getGroupVacationSchedules';
      variables: GetGroupVacationSchedulesVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/configuration';
      operationId: 'getVacationsConfigurationByContract';
      variables: GetVacationsConfigurationByContractVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/accrual-periods';
      operationId: 'getContractAccrualPeriods';
      variables: GetContractAccrualPeriodsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/accrual-periods/{startDate}/schedules';
      operationId: 'getContractAccrualPeriodsScheduledVacations';
      variables: GetContractAccrualPeriodsScheduledVacationsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules';
      operationId: 'getContractVacationsSchedule';
      variables: GetContractVacationsScheduleVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/vacations/contracts/{contractId}/schedules/{sequence}';
      operationId: 'getVacationsSchedule';
      variables: GetVacationsScheduleVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/accounting/entriesMappingRules/{periodId}';
      operationId: 'getEntriesMappingRules';
      variables: GetEntriesMappingRulesVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/accounting/chartsOfAccounts';
      operationId: 'getChartsOfAccounts';
      variables: GetChartsOfAccountsVariables;
    }
  | {
      path: '/api/associations';
      operationId: 'listAssociations';
      variables: ListAssociationsVariables;
    }
  | {
      path: '/api/associations/{associationId}';
      operationId: 'getAssociation';
      variables: GetAssociationVariables;
    }
  | {
      path: '/api/associations/{associationId}/members';
      operationId: 'listAssociationMembers';
      variables: ListAssociationMembersVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/companies/{companyId}/associations';
      operationId: 'listCompanyMemberAssociations';
      variables: ListCompanyMemberAssociationsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/associations';
      operationId: 'listOrganizationMemberAssociations';
      variables: ListOrganizationMemberAssociationsVariables;
    }
  | {
      path: '/api/agreements/rules/attributes';
      operationId: 'listRuleAttributes';
      variables: ListRuleAttributesVariables;
    }
  | {
      path: '/api/agreements';
      operationId: 'listAgreements';
      variables: ListAgreementsVariables;
    }
  | {
      path: '/api/agreements/{agreementId}';
      operationId: 'getAgreement';
      variables: GetAgreementVariables;
    }
  | {
      path: '/api/agreements/{agreementId}/executions';
      operationId: 'listAgreementExecutions';
      variables: ListAgreementExecutionsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/items/{agreementExecutionItemId}';
      operationId: 'getAgreementExecutionItem';
      variables: GetAgreementExecutionItemVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/items';
      operationId: 'listAgreementExecutionItems';
      variables: ListAgreementExecutionItemsVariables;
    }
  | {
      path: '/api/organizations/{organizationId}/agreements/{agreementId}/executions/{agreementExecutionId}';
      operationId: 'getAgreementExecution';
      variables: GetAgreementExecutionVariables;
    };
