import React from 'react';
import { useParams } from 'react-router-dom';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Skeleton, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';

import { useGetVacationsNotificationsConfiguration } from '@octopus/api';

import { BackButton } from '../../../modules/components/BackButton';

import NotificationForm, {
  VacationConfigurationResourceType,
} from './NotificationForm';

type EditVacationsNotificationProps = {
  organizationId: string;
};

export const EditVacationsNotification: React.FC<
  EditVacationsNotificationProps
> = ({ organizationId }: EditVacationsNotificationProps) => {
  const { resourceType, ruleId } = useParams();
  const { data, isLoading } = useGetVacationsNotificationsConfiguration(
    {
      pathParams: {
        organizationId,
        resourceType,
        ruleId,
      },
    },
    {
      enabled: !!organizationId && !!resourceType && !!ruleId,
      cacheTime: 0,
      staleTime: 1,
    },
  );
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        py: 9,
        px: 10,
      }}
    >
      <Box>
        <BackButton destination="/vacations-notifications" />
      </Box>
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="group-vacations-header"
          gap={1}
          mb={5}
        >
          <BeachAccessOutlined
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Notificações de Férias</Typography>
        </Box>

        <Box>
          {isLoading ? (
            <Skeleton variant="rectangular" width="80vw" height="60vh" />
          ) : (
            <NotificationForm
              formData={data}
              organizationId={organizationId}
              resourceType={resourceType as VacationConfigurationResourceType}
              ruleId={ruleId}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};
