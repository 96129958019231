import React from 'react';
import { useParams } from 'react-router-dom';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box, Container } from '@mui/system';

import {
  NotificationAction,
  NotificationConfigurationInput,
  NotificationTiming,
} from '@octopus/api';
import { baseNotifications } from '@octopus/vacations-types';

import { BackButton } from '../../../modules/components/BackButton';

import NotificationForm from './NotificationForm';

type NewVacationsNotificationProps = {
  organizationId: string;
};

const emptyFormData = (resourceType: string) => {
  return {
    resourceType,
    ruleId: '',
    condition: {},
    name: '',
    description: '',
    tags: [] as string[],
    actions: [] as NotificationAction[],
    timings: [] as NotificationTiming[],
  } as NotificationConfigurationInput;
};

export const NewVacationsNotification: React.FC<
  NewVacationsNotificationProps
> = ({ organizationId }: NewVacationsNotificationProps) => {
  const { ruleId } = useParams();
  const formData = baseNotifications.find(
    (notification) => notification.ruleId === ruleId,
  ) as NotificationConfigurationInput;
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        py: 9,
        px: 10,
      }}
    >
      <Box>
        <BackButton />
      </Box>
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="group-vacations-header"
          gap={1}
          mb={5}
        >
          <BeachAccessOutlined
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Notificações de Férias</Typography>
        </Box>

        <Box>
          <NotificationForm
            formData={formData || emptyFormData(ruleId)}
            organizationId={organizationId}
          />
        </Box>
      </Container>
    </Box>
  );
};
