import { useQuery } from '@tanstack/react-query';

import { Box, CircularProgress, Dialog } from '@mui/material';

import { PayrollTypes, fetchSearchAllPeriodsSummaries } from '@octopus/api';

import { DataFetching } from '../../../../modules/dataFetching';
import { PeriodFormat } from '../../../../modules/types';

import { ApprovalConfirmationModal } from './ApprovalConfirmationModal';
import { PendingPayrollsModal } from './PendingPayrollsModal';

export function ApprovalModal({
  open,
  setOpen,
  organizationId,
  companyId,
  period,
  type,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  period: PeriodFormat;
  type: PayrollTypes;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      {type === 'monthly' ? (
        <MonthlyApprovalModal
          organizationId={organizationId}
          companyId={companyId}
          period={period}
          type={type}
          setOpen={setOpen}
        />
      ) : (
        <NonRegularApprovalModal
          organizationId={organizationId}
          companyId={companyId}
          period={period}
          type={type}
          setOpen={setOpen}
        />
      )}
    </Dialog>
  );
}

function MonthlyApprovalModal({
  organizationId,
  companyId,
  period,
  type,
  setOpen,
}: {
  organizationId: string;
  companyId: string;
  period: PeriodFormat;
  type: PayrollTypes;
  setOpen: (open: boolean) => void;
}) {
  const useHook = () =>
    useQuery({
      queryKey: [organizationId, companyId, period],
      queryFn: () => {
        return fetchSearchAllPeriodsSummaries({
          pathParams: {
            organizationId: organizationId!,
            companyId: companyId!,
          },
          body: {
            sorting: [
              {
                field: 'period',
                order: 'desc',
              },
            ],
            filtering: {
              elements: {
                active: ['true'],
                period: [period],
                type: [
                  'thirteenthFirst',
                  'thirteenthSecond',
                  'termination',
                  'vacations',
                  'advance',
                  'complementary',
                ] as PayrollTypes[],
              },
            },
          },
        });
      },
      enabled: !!organizationId && !!companyId,
    });

  return (
    <DataFetching
      useHook={useHook}
      Data={({ data }) => {
        if (!data) {
          return null;
        }

        const summaries = data.data;

        const pendingSummaries =
          summaries.filter((summary) => summary.status === 'open').length > 0;

        if (pendingSummaries) {
          return (
            <PendingPayrollsModal
              summaries={data.data}
              close={() => setOpen(false)}
            />
          );
        }

        return (
          <ApprovalConfirmationModal
            organizationId={organizationId}
            companyId={companyId}
            period={period}
            type={type}
            close={() => setOpen(false)}
          />
        );
      }}
      Loading={() => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="360px"
          width="600px"
        >
          <CircularProgress />
        </Box>
      )}
    />
  );
}

function NonRegularApprovalModal({
  organizationId,
  companyId,
  period,
  type,
  setOpen,
}: {
  organizationId: string;
  companyId: string;
  period: PeriodFormat;
  type: PayrollTypes;
  setOpen: (open: boolean) => void;
}) {
  return (
    <ApprovalConfirmationModal
      organizationId={organizationId}
      companyId={companyId}
      period={period}
      type={type}
      close={() => setOpen(false)}
    />
  );
}
