import { useEffect, useState } from 'react';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { useSnackbar } from '../modules/hooks/useSnackbar';
import { UserContext } from '../modules/types';
import { getRUMInfo } from '../utils/getRUMInfo';

export function UserInfo({
  organizationId,
  companyId,
  userData,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
  userData: UserContext;
}) {
  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (
        (event.key === 'u' && event.metaKey) ||
        (event.key === 'i' && event.ctrlKey)
      ) {
        setOpen(true);
        event.preventDefault();
      }
    };
    // eslint-disable-next-line no-restricted-globals
    addEventListener('keydown', listener);
    // eslint-disable-next-line no-restricted-globals
    return () => removeEventListener('keydown', listener);
  }, [setOpen]);

  if (!open) {
    return null;
  }

  const rumInfo = getRUMInfo();

  const info: { name: string; value: string }[] = [
    { name: 'OrganizationId', value: organizationId },
    { name: 'CompanyId', value: companyId },
    { name: 'UserId', value: userData.userId },
    ...(userData.personId
      ? [{ name: 'PersonId', value: userData.personId }]
      : []),
    { name: 'Email', value: userData.email },
    { name: 'RUM UserId', value: rumInfo?.userId ?? 'Not found' },
    {
      name: 'RUM SessionId',
      value: rumInfo?.sessionId ?? 'Not found',
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        px: 4,
        py: 5,
      }}
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1.5}>
          {info.map(({ name, value }) => (
            <Info key={name} name={name} value={value} />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => setOpen(false)}>
          Fechar
        </Button>
        <Button
          color="primaryAlt"
          onClick={() => {
            navigator.clipboard.writeText(
              info.map(({ name, value }) => `${name}: ${value}`).join('\n'),
            );
            showSnackbar({
              isOpen: true,
              Message: 'Informações copiados com sucesso!',
              autoHideDuration: 3000,
            });
            setOpen(false);
          }}
        >
          Copiar todos
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Info({ name, value }: { name: string; value: string | undefined }) {
  return (
    <Box display="flex" flexDirection="column" gap={0.5} key={name}>
      <Typography variant="body1" fontWeight="700">
        {name}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
      >
        <Typography variant="body2" fontWeight="400">
          {value}
        </Typography>
        <ContentCopyOutlinedIcon
          sx={{
            cursor: 'pointer',
            color: 'rgba(0, 0, 0, 0.54)',
            '&:active': {
              color: 'rgba(0, 0, 0, 0.87)',
            },
          }}
          onClick={() => navigator.clipboard.writeText(value || '')}
        />
      </Box>
    </Box>
  );
}

export default UserInfo;
