import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { LegalEntityContext } from '../../../modules/types';

import { ApprovalConfirmationModal } from './modals/ApprovalConfirmationModal';
import { CancelConfirmationModal } from './modals/CancelConfirmationModal';
import { CreateRPAsModal } from './modals/CreateRPAsModal';
import { PasteCPFListModal } from './modals/PasteCPFListModal';
import { PeriodApprovalConfirmationModal } from './modals/PeriodApprovalConfirmationModal';
import { SelectLegalEntityModal } from './modals/SelectLegalEntityModal';
import { SelectPeriodModal } from './modals/SelectPeriodModal';

export function ApprovalDialog({
  open,
  setOpen,
  organizationId,
  companyId,
  payrollId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  payrollId: string;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      <ApprovalConfirmationModal
        organizationId={organizationId}
        companyId={companyId}
        payrollId={payrollId}
        close={() => setOpen(false)}
      />
    </Dialog>
  );
}

export function CancelDialog({
  open,
  setOpen,
  organizationId,
  companyId,
  payrollId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  payrollId: string;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="cancel-payroll-modal"
    >
      <CancelConfirmationModal
        organizationId={organizationId}
        companyId={companyId}
        payrollId={payrollId}
        close={() => setOpen(false)}
      />
    </Dialog>
  );
}

export function SelectPeriodDialog({
  open,
  setOpen,
  organizationId,
  companyId,
  mode,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  organizationId: string;
  companyId: string;
  mode?: 'edit' | 'create' | 'approve';
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      {['create', 'edit'].includes(mode) ? (
        <SelectPeriodModal
          organizationId={organizationId}
          companyId={companyId}
          close={() => setOpen(false)}
          isEditing={mode === 'edit'}
        />
      ) : (
        <PeriodApprovalConfirmationModal
          organizationId={organizationId}
          companyId={companyId}
          close={() => setOpen(false)}
        />
      )}
    </Dialog>
  );
}

export function SelectLegalEntityDialog({
  open,
  setOpen,
  selectedLegalEntity,
  setSelectedLegalEntity,
  legalEntities,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedLegalEntity: string;
  setSelectedLegalEntity: (legalEntity: string) => void;
  legalEntities: LegalEntityContext[];
}) {
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <SelectLegalEntityModal
        close={() => setOpen(false)}
        selectedLegalEntity={selectedLegalEntity}
        setSelectedLegalEntity={setSelectedLegalEntity}
        legalEntities={legalEntities}
      />
    </Dialog>
  );
}

export function CreateRPAsDialog({
  open,
  setOpen,
  onClick,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClick: () => void;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
      data-testid="approve-payrolls-modal"
    >
      <CreateRPAsModal onClick={onClick} close={() => setOpen(false)} />
    </Dialog>
  );
}

export function PasteCPFListDialog({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (cpfList: string[]) => void;
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{
        px: 4,
        py: 5,
      }}
      onClose={() => setOpen(false)}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(8px)',
            background: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <PasteCPFListModal onSubmit={onSubmit} close={() => setOpen(false)} />
    </Dialog>
  );
}

export function MissingCPFsDialog({
  open,
  resetMissingCPFs,
  missingCPFs,
}: {
  open: boolean;
  resetMissingCPFs: () => void;
  missingCPFs: string[];
}) {
  if (!open) {
    return null;
  }
  return (
    <Dialog open={true} maxWidth="sm">
      <Box >
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              alignItems: 'flex-start',
              m: 0,
              width: '100%',
            }}
          >
            <Typography>
              CPFs não encontrados:
              <Box
                sx={{
                  backgroundColor: '#F5F5F5',
                  padding: 2,
                  borderRadius: 1,
                  marginTop: 1,
                  cursor: 'text',
                  userSelect: 'text',
                }}
              >
                {missingCPFs.map((cpf) => (
                  <Typography key={cpf}>{cpf}</Typography>
                ))}
              </Box>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primaryAlt"
            variant="contained"
            size="large"
            sx={{
              width: '100%',
              height: '48px',
            }}
            onClick={resetMissingCPFs}
          >
            Fechar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
