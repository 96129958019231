import {
  AdmissionDraftAttachmentType,
  AdmissionDraftInputFormSectionEnum,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftStatus,
  MembershipTypes,
} from '@octopus/api';
import { WorkerCategory, workerCategory } from '@octopus/contract-types';

export const admissionDraftStatuses: {
  [key in AdmissionDraftStatus]: AdmissionDraftStatus;
} = {
  none: 'none',
  draft_created: 'draft_created',
  invite_sent: 'invite_sent',
  user_created: 'user_created',
  admission_partially_filled_admin: 'admission_partially_filled_admin',
  admission_partially_filled_user: 'admission_partially_filled_user',
  admission_submitted: 'admission_submitted',
  admission_approved: 'admission_approved',
  contract_created: 'contract_created',
  archived: 'archived',
} as const;

export const finalStatuses = new Set([
  admissionDraftStatuses.admission_approved,
  admissionDraftStatuses.contract_created,
  admissionDraftStatuses.archived,
]);

export const adminOnlyStatuses = new Set([
  admissionDraftStatuses.none,
  admissionDraftStatuses.draft_created,
  admissionDraftStatuses.admission_partially_filled_admin,
  admissionDraftStatuses.admission_submitted,
]);

export const workerOnlyStatuses = new Set([
  admissionDraftStatuses.invite_sent,
  admissionDraftStatuses.user_created,
  admissionDraftStatuses.admission_partially_filled_user,
]);

export const admissionFilledStatuses = new Set([
  admissionDraftStatuses.admission_submitted,
  admissionDraftStatuses.admission_approved,
  admissionDraftStatuses.contract_created,
  admissionDraftStatuses.archived,
]);

const defaultOptionalSteps: AdmissionDraftInputFormStepEnum[] = [
  'finaliza_admissao',
];
export const optionalSteps: Record<
  WorkerCategory,
  Set<AdmissionDraftInputFormStepEnum>
> = {
  [workerCategory.cltJovemAprendiz]: new Set([
    ...defaultOptionalSteps,
    'jovem_aprendiz_info',
  ]),
  [workerCategory.cltGeral]: new Set([...defaultOptionalSteps]),
  [workerCategory.cltAutonomo]: new Set([...defaultOptionalSteps]),
  [workerCategory.cltTsv]: new Set([...defaultOptionalSteps]),
  [workerCategory.cltEstagiario]: new Set([...defaultOptionalSteps]),
  [workerCategory.pj]: new Set([...defaultOptionalSteps]),
};

export const admissionDraftFormSections: {
  [key in AdmissionDraftInputFormSectionEnum]: AdmissionDraftInputFormSectionEnum;
} = {
  payroll_data: 'payroll_data',
  personal_data: 'personal_data',
};

export const admissionDraftFormSteps: {
  [key in AdmissionDraftInputFormStepEnum]: AdmissionDraftInputFormStepEnum;
} = {
  modalidade_contrato: 'modalidade_contrato',
  criacao_usuario: 'criacao_usuario',
  profissional: 'profissional',
  regime_trabalho: 'regime_trabalho',
  jornada: 'jornada',
  duracao: 'duracao',
  sindicato: 'sindicato',
  remuneracao: 'remuneracao',
  envio_convite: 'envio_convite',
  dados_pessoais: 'dados_pessoais',
  formacao_academica: 'formacao_academica',
  endereco_e_contatos: 'endereco_e_contatos',
  deficiencias: 'deficiencias',
  dependentes: 'dependentes',
  dados_bancarios: 'dados_bancarios',
  documentos: 'documentos',
  finaliza_admissao: 'finaliza_admissao',
  estagio_info: 'estagio_info',
  pj_info: 'pj_info',
  jovem_aprendiz_info: 'jovem_aprendiz_info',
  dados_empresa: 'dados_empresa',
  custom_fields_admin: 'custom_fields_admin',
  custom_fields_worker: 'custom_fields_worker',
  custom_fields: 'custom_fields', //deprecated
  foto_perfil: 'foto_perfil',
} as const;

export const initialSteps = new Set([
  admissionDraftFormSteps.modalidade_contrato,
  admissionDraftFormSteps.criacao_usuario,
  admissionDraftFormSteps.profissional,
]);

export const customSteps = new Set([
  admissionDraftFormSteps.envio_convite,
  admissionDraftFormSteps.finaliza_admissao,
]);

export const adminSteps = new Set([
  admissionDraftFormSteps.modalidade_contrato,
  admissionDraftFormSteps.criacao_usuario,
  admissionDraftFormSteps.profissional,
  admissionDraftFormSteps.regime_trabalho,
  admissionDraftFormSteps.duracao,
  admissionDraftFormSteps.jornada,
  admissionDraftFormSteps.sindicato,
  admissionDraftFormSteps.remuneracao,
  admissionDraftFormSteps.envio_convite,
  admissionDraftFormSteps.foto_perfil,
  admissionDraftFormSteps.finaliza_admissao,
  admissionDraftFormSteps.estagio_info,
  admissionDraftFormSteps.pj_info,
  admissionDraftFormSteps.jovem_aprendiz_info,
  admissionDraftFormSteps.custom_fields_admin,
]);

export const workerSteps = new Set([
  admissionDraftFormSteps.dados_pessoais,
  admissionDraftFormSteps.formacao_academica,
  admissionDraftFormSteps.dados_empresa,
  admissionDraftFormSteps.endereco_e_contatos,
  admissionDraftFormSteps.deficiencias,
  admissionDraftFormSteps.dependentes,
  admissionDraftFormSteps.dados_bancarios,
  admissionDraftFormSteps.foto_perfil,
  admissionDraftFormSteps.documentos,
  admissionDraftFormSteps.custom_fields_worker,
  admissionDraftFormSteps.finaliza_admissao,
]);

export const membershipTypes: {
  [key in MembershipTypes]: MembershipTypes;
} = {
  external: 'external',
  internal: 'internal',
  owner: 'owner',
  'tako:support': 'tako:support',
} as const;

export const admissionDraftAttachmentTypes: {
  [key in AdmissionDraftAttachmentType]: string;
} = {
  rg: 'RG',
  cnh: 'CNH',
  cpf: 'CPF',
  titulo_eleitor: 'Título de eleitor',
  certidao_nascimento: 'Certidão de nascimento',
  certidao_casamento: 'Certidão de casamento',
  certificado_formacao: 'Certificado de formação',
  passaporte: 'Passaporte',
  comprovante_residencia: 'Comprovante de residência',
  cartao_cnpj: 'Cartão CNPJ',
  contrato_social: 'Contrato Social - Inteiro Teor',
  outro: '',
} as const;

export const dependentAttachmentTypes: AdmissionDraftAttachmentType[] = [
  'rg',
  'cnh',
  'cpf',
  'titulo_eleitor',
  'certidao_nascimento',
  'certidao_casamento',
  'passaporte',
];

export const personalAttachmentTypes: AdmissionDraftAttachmentType[] = [
  'rg',
  'cnh',
  'cpf',
  'titulo_eleitor',
  'certidao_nascimento',
  'certidao_casamento',
  'passaporte',
];

export const grauInstrpersonalAttachmentTypes: AdmissionDraftAttachmentType[] =
  ['certificado_formacao'];

export const addressAttachmentTypes: AdmissionDraftAttachmentType[] = [
  'comprovante_residencia',
];

export const admissionResourceType = 'admission';

export type AdmissionNotificationContext = {
  resourceId: string;
  resourceType: string;
  organizationId: string;
  draftId: string;
  draftStatus: string;
  admissionDate: string;
  admissionType: string;
  bankingDaysWarningLimitDateWorker: string;
  bankingDaysWarningLimitDateAdmin: string;
  workerName: string;
  workerId: string;
  companyName: string;
  admissionSubmittedByEmployee: string;
  admissionFilled: string;
};
