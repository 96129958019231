import { GridApi } from 'ag-grid-community';

import { Box, Divider, Popper, Skeleton, Typography } from '@mui/material';

import {
  ContractBRCltEntry,
  useGetContract,
  useGetJobTitleEntry,
} from '@octopus/api';
import { formatDateTimeBR } from '@octopus/formatters';

import UserAvatar from '../../../../modules/components/UserAvatar';
import { RowInfo } from '../../[period]/[type]/inputs/types';
import { InputsEdit } from '../../[period]/[type]/inputs/useSubmissionState';

export type RowInfoPopoverProps = {
  organizationId: string;
  companyId: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  info: RowInfo | null;
  api: GridApi<unknown> | null;
  edit: InputsEdit;
  handleClose: () => void;
};

export function RowInfoPopper({
  organizationId,
  open,
  anchorEl,
  info,
  handleClose,
  api,
  edit,
}: RowInfoPopoverProps) {
  if (anchorEl === null || info === null) {
    return null;
  }
  return (
    <Popper
      id="RowInfoPopover"
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [-8, 8],
          },
        },
      ]}
    >
      <Box
        minWidth="376px"
        display="flex"
        flexDirection="column"
        bgcolor="background.paper"
        p={1.5}
        border="1px solid rgba(0, 0, 0, 0.1)"
        borderRadius={1}
        overflow="hidden"
      >
        <RowInfoHeader
          organizationId={organizationId}
          contractId={info.contractId}
          personId={info.personId}
          name={info.name}
          employeeId={info.employeeId}
        />
        <Box p={0.5}>
          <Divider flexItem />
        </Box>
        <DeleteRowMenuItem
          deleteRow={() => {
            api.clearCellSelection();
            api.setFocusedCell(info.rowIndex, api.getColumns()[0], null);
            edit.removeEmployee(info.contractId);
            api.addCellRange({
              rowStartIndex: info.rowIndex,
              rowEndIndex: info.rowIndex,
              columns: api.getColumns(),
            });
          }}
          handleClose={handleClose}
        />
      </Box>
    </Popper>
  );
}

type RowInfoHeaderProps = {
  organizationId: string;
  contractId: string;
  personId: string;
  name: string;
  employeeId: string;
};

function RowInfoHeader({
  organizationId,
  contractId,
  name,
  employeeId,
}: RowInfoHeaderProps) {
  const { data: contractData } = useGetContract({
    pathParams: { organizationId, contractId },
  });
  const cltContract = contractData?.br as ContractBRCltEntry;
  const { data: jobTitleData, isLoading } = useGetJobTitleEntry(
    {
      pathParams: {
        organizationId,
        jobTitleId: cltContract?.trabalho?.jobTitleId,
      },
    },
    { enabled: !!cltContract?.trabalho?.jobTitleId },
  );
  return (
    <Box display="flex" flexDirection="column" px={1} pt={1} pb={2} gap={0.75}>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <UserAvatar
          size={40}
          pictureUrl={contractData?.profilePicture?.pictureUrl}
          name={name}
          showFullName={false}
          avatarProps={{ mr: 0, ml: 0, my: 0 }}
          avatarTextProps={{ fontSize: '16px', fontWeight: '700' }}
        />
        <Box display="flex" alignItems="center" gap={1.5}>
          <Typography variant="caption" color="text.secondary">
            #{employeeId}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="h4" fontWeight="700">
          {name}
        </Typography>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Box display="flex" gap={1} alignItems="center">
            {isLoading || !jobTitleData ? (
              <Skeleton variant="rectangular" width="90px" height="16px" />
            ) : (
              <Typography variant="caption" fontWeight="500">
                {jobTitleData.name}
              </Typography>
            )}
            <Divider
              variant="middle"
              orientation="vertical"
              flexItem
              sx={{ my: 0.25 }}
            />
            {isLoading || !contractData ? (
              <Skeleton variant="rectangular" width="90px" height="16px" />
            ) : (
              <Typography variant="caption" fontWeight="500">
                {cltContract.trabalho.departamento}
              </Typography>
            )}
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            {isLoading || !contractData ? (
              <Skeleton variant="rectangular" width="144px" height="16px" />
            ) : (
              <Typography
                variant="caption"
                color="text.secondary"
                fontWeight="500"
              >
                Inicio em {formatDateTimeBR(cltContract.regime.dtAdm)}
              </Typography>
            )}

            <Divider
              variant="middle"
              orientation="vertical"
              flexItem
              sx={{ my: 0.25 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export type DeleteRowMenuItemProps = {
  deleteRow: () => void;
  handleClose: () => void;
};

export function DeleteRowMenuItem({
  deleteRow,
  handleClose,
}: DeleteRowMenuItemProps) {
  return (
    <Box
      px={1.5}
      py={1}
      borderRadius={1}
      display="flex"
      flexDirection="column"
      bgcolor="background.paper"
      onClick={() => {
        handleClose();
        deleteRow();
      }}
      sx={{
        '&:hover': {
          bgcolor: 'background.default',
        },
        '&:active': {
          bgcolor: 'strokes.heavy',
        },
        cursor: 'pointer',
      }}
    >
      <Typography variant="body2" fontWeight="500" color="error">
        Remover da lista
      </Typography>
    </Box>
  );
}
