import { cnpj } from 'cpf-cnpj-validator';
import { z } from 'zod';

const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

export const cnpjSchema = z
  .string()
  .regex(cnpjRegex)
  .refine(
    (val) => {
      try {
        return cnpj.isValid(val);
      } catch (_) {
        return false;
      }
    },
    {
      params: {
        i18n: { key: 'form_invalid_cnpj' },
      },
    },
  );

export const optionalCnpjSchema = z.string().regex(cnpjRegex).optional();
