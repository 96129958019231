import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import { WorkerCategory } from '@octopus/contract-types';
import {
  ContratoRegimeParcial,
  TipoJornada,
  TipoRegimeJornada,
} from '@octopus/esocial/mapper';
import {
  IFormDefinition,
  NEW_UI_TYPE,
  UI_TYPE,
  nonEmptyString,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { getOptionsFromMapper, toCheckboxValue } from '../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../types';

const getFieldDefinition = ({
  formState,
  hideJornadaFields,
  workerCategory,
}: {
  formState: AdmissionFormState;
  hideJornadaFields: boolean;
  workerCategory: WorkerCategory;
}): IFormDefinition => {
  const { tpRegJor, tpJornada, horNoturno, qtdHrsSem, tmpParc, descJorn } =
    formState;

  return [
    hideJornadaFields
      ? undefined
      : {
          label: 'Regime jornada',
          type: z.number().int(),
          name: 'tpRegJor',
          uiType: UI_TYPE.SELECT,
          placeholder: 'Selecione o regime jornada',
          options: getOptionsFromMapper({
            mapper: TipoRegimeJornada,
            currentValue: tpRegJor,
            enabledKeys:
              workerCategory === 'clt:jovemAprendiz'
                ? new Set(['1', '2'])
                : undefined,
          }),
          hint: {
            title: 'Tipo de jornada de trabalho',
            body: 'Definir o tipo de jornada é importante para a organização do horário de trabalho, cálculo de horas extras e benefícios.',
          },
        },
    {
      label: 'Tipo de jornada',
      type: z.number().int(),
      name: 'tpJornada',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione o tipo de jornada',
      options: getOptionsFromMapper({
        mapper: TipoJornada,
        currentValue: tpJornada,
      }),
      hint: {
        title: 'Detalhe sobre a jornada de trabalho',
        body: 'Especifica a natureza da jornada de trabalho, ajudando na gestão de horários e escalas de trabalho.',
      },
    },
    hideJornadaFields || workerCategory === 'clt:jovemAprendiz'
      ? undefined
      : {
          label: 'Regime Parcial do Contrato',
          type: z.number().int(),
          name: 'tmpParc',
          uiType: UI_TYPE.SELECT,
          options: getOptionsFromMapper({
            mapper: ContratoRegimeParcial,
            currentValue: tmpParc,
          }),
        },
    {
      name: 'horasFieldset',
      label: '-',
      uiType: UI_TYPE.FIELDSET,
      fieldsetInline: true,
      fields: [
        {
          label: 'Jornada em horas semanais',
          type: z.number().min(1).max(44),
          name: 'qtdHrsSem',
          uiType: UI_TYPE.TEXT_NUMBER,
          placeholder: 'Ex: 44',
          value: qtdHrsSem,
        },
        hideJornadaFields || workerCategory === 'clt:jovemAprendiz'
          ? undefined
          : {
              label: 'Hora noturna',
              type: z.boolean().optional(),
              uiType: NEW_UI_TYPE.CHECKBOX,
              value: toCheckboxValue(horNoturno),
              name: 'horNoturno',
            },
      ].filter((item) => item),
    },
    {
      label: 'Descrição da Jornada',
      type: nonEmptyString,
      name: 'descJorn',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: Jornada padrão da empresa',
      value: descJorn,
    },
  ].filter((item) => item);
};

export const getJornadaFields = ({
  formState,
  jornadaFormSteps,
  hideJornadaFields = false,
  workerCategory,
}: {
  formState: AdmissionFormState;
  jornadaFormSteps: AdmissionDraftInputFormStep;
  hideJornadaFields?: boolean;
  workerCategory?: WorkerCategory;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    formState,
    hideJornadaFields,
    workerCategory,
  }),
  options: {
    id: admissionDraftFormSteps.jornada,
    title: 'Jornada de trabalho',
    completed: jornadaFormSteps?.completed,
    reviewed: jornadaFormSteps?.reviewed,
  },
});
