import { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { useQueries } from '@tanstack/react-query';
import { merge } from 'lodash';

import { Box, Skeleton, Tab, Tabs } from '@mui/material';

import {
  VacationsAccrualPeriodList,
  VacationsScheduleList,
  fetchSearchAllScheduledVacations,
  fetchSearchManagedByVacationsAccrualPeriods,
  fetchSearchManagedByVacationsSchedules,
  fetchSearchVacationsAccrualPeriods,
} from '@octopus/api';
import { DataGrid, DataGridToolbar, useDataGrid } from '@octopus/ui/data-grid';

import TabLabel from '../../modules/components/TabLabel';
import { DataFetching, FetchResult } from '../../modules/dataFetching';
import { prepareDataGridSearchInput } from '../../utils';

import { useFilters } from './components/table/hooks/useFilters';
import {
  getColumnsByTab,
  getVacationsTabsConfig,
} from './components/table/Tabs';
import { addStatus } from './utils/data';
import { VacationsTableTabs, VacationsTabs } from './utils/types';
import { VacationsDrawerDetails } from './VacationsDetails';

export default function VacationsTable({
  organizationId,
  contractId,
  isFFEnabled,
}: {
  organizationId: string | undefined;
  contractId: string | undefined;
  isFFEnabled: boolean;
}) {
  const [params] = useSearchParams();
  const { role } = useParams();
  const initialTab = params.get('tab');
  const [vacationsStatus, setVacationsStatus] = useState<{
    [key: string]: number;
  }>({});
  const [tab, setTab] = useState<keyof VacationsTableTabs>(
    (initialTab || VacationsTabs.people) as keyof VacationsTableTabs,
  );
  const isManager = role === 'manager';
  const isInPeopleTab = tab === VacationsTabs.people;

  const filters = useFilters(tab as keyof VacationsTableTabs);
  const dataGridProps = useDataGrid({ filters });
  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;
  const searchInput = prepareDataGridSearchInput(dataGridProps);
  const searchStatus = addStatus(tab as keyof VacationsTableTabs);

  const queryKey = (q: string) => [
    q,
    organizationId,
    searchInput,
    paginationProps,
    tab,
    role,
  ];

  const queryParams = {
    enabled:
      isManager && isFFEnabled
        ? !!organizationId && !!contractId
        : !!organizationId,
  };

  const searchParams = (searchInput: any) => ({
    pathParams: {
      organizationId: organizationId ?? '',
      contractId: contractId ?? '',
    },
    body: searchInput,
  });

  const fetchSearchAccrualPeriods =
    isFFEnabled && isManager
      ? fetchSearchManagedByVacationsAccrualPeriods
      : fetchSearchVacationsAccrualPeriods;

  const fetchSearchAllScheduled =
    isFFEnabled && isManager
      ? fetchSearchManagedByVacationsSchedules
      : fetchSearchAllScheduledVacations;

  const searchVacationsAccrualPeriods = () =>
    fetchSearchAccrualPeriods(
      searchParams(
        isInPeopleTab
          ? {
              ...searchInput,
              sorting: [
                ...searchInput.sorting,
                ...accrualPeriodDefaultSorting.filter(
                  (field) => field.field !== sortingProps.field,
                ),
              ],
            }
          : {},
      ),
    );

  const searchAllScheduledVacations = () =>
    fetchSearchAllScheduled(
      searchParams({
        ...(isInPeopleTab ? {} : searchInput),
        ...(searchInput?.filtering?.elements?.status?.length > 0
          ? {}
          : merge(searchStatus, {
              ...searchInput,
              sorting: [
                ...searchInput.sorting,
                ...schedulesDefaultSortingByTab(tab).filter(
                  (field) => field.field !== sortingProps.field,
                ),
              ],
            })),
      }),
    );

  const results = useQueries({
    queries: [
      {
        queryKey: queryKey('searchVacationsAccrualPeriods'),
        queryFn: searchVacationsAccrualPeriods,
        ...queryParams,
      },
      {
        queryKey: queryKey('searchAllScheduledVacations'),
        queryFn: searchAllScheduledVacations,
        ...queryParams,
      },
    ],
  });

  const [vacationsAccrualPeriods, allScheduledVacations] = results;
  const refetchAll = async () => {
    await Promise.all([
      allScheduledVacations.refetch(),
      vacationsAccrualPeriods.refetch(),
    ]);
  };

  const columnsByTab = getColumnsByTab(isManager, refetchAll);
  const vacationsTabsConfig = getVacationsTabsConfig(isManager);

  const switchTab = (tab: string) => {
    sortingProps.setField();
    sortingProps.setOrder();
    paginationProps.handleChangePage(null, 0);
    dataGridProps.selectionProps.clear();
    filteringProps.clearFilters();
    setTab(tab as keyof typeof vacationsTabsConfig);
  };

  const { waitingApproval, payrollCreated } = vacationsStatus || {};

  useEffect(() => {
    const { status } =
      allScheduledVacations?.data?.metadata?.buckets?.counters?.byProp || {};
    const { isLoading, isFetching, isRefetching } = allScheduledVacations;
    if (!isLoading && !isFetching && !isRefetching) {
      setVacationsStatus(status);
    }
  }, [allScheduledVacations]);

  const getTabCount = ({ key }: { key: string }) => {
    switch (key) {
      case VacationsTabs.requests:
        return waitingApproval;
      case VacationsTabs.calculations:
        return payrollCreated;
      default:
        return undefined;
    }
  };

  const [selectedPerson, setSelectedPerson] = useState<any>();
  const [open, setOpen] = useState(false);

  const onRowClick = (params: any) => {
    setOpen(true);
    setSelectedPerson(params.row);
  };

  const onClose = () => {
    setOpen(false);
  };

  const controls = {
    onDismiss: onClose,
    hasNext: false,
    onNext: () => null as any,
    hasPrevious: false,
    onPrevious: () => null as any,
  };

  return (
    <>
      {/* TODO: Redirect when user is not owner or manager */}
      {(!isFFEnabled || !isManager) && <Navigate to={'/vacations/admin'} />}
      <VacationsDrawerDetails
        open={open}
        onClose={onClose}
        onUpdate={refetchAll}
        row={selectedPerson}
        detailsTab={vacationsTabsConfig[tab]?.detailsTab}
        isManager={isManager}
        controls={controls}
      />
      <Box
        paddingBottom={1.5}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box alignSelf="stretch" width={'100%'}>
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            // totals={totals}
            typeOfResultLabel={'resultados'}
          />
        </Box>
      </Box>
      <Box
        my={2}
        sx={(theme) => ({
          boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
        })}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tabs
          value={tab}
          onChange={(_, newTab) => switchTab(newTab)}
          textColor="inherit"
          data-testid="vacations-status-tabs"
          TabIndicatorProps={{
            sx: {
              bgcolor: 'primary.main',
            },
          }}
        >
          {Object.entries(vacationsTabsConfig).map(([key, tabConfig]) => (
            <Tab
              key={key}
              value={key}
              icon={
                <TabLabel
                  isSelected={key === tab}
                  count={getTabCount({ key })}
                  color="primary"
                  label={tabConfig.label}
                />
              }
            />
          ))}
        </Tabs>
      </Box>
      <DataFetching
        fetchResult={
          (isInPeopleTab
            ? vacationsAccrualPeriods
            : allScheduledVacations) as FetchResult<
            VacationsScheduleList | VacationsAccrualPeriodList
          >
        }
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          return (
            <Box mt={2}>
              {data ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={data.total || 0}
                  getRowId={(row) => row.id}
                  rows={data.data}
                  onRowClick={(params) => onRowClick(params)}
                  columns={columnsByTab[tab as keyof VacationsTableTabs]}
                  emptyMessage={
                    vacationsTabsConfig[tab as keyof VacationsTableTabs]
                      .emptyMessage
                  }
                />
              ) : null}
            </Box>
          );
        }}
      />
    </>
  );
}

const accrualPeriodDefaultSorting = [
  {
    field: 'limitDateToStartVacations',
    order: 'asc',
  },
  {
    field: 'totalDaysAvailable',
    order: 'desc',
  },
  {
    field: 'workerData.name',
    order: 'asc',
  },
];

function schedulesDefaultSortingByTab(
  tab: VacationsTabs,
): { field: string; order: string }[] {
  switch (tab) {
    case VacationsTabs.people:
      return [];
    case VacationsTabs.vacationsHistory:
      return [
        {
          field: 'startDate',
          order: 'desc',
        },
        {
          field: 'workerData.name',
          order: 'asc',
        },
      ];
    case VacationsTabs.calculations:
      return [
        {
          field: 'statusIndex',
          order: 'asc',
        },
        {
          field: 'approvalDeadline',
          order: 'asc',
        },
        {
          field: 'startDate',
          order: 'desc',
        },
        {
          field: 'workerData.name',
          order: 'asc',
        },
      ];
    case VacationsTabs.requests:
      return [
        {
          field: 'statusIndex',
          order: 'asc',
        },
        {
          field: 'approvalDeadline',
          order: 'asc',
        },
        {
          field: 'startDate',
          order: 'asc',
        },
        {
          field: 'workerData.name',
          order: 'asc',
        },
      ];
    default:
      return [];
  }
}
