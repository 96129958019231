import { useRef } from 'react';

import { Check } from '@mui/icons-material';
import { Box, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { NEW_UI_TYPE } from '@octopus/libs/forms';

import { TFieldInputRenderProps, TFieldRenderProps } from '../parseField/types';

type FieldCheckboxProps = {
  field: TFieldRenderProps & TFieldInputRenderProps;
  sx?: SxProps<Theme>;
};

const commonCardSxProps: SxProps<Theme> = {
  display: 'inline-flex',
  boxSizing: 'border-box',
  gap: 1,
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
};

const commonCheckboxProps: SxProps<Theme> = {
  width: '18px',
  height: '18px',
  flex: '0 0 auto',
  margin: 0.375,
  boxSizing: 'border-box',
  border: '1.5px solid',
  borderColor: (theme) => theme.palette.strokes.heavy,
  borderRadius: '4px',
};

const LabelElement = ({
  field,
}: {
  field: TFieldRenderProps & TFieldInputRenderProps;
}) => <Typography variant="body2">{field.label.textContent}</Typography>;
export function FieldCheckbox(props: FieldCheckboxProps) {
  const { field } = props;

  const fieldRef = useRef(null);

  return (
    <Box
      {...field.props}
      tabIndex={0}
      sx={{
        flex: '1 0 auto',
      }}
    >
      {/** Please do not remve the following Box.
       * It's being used to align the checkbox inside a fieldset
       * #euNaoMeOrgulhoDisso */}
      <Box height={24}></Box>

      <Box
        component="input"
        {...field.input.props}
        defaultChecked={field.input.props.defaultChecked}
        ref={fieldRef}
        sx={{
          display: 'none',
          [`
            &:checked ~ [data-label-unchecked],
            &:not(:checked) ~ [data-label-checked]
          `]: {
            display: 'none',
          },
        }}
      />
      <Box
        component="label"
        sx={{
          ...commonCardSxProps,
        }}
        {...field.label.props}
        data-label-checked
      >
        <Box
          component="span"
          role="presentation"
          sx={{
            ...commonCheckboxProps,
            backgroundColor: (theme) => theme.palette.primary.main,
            borderColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Check
            sx={{
              fill: '#fff',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </Box>
        <LabelElement field={field} />
      </Box>
      <Box
        component="label"
        sx={{
          ...commonCardSxProps,
        }}
        {...field.label.props}
        data-label-unchecked
      >
        <Box
          component="span"
          role="presentation"
          sx={{
            ...commonCheckboxProps,
          }}
        ></Box>
        <LabelElement field={field} />
      </Box>
    </Box>
  );
}

FieldCheckbox.uiType = NEW_UI_TYPE.CHECKBOX;
FieldCheckbox.canRender = (
  field: TFieldRenderProps,
): field is FieldCheckboxProps['field'] => {
  return 'input' in field && field.input.props.type === 'checkbox';
};
