import { useRef } from 'react';

import { CustomHeaderProps } from 'ag-grid-react';

import { Box, Typography } from '@mui/material';

export type InputCellHeaderProps = CustomHeaderProps & {
  tag: 'earnings' | 'deductions' | 'informative' | 'events';
  showColumnInfo: (element: HTMLElement) => void;
};

export function InputCellHeader({
  displayName,
  showColumnInfo,
  tag,
}: InputCellHeaderProps) {
  const showRef = useRef<boolean>(false);
  const headerRef = useRef<HTMLElement>(null);
  const color = (() => {
    switch (tag) {
      case 'earnings':
        return 'strokes.success';
      case 'deductions':
        return 'strokes.error';
      case 'informative':
        return 'strokes.info';
      case 'events':
        return 'strokes.warning';
      default:
        return undefined;
    }
  })();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="100%"
      gap={1}
      onMouseEnter={() => {
        showRef.current = true;
        setTimeout(() => {
          if (showRef.current) {
            showColumnInfo(headerRef.current);
          }
        }, 300);
      }}
      onMouseLeave={() => {
        showRef.current = false;
      }}
      ref={headerRef}
    >
      <Typography
        variant="caption"
        fontWeight="700"
        maxHeight="2rem"
        maxWidth="calc(100% - 16px)"
        sx={{
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {displayName}
      </Typography>
      {color && (
        <Box minWidth="8px" minHeight="8px" borderRadius={5} bgcolor={color} />
      )}
    </Box>
  );
}
