import React from 'react';

import { Box, Skeleton } from '@mui/material';

type Props = {
  columnsCount: number;
  rowsCount: number;
};

export function DataGridSkeleton({ columnsCount, rowsCount }: Props) {
  const rows = new Array(rowsCount);
  for (let i = 0; i < rowsCount; i++) {
    rows.push(<RowSkeleton columnsCount={columnsCount} />);
  }
  console.log({ rows });
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="flex-start"
      width="100%"
      boxSizing="border-box"
      gap={1}
      p={2}
    >
      {rows}
    </Box>
  );
}

function RowSkeleton({ columnsCount }: { columnsCount: number }) {
  const columns = new Array(columnsCount);
  console.log({ columns });
  for (let i = 0; i < columnsCount; i++) {
    const width = i === 0 ? '50px' : '250px';
    columns.push(<Skeleton variant="rounded" height={40} width={width} />);
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      height="100%"
      width="100%"
      boxSizing="border-box"
      gap={2}
      p={1}
    >
      {columns}
    </Box>
  );
}
