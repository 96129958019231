import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';

import { FilterOption } from '../filtering';

export function makeElementListFilter({
  label,
  icon,
  propertyToFilter,
  elements,
  labels,
  disableSearch,
  disableSelectAll,
  sortElements,
  isNested = false,
}: {
  label: string;
  icon?: React.ReactNode;
  propertyToFilter: string;
  elements: string[];
  labels?: Record<string, string>;
  disableSearch?: boolean;
  disableSelectAll?: boolean;
  sortElements?: boolean;
  isNested?: boolean;
}): FilterOption {
  return {
    id: propertyToFilter,
    label,
    icon,
    elements,
    filterFn: () => true,
    component: (props) => {
      const getFilteredElements = () => {
        return isNested
          ? props.filterState.nestedFilters[propertyToFilter] || []
          : props.filterState.elementFilters[propertyToFilter] || [];
      };
      const changeFilteredElements = (
        updateElements: (currentElements: string[]) => string[],
      ) => {
        props.changeFilterState(
          isNested
            ? {
                nestedFilters: {
                  [propertyToFilter]: updateElements(getFilteredElements()),
                },
              }
            : {
                elementFilters: {
                  [propertyToFilter]: updateElements(getFilteredElements()),
                },
              },
        );
      };
      return (
        <ElementListFilter
          getFilteredElements={getFilteredElements}
          changeFilteredElements={changeFilteredElements}
          elements={elements}
          labels={labels}
          disableSearch={disableSearch}
          disableSelectAll={disableSelectAll}
          sortElements={sortElements}
          data-testid="element-list-filter"
        />
      );
    },
    preview: (filterState) => {
      const selected = isNested
        ? filterState.nestedFilters[propertyToFilter]?.length || 0
        : filterState.elementFilters[propertyToFilter]?.length || 0;
      if (selected === 0 || selected === elements.length) {
        return 'Todos';
      }

      if (selected === 1) {
        const element = isNested
          ? filterState.nestedFilters[propertyToFilter]![0]!
          : filterState.elementFilters[propertyToFilter]![0]!;
        return labels?.[element] || element;
      }

      return `${selected} selecionados`;
    },

    canInvertSelection: true,
    getInvertSelectionLabel: (invertSelection) =>
      invertSelection ? 'não contém' : 'contém',
  };
}

function ElementListFilter({
  getFilteredElements,
  changeFilteredElements,
  elements,
  labels,
  disableSearch = false,
  disableSelectAll = false,
  sortElements = true,
}: {
  getFilteredElements: () => string[];
  changeFilteredElements: (
    updateState: (currentState: string[]) => string[],
  ) => void;
  elements: string[];
  labels?: Record<string, string>;
  disableSearch?: boolean;
  disableSelectAll?: boolean;
  sortElements?: boolean;
}) {
  const [search, setSearch] = React.useState('');

  const sortedElements = React.useMemo(() => {
    if (sortElements) {
      return elements.sort((a, b) => {
        const elementA = labels?.[a] || a;
        const elementB = labels?.[b] || b;
        return elementA.localeCompare(elementB);
      });
    }
    return elements;
  }, [elements, labels, sortElements]);

  const prepareElementList = () => {
    const filteredElements = sortedElements.filter((element) => {
      if (search.length > 0) {
        return (labels?.[element] || element)
          .toLowerCase()
          .startsWith(search.toLowerCase());
      }
      return true;
    });
    if (filteredElements.length === 0) {
      return (
        <ListItem sx={{ height: '40px', paddingBottom: '0px' }}>
          <ListItemText
            primary="Nenhum resultado encontrado"
            primaryTypographyProps={{
              variant: 'body2',
              color: '#BABABF',
            }}
          />
        </ListItem>
      );
    }
    return filteredElements.map((element) => (
      <ListItem key={element} sx={{ height: '40px', p: 0 }}>
        <Checkbox
          checked={getFilteredElements()?.includes(element) ?? false}
          onChange={(e) => {
            if (e.target.checked) {
              changeFilteredElements((currentState) => [
                ...currentState,
                element,
              ]);
            } else {
              changeFilteredElements((currentState) =>
                currentState.filter((item) => item !== element),
              );
            }
          }}
        />
        <ListItemText
          primary={labels?.[element] || element}
          primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }}
        />
      </ListItem>
    ));
  };

  return (
    <Box
      width="276px"
      maxHeight="50vh"
      display="flex"
      flexDirection="column"
      gap={1}
    >
      {!disableSearch && (
        <TextField
          sx={{
            width: '100%',
          }}
          placeholder="Procurar"
          InputProps={{
            startAdornment: <SearchIcon sx={{ fontSize: '16px' }} />,
            endAdornment: search.length > 0 && (
              <CancelIcon
                color="disabled"
                sx={{ fontSize: '16px', cursor: 'pointer' }}
                onClick={() => setSearch('')}
              />
            ),
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
        />
      )}
      {!disableSelectAll && search.length === 0 && (
        <>
          <Box
            mx={0.5}
            my={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Typography
              variant="body2"
              fontWeight="bold"
              color={
                getFilteredElements().length < elements.length
                  ? 'primary.main'
                  : '#BABABF'
              }
              onClick={() => {
                changeFilteredElements(() => elements);
              }}
              sx={{ cursor: 'pointer' }}
            >
              Selecionar todos
            </Typography>
            <CircleIcon
              color="disabled"
              sx={{ width: '4px', height: '4px', mx: 2 }}
            />
            <Typography
              variant="body2"
              fontWeight="bold"
              color={getFilteredElements().length ? 'primary.main' : '#BABABF'}
              onClick={() => {
                changeFilteredElements(() => []);
              }}
              sx={{ cursor: 'pointer' }}
            >
              Limpar
            </Typography>
          </Box>
          <Divider />
        </>
      )}
      <List sx={{ pt: 0, pb: 0.1, maxHeight: '100%', overflow: 'auto' }}>
        {prepareElementList()}
      </List>
    </Box>
  );
}
