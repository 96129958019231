import { IconHourglassHigh } from '@tabler/icons-react';

import { Check, CloseOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress } from '@mui/material';

import { VacationsScheduleSummary } from '@octopus/api';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { usePopover } from '../../hooks/usePopover';
import {
  useGetRequestedRoles,
  useVacationsSchedule,
} from '../../hooks/useVacationsApprove';

export const VacationsScheduleActions = (props: {
  row: VacationsScheduleSummary;
  isManager: boolean;
  onApproval: () => Promise<void>;
}) => {
  const { isManager, onApproval } = props;
  const { row } = props;
  const {
    RejectScheduleModal,
    approveVacation,
    isSubmitting,
    openRejectModal,
  } = useVacationsSchedule(row, onApproval, isManager);
  const { shouldShowApprovalButton, isFinished } = useGetRequestedRoles(
    row,
    isManager,
  );
  const [WithPopover, handlePopoverOpen, handlePopoverClose] = usePopover();
  const [WithPopoverReject, handlePopoverOpenReject, handlePopoverCloseReject] =
    usePopover();
  const isApproved = row.status === vacationsScheduleStatuses.approved;

  if (isApproved || isFinished) {
    return null;
  }

  if (isSubmitting) {
    return <CircularProgress size={16} sx={{ ml: 3, color: 'currentColor' }} />;
  }

  if (!shouldShowApprovalButton) {
    return (
      <Box display="flex" ml={2} gap={1}>
        <IconHourglassHigh
          style={{
            opacity: 0.1,
            transition: 'opacity 0.3s',
          }}
          onMouseEnter={(e) => {
            handlePopoverOpen(e);
            e.currentTarget.style.opacity = '1';
          }}
          onMouseLeave={(e) => {
            handlePopoverClose();
            e.currentTarget.style.opacity = '0.1';
          }}
        />
        <WithPopover>Aguardando outros aprovadores</WithPopover>
      </Box>
    );
  }

  return (
    <Box display="flex" onClick={(e) => e.stopPropagation()}>
      <RejectScheduleModal override={false} asManager={isManager} />
      <Button
        variant="outlined"
        color="success"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => approveVacation(false, isManager)}
        disabled={isSubmitting}
        sx={{ mr: 1, minWidth: '32px', minHeight: '32px', padding: '8px' }}
      >
        <Check sx={{ width: '16px', height: '16px' }} />
        <WithPopover>Aprovar</WithPopover>
      </Button>
      <Button
        onClick={openRejectModal}
        disabled={isSubmitting}
        onMouseEnter={handlePopoverOpenReject}
        onMouseLeave={handlePopoverCloseReject}
        variant="outlined"
        color="error"
        sx={{
          minWidth: '32px',
          minHeight: '32px',
          padding: '8px',
        }}
      >
        <CloseOutlined sx={{ width: '16px', height: '16px' }} />
        <WithPopoverReject>Recusar</WithPopoverReject>
      </Button>
    </Box>
  );
};
