import { useState } from 'react';

import { Popover, PopoverProps, Typography } from '@mui/material';
import { Box } from '@mui/system';

export function usePopoverMenu<SelectionValue = string>({
  anchorOrigin,
  anchorEl,
  options,
  onSelect,
}: {
  anchorOrigin: PopoverProps['anchorOrigin'];
  anchorEl: PopoverProps['anchorEl'];
  options: { label: string; value: SelectionValue }[];
  onSelect: ({
    label,
    value,
  }: {
    label: string;
    value: SelectionValue;
  }) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen((prev) => !prev);
  const close = () => setIsOpen(false);

  const MenuComponent = (
    <PopoverMenu
      anchorOrigin={anchorOrigin}
      anchorEl={anchorEl}
      options={options}
      onSelect={onSelect}
      close={close}
      isOpen={isOpen}
    />
  );

  return { isOpen, toggleOpen, close, MenuComponent };
}

function PopoverMenu<SelectionValue = string>({
  anchorOrigin,
  anchorEl,
  options,
  onSelect,
  close,
  isOpen,
}: {
  anchorEl: PopoverProps['anchorEl'];
  anchorOrigin: PopoverProps['anchorOrigin'];
  options: { label: string; value: SelectionValue }[];
  onSelect: ({
    label,
    value,
  }: {
    label: string;
    value: SelectionValue;
  }) => void;
  close: () => void;
  isOpen: boolean;
}) {
  const hasMultipleOptions = options.length > 1;
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={close}
      anchorOrigin={anchorOrigin}
    >
      <Box display="flex" flexDirection="column" sx={{ py: 1 }}>
        {options.map(({ label, value }) => (
          <Box
            key={label}
            onClick={() => onSelect({ label, value })}
            sx={{
              py: hasMultipleOptions ? 0.5 : 0,
              px: 2,
              '&:hover': { cursor: 'pointer' },
            }}
          >
            <Typography variant="body2" color="textPrimary">
              {label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Popover>
  );
}
