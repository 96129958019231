import { useState } from 'react';

import dayjs from 'dayjs';

import { Cancel, CheckCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { fetchApproveAllPayrolls } from '@octopus/api';
import { OctopusLoading } from '@octopus/ui/design-system';

import { useSnackbar } from '../../../../modules/hooks/useSnackbar';

export function PeriodApprovalConfirmationModal({
  organizationId,
  companyId,
  close,
}: {
  organizationId: string;
  companyId: string;
  close: () => void;
}) {
  const [dateSelected, setDateSelected] = useState<dayjs.Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const showSnackbar = useSnackbar().showSnackbar;
  const confirm = () => {
    if (!dateSelected) {
      return;
    }
    setIsLoading(true);
    fetchApproveAllPayrolls({
      pathParams: {
        organizationId,
        companyId,
        periodId: dateSelected?.format('YYYY-MM'),
        payrollType: 'rpa',
      },
    })
      .then(() => {
        showSnackbar({
          isOpen: true,
          Message: 'Pagamentos aprovados com sucesso',
          StartAdornment: <CheckCircle />,
          autoHideDuration: 5000,
          hasCloseAction: true,
        });
        close();
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadingScene />;
  }
  if (isError) {
    return <ErrorScene close={close} />;
  }
  return (
    <InitialScene
      date={dateSelected}
      setDate={setDateSelected}
      confirm={confirm}
      close={close}
    />
  );
}

function InitialScene({
  close,
  date,
  setDate,
  confirm,
}: {
  close: () => void;
  date: dayjs.Dayjs | null;
  setDate: (date: dayjs.Dayjs | null) => void;
  confirm: () => void;
}) {
  return (
    <Box width={'600px'}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'flex-start',
            m: 0,
            width: '100%',
          }}
        >
          <Typography variant="h5" fontSize="24px" fontWeight={700}>
            Aprovar pagamentos
          </Typography>

          <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
            <Typography variant="body1">
              {`Ao confirmar, o que acontece em seguida:`}
            </Typography>
            <Box display="flex" flexDirection="column">
              <Box component="ul" pl={2} display="flex" flexDirection="column">
                <li>
                  <Typography fontWeight={500} variant="body1" fontSize="16px">
                    Geração de recibos para a competência selecionada
                  </Typography>
                  <Typography
                    fontWeight={450}
                    variant="subtitle1"
                    fontSize="14px"
                  >
                    Os recibos serão gerados e ficaram disponíveis para envio
                  </Typography>
                </li>
              </Box>
            </Box>
          </Box>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DatePicker
              value={date}
              onChange={setDate}
              format="MM/YYYY"
              views={['year', 'month']}
              openTo="month"
              disableHighlightToday={true}
              slotProps={{
                textField: {
                  placeholder: 'Selecione a competência',
                },
              }}
              sx={{
                width: '100%',
              }}
            />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={() => {
            close();
          }}
          data-testid="cancel-approve-payroll-button"
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={confirm}
          data-testid="confirm-approve-payroll-button"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Box>
  );
}

function ErrorScene({ close }: { close: () => void }) {
  return (
    <>
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.8} pb={3}>
          <Cancel fontSize="huge" color="error" />
          <Typography variant="h1">Erro ao aprovar pagamentos</Typography>
        </Box>
        <Typography variant="body1">
          Não foi possível aprovar os pagamentos, tente novamente. Se persistir
          o erro, entre em contato com o suporte.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button size="large" sx={{ minWidth: '140px' }} onClick={close}>
          Ok
        </Button>
      </DialogActions>
    </>
  );
}

function LoadingScene() {
  return (
    <DialogContent>
      <Box
        py={10}
        px={16}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box width="100px">
          <OctopusLoading />
        </Box>
        <Box pt={2}>
          <Typography variant="h4" textAlign="center">
            Aprovando pagamentos, aguarde alguns segundos...
          </Typography>
        </Box>
      </Box>
    </DialogContent>
  );
}
