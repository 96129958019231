import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CheckCircle, CloseRounded, Edit, History } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Modal,
  Popover,
  Radio,
  RadioGroup,
  Skeleton,
  Slide,
  Typography,
} from '@mui/material';

import {
  PayrollPeriodSummary,
  PayrollPeriodSummaryEntry,
  PayrollPeriodTotalizersTotals,
  PayrollTypes,
  fetchGetPeriodSummary,
  fetchSearchAllPeriodsSummaries,
  useGetPeriodSummary,
} from '@octopus/api';
import { formatMoney, formatPeriodDate } from '@octopus/formatters';
import { summaryElements } from '@octopus/payroll-engine/public-types/core';
import { Tag } from '@octopus/ui/design-system';

import { DisplayDiff } from '../../../../modules/components/payrolls/DisplayDiff';
import { DataFetching } from '../../../../modules/dataFetching';
import { FLAGS } from '../../../../modules/flags';
import { formatPayrollType } from '../../../../modules/formatters';
import { PeriodFormat } from '../../../../modules/types';
import { getNextPeriod, getPreviousPeriod } from '../../../../utils';

const COMPARABLE_PAYROLL_TYPES: PayrollTypes[] = ['monthly', 'advance'];

const SUMMARY_OPTIONS = FLAGS.ENABLE_PAYROLL_TOTAL_COST
  ? (['netPay', 'totalCost'] as const)
  : (['netPay'] as const);

export type ArrayElement<TArray extends readonly unknown[]> =
  TArray extends readonly (infer TArrayElementType)[]
    ? TArrayElementType
    : never;

type SummaryOption = ArrayElement<typeof SUMMARY_OPTIONS>;

export function PeriodInfo({
  organizationId,
  companyId,
  period,
  type,
  compareTo,
  setCompareTo,
}: {
  organizationId: string;
  companyId: string;
  period: PeriodFormat;
  type: PayrollTypes;
  compareTo: PeriodFormat | undefined;
  setCompareTo: (compareTo: PeriodFormat | undefined) => void;
}) {
  const [comparables, setComparables] = useState<PeriodFormat[]>([]);
  const [comparisonEntry, setComparisonEntry] = useState<
    PayrollPeriodSummaryEntry | undefined
  >(undefined);

  useEffect(() => {
    if (!COMPARABLE_PAYROLL_TYPES.includes(type)) {
      return;
    }

    fetchSearchAllPeriodsSummaries({
      pathParams: {
        organizationId: organizationId,
        companyId: companyId,
      },
      body: {
        filtering: {
          elements: {
            type: [type],
          },
          ranges: {
            period: {
              min: getNextPeriod(period),
            },
          },
        },
        sorting: [
          {
            field: 'period',
            order: 'asc',
          },
        ],
      },
    })
      .then((response) => {
        setComparables((comparables) => [
          ...new Set(
            comparables.concat(
              response.data.map((p) => p.period as PeriodFormat),
            ),
          ),
        ]);
      })
      .catch((error) => {
        console.error('Failed to retrieve next periods', error);
      });

    fetchSearchAllPeriodsSummaries({
      pathParams: {
        organizationId,
        companyId,
      },
      body: {
        pagination: {
          size: 6,
        },
        sorting: [
          {
            field: 'period',
            order: 'desc',
          },
        ],
        filtering: {
          ranges: {
            period: {
              max: getPreviousPeriod(period),
            },
          },
          elements: {
            type: [type],
          },
        },
      },
    })
      .then((response) => {
        setComparables((comparables) => [
          ...new Set(
            response.data
              .map((p) => p.period as PeriodFormat)
              .concat(comparables),
          ),
        ]);
      })
      .catch((error) => {
        console.error('Error loading comparable periods', error);
      });
  }, [organizationId, companyId, period]);

  useEffect(() => {
    if (!compareTo) {
      setComparisonEntry(undefined);
    } else {
      fetchGetPeriodSummary({
        pathParams: {
          organizationId,
          companyId,
          periodId: compareTo,
          payrollType: type,
        },
      })
        .then(setComparisonEntry)
        .catch((err) => {
          console.error('Error loading comparison period', err);
        });
    }
  }, [compareTo]);

  const useFetch = () =>
    useGetPeriodSummary({
      pathParams: {
        organizationId,
        companyId,
        periodId: period,
        payrollType: type,
      },
    });

  return (
    <DataFetching
      useHook={useFetch}
      Loading={() => (
        <Box py={4} px={4}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">
              <Skeleton width={100} />
            </Typography>
            <Skeleton variant="text" width={100} />
          </Box>
          <Typography variant="h1">
            <Skeleton />
          </Typography>
          <Box display="flex" gap={2} pt={2}>
            <Skeleton variant="rounded" width="70%" height={90} />
          </Box>
        </Box>
      )}
      Data={({ data }) => (
        <Info
          type={type}
          period={data}
          comparison={comparisonEntry}
          comparables={comparables}
          compareTo={compareTo}
          setCompareTo={setCompareTo}
        />
      )}
    />
  );
}

export function PeriodStatusTag({
  period,
  hasIcon = true,
}: {
  period: PayrollPeriodSummaryEntry | PayrollPeriodSummary | undefined;
  hasIcon?: boolean;
}) {
  if (!period) {
    return null;
  }

  if (period.status === 'open') {
    if (period.type === 'termination' || period.type === 'vacations') {
      return (
        <Tag
          left={hasIcon ? <Edit /> : undefined}
          color="default"
          emphasis="high"
        >
          Prévia
        </Tag>
      );
    }

    return (
      <Tag
        left={hasIcon ? <Edit /> : undefined}
        color="warning"
        emphasis="high"
      >
        Aberta
      </Tag>
    );
  }

  if (period.status === 'approved') {
    return (
      <Tag
        left={hasIcon ? <CheckCircle /> : undefined}
        color="info"
        emphasis="high"
      >
        Aprovada
      </Tag>
    );
  }

  if (period.status === 'closed') {
    return (
      <Tag
        left={hasIcon ? <CheckCircle /> : undefined}
        color="success"
        emphasis="high"
      >
        Fechada
      </Tag>
    );
  }

  if (period.status === 'archived') {
    return null;
  }

  period.status satisfies never;
  return null;
}

function Info({
  type,
  period,
  comparison,
  comparables,
  compareTo,
  setCompareTo,
}: {
  type: PayrollTypes;
  period: PayrollPeriodSummaryEntry | undefined;
  comparison: PayrollPeriodSummaryEntry | undefined;
  comparables: PeriodFormat[];
  compareTo: PeriodFormat | undefined;
  setCompareTo: (compareTo: PeriodFormat | undefined) => void;
}) {
  const [selectedSummary, setSelectedSummary] =
    useState<SummaryOption>('netPay');
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (summary: SummaryOption) => {
    setModalOpen(true);
    setSelectedSummary(summary);
  };
  const closeModal = () => setModalOpen(false);

  const elementTotalsModal = (
    <Modal
      open={!!modalOpen}
      onClose={() => setModalOpen(false)}
      closeAfterTransition
      keepMounted
      sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(4px)',
          },
        },
      }}
    >
      <Slide in={!!modalOpen} direction="up">
        <Box
          sx={{
            position: 'absolute',
            mt: 12,
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1.5,
            pt: 1,
          }}
        >
          <IconButton
            onClick={closeModal}
            sx={{
              position: 'absolute',
              top: -65,
              right: -60,
            }}
            size="large"
          >
            <CloseRounded
              sx={{
                color: 'white',
                backgroundColor: 'text.primary',
                p: 1,
                borderRadius: '50%',
              }}
            />
          </IconButton>
          {selectedSummary === 'netPay' && (
            <Box p={4}>
              <Typography variant="h3" sx={{ px: 1, pb: 1.5 }}>
                Proventos
              </Typography>
              <ElementsTotalsTable
                calculationTotals={period?.totalizers?.totals}
                comparisonTotals={comparison?.totalizers?.totals}
                summaryKey={summaryElements.workerEarningsTotal}
                closeModal={closeModal}
              />
              <Typography variant="h3" sx={{ px: 1, pt: 5, pb: 1.5 }}>
                Deduções
              </Typography>
              <ElementsTotalsTable
                calculationTotals={period?.totalizers?.totals}
                comparisonTotals={comparison?.totalizers?.totals}
                summaryKey={summaryElements.workerDeductionsTotal}
                closeModal={closeModal}
              />
            </Box>
          )}
          {selectedSummary === 'totalCost' && (
            <Box p={4}>
              <ElementsTotalsTable
                calculationTotals={period?.totalizers?.totals}
                comparisonTotals={comparison?.totalizers?.totals}
                summaryKey={summaryElements.totalCost}
                closeModal={closeModal}
              />
            </Box>
          )}
          <Divider light sx={{ my: 0.5 }} />
          {selectedSummary && (
            <Box px={4} py={3}>
              <table
                width="100%"
                style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }}
              >
                <tbody>
                  <tr>
                    <th role="presentation" style={{ width: '8px' }} />
                    {/* spacing for border */}
                    <th align="left" style={{ width: '60%' }}>
                      <Typography variant="h4" fontWeight="bold">
                        {
                          { netPay: 'Valor líquido', totalCost: 'Custo total' }[
                            selectedSummary
                          ]
                        }
                      </Typography>
                    </th>
                    <th
                      role="presentation"
                      align="left"
                      style={{ width: '20%' }}
                    />
                    <th align="left" style={{ width: '20%' }}>
                      <Typography variant="h4" fontWeight="bold">
                        {formatMoney(
                          period?.totalizers?.totals?.[selectedSummary]
                            ?.total || '0',
                        )}
                      </Typography>
                    </th>

                    {Boolean(comparison?.totalizers?.totals) && (
                      <th
                        role="presentation"
                        align="left"
                        style={{ width: '20%' }}
                      />
                    )}
                    {FLAGS.ENABLE_PAYROLL_RUBRICA_FILTER && (
                      <th role="presentation" style={{ width: '40px' }} />
                    )}
                    <th role="presentation" style={{ width: '8px' }} />
                    {/* spacing for border */}
                  </tr>
                </tbody>
              </table>
            </Box>
          )}
        </Box>
      </Slide>
    </Modal>
  );

  return (
    <Box
      pt={4}
      pb={3}
      display="flex"
      justifyContent="space-between"
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <Box data-testid="payrolls-period-info">
        <Box px={1.5}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ display: 'flex', columnGap: '5px', pb: 0.5 }}
          >
            {getTypeLabel(type)}
          </Typography>
          <Typography variant="display3" sx={{ color: '#25252D' }}>
            {period && formatPeriodDate(period.period)}
          </Typography>
        </Box>
        <Box display="flex" pt={2} gap={7}>
          {SUMMARY_OPTIONS.map((field) => (
            <Box
              key={field}
              py={1.5}
              px={1.5}
              sx={{
                borderRadius: 1,
                transition: 'all 0.2s',
                cursor: 'pointer',
                ':hover': {
                  backgroundColor: 'strokes.light',
                },
              }}
              onClick={() => openModal(field)}
            >
              <Box>
                <Typography variant="caption" color="text.secondary">
                  {
                    {
                      netPay: 'Valor Líquido',
                      totalCost: 'Custo Total',
                    }[field]
                  }{' '}
                  ›
                </Typography>
                <Typography variant="h3">
                  {formatMoney(
                    period?.totalizers?.totals?.[field]?.total || '0',
                  )}
                </Typography>
                {compareTo && (
                  <DisplayDiff
                    variant="body2"
                    current={period?.totalizers?.totals?.[field]?.total}
                    compare={comparison?.totalizers?.totals?.[field]?.total}
                    withSign={false}
                    withCurrency={false}
                  />
                )}
              </Box>
            </Box>
          ))}
          {elementTotalsModal}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="end"
        justifyContent="space-between"
        pl={4}
      >
        <Box display="flex" alignItems="end" flexDirection="column" gap={1}>
          <PeriodStatusTag period={period} />
          <Typography variant="caption" color="text.secondary">
            {period?.status === 'open' ? 'Aprovar até --/-- às 23:59' : ''}
          </Typography>
        </Box>
        <Box>
          <ComparisonSelector
            compareTo={compareTo}
            setCompareTo={setCompareTo}
            comparables={comparables}
            type={type}
          />
        </Box>
      </Box>
    </Box>
  );
}

function getTypeLabel(type: PayrollTypes): string {
  switch (type) {
    case 'monthly':
      return 'Folha mensal';
    case 'advance':
      return 'Folha de adiantamento';
    case 'thirteenthFirst':
      return 'Folha de 13º salário - Adiantamento';
    case 'thirteenthSecond':
      return 'Folha de 13º salário - 2ª parcela';
    case 'rpa':
      return 'Recibo de Pagamento Autônomo - RPA';
    default:
      return 'Folha complementar';
  }
}

function ComparisonSelector({
  compareTo,
  setCompareTo,
  comparables,
  type,
}: {
  compareTo: PeriodFormat | undefined;
  setCompareTo: (compareTo: PeriodFormat | undefined) => void;
  comparables: PeriodFormat[];
  type: PayrollTypes;
}) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverAnchorRef = useRef<HTMLButtonElement>(null);

  if (comparables.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        color="secondary"
        endIcon={<History />}
        onClick={() => setPopoverOpen(true)}
        ref={popoverAnchorRef}
        sx={{
          py: '8px',
          px: '12px',
          '.MuiButton-endIcon': {
            ml: '4px',
            mr: 0,
            ...(Boolean(compareTo) && {
              color: 'primary.main',
            }),
          },
        }}
      >
        {compareTo ? (
          <Box component="span">
            Comparando com:{' '}
            <Box component="span" color="info.main">
              {formatPeriodDate(compareTo)}
            </Box>
          </Box>
        ) : (
          'Comparar'
        )}
      </Button>
      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchorRef.current}
        onClose={() => setPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        PaperProps={{
          sx: {
            mt: 1,
          },
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box p="8px 12px">
            <FormControl
              size={undefined}
              sx={{ height: '100%', display: 'block', p: '8px 4px' }}
            >
              <FormLabel
                sx={{
                  fontWeight: 'bold',
                  display: 'block',
                  p: '10px 0px 11px 4px',
                }}
              >
                Comparar com
              </FormLabel>
              <RadioGroup
                value={compareTo}
                onChange={(e) => {
                  setCompareTo(e.target.value as PeriodFormat);
                }}
                sx={{
                  p: '8px 8px',
                }}
              >
                {comparables.map((period) => (
                  <FormControlLabel
                    key={period}
                    value={period}
                    control={<Radio />}
                    label={
                      <Typography
                        variant="body2"
                        sx={{
                          ...(period === compareTo && {
                            color: 'primary.main',
                          }),
                        }}
                      >
                        {formatPayrollType(type)} - {formatPeriodDate(period)}
                      </Typography>
                    }
                    sx={{ p: '8px' }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <Button
              variant="text"
              size="small"
              onClick={() => setCompareTo(undefined)}
              disabled={!compareTo}
              sx={{
                p: '8px 4px',
              }}
            >
              Limpar
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

function ElementsTotalsTable({
  calculationTotals,
  comparisonTotals,
  summaryKey,
  closeModal,
}: {
  calculationTotals: PayrollPeriodTotalizersTotals | undefined;
  comparisonTotals: PayrollPeriodTotalizersTotals | undefined;
  summaryKey: keyof typeof summaryElements;
  closeModal: () => void;
}) {
  const [, setSearchParams] = useSearchParams();

  if (!calculationTotals) {
    return null;
  }

  const allElements = calculationTotals.elements;
  const elements = calculationTotals[summaryKey]?.elements ?? [];
  const total = calculationTotals[summaryKey]?.total ?? '0';

  return (
    <table
      width="100%"
      style={{
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
      }}
    >
      <thead>
        <Box
          component="tr"
          sx={{
            th: {
              padding: 0,
            },
            paddingBottom: '16px',
          }}
        >
          <th style={{ width: '8px' }}>{/* spacing for border */}</th>
          <th align="left" style={{ width: '60%' }}>
            <Typography fontWeight="bold" variant="body2">
              Descrição
            </Typography>
          </th>
          <th align="left" style={{ width: '20%' }}>
            <Typography fontWeight="bold" variant="body2">
              Colaboradores
            </Typography>
          </th>
          <th align="left" style={{ width: '20%' }}>
            <Typography fontWeight="bold" variant="body2">
              Valor
            </Typography>
          </th>
          {comparisonTotals && (
            <th align="left" style={{ width: '20%' }}>
              <Typography fontWeight="bold" variant="body2">
                Comparação
              </Typography>
            </th>
          )}
          {FLAGS.ENABLE_PAYROLL_RUBRICA_FILTER && (
            <th style={{ width: '40px' }}>{/* Filter > call to action */}</th>
          )}
          <th style={{ width: '8px' }}>{/* spacing for border */}</th>
        </Box>
        <tr>
          <td />
          <td colSpan={5}>
            <Divider light sx={{ my: 1 }} />
          </td>
        </tr>
      </thead>
      <tbody>
        {elements.map((id) => {
          const element = allElements?.[id];
          if (!element) {
            return null;
          }
          const comparisonElement = comparisonTotals?.elements?.[id];
          const onClickComparisonElement =
            FLAGS.ENABLE_PAYROLL_RUBRICA_FILTER && {
              onClick: () => {
                closeModal();
                setTimeout(() => {
                  // Let Modal Close before changing search params
                  setSearchParams(
                    { query: `rubrica:${element.name}` },
                    { replace: true },
                  );
                }, 250);
              },
            };
          return (
            <Box
              component="tr"
              {...onClickComparisonElement}
              key={id}
              sx={{
                ...(FLAGS.ENABLE_PAYROLL_RUBRICA_FILTER && {
                  'td:nth-last-of-type(2)': {
                    transition: 'all 0.2s',
                    opacity: 0,
                  },
                  ':hover td:nth-last-of-type(2)': {
                    opacity: 1,
                  },
                }),
                cursor: 'pointer',
                transition: 'all 0.2s',
                td: {
                  padding: 0,
                },
                'td:first-of-type': {
                  borderRadius: '4px 0 0 4px',
                },
                'td:last-of-type': {
                  borderRadius: '0 4px 4px 0',
                },
                ':hover': {
                  transform: 'translate(1px)',
                  backgroundColor: 'strokes.light',
                },
              }}
            >
              <td />
              <td>
                <Typography variant="body2" sx={{ py: 0.5 }}>
                  {element.name}
                </Typography>
              </td>
              <td>
                <Typography variant="body2">{element.workersCount}</Typography>
              </td>
              <td>
                <Typography variant="body2">
                  {formatMoney(element.amount)}
                </Typography>
              </td>
              {comparisonTotals && (
                <td>
                  <DisplayDiff
                    current={element.amount}
                    compare={comparisonElement?.amount}
                    variant="body2"
                  />
                </td>
              )}
              {FLAGS.ENABLE_PAYROLL_RUBRICA_FILTER && (
                <td>
                  <Typography
                    variant="caption"
                    sx={{ color: 'text.secondary' }}
                    component="p"
                  >
                    Filtrar ›
                  </Typography>
                </td>
              )}
              <td />
            </Box>
          );
        })}
        <tr>
          <td />
          <td colSpan={2}>
            <Typography variant="body2" sx={{ py: 1 }} fontWeight="bold">
              Total
            </Typography>
          </td>
          <td>
            <Typography variant="body2" sx={{ py: 1 }} fontWeight="bold">
              {formatMoney(total)}
            </Typography>
          </td>
          {comparisonTotals && (
            <td>
              <DisplayDiff
                current={total}
                compare={comparisonTotals[summaryKey]?.total}
                variant="body2"
              />
            </td>
          )}
        </tr>
      </tbody>
    </table>
  );
}
