import {
  AndCondition,
  CheckCondition,
  Condition,
  OrCondition,
} from '@octopus/libs/conditional-evaluator';

export type ConditionEditorProps = {
  condition: Condition;
  onChange: (updatedCondition: Condition) => void;
  onDelete: () => void;
  resourceType: string;
};

export type NestedConditionEditorProps = {
  condition: OrCondition | AndCondition;
  onChange: (updatedCondition: OrCondition | AndCondition) => void;
  onDelete: () => void;
  resourceType: string;
};

export type CheckConditionEditorProps = {
  condition: CheckCondition;
  onChange: (updatedCondition: CheckCondition) => void;
  onDelete: () => void;
  resourceType: string;
};

export const CheckConditionOperators = {
  eq: 'Igual a',
  neq: 'Diferente de',
  gt: 'Maior que',
  lt: 'Menor que',
  gte: 'Maior ou igual a',
  lte: 'Menor ou igual a',
  between: 'Entre',
  in: 'Em',
  nin: 'Não em',
};
