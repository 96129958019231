import dayjs from 'dayjs';

import { Box, Typography } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { ExpandableTypography } from '../../../../modules/components/ExpandableTypography';

import { AlertWithPopover } from './Alert';
import { VacationsBalance } from './Balance';

export const nameColumn: GridColDef = {
  field: 'workerData.name',
  sortable: true,
  width: 150,
  headerName: 'Pessoa',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.workerData.name;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value || '-'}</ExpandableTypography>;
  },
};

export const departmentColumn: GridColDef = {
  sortable: false,
  width: 150,
  field: 'department',
  headerName: 'Departamento',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.workerData.department;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value || '-'}</ExpandableTypography>;
  },
};

export const nameDepartmentColumn: GridColDef = {
  sortable: false,
  width: 150,
  field: 'vacationNameDepartment',
  headerName: 'Pessoa',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row;
  },
  renderCell: ({ value }) => {
    return (
      <Box display="flex" flexDirection="column">
        <Typography noWrap variant="body2">
          {value.workerData.name}
        </Typography>
        <Typography noWrap variant="caption">
          {value.workerData.department}
        </Typography>
      </Box>
    );
  },
};

export const balanceColumn: GridColDef = {
  sortable: true,
  field: 'totalDaysAvailable',
  headerName: 'Dias disponíveis',
  renderHeader: (params) => {
    return <Box whiteSpace="nowrap">{params.colDef.headerName}</Box>;
  },
  valueGetter: (params: GridValueGetterParams) => {
    return params.row;
  },
  renderCell: ({ value: { totalDaysAvailable, hasOverdueVacations } }) => {
    return (
      <VacationsBalance
        totalDaysAvailable={totalDaysAvailable}
        hasOverdueVacations={hasOverdueVacations}
      />
    );
  },
};

export const concessionPeriodColumn: GridColDef = {
  sortable: false,
  field: 'currentVacationsAccrualPeriodStartAndEndDate',
  headerName: 'Período concessivo vigente',
  renderHeader: (params) => {
    return <Box whiteSpace="nowrap">{params.colDef.headerName}</Box>;
  },
  valueGetter: (params: GridValueGetterParams) => {
    const startDate = dayjs(params.row.currentVacationsAccrualPeriodStartDate)
      .add(1, 'year')
      .format('DD/MM/YY');
    const endDate = dayjs(params.row.currentVacationsAccrualPeriodEndDate)
      .add(1, 'year')
      .format('DD/MM/YY');
    return `${startDate} a ${endDate}`;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value ?? '-'}</ExpandableTypography>;
  },
};

export const limitDateToStartVacationsColumn: GridColDef = {
  sortable: true,
  field: 'limitDateToStartVacations',
  headerName: 'Limite para início do gozo',
  renderHeader: (params) => {
    return <Box whiteSpace="nowrap">{params.colDef.headerName}</Box>;
  },
  valueGetter: ({ row }: GridValueGetterParams) => {
    const limitDateToStartVacations = row.limitDateToStartVacations
      ? dayjs(row.limitDateToStartVacations).format('DD/MM/YY')
      : '-';
    return {
      ...row,
      limitDateToStartVacations,
    };
  },
  renderCell: ({
    value: {
      limitDateToStartVacations,
      hasVacationsCloseToDueDate,
      hasOverdueVacations,
    },
  }) => {
    const popoverContent = hasOverdueVacations
      ? 'Passou da data limite'
      : hasVacationsCloseToDueDate
        ? 'Próximo da data limite'
        : null;
    const variant = hasOverdueVacations
      ? 'error'
      : hasVacationsCloseToDueDate
        ? 'warning'
        : null;
    return (
      <AlertWithPopover
        value={limitDateToStartVacations}
        variant={variant}
        popoverContent={popoverContent}
      />
    );
  },
};
