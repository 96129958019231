import { SvgIconComponent } from '@mui/icons-material';

import { MembershipContext, MembershipOption } from '../../modules/types';
import { features } from '../fflags';

export enum AppTitles {
  ORGANIZATION = 'Organização',
  PEOPLE = 'Pessoas',
  PAYROLLS = 'Folhas de pagamento',
  PAYMENT_REQUESTS = 'Solicitações de pagamento',
  RPA_PAYMENTS = 'Pagamento a autônomos',
  ADMISSIONS = 'Admissões',
  TERMINATIONS = 'Rescisões',
  VACATIONS = 'Férias',
  LEADERS = 'Liderados',
  ME = 'Eu',
  REPORTS = 'Relatórios',
  MY_ADMISSION = 'Minha admissão',
  MY_DATA = 'Meus dados',
  PAYSLIPS_AND_RECEIPTS = 'Recibos e holerites',
  SECURITY = 'Segurança',
}

export const OrderedApps = [
  AppTitles.ORGANIZATION,
  AppTitles.PEOPLE,
  AppTitles.PAYROLLS,
  AppTitles.PAYMENT_REQUESTS,
  AppTitles.RPA_PAYMENTS,
  AppTitles.ADMISSIONS,
  AppTitles.TERMINATIONS,
  AppTitles.VACATIONS,
  AppTitles.REPORTS,
  AppTitles.MY_DATA,
  AppTitles.MY_ADMISSION,
  AppTitles.PAYSLIPS_AND_RECEIPTS,
  AppTitles.SECURITY,
];

export type SidebarApp = {
  Icon: SvgIconComponent | React.ElementType;
  title: AppTitles;
  path: string;
  fflag?: keyof typeof features;
  children?: SidebarApp[];
  isExpanded?: boolean;
};

export type SidebarProps = {
  username: string;
  pictureUrl?: string;
  apps: SidebarApp[];
  membership: MembershipContext;
  membershipOptions: MembershipOption[];
  changeMembership: (context: MembershipContext) => void;
  logout: () => void;
};
