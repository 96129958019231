import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { ChevronRight } from '@mui/icons-material';
import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material';

import {
  PayrollCalculationTotals,
  PayrollPayslipSummary,
  PayrollStatus,
  PayrollSummary,
  PayrollType,
  SearchFilteringRange,
  fetchSearchAllPayrolls,
  fetchSearchAllPayslips,
  useGetLegalEntities,
} from '@octopus/api';
import {
  formatDateBR,
  formatDateWithDaysAdded,
  formatMoney,
  formatPeriodDate,
} from '@octopus/formatters';
import { summaryElements } from '@octopus/payroll-engine/public-types/core';
import { payrollStatuses } from '@octopus/payroll-types';
import {
  DataGrid,
  FilterOptions,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
  makeElementListFilter,
  makeMoneyRangeFilter,
  useDataGrid,
} from '@octopus/ui/data-grid';
import { Tag } from '@octopus/ui/design-system';

import SendIcon from '../../../assets/send.svg';
import { useSendPayslip } from '../../../modules/components/payslips/SendPayslips';
import { DataFetching } from '../../../modules/dataFetching';
import { FLAGS } from '../../../modules/flags';
import { PeriodFormat } from '../../../modules/types';
import { getActiveElementFilters } from '../../../utils';
import { hasGeneratedAllPayslips } from '../../../utils/statusIndexUtils';

type LegalEntityEntry = { id: string; name: string };

export function RpaTable({
  organizationId,
  companyId,
  type,
  compareTo,
  onWorkerClick,
  selectedStatus,
}: {
  organizationId: string;
  companyId: string;
  type: PayrollType;
  compareTo: PeriodFormat | undefined;
  onWorkerClick: (
    payrollId: string,
    contractId: string,
    rows: Array<PayrollSummary>,
    selectedTab: PayrollStatus,
  ) => void;
  selectedStatus: PayrollStatus;
}) {
  const [legalEntities, setLegalEntities] = useState<LegalEntityEntry[]>([]);
  const [rubricaElements] = useState<{ [key: string]: string } | null>(null);

  const filters = useFilters({ rubricaElements, legalEntities });
  const { sortingProps, filteringProps, searchProps, paginationProps } =
    useDataGrid({
      filters,
    });

  const legalEntitiesQuery = useGetLegalEntities(
    {
      pathParams: {
        organizationId,
        companyId,
      },
    },
    {
      enabled: !!organizationId && !!companyId,
    },
  );

  useEffect(() => {
    if (!legalEntitiesQuery.isError && legalEntitiesQuery.data) {
      setLegalEntities(
        legalEntitiesQuery.data.data.map((entity) => ({
          id: entity.legalEntityId,
          name: entity.br.nomeFantasia,
        })),
      );
    }
  }, [legalEntitiesQuery.isError, legalEntitiesQuery.data]);

  const useFetch = () => {
    const rangeFilters = new Set(
      Object.values(summaryElements).map(
        (element) => `calculationTotals.${element}`,
      ) as string[],
    );
    const rangeFiltersValue = Object.fromEntries(
      Object.entries(filteringProps.filtersState.rangeFilters)
        .filter(([k]) => filteringProps.activeFilters.has(k))
        .map(([k, v]) => [`calculationTotals.${k}`, v] as const)
        .filter(([k]) => rangeFilters.has(k)),
    ) as Record<string, SearchFilteringRange>;

    const [query] = (() => {
      const { searchTerm } = searchProps;

      if (!searchTerm.startsWith('rubrica:')) {
        return [searchTerm, ''];
      }

      const [, element] = searchTerm.split(':');
      return ['', element];
    })();

    const elementFilters = {
      type: [type],
      status:
        selectedStatus === 'closed'
          ? ['reconciled', selectedStatus]
          : [selectedStatus],
    };

    const arrayFilters = getActiveElementFilters(filteringProps);
    const payrollBody = {
      query: query.length > 0 ? query : undefined,
      pagination: {
        size: paginationProps.rowsPerPage,
        page: paginationProps.page,
      },
      ...(sortingProps.field
        ? {
            sorting: [
              {
                field: prepareSortField(sortingProps.field),
                order: sortingProps.order,
              },
            ],
          }
        : {}),
      filtering: {
        ranges: rangeFiltersValue,
        elements: elementFilters,
        arrays: arrayFilters,
      },
    };
    return useQuery({
      queryKey: [
        'searchAllRPAs',
        organizationId,
        companyId,
        query,
        paginationProps,
        sortingProps,
        rangeFiltersValue,
        elementFilters,
        arrayFilters,
        compareTo,
      ],
      queryFn: async () => {
        const payrolls = await fetchSearchAllPayrolls({
          pathParams: {
            organizationId,
            companyId,
          },
          body: payrollBody,
        });
        let payslips = { data: [] as PayrollPayslipSummary[] };
        if (selectedStatus !== payrollStatuses.open) {
          const payslipBody = {
            filtering: {
              ranges: rangeFiltersValue,
              elements: {
                type: [type],
                status: ['approved'],
                payrollId: payrolls.data.map((payroll) => payroll.payrollId),
              },
            },
          };

          payslips = await fetchSearchAllPayslips({
            pathParams: {
              organizationId,
              companyId,
            },
            body: payslipBody,
          });
        }

        const comparisons: Record<string, PayrollCalculationTotals> = {};
        if (compareTo) {
          const fetchComparisonPayrollsResult = await fetchSearchAllPayrolls({
            pathParams: {
              organizationId,
              companyId,
            },
            body: {
              ...payrollBody,
              filtering: {
                elements: {
                  ...elementFilters,
                  period: [compareTo],
                  contractId: payrolls.data.map(
                    (payroll) => payroll.contractId,
                  ),
                },
                arrays: arrayFilters,
              },
            },
          });
          for (const payroll of fetchComparisonPayrollsResult.data) {
            if (payroll.calculationTotals) {
              comparisons[payroll.contractId] = payroll.calculationTotals;
            }
          }
        }
        const payslipLookup = new Set(
          payslips.data.map((payslip) => payslip.payrollId),
        );
        return {
          ...payrolls,
          data: payrolls.data.map((payroll) => {
            return {
              ...payroll,
              ...(compareTo && {
                comparison: comparisons[payroll.contractId],
              }),
              ...{
                hasApprovedPayslip: payslipLookup.has(payroll.payrollId),
              },
            };
          }),
        };
      },
    });
  };

  return (
    <DataFetching
      useHook={useFetch}
      Loading={() => (
        <Box display="flex" flexDirection="column" gap="8px" pt={1}>
          <Skeleton variant="rounded" height={300} width="100%" />
        </Box>
      )}
      Data={({ data: response }) => {
        return response ? (
          <DataGrid<PayrollSummary>
            sortingProps={sortingProps}
            paginationProps={paginationProps}
            totalRowCount={response.total}
            getRowId={(row) => `${row.contractId}/${row.payrollId}`}
            rows={response.data}
            columns={getColumnsForStatus(selectedStatus)}
            onRowClick={(params) =>
              onRowClick({
                onWorkerClick,
                row: params.row,
                rows: response.data,
              })
            }
            emptyMessage={getEmptyMessageForStatus(selectedStatus)}
            getRowSx={() => ({
              'td:nth-last-of-type': {
                opacity: 0,
              },
              '&:hover': {
                'td:nth-last-of-type': {
                  opacity: 1,
                },
              },
            })}
          />
        ) : null;
      }}
    />
  );
}

function onRowClick({
  onWorkerClick,
  row,
  rows,
}: {
  row: PayrollSummary;
  onWorkerClick: (
    payrollId: string,
    contractId: string,
    rows: Array<PayrollSummary>,
    status: PayrollStatus,
  ) => void;
  rows: Array<PayrollSummary>;
}) {
  if (!row) {
    return;
  }

  onWorkerClick(row.payrollId, row.contractId, rows, row.status);
}

function useFilters({
  legalEntities,
  rubricaElements,
}: {
  legalEntities: LegalEntityEntry[];
  rubricaElements: { [key: string]: string } | null;
}): FilterOptions {
  return [
    rubricaElements &&
      makeElementListFilter({
        label: 'Rubricas',
        propertyToFilter: 'calculationTotals.elementIds',
        elements: Object.keys(rubricaElements),
        labels: rubricaElements,
      }),
    makeMoneyRangeFilter({
      label: 'Proventos',
      propertyToFilter: summaryElements.workerEarningsTotal,
      getRangeMin: () => 0,
      getRangeMax: () => 150_000,
    }),
    makeMoneyRangeFilter({
      label: 'Deduções',
      propertyToFilter: summaryElements.workerDeductionsTotal,
      getRangeMin: () => 0,
      getRangeMax: () => 150_000,
    }),
    makeMoneyRangeFilter({
      label: 'Líquido',
      propertyToFilter: summaryElements.netPay,
      getRangeMin: () => 0,
      getRangeMax: () => 150_000,
    }),
    FLAGS.ENABLE_PAYROLL_TOTAL_COST &&
      makeMoneyRangeFilter({
        label: 'Custo Total',
        propertyToFilter: summaryElements.totalCost,
        getRangeMin: () => 0,
        getRangeMax: () => 150_000,
      }),
    ...(legalEntities.length > 1
      ? [
          makeElementListFilter({
            label: 'Empregador',
            propertyToFilter: 'legalEntityId.keyword',
            elements: legalEntities.map(({ id }) => id),
            labels: legalEntities.reduce(
              (acc, { id, name }) => {
                acc[id] = name;
                return acc;
              },
              {} as Record<string, string>,
            ),
          }),
        ]
      : []),
  ].filter(Boolean);
}

const columns: GridColDef<PayrollSummary>[] = [
  {
    field: 'name',
    headerName: 'Autônomo',
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.workerData.name;
    },
  },
  {
    field: 'period',
    headerName: 'Competência',
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return formatPeriodDate(params.row.period);
    },
  },
  {
    field: 'calculationTotals.workerEarningsTotal',
    headerName: 'Valor',
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return formatMoney(params.row.calculationTotals.workerEarningsTotal);
    },
  },
  {
    field: 'paymentDate',
    headerName: 'Data de pagamento',
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return formatDateBR(params.row.paymentDate || params.row.date);
    },
  },
].filter(Boolean);

function prepareSortField(orderBy?: string) {
  if (orderBy === 'name') {
    return 'workerData.name';
  }
  return orderBy;
}

function getColumnsForStatus(state: PayrollStatus) {
  switch (state) {
    case 'open':
      return [
        ...columns,
        {
          field: 'approvalDate',
          headerName: 'Aprovar até',
          valueGetter: (params: GridValueGetterParams) => {
            return formatDateBR(
              formatDateWithDaysAdded(
                params.row.paymentDate || params.row.date,
                -2,
              ),
            );
          },
        },
        {
          field: 'reviewCall',
          headerName: '',
          renderCell: () => {
            return (
              <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                alignContent="center"
                height={'36px'}
              >
                <Typography variant="body2" color="info.main" fontWeight={550}>
                  Revisar
                </Typography>
                <ChevronRight
                  sx={{ color: 'blue', height: '18px', width: '18px' }}
                />
              </Box>
            );
          },
        },
      ];
    case 'approved':
    case 'closed':
      return [
        ...columns,
        {
          field: 'receipts',
          headerName: 'RPA',
          renderCell: (
            params: GridRenderCellParams<
              string | undefined,
              PayrollSummary & { hasApprovedPayslip: boolean }
            >,
          ) => {
            const { sendPayslipsProps, sendPayslips, SendPayslipsComponent } =
              // The linter is whining about this not being a React component because it doesn't
              // start with a capital letter!
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useSendPayslip({
                organizationId: params.row.organizationId,
                companyId: params.row.companyId,
              });

            // TODO: https://linear.app/wgmi-octopus/issue/OCT-4470/[opensearch]-add-payslip-status-to-opensearch
            const hasAlreadySent = !params.row.hasApprovedPayslip;
            //params.row.status === 'approved';

            if (hasAlreadySent) {
              return <Tag color="success">Enviado</Tag>;
            }

            const button = (
              <Button
                color="secondary"
                endIcon={<Box component="img" src={SendIcon} />}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  sendPayslips({
                    payrollId: params.row.payrollId,
                  });
                }}
              >
                Enviar
              </Button>
            );

            return (
              <Box display="flex" alignItems="center">
                {hasGeneratedAllPayslips ? (
                  button
                ) : (
                  <Tooltip title="Os recibos ainda estão sendo gerados. Tente novamente dentro de alguns instantes.">
                    <span>{button}</span>
                  </Tooltip>
                )}
                <SendPayslipsComponent
                  {...sendPayslipsProps}
                  payrollType={params.row.type}
                />
              </Box>
            );
          },
        },
      ];
    default:
      return columns;
  }
}

function getEmptyMessageForStatus(state: PayrollStatus) {
  switch (state) {
    case 'open':
      return 'Não existem pagamentos em aberto.';
    case 'approved':
      return 'Não existem pagamentos aprovados.';
    case 'closed':
      return 'Não existem pagamentos fechados.';
    default:
      return 'Não há pagamentos.';
  }
}
