import { z } from 'zod';

import { AdmissionDraftInputFormStep } from '@octopus/api';
import {
  IFormDefinition,
  UI_TYPE,
  cnpjSchema,
  nonEmptyString,
  optionalCnpjSchema,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { AdmissionFormState, FormStepDefinition } from '../../types';

const getFieldDefinition = ({
  formState,
  reviewMode,
}: {
  formState: AdmissionFormState;
  reviewMode: boolean;
}): IFormDefinition => {
  const { cnpjEntQual, razaoSocialEntQual } = formState;

  return [
    {
      label: 'Razão social da entidade qualificadora',
      placeholder: 'Ex: Escola de Informática ABC',
      type: reviewMode ? nonEmptyString : z.string().optional(),
      name: 'razaoSocialEntQual',
      uiType: UI_TYPE.TEXT,
      value: razaoSocialEntQual,
      hint: {
        title: 'Razão social da entidade qualificadora',
        body: 'É o nome da entidade intermediadora da contratação e pode ser encontrado no contrato. Exemplo: Universidade de São Paulo.',
      },
    },
    {
      label: 'CNPJ da entidade qualificadora',
      placeholder: '00.000.000/0000-00',
      mask: 'cnpj',
      type: reviewMode ? cnpjSchema : optionalCnpjSchema,
      name: 'cnpjEntQual',
      uiType: UI_TYPE.TEXT,
      value: cnpjEntQual,
    },
  ];
};

export const getJovemAprendizFields = ({
  formState,
  jovemAprendizFormSteps,
  reviewMode,
}: {
  formState: AdmissionFormState;
  jovemAprendizFormSteps: AdmissionDraftInputFormStep;
  reviewMode: boolean;
}): FormStepDefinition => ({
  definition: getFieldDefinition({
    formState,
    reviewMode,
  }),
  options: {
    id: admissionDraftFormSteps.jovem_aprendiz_info,
    title: 'Sobre o contrato do aprendiz',
    completed: jovemAprendizFormSteps?.completed,
    reviewed: jovemAprendizFormSteps?.reviewed,
    started: jovemAprendizFormSteps?.started,
  },
});
