import { vacationsScheduleStatuses } from '@octopus/vacations-types';

export const vacationsScheduleStatusesMap: Record<string, string> = {
  [vacationsScheduleStatuses.created]: 'Aguardando aprovação',
  [vacationsScheduleStatuses.waitingApproval]: 'Aguardando aprovação',
  [vacationsScheduleStatuses.approved]: 'Aprovado',
  [vacationsScheduleStatuses.rejected]: 'Rejeitado',
  [vacationsScheduleStatuses.canceled]: 'Cancelado',
  [vacationsScheduleStatuses.payrollCreated]: 'Folha criada',
  [vacationsScheduleStatuses.payrollApproved]: 'Folha aprovada',
  [vacationsScheduleStatuses.payrollArchived]: 'Folha arquivada',
  [vacationsScheduleStatuses.contractTerminated]: 'Contrato encerrado',
};

