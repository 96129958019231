import { GridColDef } from '@mui/x-data-grid';

import { vacationsScheduleStatuses } from '@octopus/vacations-types';


export enum VacationsDetailsTabs {
  available = 'available',
  requests = 'requests',
  calculations = 'calculations',
  history = 'history',
}

export enum OldVacationsDetailsTabs {
  available = 'available',
  requests = 'requests',
  scheduled = 'scheduled',
  history = 'history',
}

export type VacationsDetailsTabsConfig = {
  [key in VacationsDetailsTabs]: {
    key: string;
    label: string;
    count?: number;
    emptyMessage?: string;
  };
};

export enum VacationsTabs {
  people = 'people',
  vacationsHistory = 'vacationsHistory',
  requests = 'requests',
  calculations = 'calculations',
}

export type VacationsTableColumns = Record<string, GridColDef[]>;

export type VacationsTableTabs =
  | VacationsTabsConfig
  | ManagerVacationsTabsConfig;

export type VacationsTabsConfig = {
  [key in VacationsTabs]: {
    key: string;
    label: string;
    detailsTab?: VacationsDetailsTabs;
    emptyMessage?: string;
  };
};

export type ManagerVacationsTabsConfig = Omit<
  VacationsTabsConfig,
  VacationsTabs.calculations
>;

export type DeclinedVacationStatus =
  | typeof vacationsScheduleStatuses.rejected
  | typeof vacationsScheduleStatuses.canceled
  | typeof vacationsScheduleStatuses.payrollArchived;

export const declinedStatus = [
  vacationsScheduleStatuses.rejected,
  vacationsScheduleStatuses.canceled,
  vacationsScheduleStatuses.payrollArchived,
];

export type VacationsApprovedStatus =
  | typeof vacationsScheduleStatuses.approved
  | typeof vacationsScheduleStatuses.payrollCreated
  | typeof vacationsScheduleStatuses.payrollApproved;

export type VacationsPayrollStatus =
  | typeof vacationsScheduleStatuses.payrollCreated
  | typeof vacationsScheduleStatuses.payrollApproved;

export const payrollStatus = [
  vacationsScheduleStatuses.payrollCreated,
  vacationsScheduleStatuses.payrollApproved,
];

export const vacationsApprovedStatus = [
  vacationsScheduleStatuses.approved,
  ...payrollStatus,
];

export type VacationsScheduledStatus =
  | typeof vacationsScheduleStatuses.created
  | typeof vacationsScheduleStatuses.waitingApproval
  | typeof vacationsScheduleStatuses.approved;

export const vacationsScheduledStatus = [
  vacationsScheduleStatuses.created,
  vacationsScheduleStatuses.waitingApproval,
  vacationsScheduleStatuses.approved,
];
