import { MouseEvent, useState } from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import {
  ReportProblemOutlined,
  ReportProblemRounded,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Divider,
  Link,
  TextField,
  Typography,
} from '@mui/material';

import { Challenge } from '@octopus/api';

import { ShowablePasswordField, loginUser } from '../../../modules/login';
import { GoogleLoginButton } from '../../../modules/login/components/sso';
import { SSOUtils } from '../../../utils/sso';
import { useLoginProps } from '../page';

export function UserCredentials() {
  const { onSuccess } = useLoginProps();
  const navigate = useNavigate();

  const [state, setState] = useState({
    username: '',
    password: '',
    showPassword: false,
  });

  const showPassword = () => {
    setState((oldState) => ({
      ...oldState,
      showPassword: !oldState.showPassword,
    }));
  };

  const { mutate, isError, isLoading, error } = useMutation(
    async (e: MouseEvent) => {
      e.preventDefault();
      if (state.username === '' || state.password === '') {
        throw new Error('Preencha todos os campos');
      }
      const result = await loginUser(state.username, state.password);
      if ('token' in result) {
        onSuccess(result.token);
      } else {
        navigate(getChallengePage(result), {
          state: {
            username: state.username,
            challenge: result,
          },
        });
      }
    },
  );

  return (
    <Box width="100%" display="flex" flexDirection="column" gap={3}>
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography
            variant="caption"
            color="text.secondary"
            fontWeight="500"
            data-testid="login-title"
          >
            Email
          </Typography>
          <TextField
            fullWidth
            error={isError}
            variant="outlined"
            placeholder="Seu email"
            onChange={(e) =>
              setState((oldState) => ({
                ...oldState,
                username: e.target.value,
              }))
            }
            data-testid="username"
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            Senha
          </Typography>
          <ShowablePasswordField
            placeholder="Sua senha"
            error={isError}
            show={state.showPassword}
            setShow={showPassword}
            onChange={(e) =>
              setState((oldState) => ({
                ...oldState,
                password: e.target.value,
              }))
            }
            data-testid="password"
          />
        </Box>
      </Box>
      {isError && <AlertMessage message={(error as Error).message} />}
      <Box display="flex" justifyContent="center">
        <Link
          variant="body2"
          fontWeight="700"
          underline="none"
          component={RouterLink}
          to="/login/forgot-password"
          data-testid="forgot-password"
        >
          Esqueci a senha
        </Link>
      </Box>
      <LoadingButton
        fullWidth
        loading={isLoading}
        onClick={mutate}
        variant="contained"
        data-testid="submit"
        sx={{ p: 1.5 }}
        color="primaryAlt"
      >
        <Typography variant="body1" color="secondary" fontWeight="700">
          Entrar
        </Typography>
      </LoadingButton>
      <SSOLoginButtons />
      <Box display="flex" justifyContent="center" sx={{ paddingTop: 4 }}>
        <Link
          variant="caption"
          underline="hover"
          color="seconday"
          component={RouterLink}
          to="/privacy-policy"
          data-testid="privacy-policy"
        >
          Política de Privacidade
        </Link>
      </Box>
    </Box>
  );
}

function getChallengePage({ challenge }: Challenge) {
  switch (challenge?.name) {
    case 'NEW_PASSWORD_REQUIRED':
      return 'terms-of-service';
    default:
      throw new Error('Challenge desconhecido');
  }
}

type AlertMessageProps = {
  message: string;
  icon?: 'rounded' | 'outlined';
  dataTestId?: string;
};

function AlertMessage({
  message,
  icon = 'rounded',
  dataTestId = 'login-error',
}: AlertMessageProps) {
  const alertIcon =
    icon === 'rounded' ? (
      <ReportProblemRounded
        fontSize="small"
        color="error"
        sx={{ marginRight: '4px' }}
      />
    ) : (
      <ReportProblemOutlined
        fontSize="small"
        color="error"
        sx={{ marginRight: '4px' }}
      />
    );

  return (
    <Alert
      severity="error"
      icon={alertIcon}
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        paddingLeft: '24px',
      }}
    >
      <Typography variant="caption" fontWeight="500" data-testid={dataTestId}>
        {message}
      </Typography>
    </Alert>
  );
}

function SSOLoginButtons() {
  const [searchParams] = useSearchParams();
  const pageErrorCode = searchParams.get('error_code');

  if (!SSOUtils.Config.isEnabled()) {
    return null;
  }

  const alertMessageComponent = pageErrorCode ? (
    <AlertMessage
      message={SSOUtils.Errors.getMessage(pageErrorCode)}
      icon="outlined"
      dataTestId="sso-login-error"
    />
  ) : null;

  return (
    <>
      <Divider>
        <Typography
          textAlign="center"
          variant="caption"
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          ou
        </Typography>
      </Divider>
      <GoogleLoginButton
        origin={window.location.origin}
        title="Entrar com Google"
      />
      {alertMessageComponent}
    </>
  );
}
