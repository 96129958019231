import { Box, Skeleton, Tab, Tabs } from '@mui/material';

import { DrawerCard } from './DrawerCard';

export const DrawerSkeleton = (props: { isManager: boolean }) => {
  const { isManager } = props;
  return (
    <Box px={5} mr="15px">
      {/* Header Skeleton */}
      <Box pt={5} pb={2}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
          mt={2}
        >
          <Box>
            <Skeleton variant="text" width="200px" height={25} />
          </Box>
        </Box>
        <Box mt={1} display="flex" flexDirection="row" alignItems="center">
          <Box>
            <Skeleton variant="text" width="200px" height={20} />
            <Skeleton variant="text" width="200px" height={20} />
          </Box>
        </Box>
      </Box>

      {/* Tabs Skeleton */}
      <Box width={496}>
        <Tabs value={0}>
          {Array.from({ length: isManager ? 3 : 4 }).map((_, index) => (
            <Tab
              key={index}
              label={<Skeleton variant="text" width={90} height={30} />}
            />
          ))}
        </Tabs>
      </Box>

      {/* Content Skeleton */}
      <Box mt={3}>
        {Array.from({ length: 2 }).map((_, index) => (
          <VacationSkeletonCard key={index} index={`${index}`} />
        ))}
      </Box>
    </Box>
  );
};

export const VacationSkeletonCard = ({ index }: { index: string }) => {
  return (
    <DrawerCard key={index}>
      <Box display="flex" flexDirection="column" width="420px">
        <Skeleton variant="text" width={120} height={29} sx={{ mb: 0.5 }} />
        <Skeleton
          variant="rectangular"
          width={250}
          height={15}
          sx={{ mb: 0.5 }}
        />
        <Skeleton variant="rectangular" width={250} height={15} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={0.5}
        >
          <Skeleton variant="text" width={100} height={29} />
        </Box>
      </Box>
    </DrawerCard>
  );
};
