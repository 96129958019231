import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { Box, ClickAwayListener } from '@mui/material';

import {
  PayrollInputsConfig,
  SearchInput,
  useSearchAllContracts,
} from '@octopus/api';
import { contractStatuses, contractTypes } from '@octopus/contract-types';

import { ColumnInfoPopper } from '../../[period]/[type]/inputs/ColumnInfoPopper';
import { PayrollEmployeeData } from '../../[period]/[type]/inputs/types';
import { useInfoPoppers } from '../../[period]/[type]/inputs/useInfoPoppers';
import {
  EmployeesState,
  HasPayrollBeenEdited,
} from '../../[period]/[type]/inputs/useSubmissionState';

import { prepareColDef } from './columnDefs';
import { InputNameCellEditor } from './InputNameCellEditor';
import { RowInfoPopper } from './RowInfoPopper';
import { copyCell, copyHeader, pasteCell } from './utils';

const CREATE_RPA_PREFIX = 'create-rpa';

export type InputRpaTableProps = {
  organizationId: string;
  companyId: string;
  config: PayrollInputsConfig;
  state: EmployeesState;
  hasPayrollBeenEdited: HasPayrollBeenEdited;
  mode: 'single' | 'multiple';
  legalEntityIdRef: MutableRefObject<string>;
};

function InputRpaTable({
  organizationId,
  companyId,
  config,
  state: { data, edit },
  hasPayrollBeenEdited,
  mode,
  legalEntityIdRef,
}: InputRpaTableProps) {
  const gridRef = useRef<AgGridReact>();
  const [activeEditor, setActiveEditor] = useState<InputNameCellEditor | null>(
    null,
  );
  const { mutate, data: autonomoContracts } = useSearchAllContracts();
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [gridData, setGridData] = useState<PayrollEmployeeData[]>([]);

  const EMPTY_EMPLOYEE = {
    name: '',
    contractId: '',
    personId: '',
    employeeId: '',
    inputs: {},
  };

  useEffect(() => {
    const newEmployeeEntry =
      mode === 'multiple'
        ? [
            {
              ...EMPTY_EMPLOYEE,
              payrollId: `${CREATE_RPA_PREFIX}-${gridData.length + 1}`,
              legalEntityId: legalEntityIdRef.current,
            },
          ]
        : [];
    setGridData([...data, ...newEmployeeEntry]);
  }, [data]);

  const {
    columnInfoState,
    rowInfoState,
    showColumnInfo,
    showRowInfo,
    hideInfoPoppers,
  } = useInfoPoppers();

  const filteredContracts = useMemo(() => {
    if (!autonomoContracts?.data) return [];
    return autonomoContracts.data.filter(
      (contract) =>
        !gridData.find(
          (employee) => employee.contractId === contract.contractId,
        ),
    );
  }, [autonomoContracts?.data, gridData]);

  useEffect(() => {
    if (activeEditor && filteredContracts) {
      activeEditor.updateValues(filteredContracts);
    }
  }, [filteredContracts, activeEditor]);

  const colDefs = prepareColDef({
    config,
    showColumnInfo,
    showRowInfo,
    hideInfoPoppers,
    state: { data, edit },
    hasPayrollBeenEdited,
    autonomoContracts: autonomoContracts?.data ?? [],
    setSearchTerm,
    onEditorCreated: (editor: InputNameCellEditor) => {
      setActiveEditor(editor);
    },
    onEditorDestroyed: () => {
      setActiveEditor(null);
    },
    legalEntityIdRef,
  });

  useEffect(() => {
    if (organizationId) {
      mutate({
        pathParams: {
          organizationId,
        },
        body: {
          ...(searchTerm ? { query: searchTerm } : {}),
          filtering: {
            elements: {
              workerCategory: ['clt:autonomo'],
              status: [{ not: contractStatuses.terminated }],
              contractType: [contractTypes.brClt],
              companyId: [companyId],
            },
          },
          sorting: [
            {
              field: 'name',
              order: 'asc',
            },
          ],
        } as SearchInput,
      });
    }
  }, [organizationId, searchTerm, mutate, companyId]);

  return (
    <ClickAwayListener
      onClickAway={() => gridRef.current?.api?.clearCellSelection()}
    >
      <Box width="100%" height="100%">
        <Box
          className="ag-theme-quartz"
          width="100%"
          height="100%"
          sx={{
            minHeight: '48px',
            maxHeight: '60vh',
            overflow: 'auto',
          }}
        >
          <AgGridReact<PayrollEmployeeData>
            headerHeight={48}
            domLayout="normal"
            noRowsOverlayComponent={() => (
              <div style={{ padding: '30px', textAlign: 'center' }} />
            )}
            columnDefs={colDefs}
            rowData={gridData}
            onGridReady={initGrid}
            ref={gridRef}
            onUndoStarted={() => edit.undo()}
            onRedoStarted={() => edit.redo()}
            processCellForClipboard={(params) => copyCell(params, config)}
            processCellFromClipboard={(params) => pasteCell(params, config)}
            processHeaderForClipboard={copyHeader}
            cellSelection={true}
            autoSizeStrategy={{
              type: 'fitGridWidth',
              defaultMinWidth: 100,
            }}
          />
          {mode === 'multiple' && (
            <ColumnInfoPopper
              open={columnInfoState.open}
              anchorEl={columnInfoState.ref}
              info={columnInfoState.info}
              data={data}
              api={gridRef.current?.api}
              handleClose={hideInfoPoppers}
              setAll={edit.setAll}
            />
          )}
          {mode === 'multiple' && (
            <RowInfoPopper
              organizationId={organizationId}
              companyId={companyId}
              open={rowInfoState.open}
              anchorEl={rowInfoState.ref}
              info={rowInfoState.info}
              api={gridRef.current?.api}
              handleClose={hideInfoPoppers}
              edit={edit}
            />
          )}
        </Box>
      </Box>
    </ClickAwayListener>
  );
}

function initGrid({ api }: GridReadyEvent<PayrollEmployeeData>) {
  api.applyColumnState({
    defaultState: { sort: null },
  });
}

export default InputRpaTable;
