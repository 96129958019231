import { Typography } from '@mui/material';

export const BodyText = ({
  children,
  color = 'text.secondary',
}: {
  children: React.ReactNode;
  color?: string;
}) => {
  return (
    <Typography mb={0.5} fontSize={12} variant="body2" color={color}>
      {children}
    </Typography>
  );
};

export const DetailText = ({
  children,
  color = 'text.primary',
}: {
  children: React.ReactNode;
  color?: string;
}) => {
  return (
    <Typography mb={0.5} fontSize={12} variant="body2" color={color}>
      {children}
    </Typography>
  );
};

export const LinkText = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <Typography
      onClick={onClick}
      mb={0.5}
      fontSize={12}
      variant="body2"
      color="info.main"
      sx={{ cursor: 'pointer' }}
      display="flex"
    >
      {children}
    </Typography>
  );
};
