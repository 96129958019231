import { useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, IconButton, Typography } from '@mui/material';

import { VacationsScheduleSummary } from '@octopus/api';

import { DrawerEmptyState } from './DrawerEmptyState';
import { VacationHistoryCard } from './HistoryCard';
import { vacationsDetailsTabsConfig } from './VacationsTabs';

export const HistoryTab = ({
  history,
  rejectedOrCanceledVacations,
  isManager,
}: {
  history: VacationsScheduleSummary[];
  rejectedOrCanceledVacations: VacationsScheduleSummary[];
  isManager: boolean;
}) => {
  const [expandButtonState, setExpandButtonState] = useState(false);

  const handleExpandButtonClick = () => {
    setExpandButtonState(!expandButtonState);
  };
  return (
    <Box>
      {history.length === 0 && rejectedOrCanceledVacations.length === 0 && (
        <DrawerEmptyState
          message={vacationsDetailsTabsConfig.history.emptyMessage}
        />
      )}

      {history.map((summary: VacationsScheduleSummary, idx: number) => {
        return (
          <VacationHistoryCard
            key={`${summary.sequence}-${idx}`}
            summary={summary}
            isManager={isManager}
          />
        );
      })}

      {rejectedOrCanceledVacations.length > 0 && (
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={5}
            mb={1}
            px={0.5}
            onClick={handleExpandButtonClick}
            style={{ cursor: 'pointer' }}
          >
            <Typography
              variant="subtitle1"
              fontSize="14px"
              fontWeight={700}
              alignContent="center"
            >
              {'Recusadas e canceladas'}
            </Typography>

            <IconButton>
              {expandButtonState ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
          <Collapse in={expandButtonState} timeout="auto" unmountOnExit>
            {rejectedOrCanceledVacations.map(
              (summary: VacationsScheduleSummary, idx: number) => {
                return (
                  <VacationHistoryCard
                    key={`${summary.sequence}-${idx}`}
                    summary={summary}
                    isManager={isManager}
                  />
                );
              },
            )}
          </Collapse>
        </Box>
      )}
    </Box>
  );
};
