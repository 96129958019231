import { IconUserPlus } from '@tabler/icons-react';

import { Box, Divider, Typography } from '@mui/material';

type Props = {
  hideLeftMenu: boolean;
  title: string;
};

export const NewAdmissionHeader = ({ hideLeftMenu, title }: Props) => {
  return hideLeftMenu ? (
    <Box
      sx={{
        marginBottom: 3,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        component={IconUserPlus}
        sx={{ height: '40px', width: 'auto', mr: 2 }}
      />
      <Typography variant="h2">Nova admissão</Typography>
    </Box>
  ) : (
    title && (
      <Box
        sx={{
          marginBottom: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3">{title}</Typography>
        <Divider sx={{ mt: 2, mb: 3 }} />
      </Box>
    )
  );
};
