import {
  AdmissionDraftInputFormSteps,
  AdmissionDraftStatus,
} from '@octopus/api';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CompanyContext } from '../../../../../modules/types';
import { TFFlags } from '../../../../fflags';
import { getProfissionalFields } from '../fieldDefinitions';
import { getCriacaoUsuarioFields } from '../fieldDefinitions/criacaoUsuario';
import { getModalidadeContratoFields } from '../fieldDefinitions/modalidadeContrato';
import { getDadosEmpresaFields } from '../fieldDefinitions/pj/dadosEmpresa';
import { getHonorariosFields } from '../fieldDefinitions/pj/honorarios';
import { getPjFields } from '../fieldDefinitions/pj/pj';
import {
  AdmissionFormState,
  AdmissionFormSteps,
  FormStepDefinition,
} from '../types';

import * as common from './common';
import { getSummaryStep } from './common';
import { IFormSteps } from './types';

const getAdminSteps = async ({
  organizationId,
  companyId,
  draftStatus,
  formState,
  companyContext,
  formSteps,
  isWorkerExperience,
  fflags,
}: {
  organizationId: string;
  companyId: string;
  draftStatus: AdmissionDraftStatus;
  formState: AdmissionFormState;
  companyContext: CompanyContext;
  formSteps: AdmissionDraftInputFormSteps;
  isWorkerExperience: boolean;
  fflags: TFFlags;
}): Promise<AdmissionFormSteps> => {
  if (
    !companyContext ||
    !companyContext.costCenters ||
    !companyContext.jobTitles ||
    !companyContext.legalEntities
  ) {
    return {};
  }

  const { admissionType } = formState;

  const modalidade_contrato = getModalidadeContratoFields({
    companyContext,
    formState,
    fflags,
    modalidadeFormSteps: formSteps[admissionDraftFormSteps.modalidade_contrato],
  });

  const criacao_usuario = getCriacaoUsuarioFields({
    formState,
    criacaoUsuarioFormSteps: formSteps[admissionDraftFormSteps.criacao_usuario],
    pjEnabled: fflags.pjAdmissionEnabled.enabled,
  });

  const profissional = await getProfissionalFields({
    companyContext,
    formState,
    organizationId,
    companyId,
    profissionalFormSteps: formSteps[admissionDraftFormSteps.profissional],
    hideCargoConfianca: true,
    isPj: true,
  });

  const pj_info = getPjFields({
    formState,
    pjFormSteps: formSteps[admissionDraftFormSteps.pj_info],
  });

  const remuneracao = getHonorariosFields({
    formState,
    remuneracaoFormSteps: formSteps[admissionDraftFormSteps.remuneracao],
  });

  const envio_convite = getSummaryStep({
    draftStatus,
    admissionType,
    stepName: 'envio_convite',
    formSteps,
    isWorkerExperience,
  });

  const { custom_fields_admin } = await common.getAdminSteps({
    organizationId,
    formState,
    formSteps,
  });

  return {
    modalidade_contrato,
    criacao_usuario,
    profissional,
    pj_info,
    remuneracao,
    ...(custom_fields_admin && { custom_fields_admin }),
    ...(envio_convite && { envio_convite }),
  };
};

export const getWorkerSteps = async ({
  organizationId,
  formState,
  formSteps,
  finalizaAdmissao,
}: {
  organizationId: string;
  formState: AdmissionFormState;
  formSteps: AdmissionDraftInputFormSteps;
  finalizaAdmissao: FormStepDefinition;
}): Promise<AdmissionFormSteps> => {
  const {
    dados_pessoais,
    endereco_e_contatos,
    dependentes,
    dados_bancarios,
    documentos,
    finaliza_admissao,
    custom_fields_worker,
  } = await common.getWorkerSteps({
    organizationId,
    formState,
    formSteps,
    finalizaAdmissao,
    isPj: true,
  });

  const dados_empresa = getDadosEmpresaFields({
    formState,
    empresaFormSteps: formSteps[admissionDraftFormSteps.dados_empresa],
  });

  return {
    dados_pessoais,
    dados_empresa,
    endereco_e_contatos,
    dependentes,
    dados_bancarios,
    documentos,
    ...(custom_fields_worker && { custom_fields_worker }),
    ...(finaliza_admissao && { finaliza_admissao }),
  };
};

export const pj = {
  getAdminSteps,
  getWorkerSteps,
} as IFormSteps;
