import { useNavigate } from 'react-router-dom';


import { IconChevronRight } from '@tabler/icons-react';

import { Box, Button, Typography } from '@mui/material';

import { VacationsScheduleSummary } from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';

import { shouldShowReceipt } from '../../utils/status';
import {
  VacationInfoTagsVariants,
  VacationStatusTag,
  VacationsChip,
} from '../Status';

import { DrawerCard } from './DrawerCard';

export const VacationHistoryCard = ({
  summary,
  isManager,
}: {
  summary: VacationsScheduleSummary;
  isManager: boolean;
}) => {
  const {
    daysTaken,
    startDate,
    endDate,
    thirteenthAdvance,
    accrualPeriod,
    daysSold,
  } = summary;

  const vacationsInfo = [
    {
      text: daysSold > 0 ? `${daysSold} dias abonados` : 'Sem abono',
      variant: daysSold > 0 ? 'success' : 'default',
      disabled: daysSold === 0,
    },
    {
      text: thirteenthAdvance
        ? 'Pediu adiantamento de 13º'
        : 'Sem adiantamento de 13º',
      variant: thirteenthAdvance ? 'success' : 'default',
      disabled: !thirteenthAdvance,
    },
  ];

  const shouldSeeReceipt = !isManager && shouldShowReceipt(summary.status);
  const navigate = useNavigate();
  const onClick = () => {
    navigate(
      `/vacations/${summary.contractId}/receipt?sequence=${summary.sequence}`,
    );
  };

  return (
    <DrawerCard>
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex">
          <Typography mb={0.5} variant="h4">
            {`${daysTaken} dias,`}
          </Typography>
          <Typography ml={0.5} variant="h4" fontWeight={400}>
            {`de ${formatDateBR(startDate)} a ${formatDateBR(endDate)}`}
          </Typography>
        </Box>

        <Typography fontSize={12} variant="body1" component="div">
          {`Periodo aquisitivo: ${formatDateBR(accrualPeriod.startDate)} a ${formatDateBR(accrualPeriod.endDate)}`}
        </Typography>

        <Box mt={1} display="flex" gap={1} width="fit-content">
          {vacationsInfo.map((info, idx) => (
            <VacationsChip
              key={`info-${idx}`}
              text={info.text}
              variant={info.variant as VacationInfoTagsVariants}
              disabled={info.disabled}
            />
          ))}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="end"
      >
        <VacationStatusTag summary={summary} />
        {shouldSeeReceipt && (
          <Button
            onClick={onClick}
            variant="text"
            size="small"
            sx={{
              fontSize: '12px',
              mt: 1.5,
              pr: 0,
            }}
            endIcon={<IconChevronRight size={16} />}
          >
            Recibo
          </Button>
        )}
      </Box>
    </DrawerCard>
  );
};
