import { z } from 'zod';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import {
  AdmissionDraftInputFormStep,
  OrganizationEntry,
  SearchInput,
  fetchGetOrganizationEntry,
  fetchSearchAdmissionDrafts,
} from '@octopus/api';
import { CBOs } from '@octopus/esocial/mapper';
import {
  IFormDefinition,
  NEW_UI_TYPE,
  UI_TYPE,
  nonEmptyString,
} from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CompanyContext } from '../../../../../modules/types';
import {
  getOptionsFromCompanyContext,
  getOptionsFromMapper,
} from '../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../types';

import { cargoConfiancaSchema } from './profissionalSchema';

const getCargoConfianca = ({
  hideCargoConfianca,
  formState,
}: {
  hideCargoConfianca: boolean;
  formState: AdmissionFormState;
}) => {
  if (hideCargoConfianca) return undefined;

  const { cargoConfianca } = formState;

  return {
    label: 'É cargo de confiança?',
    type: cargoConfiancaSchema,
    name: 'cargoConfianca',
    uiType: UI_TYPE.SELECT,
    hint: {
      title: 'Indique se o colaborador ocupa um cargo de confiança',
      body: 'Cargos de confiança têm mais responsabilidades e autonomia, com regras diferentes para jornada, benefícios e decisões. Isso impacta a folha de pagamento.',
    },
    fieldsOptions: [
      {
        label: 'Não',
        selected:
          cargoConfianca?.enabled == null ||
          cargoConfianca?.enabled === 'false',
        fields: [
          {
            label: '-',
            hidden: true,
            type: nonEmptyString,
            name: 'disabled',
            value: 'true',
            uiType: UI_TYPE.TEXT,
          },
        ],
      },
      {
        label: 'Sim',
        selected: cargoConfianca?.enabled === 'true',
        fields: [
          {
            label: '-',
            hidden: true,
            type: nonEmptyString,
            name: 'enabled',
            value: 'true',
            uiType: UI_TYPE.TEXT,
          },
          {
            label: 'CBO da Função',
            type: nonEmptyString,
            name: 'CBOFuncao',
            uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
            placeholder: 'Selecione uma função',
            options: getOptionsFromMapper({
              mapper: CBOs,
              currentValue: cargoConfianca?.CBOFuncao,
              showKey: true,
            }),
          },
          {
            label: 'Nome da Função',
            type: nonEmptyString,
            name: 'nmFuncao',
            uiType: UI_TYPE.TEXT,
            value: cargoConfianca?.nmFuncao || '',
          },
        ],
      },
    ],
  };
};

const getFieldDefinition = ({
  companyContext,
  formState,
  hideCargoConfianca,
  isPj,
  latestAdmissions,
  legalEntityId,
}: {
  companyContext: CompanyContext;
  formState: AdmissionFormState;
  hideCargoConfianca: boolean;
  isPj: boolean;
  latestAdmissions: {
    latestWorkerIds: string[] | null;
    latestMatriculas: string[] | null;
    showLegalEntity: boolean;
  };
  legalEntityId: string;
}): IFormDefinition => {
  const {
    workerId,
    emailCorp,
    matricula,
    centro_de_custo,
    departamento,
    cargo,
  } = formState;

  const cargoConfianca = getCargoConfianca({ hideCargoConfianca, formState });
  const { latestWorkerIds, latestMatriculas, showLegalEntity } =
    latestAdmissions;

  const filial = showLegalEntity
    ? companyContext?.legalEntities?.find(
        (legalEntity) => legalEntity.id === legalEntityId,
      )?.name
    : null;

  const latestWorkerIdsTitle = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {filial != null && (
        <>
          <Typography variant="h4">Filial</Typography>
          <Typography variant="body1" whiteSpace="preserve">
            {filial}
          </Typography>
        </>
      )}
      <Typography variant="h4">Últimas matrículas cadastradas</Typography>
    </Box>
  );

  return [
    {
      label: 'Matrícula',
      type: isPj ? z.string().optional() : nonEmptyString,
      name: 'workerId',
      uiType: UI_TYPE.TEXT,
      placeholder: '000000',
      value: workerId || '',
      hint:
        latestWorkerIds && latestWorkerIds.length > 0
          ? {
              title: latestWorkerIdsTitle,
              body: latestWorkerIds.join('\n'),
            }
          : undefined,
    },
    !isPj && {
      label: 'Matrícula do eSocial',
      type: nonEmptyString,
      name: 'matricula',
      uiType: UI_TYPE.TEXT,
      placeholder: '000000',
      value: matricula || '',
      hint:
        latestMatriculas && latestMatriculas.length > 0
          ? {
              title: 'Últimas matrículas eSocial cadastradas',
              body: latestMatriculas.join('\n'),
            }
          : undefined,
    },
    {
      label: 'Cargo',
      type: nonEmptyString,
      name: 'cargo',
      uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
      placeholder: 'Selecione um cargo',
      options: getOptionsFromCompanyContext({
        baseContext: companyContext?.jobTitles,
        currentValue: cargo,
      }),
      hint: {
        title: 'Cargo ocupado pela pessoa na empresa',
        body: 'Define a posição hierárquica e responsabilidades, influenciando tarefas, avaliações de desempenho e progressão de carreira.',
      },
    },
    cargoConfianca,
    {
      label: 'Departamento',
      type: nonEmptyString,
      name: 'departamento',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Nome do departamento',
      value: departamento || '',
      hint: {
        title: 'Nome do departamento onde o colaborador trabalha',
        body: 'Identificar o departamento facilita a gestão de equipes, alocação de recursos e coordenação de projetos.',
      },
    },
    {
      label: 'Centro de custo',
      type: nonEmptyString,
      name: 'centro_de_custo',
      uiType: UI_TYPE.SELECT,
      placeholder: 'Selecione um centro de custo',
      options: getOptionsFromCompanyContext({
        baseContext: companyContext?.costCenters,
        currentValue: centro_de_custo,
      }),
      hint: {
        title: 'Código/Nome do centro de custo associado ao colaborador',
        body: 'Importante para o controle financeiro, atribuindo custos a áreas da empresa e facilitando a análise de despesas.',
      },
    },
    {
      label: 'Email corporativo',
      type: z.string().email().optional(),
      name: 'emailCorp',
      uiType: UI_TYPE.TEXT,
      placeholder: 'Ex: exemplo@empresa.com.br',
      value: emailCorp || '',
    },
  ].filter((item) => item);
};

export const getProfissionalFields = async ({
  companyContext,
  formState,
  profissionalFormSteps,
  organizationId,
  companyId,
  hideCargoConfianca = false,
  isPj = false,
}: {
  companyContext: CompanyContext;
  formState: AdmissionFormState;
  profissionalFormSteps: AdmissionDraftInputFormStep;
  organizationId: string;
  companyId: string;
  hideCargoConfianca?: boolean;
  isPj?: boolean;
}): Promise<FormStepDefinition> => {
  const legalEntityId = formState.legalEntity;
  const latestAdmissions = await getLatestAdmissions({
    organizationId,
    companyId,
    legalEntityId,
  });

  return {
    definition: getFieldDefinition({
      companyContext,
      formState,
      hideCargoConfianca,
      isPj,
      latestAdmissions,
      legalEntityId,
    }),
    options: {
      id: admissionDraftFormSteps.profissional,
      title: 'Informações profissionais',
      completed: profissionalFormSteps?.completed,
      reviewed: profissionalFormSteps?.reviewed,
    },
  };
};

const getLatestAdmissions = async ({
  organizationId,
  companyId,
  legalEntityId,
}: {
  organizationId: string;
  companyId: string;
  legalEntityId: string;
}) => {
  const organization = (await fetchGetOrganizationEntry({
    pathParams: { organizationId },
  })) as OrganizationEntry;

  const searchInput: SearchInput = {
    pagination: { page: 0, size: 5 },
    sorting: [
      {
        field: 'createdOn',
        order: 'desc',
      },
    ],
  };

  let searchInputByWorkerId: SearchInput = null;
  let showLegalEntity = false;

  switch (organization.settings?.enforceUniqueWorkerIdBy) {
    case 'organization': {
      searchInputByWorkerId = {
        ...searchInput,
      };
      break;
    }
    case 'legalEntity': {
      showLegalEntity = true;
      searchInputByWorkerId = {
        ...searchInput,
        filtering: {
          elements: {
            legalEntityId: legalEntityId ? [legalEntityId] : [],
            companyId: [companyId],
          },
        },
      };
      break;
    }
    case 'company': {
      searchInputByWorkerId = {
        ...searchInput,
        filtering: {
          elements: {
            companyId: [companyId],
          },
        },
      };
      break;
    }
  }

  const [latestAdmissionsByWorkerId, latestAdmissionByMatricula] =
    await Promise.all([
      fetchSearchAdmissionDrafts({
        pathParams: {
          organizationId: organizationId ?? '',
        },
        body: searchInputByWorkerId,
      }),
      fetchSearchAdmissionDrafts({
        pathParams: {
          organizationId: organizationId ?? '',
        },
        body: searchInput,
      }),
    ]);

  const latestWorkerIds = latestAdmissionsByWorkerId.data
    ?.map((draft) => draft.workerId)
    .filter((workerId) => workerId);
  const latestMatriculas = latestAdmissionByMatricula.data
    ?.map((draft) => draft.matricula)
    .filter((matricula) => matricula);

  return { latestWorkerIds, latestMatriculas, showLegalEntity };
};
