import { useEffect, useState } from 'react';

import { IconFile, IconTrash } from '@tabler/icons-react';

import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Select, Tooltip, Typography } from '@mui/material';

import {
  AdmissionDraftAttachmentAllowedContentType,
  AdmissionDraftAttachmentEntry,
  AdmissionDraftAttachmentType,
  AdmissionDraftInputFormStepEnum,
  fetchDeleteAdmissionDraftAttachment,
  fetchPostAdmissionDraftAttachment,
  useGetAllAdmissionDraftAttachments,
} from '@octopus/api';
import { admissionDraftAttachmentTypes } from '@octopus/onboarding-types';

import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { FileComponent } from '../../companies/preferences/fileComponents';
import { uploadFile } from '../../companies/preferences/fileComponents/UploadComponent';
import { AdmissionFile } from '../utils/types';

import { RequiredTag } from './RequiredTag';

type Props = {
  setFileCallback?: (file: AdmissionFile) => void;
  deleteFileCallback?: (fileId: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  organizationId: string;
  companyId: string;
  draftId: string;
  allowedTypes: AdmissionDraftAttachmentType[];
  title: string;
  description: string;
  required: boolean;
  dependentId?: string;
  formStep: AdmissionDraftInputFormStepEnum;
  isLoading?: boolean;
  disabled?: boolean;
};

export const UploadAdmissionAttachment = ({
  setFileCallback,
  deleteFileCallback,
  setIsLoading,
  organizationId,
  companyId,
  draftId,
  allowedTypes,
  title,
  description,
  required,
  dependentId,
  formStep,
  isLoading,
  disabled,
}: Props) => {
  const { showSnackbar } = useSnackbar();
  const hasOnlyOneType = allowedTypes.length === 1;

  const [attachments, setAttachments] = useState<AdmissionFile[]>();
  const shouldFetchAttachments = !!organizationId && !!draftId && !attachments;

  const attachmentsQuery = useGetAllAdmissionDraftAttachments(
    {
      pathParams: {
        organizationId: organizationId ?? '',
        draftId: draftId ?? '',
      },
    },
    {
      enabled: shouldFetchAttachments,
      retry: 10,
      cacheTime: 1000,
    },
  );

  const { data, isLoading: isLoadingAttachments } = attachmentsQuery;

  useEffect(() => {
    if (dependentId === '-1') {
      setAttachments(null);
    }

    if (data != null && attachments == null) {
      const atts = data
        ?.filter((att) =>
          dependentId != null
            ? att.dependentId === dependentId
            : att.dependentId == null,
        )
        .filter((att) => allowedTypes.includes(att.type))
        ?.map((att) => ({
          url: att.downloadUrl,
          type: att.type,
          name: att.fileName,
          id: att.id,
        }));

      setAttachments(atts);
      if (atts.length > 0 && setFileCallback) {
        setFileCallback(atts[0]);
      }
    }
  }, [data, dependentId, attachments]);

  const [file, setFile] = useState<File>(null);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [fileType, setFileType] = useState<AdmissionDraftAttachmentType>(
    hasOnlyOneType ? allowedTypes[0] : undefined,
  );

  const uploadAttachment = (newFile: File) => {
    setIsLoadingFile(true);
    setIsLoading(true);

    fetchPostAdmissionDraftAttachment({
      pathParams: {
        organizationId,
        draftId,
      },
      body: {
        companyId,
        type: fileType,
        fileName: newFile.name,
        formStep,
        contentType: newFile.type as AdmissionDraftAttachmentAllowedContentType,
        contentLength: newFile.size,
        dependentId,
      },
    })
      .then((attachment: AdmissionDraftAttachmentEntry) => {
        uploadFile(attachment.uploadUrl, newFile)
          .then((resp) => {
            setFile(file);
            setFileCallback &&
              setFileCallback({
                url: resp.url,
                type: fileType,
                name: newFile.name,
                id: attachment.id,
              });
            setAttachments([
              {
                url: resp.url,
                type: fileType,
                name: newFile.name,
                id: attachment.id,
              },
            ]);
            setIsLoadingFile(false);
            setIsLoading(false);
            setFileType(hasOnlyOneType ? allowedTypes[0] : null);
          })
          .catch((error) => {
            setFile(null);
            setAttachments(null);
            setIsLoadingFile(false);
            setIsLoading(false);

            console.error(error);
            showSnackbar({
              isOpen: true,
              Message:
                'Erro ao fazer upload do documento. Por favor tente novamente ou entre em contato com a Tako.',
              variant: 'error',
              autoHideDuration: 3000,
            });
          });
      })
      .catch((error) => {
        setFile(null);
        setAttachments(null);
        setIsLoadingFile(false);
        setIsLoading(false);
        console.error(error);
        showSnackbar({
          isOpen: true,
          Message:
            'Erro ao fazer upload do documento. Por favor tente novamente ou entre em contato com a Tako.',
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  };

  const deleteFile = (fileId: string) => {
    setIsLoadingFile(true);
    setIsLoading(true);
    fetchDeleteAdmissionDraftAttachment({
      pathParams: { organizationId, draftId, attachmentId: fileId },
    })
      .then(() => {
        setIsLoadingFile(false);
        setIsLoading(false);
        deleteFileCallback && deleteFileCallback(fileId);
        setAttachments([]);
        setFile(null);
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingFile(false);
        setIsLoading(false);
        showSnackbar({
          isOpen: true,
          Message:
            'Erro ao remover documento. Por favor tente novamente ou entre em contato com a Tako.',
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  };

  const renderSelectedValue = (selected: AdmissionDraftAttachmentType) => {
    if (selected == null) {
      return 'Selecione...';
    }

    return admissionDraftAttachmentTypes[selected];
  };

  const fileToShow = attachments?.[0];
  const disableActions =
    isLoading || isLoadingFile || isLoadingAttachments || disabled;
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h4">
        {title}
        <RequiredTag required={required} />
      </Typography>
      <Box sx={{ pt: 1 }}>
        <Typography variant="caption" sx={{ whiteSpace: 'preserve' }}>
          {description}
        </Typography>
      </Box>
      {fileToShow && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            background: theme.palette.background.secondary,
            borderRadius: 1,
            gap: 1.5,
            my: 2,
            px: 2,
            py: 1.5,
          })}
        >
          <IconFile width={20} height={20} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
            }}
          >
            <Typography variant="caption">{fileToShow?.name}</Typography>
            <Typography variant="caption" color="gray">
              {fileToShow?.type
                ? admissionDraftAttachmentTypes[fileToShow?.type]
                : '-'}
            </Typography>
          </Box>
          <Tooltip title="Remover documento">
            <LoadingButton
              variant="text"
              disabled={disableActions}
              loading={isLoadingFile}
              sx={{
                p: 0,
                marginLeft: 'auto',
              }}
              onClick={(evt) => {
                evt.stopPropagation();
                deleteFile(fileToShow.id);
              }}
              data-testid={`delete-attachment`}
            >
              <IconTrash
                width={20}
                height={20}
                color={disableActions ? 'rgba(37, 37, 45, 0.16)' : '#D9204C'}
              />
            </LoadingButton>
          </Tooltip>
        </Box>
      )}
      {!fileToShow && (
        <Box sx={{ mt: 2.5 }}>
          {!hasOnlyOneType && (
            <>
              <Typography variant="caption" sx={{ display: 'flex', mb: 1 }}>
                Tipo de documento
                <RequiredTag required={required} />
              </Typography>
              <Select
                id="tipoDoc"
                fullWidth
                displayEmpty
                renderValue={renderSelectedValue}
                sx={{ mb: 2 }}
                required
                value={fileType ?? undefined}
                onChange={(evt) =>
                  setFileType(evt.target.value as AdmissionDraftAttachmentType)
                }
              >
                {allowedTypes.map((key: AdmissionDraftAttachmentType) => (
                  <MenuItem key={key} value={key} selected={key === fileType}>
                    <Typography variant="inherit" noWrap>
                      {admissionDraftAttachmentTypes[key]}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </>
          )}

          <FileComponent
            disabled={!fileType || disableActions}
            disabledMessage={
              !fileType
                ? 'Por favor selecione o tipo do documento no seletor acima'
                : isLoading || isLoadingAttachments
                  ? 'Por favor aguarde...'
                  : 'Nenhum documento pode ser enviado nesse momento'
            }
            setFile={(file) => uploadAttachment(file)}
            isLoading={isLoadingFile || isLoadingAttachments}
            file={file}
            filePreviewType="none"
          />
        </Box>
      )}
    </Box>
  );
};
