import React, { useState } from 'react';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';

import {
  PjPaymentAttachmentEntry,
  PjPaymentRequestEntry,
  fetchSearchByContractPjPaymentRequests,
} from '@octopus/api';
import { BrTaxLabel, brTaxesLabels } from '@octopus/contract-types';
import { formatDateTimeBR, formatMoney } from '@octopus/formatters';
import {
  PaymentRequestStatus,
  paymentRequestStatuses,
  paymentRequestStatusesLabels,
} from '@octopus/pj-payment-types';
import { Tag } from '@octopus/ui/design-system';

import receiptDelete from '../../../assets/receiptDelete.svg';
import trashIcon from '../../../assets/trashRed.svg';
import { downloadBlobFile } from '../../downloads/download';

import { AttachmentDeleteConfirmationDialog } from './dialogs';

export function AttachmentInfo({
  attachment,
  uploadAttachment,
  deleteAttachment,
  paymentRequestStatus,
  isLoading,
}: {
  attachment: PjPaymentAttachmentEntry;
  uploadAttachment: (file: File) => Promise<void>;
  deleteAttachment: () => Promise<void>;
  paymentRequestStatus: PaymentRequestStatus;
  isLoading: boolean;
}) {
  const [confirmation, setConfirmation] = useState(false);

  if (isLoading) {
    return (
      <Box display={'flex'} py={2} gap={1} justifyContent={'center'}>
        <Skeleton variant="rounded" height="70px" width="100%" />
      </Box>
    );
  }

  if (!attachment) {
    return (
      <Box display={'flex'} flexDirection={'column'} py={2} gap={1}>
        <Box>
          <Typography variant={'caption'} fontWeight={700}>
            Arquivo
          </Typography>
        </Box>
        <Box
          bgcolor={'background.error'}
          py={1.5}
          px={1}
          display={'flex'}
          alignItems={'center'}
          gap={2}
          borderRadius={1}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Box px={1.5}>
              <img
                src={receiptDelete}
                alt={'Sem arquivo'}
                width={'16px'}
                height={'16px'}
              />
            </Box>
            <Typography
              color={'text.primary'}
              variant={'caption'}
              fontWeight={500}
            >
              Ainda não foram enviados arquivos para essa solicitação.
            </Typography>
          </Box>
          {paymentRequestStatus === paymentRequestStatuses.created && (
            <Box
              px={1.5}
              py={1}
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  display: 'none',
                },
              })}
            >
              <Button variant={'text'}>
                <label htmlFor={'fileInput'}>Enviar</label>
                <input
                  type={'file'}
                  hidden={true}
                  accept={'.pdf,.png,.jpg,.jpeg'}
                  id={'fileInput'}
                  onChange={(event) => {
                    uploadAttachment(event.target.files[0]);
                  }}
                />
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  const handleDownload = async () => {
    const response = await fetch(attachment.downloadUrl);
    const blob = await response.blob();
    downloadBlobFile(blob, attachment.fileName);
  };

  return (
    <Box>
      {confirmation && (
        <AttachmentDeleteConfirmationDialog
          setOpen={setConfirmation}
          action={deleteAttachment}
          open={confirmation}
        />
      )}
      <Typography variant={'caption'} fontWeight={700}>
        Arquivo
      </Typography>
      <Box display={'flex'} justifyContent={'space-between'} py={2}>
        <Box display={'flex'} gap={1} alignItems={'center'} py={1}>
          <Box width={'32px'} height={'32px'}>
            <Box
              sx={{
                bgcolor: '#1E78FF',
                borderRadius: 0.5,
                color: '#FFF',
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="32px"
              height="32px"
            >
              <InsertDriveFileOutlinedIcon width={3} />
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
          >
            <Typography variant={'caption'}>{attachment.fileName}</Typography>
            <Typography variant={'caption'} color={'text.secondary'}>
              {parseInt(`${attachment.contentLength / 1000}`)} kb
            </Typography>
          </Box>
        </Box>
        <Box display={'flex'}>
          {paymentRequestStatus === paymentRequestStatuses.created && (
            <Box
              width={'32px'}
              height={'32px'}
              p={1}
              display={'flex'}
              alignItems={'center'}
            >
              <IconButton
                color={'error'}
                onClick={() => {
                  setConfirmation(true);
                }}
              >
                <img src={trashIcon} />
              </IconButton>
            </Box>
          )}

          <Box
            width={'32px'}
            height={'32px'}
            p={1}
            display={'flex'}
            alignItems={'center'}
            border-radius={'8px'}
            bgcolor={'#FFF'}
          >
            <IconButton
              color={'error'}
              onClick={handleDownload}
              sx={{
                border: '1px solid #EDEDED',
                borderRadius: '8px',
                bgcolor: '#FFFFFF',
              }}
            >
              <FileDownloadOutlinedIcon
                sx={{ color: '#616161', height: '16px', width: '16px' }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export function StateInformation({ entry }: { entry: PjPaymentRequestEntry }) {
  if (entry.status === 'created') {
    return null;
  }

  const propsByStatus: {
    [status: string]: {
      time: string;
      label: string;
      color: string;
      textColor: string;
      userFieldName: keyof PjPaymentRequestEntry['metadata'];
    };
  } = {
    approved: {
      time: entry.metadata.approvedOn,
      label: 'Aprovada',
      color: 'success',
      textColor: 'success.main',
      userFieldName: 'approvedBy',
    },
    rejected: {
      time: entry.metadata.rejectedOn,
      label: 'Recusada',
      color: 'error',
      textColor: 'error.main',
      userFieldName: 'rejectedBy',
    },
    deleted: {
      time: entry.metadata.deletedOn,
      label: 'Cancelada',
      color: 'default',
      textColor: 'text.secondary',
      userFieldName: 'deletedBy',
    },
  };

  const { status } = entry;
  const { time, label, color, textColor } = propsByStatus[status] ?? {};

  return (
    <Box
      justifyContent={'flex-end'}
      bgcolor={`background.${color}`}
      border={'1px solid'}
      borderRadius={1}
      borderColor={`strokes.${color}`}
      display={'flex'}
      flexDirection={'column'}
      px={2}
      py={2}
      gap={1.5}
    >
      <Box>
        <PaymentRequestStatusTag status={entry.status} emphasis={'high'} />
      </Box>
      <Box p={1}>
        <Typography variant={'caption'} fontWeight={700} color={textColor}>
          {label} em {formatDateTimeBR(time)}
        </Typography>
        {entry.status === 'rejected' &&
          entry.metadata.rejectionReason &&
          entry.metadata.rejectionReason !== '' && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              p={1}
              width={'100%'}
              boxSizing={'border-box'}
              justifyContent={'flex-start'}
              gap={0.5}
            >
              <Typography
                variant={'caption'}
                fontWeight={700}
                color={'text.primary'}
              >
                Motivo da recusa
              </Typography>
              <Typography color={'text.secondary'} variant={'subtitle2'}>
                "{entry.metadata.rejectionReason}"
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
}

export function PaymentRequestStatusTag({
  status,
  emphasis = 'default',
}: {
  status: PaymentRequestStatus;
  emphasis?: 'high' | 'default' | 'low';
}) {
  const tagProps: Record<
    PaymentRequestStatus,
    { tagColor: 'success' | 'error' | 'info' | 'default'; textColor: string }
  > = {
    approved: {
      tagColor: 'success',
      textColor: 'success.main',
    },
    rejected: {
      tagColor: 'error',
      textColor: 'error.main',
    },
    created: {
      tagColor: 'info',
      textColor: 'info.main',
    },
    deleted: {
      tagColor: 'default',
      textColor: 'text.secondary',
    },
  };

  const props = tagProps[status];

  return (
    <Tag color={props.tagColor} emphasis={emphasis}>
      <Typography
        component={'span'}
        variant={'body2'}
        color={props.textColor}
        noWrap={true}
      >
        {getLabelByStatus(status)}
      </Typography>
    </Tag>
  );
}

export function LucroPresumidoAmounts({
  entry,
}: {
  entry: PjPaymentRequestEntry;
}) {
  const [expanded, setExpanded] = useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      pt={1}
      border={'1px solid #EDEDED'}
      borderRadius={1}
      width={'100%'}
      boxSizing={'border-box'}
    >
      <Box
        display="flex"
        width={'100%'}
        py={1.5}
        px={3}
        boxSizing={'border-box'}
        justifyContent={'space-between'}
      >
        <Typography variant="caption" fontWeight={700}>
          Valor do serviço
        </Typography>
        <Typography variant="caption" fontWeight={700}>
          {formatMoney(entry.grossAmount)}
        </Typography>
      </Box>

      <Accordion
        expanded={expanded}
        onClick={handleExpansion}
        sx={{
          border: '1px solid #EDEDED',
          borderRadius: '8px',
        }}
      >
        <AccordionSummary aria-controls="panel1-content" id="panel1-header">
          <Box
            id={'header'}
            display={'flex'}
            justifyContent={'space-between'}
            width={'100%'}
            boxSizing={'border-box'}
            px={1}
          >
            <Box display={'flex'} alignItems={'center'} gap={0.5}>
              <Typography variant="caption" fontWeight={700}>
                Impostos e retenções
              </Typography>
              <Box p={0.5}>
                {expanded ? (
                  <KeyboardArrowUpOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}
              </Box>
            </Box>
            <Typography variant="caption" fontWeight={700}>
              {formatMoney(entry.taxesAmount)}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display={'flex'} flexWrap={'wrap'} key={'taxes'}>
            {Object.entries(entry.invoice?.taxes?.br ?? {})
              .sort()
              .map(([key, value]) => (
                <Box
                  display={'flex'}
                  flexBasis={'50%'}
                  flexDirection={'column'}
                  py={1.5}
                  px={1}
                  key={key}
                  boxSizing={'border-box'}
                >
                  <Typography
                    key={`${key}-label`}
                    variant={'caption'}
                    fontWeight={700}
                  >
                    {brTaxesLabels[key as BrTaxLabel]}
                  </Typography>
                  <Typography
                    key={`${key}-amount`}
                    variant={'body2'}
                    color={'text.secondary'}
                  >
                    {formatMoney(value)}
                  </Typography>
                </Box>
              ))}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Box
        display="flex"
        width={'100%'}
        boxSizing={'border-box'}
        py={2}
        px={3}
        justifyContent={'space-between'}
        bgcolor={'#F7F7F8'}
      >
        <Typography variant="body2" fontSize={'14px'} fontWeight={700}>
          Valor a pagar
        </Typography>
        <Typography variant="body2" fontSize={'14px'} fontWeight={700}>
          {formatMoney(entry.netAmount)}
        </Typography>
      </Box>
    </Box>
  );
}

export function SimplesNacionalAmounts({
  entry,
}: {
  entry: PjPaymentRequestEntry;
}) {
  return (
    <Box
      display="flex"
      flexDirection={'column'}
      pt={1}
      border={'1px solid #EDEDED'}
      borderRadius={1}
      width={'100%'}
      boxSizing={'border-box'}
    >
      <Box
        display="flex"
        width={'100%'}
        py={1.5}
        px={3}
        boxSizing={'border-box'}
        justifyContent={'space-between'}
      >
        <Typography variant="caption" fontWeight={700}>
          Valor do serviço
        </Typography>
        <Typography variant="caption" fontWeight={700}>
          {formatMoney(entry.grossAmount)}
        </Typography>
      </Box>

      <Box
        display="flex"
        width={'100%'}
        py={1.5}
        px={3}
        boxSizing={'border-box'}
        justifyContent={'space-between'}
      >
        <Typography variant="caption" fontWeight={700} color={'strokes.heavy'}>
          Impostos e retenções
        </Typography>
        <Typography variant="caption" fontWeight={700} color={'strokes.heavy'}>
          {formatMoney('0')}
        </Typography>
      </Box>

      <Box
        display="flex"
        width={'100%'}
        boxSizing={'border-box'}
        py={2}
        px={3}
        justifyContent={'space-between'}
        bgcolor={'#F7F7F8'}
      >
        <Typography variant="body2" fontSize={'14px'} fontWeight={700}>
          Valor a pagar
        </Typography>
        <Typography variant="body2" fontSize={'14px'} fontWeight={700}>
          {formatMoney(entry.netAmount)}
        </Typography>
      </Box>
    </Box>
  );
}

export function getLabelByStatus(status: PjPaymentRequestEntry['status']) {
  return paymentRequestStatusesLabels[status];
}

export async function poolOpenSearch({
  organizationId,
  companyId,
  contractId,
  paymentRequestId,
  filters,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
  paymentRequestId: string;
  filters?: Record<string, string[]>;
}) {
  const timeout = 1000 * 60 * 2;
  const timeoutDateTime = Date.now() + timeout;

  do {
    const result = await fetchSearchByContractPjPaymentRequests({
      pathParams: {
        organizationId: organizationId ?? '',
        companyId: companyId ?? '',
        contractId: contractId ?? '',
      },
      body: {
        pagination: {
          page: 0,
          size: 1,
        },
        sorting: [
          {
            field: 'createdOn',
            order: 'desc',
          },
        ],
        filtering: {
          elements: {
            ...filters,
            id: [paymentRequestId],
          },
        },
      },
    });

    if (result.total > 0) {
      break;
    }

    // wait 150ms
    await new Promise((resolve) => setTimeout(resolve, 300));
  } while (timeoutDateTime > Date.now());
}
