import { useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Box, Skeleton } from '@mui/material';

import {
  VacationsScheduleEntry,
  fetchGetGroupVacationSchedules,
} from '@octopus/api';
import { DataGrid, useDataGrid } from '@octopus/ui/data-grid';

import { DataFetching } from '../../../modules/dataFetching';
import { getColumnsByTab } from '../components/table/Tabs';
import { VacationsTabs } from '../utils/types';

export default function GroupVacationsTable({
  organizationId,
}: {
  organizationId: string;
}) {
  const { groupId } = useParams<{ groupId: string }>();
  const navigateTo = useNavigate();
  const columnsByTab = getColumnsByTab(false, () => Promise.resolve());
  const queryParams = {
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
  };

  const dataGridProps = useDataGrid({});
  const { sortingProps, paginationProps } = dataGridProps;

  const listGroupVacations = () =>
    fetchGetGroupVacationSchedules({ pathParams: { organizationId, groupId } });

  const listGroupVacationsQuery = useQuery({
    queryKey: ['fetchGetGroupVacationSchedules', organizationId],
    queryFn: listGroupVacations,
    ...queryParams,
  });

  return (
    <DataFetching
      fetchResult={listGroupVacationsQuery}
      Loading={() => {
        return (
          <Box display="flex" flexDirection="column" gap="8px" pt={1}>
            <Skeleton variant="rounded" height={300} width="100%" />
          </Box>
        );
      }}
      Data={({ data }: { data: VacationsScheduleEntry[] }) => {
        return (
          <Box mt={2}>
            {data ? (
              <DataGrid
                paginationProps={paginationProps}
                sortingProps={sortingProps}
                totalRowCount={data.length || 0}
                getRowId={({
                  organizationId,
                  contractId,
                  sequence,
                }: VacationsScheduleEntry) =>
                  `${organizationId}-${contractId}-${sequence}`
                }
                rows={data}
                onRowClick={({ row }) => {
                  if (row.payrollId) {
                    navigateTo(`/vacations/${row.payrollId}`);
                  }
                }}
                columns={columnsByTab[VacationsTabs.calculations]}
                emptyMessage={'Nenhuma férias coletiva encontrada.'}
              />
            ) : null}
          </Box>
        );
      }}
    />
  );
}
