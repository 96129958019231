import { useState } from 'react';

import { LoadingButton } from '@mui/lab';

import { getAuthToken } from '@octopus/api';

import { download } from '../../spreadsheets/downloader';

export default function DirectDownloadButton({
  organizationId,
  setError,
  type,
  label,
  params,
}: {
  organizationId: string;
  setError: (error: boolean) => void;
  type: string;
  label: string;
  params?: Record<string, string>;
}) {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    setError(false);

    download(getAuthToken()!, organizationId, type, params)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <LoadingButton onClick={handleClick} loading={loading} variant="contained">
      <span>{label}</span>
    </LoadingButton>
  );
}
