import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { omit } from 'lodash';

import { Box, Button, Divider } from '@mui/material';

import {
  NotificationConfigurationInput,
  useDeleteVacationsNotificationsConfiguration,
  usePostVacationsNotificationsConfiguration,
} from '@octopus/api';
import {
  accrualPeriodsResourceType,
  vacationsScheduleResourceType,
} from '@octopus/vacations-types';

import { useSnackbar } from '../../../modules/hooks/useSnackbar';

import ConditionField from './ConditionComponents';
import {
  ActionsField,
  DescriptionField,
  HandleChange,
  NameField,
  RuleIdField,
  TagsField,
  TimingsField,
} from './NotificationComponents';

export type VacationConfigurationResourceType =
  | typeof vacationsScheduleResourceType
  | typeof accrualPeriodsResourceType;

export default function NotificationForm(props: {
  formData: NotificationConfigurationInput;
  organizationId: string;
  resourceType?: VacationConfigurationResourceType;
  ruleId?: string;
}) {
  const { mutateAsync, isLoading } =
    usePostVacationsNotificationsConfiguration();
  const { mutateAsync: deleteMutateAsync, isLoading: deleteIsLoading } =
    useDeleteVacationsNotificationsConfiguration();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<NotificationConfigurationInput>(
    props?.formData,
  );
  const handleChange: HandleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleMutate = (action: 'save' | 'delete') => {
    const verb = action === 'save' ? 'salvar' : 'excluir';
    const result = action === 'save' ? 'salva' : 'excluída';
    return {
      onSuccess: () => {
        showSnackbar({
          Message: `Notificação ${result} com sucesso`,
          isOpen: true,
        });
        navigate('/vacations-notifications');
      },
      onError: () => {
        showSnackbar({
          Message: `Erro ao ${verb} notificação`,
          isOpen: true,
        });
      },
    };
  };

  const handleSave = () => {
    mutateAsync(
      {
        body: omit(formData, [
          'sortKey',
          'organizationId',
        ]) as NotificationConfigurationInput,
        pathParams: {
          organizationId: props.organizationId,
        },
      },
      handleMutate('save'),
    );
  };

  const handleDelete = () => {
    deleteMutateAsync(
      {
        pathParams: {
          organizationId: props.organizationId,
          resourceType: props.resourceType,
          ruleId: props.ruleId,
        },
      },
      handleMutate('delete'),
    );
  };

  return (
    <Box p={2}>
      {/* RuleId */}
      <RuleIdField formData={formData} handleChange={handleChange} />

      {/* Name */}
      <NameField formData={formData} handleChange={handleChange} />

      {/* Description */}
      <DescriptionField formData={formData} handleChange={handleChange} />

      <Divider />

      {/* Condition */}
      <ConditionField formData={formData} handleChange={handleChange} />

      {/* Actions */}
      <ActionsField formData={formData} handleChange={handleChange} />

      <Divider />

      {/* Timings */}
      <TimingsField formData={formData} handleChange={handleChange} />

      {/* Tags */}
      <TagsField formData={formData} handleChange={handleChange} />

      <Box display="flex" gap="8px">
        {props?.ruleId && props?.resourceType && (
          <Button
            color="error"
            onClick={handleDelete}
            style={{ marginTop: 16 }}
            disabled={deleteIsLoading}
          >
            Excluir
          </Button>
        )}
        <Button
          color="primaryAlt"
          onClick={handleSave}
          style={{ marginTop: 16 }}
          disabled={isLoading}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
}
