import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';

import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';

import { CheckCondition, Condition } from '@octopus/libs/conditional-evaluator';

import {
  CheckConditionEditorProps,
  CheckConditionOperators,
  ConditionEditorProps,
  NestedConditionEditorProps,
} from './ConditionTypes';
import { NotificationProps } from './NotificationComponents';
import { VacationsContexts } from './NotificationTypes';

export default function ConditionField(props: NotificationProps) {
  const { formData, handleChange } = props;
  const { resourceType } = formData;
  const [conditions, setConditions] = useState<Condition[]>(
    isEmpty(formData?.condition) ? [] : [formData.condition as Condition],
  );

  const handleAddCondition = (type: Condition['type']) => {
    let newCondition: Condition;
    if (type === 'check') {
      newCondition = { type, field: '', operator: 'eq', value: '' };
    } else if (type === 'always') {
      newCondition = { type };
    } else {
      newCondition = { type, conditions: [] };
    }
    setConditions([...conditions, newCondition]);
  };

  const [condition = {}] = conditions;
  useEffect(() => {
    handleChange('condition', condition as Condition);
  }, [condition]);

  return (
    <Box mt={2}>
      <Typography variant="body1">Condições de notificação</Typography>
      {conditions.length === 0 ? (
        <Box mt={1} ml={2} flexDirection="column" display="flex" gap={1}>
          <Typography variant="body2">Adicionar condição:</Typography>
          <Box display="flex" gap={1}>
            <Button
              color="primaryAlt"
              onClick={() => handleAddCondition('check')}
            >
              Se
            </Button>
            <Button color="primaryAlt" onClick={() => handleAddCondition('or')}>
              Ou
            </Button>
            <Button
              color="primaryAlt"
              onClick={() => handleAddCondition('and')}
            >
              E
            </Button>
            <Button
              color="primaryAlt"
              onClick={() => handleAddCondition('always')}
            >
              Sempre
            </Button>
          </Box>
        </Box>
      ) : (
        conditions?.map((condition, index) => (
          <Box mt={2} key={index}>
            <ConditionEditor
              key={index}
              condition={condition}
              resourceType={resourceType}
              onChange={(updatedCondition: Condition) => {
                const updatedConditions = [...conditions];
                updatedConditions[index] = updatedCondition;
                setConditions(updatedConditions);
              }}
              onDelete={() => {
                const updatedConditions = conditions.filter(
                  (_, i) => i !== index,
                );
                setConditions(updatedConditions);
              }}
            />
          </Box>
        ))
      )}
    </Box>
  );
}

export const NestedConditionEditor: React.FC<NestedConditionEditorProps> = ({
  condition,
  onChange,
  onDelete,
  resourceType,
}) => {
  const handleAddSubCondition = (type: Condition['type']) => {
    const newCondition: Condition =
      type === 'always'
        ? { type: 'always' }
        : type === 'check'
          ? { type: 'check', field: '', operator: 'eq', value: '' }
          : { type, conditions: [] };

    onChange({
      ...condition,
      conditions: [...condition.conditions, newCondition],
    });
  };

  const handleUpdateSubCondition = (
    index: number,
    updatedCondition: Condition,
  ) => {
    const updatedConditions = [...condition.conditions];
    updatedConditions[index] = updatedCondition;
    onChange({ ...condition, conditions: updatedConditions });
  };

  const handleDeleteSubCondition = (index: number) => {
    const updatedConditions = condition.conditions.filter(
      (_, i) => i !== index,
    );
    onChange({ ...condition, conditions: updatedConditions });
  };

  return (
    <Box
      border={1}
      borderColor="divider"
      p={2}
      display="flex"
      flexDirection="column"
      gap={1}
    >
      <Box display="flex" alignItems="center">
        <Typography>Condição: {ConditionType[condition.type]}</Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        {condition.conditions.map((subCondition, index) => (
          <ConditionEditor
            key={index}
            condition={subCondition}
            resourceType={resourceType}
            onChange={(updatedCondition) =>
              handleUpdateSubCondition(index, updatedCondition)
            }
            onDelete={() => handleDeleteSubCondition(index)}
          />
        ))}
      </Box>
      <Box mt={2} flexDirection="column" display="flex" gap={1}>
        <Box>
          <Typography>Adicionar condição:</Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Button
            color="primaryAlt"
            onClick={() => handleAddSubCondition('check')}
          >
            Se
          </Button>
          <Button
            color="primaryAlt"
            onClick={() => handleAddSubCondition('or')}
          >
            Ou
          </Button>
          <Button
            color="primaryAlt"
            onClick={() => handleAddSubCondition('and')}
          >
            E
          </Button>
          <Button
            color="primaryAlt"
            onClick={() => handleAddSubCondition('always')}
          >
            Sempre
          </Button>
          <Button color="error" onClick={onDelete}>
            Excluir
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const CheckConditionEditor: React.FC<CheckConditionEditorProps> = ({
  resourceType,
  condition,
  onChange,
  onDelete,
}) => {
  const { conditionFields } = VacationsContexts[resourceType];
  const conditionField = condition.field as keyof typeof conditionFields;

  return (
    <Box
      border={1}
      borderColor="divider"
      p={2}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Box>
        <Typography>Condição: Se</Typography>
      </Box>

      <Box display="flex" flexDirection="row" gap={2}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body2">Campo:</Typography>
          <TextField
            fullWidth
            value={condition.field}
            margin="dense"
            select
            onChange={(e) => onChange({ ...condition, field: e.target.value })}
          >
            <MenuItem key="empty" value="">
              Selecione
            </MenuItem>
            {Object.values(conditionFields).map(({ field, label }) => (
              <MenuItem key={field} value={field}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="body2">Operador:</Typography>
          <TextField
            select
            fullWidth
            margin="dense"
            value={condition.operator || ''}
            onChange={(e) =>
              onChange({
                ...condition,
                operator: e.target.value as CheckCondition['operator'],
              })
            }
          >
            <MenuItem value="">Selecione</MenuItem>
            {Object.entries(CheckConditionOperators).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {!['between', 'in', 'nin'].includes(condition.operator) && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body2">Valor:</Typography>

            {conditionFields[conditionField]?.options?.length > 0 ? (
              <TextField
                fullWidth
                margin="dense"
                select
                value={condition.value || ''}
                onChange={(e) =>
                  onChange({ ...condition, value: e.target.value })
                }
              >
                {conditionFields[conditionField].options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                fullWidth
                margin="dense"
                value={condition.value || ''}
                onChange={(e) =>
                  onChange({ ...condition, value: e.target.value })
                }
              />
            )}
          </Box>
        )}

        {condition.operator === 'between' && (
          <>
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="body2">Valor máximo:</Typography>
              <TextField
                fullWidth
                margin="dense"
                value={condition.maxValue || ''}
                onChange={(e) =>
                  onChange({ ...condition, maxValue: e.target.value })
                }
              />
            </Box>

            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="body2">Valor mínimo:</Typography>
              <TextField
                fullWidth
                margin="dense"
                value={condition.minValue || ''}
                onChange={(e) =>
                  onChange({ ...condition, minValue: e.target.value })
                }
              />
            </Box>
          </>
        )}

        {['in', 'nin'].includes(condition.operator) && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="body2">
              Valores (separados por vírgula):
            </Typography>
            <TextField
              fullWidth
              margin="dense"
              value={condition.values || ''}
              onChange={(e) =>
                onChange({ ...condition, values: e.target.value.split(',') })
              }
            />
          </Box>
        )}
      </Box>

      <Button sx={{ width: '100px' }} color="error" onClick={onDelete}>
        Excluir
      </Button>
    </Box>
  );
};

export const ConditionEditor: React.FC<ConditionEditorProps> = ({
  condition,
  onChange,
  onDelete,
  resourceType,
}) => {
  if (condition.type === 'check') {
    return (
      <CheckConditionEditor
        condition={condition}
        onChange={onChange}
        onDelete={onDelete}
        resourceType={resourceType}
      />
    );
  }

  if (condition.type === 'or' || condition.type === 'and') {
    return (
      <NestedConditionEditor
        condition={condition}
        onChange={onChange}
        onDelete={onDelete}
        resourceType={resourceType}
      />
    );
  }

  if (condition.type === 'always') {
    return (
      <Box>
        <Typography>Sempre</Typography>
        <Button color="error" onClick={onDelete}>
          Excluir
        </Button>
      </Box>
    );
  }

  return null;
};

const ConditionType = {
  check: 'Se',
  or: 'Ou',
  and: 'E',
  always: 'Sempre',
};
