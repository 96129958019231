import { Submission } from '@conform-to/react';
import { QueryClient } from '@tanstack/react-query';
import { get, isNumber, merge, set } from 'lodash';
import { Schema } from 'zod';

import {
  SearchInput,
  VacationsAccrualPeriodSummary,
  VacationsScheduleList,
  VacationsScheduleReviewInput,
  VacationsScheduleSummary,
  fetchGetContract,
  fetchGetContractAccrualPeriods,
  fetchGetContractVacationsSchedule,
  fetchPostReviewVacationsSchedule,
  fetchSearchAllScheduledVacations,
  fetchSearchVacationsAccrualPeriods,
} from '@octopus/api';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { VacationsTabs } from './types';

export const getStatusPath = (status: string) =>
  `metadata.buckets.counters.byProp.status.${status}`;

export const addStatus = (tab: keyof typeof VacationsTabs) => {
  const filteredStatus: (
    | 'approved'
    | 'waitingApproval'
    | 'payrollCreated'
    | 'payrollApproved'
    | 'canceled'
    | 'contractTerminated'
    | 'payrollArchived'
    | 'rejected'
    | 'payrollCreationScheduled'
  )[] = {
    [VacationsTabs.people]: [],
    [VacationsTabs.vacationsHistory]: [],
    [VacationsTabs.requests]: [
      vacationsScheduleStatuses.waitingApproval,
      vacationsScheduleStatuses.approved,
    ],
    [VacationsTabs.calculations]: [
      vacationsScheduleStatuses.payrollCreationScheduled,
      vacationsScheduleStatuses.payrollCreated,
      vacationsScheduleStatuses.payrollApproved,
    ],
  }[tab];

  return {
    filtering: {
      elements: {
        status: filteredStatus,
      },
    },
  };
};

export const defaultSearchInput = {
  pagination: {
    size: 25,
    page: 0,
  },
  filtering: {},
  sorting: [
    {
      field: 'approvalDeadline',
      order: 'asc',
    },
    {
      field: 'startDate',
      order: 'asc',
    },
  ],
} as SearchInput;

export const searchQueryKey = (organizationId: string, companyId: string) => {
  return (
    q: string,
    searchInput?: SearchInput,
    tab?: keyof typeof VacationsTabs,
  ) => {
    // We have two searches, in the people tab we search for accrual periods,
    // in the other tabs we search for scheduled vacations
    // This is a trick to fill the cache when we schedule a new vacation
    // from the people tab and after that redirects to the requests tab
    const defaultTab =
      tab === VacationsTabs.people ? VacationsTabs.requests : tab;

    return [q, organizationId, companyId, searchInput, defaultTab];
  };
};

export const getSearchQueries = (
  organizationId: string,
  companyId: string,
  searchInput: SearchInput,
  tab: keyof typeof VacationsTabs,
) => {
  const isInPeopleTab = tab === VacationsTabs.people;
  const queryKey = searchQueryKey(organizationId, companyId);
  const searchStatus = addStatus(tab);
  const searchParams = (searchInput: SearchInput) => ({
    pathParams: { organizationId },
    body: searchInput,
  });

  const queryParams = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60, // 1 minute
    staleTime: 1000 * 60, // 1 minute
    enabled: !!organizationId,
  };

  // In the people tab we need to add the filters in search for accrual periods
  const searchVacationsAccrualPeriodsPayload = searchParams(
    isInPeopleTab ? searchInput : {},
  );
  const searchVacationsAccrualPeriods = () =>
    fetchSearchVacationsAccrualPeriods(searchVacationsAccrualPeriodsPayload);

  // In another tabs we need to add the filters in search for scheduled vacations
  // and we need merge the tab status + filter statuses (addStatus method)
  const searchAllScheduledVacationsPayload = searchParams(
    !isInPeopleTab ? merge(searchInput, searchStatus) : {},
  );
  const searchAllScheduledVacations = () =>
    fetchSearchAllScheduledVacations(searchAllScheduledVacationsPayload);

  return {
    searchVacationsAccrualPeriods: {
      queryKey: queryKey('searchVacationsAccrualPeriods', searchInput),
      queryFn: searchVacationsAccrualPeriods,
      ...queryParams,
    },
    searchAllScheduledVacations: {
      queryKey: queryKey('searchAllScheduledVacations', searchInput, tab),
      queryFn: searchAllScheduledVacations,
      ...queryParams,
    },
  };
};

export const getContractQueries = (
  row: VacationsScheduleSummary | VacationsAccrualPeriodSummary,
) => {
  const pathParams = {
    organizationId: row?.organizationId,
    contractId: row?.contractId,
  };
  const fetchContractAccrualPeriods = () =>
    fetchGetContractAccrualPeriods({ pathParams });
  const fetchContractVacationsSchedule = () =>
    fetchGetContractVacationsSchedule({ pathParams });
  const fetchContract = () => fetchGetContract({ pathParams });
  const queryParams = { enabled: !!row, refetchOnWindowFocus: false };
  const queryKey = (q: string) => [q, row?.organizationId, row?.contractId];

  return {
    contractAccrualPeriods: {
      queryKey: queryKey('contractAccrualPeriods'),
      queryFn: fetchContractAccrualPeriods,
      ...queryParams,
    },
    contractVacationsSchedule: {
      queryKey: queryKey('contractVacationsSchedule'),
      queryFn: fetchContractVacationsSchedule,
      ...queryParams,
    },
    contract: {
      queryKey: queryKey('contract'),
      queryFn: fetchContract,
      ...queryParams,
    },
  };
};

export const getTabCount = (
  tab: string,
  vacationsStatus: { [key: string]: number },
) => {
  const { waitingApproval, payrollCreated } = vacationsStatus || {};
  const requestsStatuses = [waitingApproval];
  const calculationsStatuses = [payrollCreated];
  const sumTotals = (acc: number, val: number) => {
    return isNumber(val) ? acc + Number(val) : acc;
  };
  switch (tab) {
    case VacationsTabs.requests:
      return requestsStatuses.reduce(sumTotals, 0) || undefined;
    case VacationsTabs.calculations:
      return calculationsStatuses.reduce(sumTotals, 0) || undefined;
    default:
      return undefined;
  }
};

export const getContractMutations = (
  summary: VacationsScheduleSummary,
  onStartReview: () => void,
  onReviewSuccess: (action: string) => () => void,
  onReviewError: (action: string) => () => void,
  queryClient: QueryClient,
) => {
  const { organizationId, companyId, contractId, sequence, id } = summary;
  const pathParams = {
    sequence: `${sequence}`,
    organizationId,
    contractId,
  };

  const searchRequestQuery = getSearchQueries(
    organizationId,
    companyId,
    defaultSearchInput,
    VacationsTabs.requests,
  );
  const { queryKey } = searchRequestQuery.searchAllScheduledVacations;
  const waitingApprovalStatus = vacationsScheduleStatuses.waitingApproval;
  const statusPath = getStatusPath(waitingApprovalStatus);

  const onApproveVacations = () => {
    queryClient.setQueryData(queryKey, (oldData: VacationsScheduleList) => {
      const newData = { ...oldData };
      const oldStatusCount = get(oldData, statusPath);
      set(newData, statusPath, oldStatusCount - 1);
      newData.data = oldData.data.map((vacation) => {
        if (vacation.id === id) {
          return {
            ...vacation,
            status: vacationsScheduleStatuses.approved,
          };
        }
        return vacation;
      });
      return newData as VacationsScheduleList;
    });
  };

  const onRejectVacations = () => {
    queryClient.setQueryData(queryKey, (oldData: VacationsScheduleList) => {
      const newData = { ...oldData };
      const oldStatusCount = get(oldData, statusPath);
      set(newData, statusPath, oldStatusCount - 1);
      newData.data = oldData.data.filter((vacation) => {
        return vacation.id === id;
      });
      return newData as VacationsScheduleList;
    });
  };

  const fetchReviewVacationsSchedule = ({
    sequence,
    action,
    comment,
    role,
    override,
  }: {
    sequence: string;
  } & VacationsScheduleReviewInput) =>
    fetchPostReviewVacationsSchedule({
      pathParams: { sequence, ...pathParams },
      body: {
        action,
        comment,
        role,
        override,
      },
    });

  const reproveVacation = (
    _: any,
    formData: Submission<Schema, string[], Schema>,
  ) => {
    onStartReview();
    fetchReviewVacationsSchedule({
      action: 'reject',
      sequence: `${sequence}`,
      comment: formData.payload.rejectReason as string,
      role: 'membership:owner', // TODO change for workflowV2
      override: true, // TODO change for workflowV2,
    })
      .then(onRejectVacations)
      .then(onReviewSuccess('reprovadas'))
      .catch(onReviewError('reprovar'));
  };

  const approveVacation = () => {
    onStartReview();
    fetchReviewVacationsSchedule({
      action: 'approve',
      sequence: `${sequence}`,
      role: 'membership:owner', // TODO change for workflowV2
      override: true, // TODO change for workflowV2,
    })
      .then(onApproveVacations)
      .then(onReviewSuccess('aprovar'))
      .catch(onReviewError('aprovar'));
  };

  return {
    reproveVacation,
    approveVacation,
  };
};
