import { IconBeach } from '@tabler/icons-react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { VacationsScheduleEntry, VacationsScheduleSummary } from '@octopus/api';
import { formatDateBR, getShortWeekday } from '@octopus/formatters';

import { VacationStatusTag } from '../Status';

export const ScheduleCardHeader = (props: {
  summary: VacationsScheduleEntry | VacationsScheduleSummary;
  handleExpandButtonClick: () => void;
  expandButtonState: boolean;
}) => {
  const { summary, handleExpandButtonClick, expandButtonState } = props;
  const { startDate, endDate, daysTaken } = summary;
  return (
    <Box mb={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={handleExpandButtonClick}
        style={{ cursor: 'pointer' }}
      >
        <IconBeach style={{ color: '#BABABF', marginRight: 16 }} size="24" />

        <Box mr="auto" display="flex" flexDirection="column">
          <Typography variant="h4">
            {`${getShortWeekday(startDate)}. ${formatDateBR(startDate)}`}
            <Typography mx={0.5} component="span" variant="h4" color="#BABABF">
              {' → '}
            </Typography>
            {`${getShortWeekday(endDate)}. ${formatDateBR(endDate)}`}
          </Typography>
          <Typography fontSize={14} variant="body2" component="div">
            {`${daysTaken} dias`}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          alignItems="flex-end"
        >
          <VacationStatusTag summary={summary} />
        </Box>

        <Box ml={2}>{expandButtonState ? <ExpandLess /> : <ExpandMore />}</Box>
      </Box>
    </Box>
  );
};
