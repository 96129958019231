import { Warning } from '@mui/icons-material';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelClick: () => void;
  confirmClick: () => void;
  showWarningMessage: boolean;
};

export function DocumentReviewFinishDialog({
  open,
  setOpen,
  cancelClick,
  confirmClick,
  showWarningMessage,
}: Props) {
  return (
    <Dialog
      disableEscapeKeyDown={false}
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0,0,0, 0.8)',
          },
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 1,
            alignItems: 'flex-start',
            textAlign: 'left',
          }}
        >
          <Typography variant="h1">Revisão dos documentos concluída</Typography>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            Você pode agora revisar os dados do colaborador que não possuem
            documentos, isso pode ser importante para averiguar o correto
            preenchimento antes do envio ao eSocial.
          </Typography>
          {showWarningMessage && (
            <Alert severity="warning" icon={<Warning />}>
              Existem campos obrigatórios que precisam ser preenchidos pelo
              departamento pessoal. Para prosseguir com a admissão, você deve
              preenchê-los primeiro.
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <>
          {!showWarningMessage && (
            <Button
              variant="outlined"
              color="primaryAlt"
              sx={{ py: 1, px: 4 }}
              onClick={cancelClick}
            >
              Ver resumo e finalizar
            </Button>
          )}
          <Button
            variant="contained"
            color="primaryAlt"
            sx={{ py: 1, px: 4 }}
            onClick={confirmClick}
          >
            Revisar dados do colaborador
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}
