import React from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, IconButton } from '@mui/material';

export function FilePreviewImage({
  file,
  setFile,
  showDeleteButton,
}: {
  file?: File;
  setFile: (file?: File) => void;
  showDeleteButton: boolean;
}) {
  if (!file) {
    return undefined;
  }

  return (
    <Box
      display={'flex'}
      flex={'auto'}
      boxSizing={'border-box'}
      position={'relative'}
      height={file.type === 'application/pdf' ? '100%' : undefined}
    >
      {file.type === 'application/pdf' && (
        <iframe
          src={URL.createObjectURL(file) + '#navpanes=0&scrollbar=0'}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      {file.type.startsWith('image/') && (
        <Box>
          <img
            src={URL.createObjectURL(file)}
            style={{
              width: '100%',
            }}
          />
        </Box>
      )}
      {showDeleteButton && (
        <Box
          position={'absolute'}
          bottom={0}
          sx={{
            transform: 'translateX(-50%) translateY(50%)',
            left: '50%',
            bgcolor: '#FFF',
          }}
        >
          <IconButton
            onClick={() => {
              setFile(undefined);
            }}
            sx={{
              border: '1px solid #EDEDED',
              borderRadius: 1,
              boxShadow:
                '0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 16px 0px rgba(0, 0, 0, 0.03)',
            }}
          >
            <DeleteOutlineOutlinedIcon
              color={'action'}
              width={'16px'}
              height={'16px'}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
