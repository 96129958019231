import { useState } from 'react';

import { HourglassEmpty } from '@mui/icons-material';

import {
  VacationsScheduleEntry,
  VacationsScheduleSummary,
  fetchPostVacationsScheduleCreatePayroll,
} from '@octopus/api';
import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { pollingSearch } from '../utils/polling';

export const useVacationsGeneratePayroll = (
  value: VacationsScheduleSummary | VacationsScheduleEntry,
  onSuccess: () => Promise<void>,
) => {
  const { showSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const { sequence, contractId, organizationId } = value;
  const pathParams = {
    contractId,
    organizationId,
    sequence: `${sequence}`,
  };

  const awaitingChangeStatus = ({
    contractId,
    organizationId,
    sequence,
  }: VacationsScheduleEntry) =>
    pollingSearch({
      organizationId,
      contractId,
      id: `${organizationId}|${contractId}|${sequence}`,
      vacationsScheduleStatusesList: [vacationsScheduleStatuses.payrollCreated],
    });

  const onGeneratePayroll = () => {
    setIsLoading(true);
    showSnackbar({
      isOpen: true,
      Message: 'Calculando, isso pode levar alguns instantes...',
      StartAdornment: <HourglassEmpty />,
    });
    fetchPostVacationsScheduleCreatePayroll({ pathParams })
      .then(awaitingChangeStatus)
      .then(onSuccess)
      .then(() => {
        showSnackbar({
          isOpen: true,
          Message: 'Folha de pagamento calculada com sucesso',
          hasCloseAction: true,
        });
        setIsLoading(false);
      })
      .catch(() => {
        showSnackbar({
          isOpen: true,
          variant: 'error',
          Message: 'Erro ao calcular folha de pagamento',
          hasCloseAction: true,
        });
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    onGeneratePayroll,
  };
};
