import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import { ContractEntry } from '@octopus/api';
import {
  getAdmissionDate,
  getDepartment,
  getName,
  getRoleName,
  getSalary,
  getWorkerId,
} from '@octopus/contract-types';
import { formatDateBR, formatMoney } from '@octopus/formatters';

export type Controls = {
  onDismiss: () => void;
  hasNext: boolean;
  onNext: () => void;
  onPrevious: () => void;
  hasPrevious: boolean;
};

export const DrawerHeader = ({
  controls: { onDismiss, hasNext, onNext, hasPrevious, onPrevious },
  contract,
}: {
  controls?: Controls;
  contract: ContractEntry;
}) => {
  const name = getName(contract);
  const firstLine = [
    getRoleName(contract),
    getDepartment(contract),
    `${formatMoney(getSalary(contract))} /mês`,
  ];
  const secondLine = [
    `Data de início em ${formatDateBR(getAdmissionDate(contract))}`,
    `Matrícula ${getWorkerId(contract)}`,
  ];

  const InfoItem = ({ idx, value }: { idx: number; value: string }) => (
    <>
      {idx !== 0 && (
        <Typography
          mx={0.75}
          sx={(theme) => ({ color: theme.palette.strokes.heavy })}
          variant="caption"
        >
          |
        </Typography>
      )}
      <Typography color="textSecondary" variant="caption">
        {value}
      </Typography>
    </>
  );

  return (
    <Box pt={5} pb={2} display="flex" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
          <Typography variant="h3">{name}</Typography>
        </Box>
        <Box mt={1} display="flex" flexDirection="row">
          {firstLine.map((value, idx) => (
            <InfoItem key={value} value={value} idx={idx} />
          ))}
        </Box>
        <Box mt={0.5} display="flex" flexDirection="row">
          {secondLine.map((value, idx) => (
            <InfoItem key={value} value={value} idx={idx} />
          ))}
        </Box>
      </Box>
      <Box
        gap={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        alignSelf="start"
      >
        <Typography
          color="textSecondary"
          variant="body2"
          sx={{ width: '100%' }}
        ></Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <IconButton
            onClick={onPrevious}
            disabled={!hasPrevious}
            sx={(theme) => ({
              py: 0,
              px: 0.5,
              height: '32px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '8px 0 0 8px',
              border: `1px solid ${theme.palette.strokes.light}`,
              borderRight: hasNext
                ? `0.5px solid ${theme.palette.strokes.light}`
                : undefined,
            })}
          >
            <ExpandLess />
          </IconButton>
          <IconButton
            disabled={!hasNext}
            onClick={onNext}
            sx={(theme) => ({
              py: 0,
              px: 0.5,
              height: '32px',
              backgroundColor: theme.palette.background.paper,
              borderRadius: '0 8px 8px 0',
              border: `1px solid ${theme.palette.strokes.light}`,
              borderLeft: `0.5px solid ${theme.palette.strokes.light}`,
            })}
          >
            <ExpandMore />
          </IconButton>
        </Box>
        <IconButton
          sx={{
            py: 0.5,
            px: 0.5,
            width: '32px',
            height: '32px',
            borderRadius: '4px',
          }}
          onClick={onDismiss}
        >
          <Close sx={{ fontSize: '24px' }} />
        </IconButton>
      </Box>
    </Box>
  );
};
