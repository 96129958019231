import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button as ButtonMUI,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { AdmissionDraftInputFormStepEnum } from '@octopus/api';
import { WorkerCategory } from '@octopus/contract-types';

import { UploadAdmissionAttachment } from '../../components/UploadAdmissionAttachment';
import { AdmissionFormState, FormStepDefinition } from '../form/types';

import { getDocumentSections } from './utils';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  onSubmitCallback: (newFormState: AdmissionFormState) => void; // do we need this?
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  organizationId: string;
  companyId: string;
  draftId: string;
  submitLabel: string;
  workerCategory: WorkerCategory;
};

export const NewAdmissionDocuments = ({
  disabled,
  isLoading,
  onSubmitCallback,
  goToPreviousStep,
  organizationId,
  companyId,
  draftId,
  submitLabel,
  workerCategory,
}: Props) => {
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const documentSections = getDocumentSections(workerCategory);

  const goBackButton = (
    <ButtonMUI
      fullWidth
      color="secondary"
      variant="outlined"
      disabled={disabled || isLoadingFile}
      sx={(theme) => ({
        [theme.breakpoints.up('md')]: {
          px: 6,
          marginLeft: 'auto',
          width: 'auto',
        },
        [theme.breakpoints.down('md')]: {
          py: 1.25,
          px: 2,
          mt: 1.5,
          display: 'block',
          width: 'calc(100svw - 64px)',
        },
      })}
      onClick={goToPreviousStep}
    >
      Voltar
    </ButtonMUI>
  );

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          gap: 4,
        },
        [theme.breakpoints.down('md')]: {
          gap: 2,
          width: '100%',
          height: `calc(100svh - ${theme.spacing(38)})`,
          overflow: 'scroll',
          '::-webkit-scrollbar': { display: 'none' },
        },
      })}
    >
      {Object.entries(documentSections).map(([key, doc]) => (
        <UploadAdmissionAttachment
          setIsLoading={setIsLoadingFile}
          organizationId={organizationId}
          companyId={companyId}
          draftId={draftId}
          allowedTypes={doc.allowedTypes}
          title={doc.title}
          description={doc.description}
          required={doc.required}
          key={key}
          formStep={key as AdmissionDraftInputFormStepEnum}
          isLoading={isLoadingFile}
          disabled={disabled}
        />
      ))}
      <Box
        sx={(theme) => ({
          width: '100%',
          [theme.breakpoints.up('md')]: {
            display: 'flex',
            paddingTop: 3,
            gap: 2,
          },
          [theme.breakpoints.down('md')]: {
            position: 'fixed',
            bottom: 0,
            paddingBottom: 3,
            left: 0,
            background: theme.palette.background.paper,
            borderColor: 'transparent',
            border: 'none',
            px: 4,
          },
        })}
      >
        {!isMobile && goBackButton}
        <LoadingButton
          fullWidth
          color="primaryAlt"
          variant="contained"
          disabled={disabled || isLoadingFile}
          loading={isLoading}
          sx={(theme) => ({
            py: 1.25,
            [theme.breakpoints.up('md')]: {
              px: 6,
              width: 'auto',
            },
            [theme.breakpoints.down('md')]: {
              px: 2,
              display: 'block',
              width: 'calc(100svw - 64px)',
            },
          })}
          onClick={() => onSubmitCallback({})}
          data-testid={`submit-documentos`}
        >
          {submitLabel}
        </LoadingButton>
        {isMobile && goBackButton}
      </Box>
    </Box>
  );
};
