import { AdmissionDraftInputFormStep } from '@octopus/api';
import { IFormDefinition, NEW_UI_TYPE, cnpjSchema } from '@octopus/libs/forms';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import { CompanyContext } from '../../../../../modules/types';
import { getOptionsFromCompanyContext } from '../inputUtils';
import { AdmissionFormState, FormStepDefinition } from '../types';

const getFieldDefinition = ({
  formState,
  companyContext,
}: {
  formState: AdmissionFormState;
  companyContext: CompanyContext;
}): IFormDefinition => {
  const { cnpjSindCategProf } = formState;

  return [
    {
      label: 'CNPJ do sindicato',
      type: cnpjSchema,
      name: 'cnpjSindCategProf',
      uiType: NEW_UI_TYPE.SELECT_AUTOCOMPLETE,
      placeholder: 'Selecione um sindicato',
      options: getOptionsFromCompanyContext({
        baseContext: companyContext?.sindicatos,
        currentValue: cnpjSindCategProf,
      }),
      hint: {
        title: 'Nome do sindicato ao qual o colaborador é filiado',
        body: 'Identificar o sindicato é importante para garantir que os direitos do colaborador sejam representados e para a negociação de acordos coletivos.',
      },
    },
  ];
};

export const getSindicatoFields = ({
  formState,
  sindicatoFormSteps,
  companyContext,
}: {
  formState: AdmissionFormState;
  sindicatoFormSteps: AdmissionDraftInputFormStep;
  companyContext: CompanyContext;
}): FormStepDefinition => ({
  definition: getFieldDefinition({ formState, companyContext }),
  options: {
    id: admissionDraftFormSteps.sindicato,
    title: 'Sindicato',
    completed: sindicatoFormSteps?.completed,
    reviewed: sindicatoFormSteps?.reviewed,
  },
});
