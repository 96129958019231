import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconSend } from '@tabler/icons-react';

import { Box } from '@mui/material';

import {
  PayrollEntry,
  VacationsScheduleEntry,
  fetchSendPayrollPayslip,
} from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';
import { Button as DSButton } from '@octopus/ui/design-system';

import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import { shouldShowSendPayslip } from '../../utils/status';
import { BodyText } from '../Text';

export const PayrollSendPayslip = (props: {
  vacationSchedule: VacationsScheduleEntry;
  payroll: PayrollEntry;
  onSuccess: () => Promise<void>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { vacationSchedule, payroll, onSuccess } = props;
  const { status, payslipStatus } = vacationSchedule;
  const { approvalDeadline } = vacationSchedule;

  const onClickSendPayslip = async () => {
    setIsLoading(true);
    await fetchSendPayrollPayslip({
      pathParams: {
        companyId: payroll.companyId,
        organizationId: payroll.organizationId,
        payrollId: payroll.payrollId,
      },
    })
      .then(() => {
        showSnackbar({
          Message: 'Recibo enviado com sucesso',
          isOpen: true,
        });
        setIsSuccess(true);
      })
      .catch(() => {
        showSnackbar({
          Message: 'Erro ao enviar recibo',
          variant: 'error',
          isOpen: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    await onSuccess();
  };

  const goToPayroll = () => {
    navigate(
      `/vacations/${payroll.contractId}/receipt?sequence=${vacationSchedule.sequence}`,
    );
  };

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box display="flex" flexDirection="column" justifyContent="center">
        {approvalDeadline && (
          <BodyText>
            {`Limite para envio do recibo: ${formatDateBR(approvalDeadline)}`}
          </BodyText>
        )}
      </Box>
      <Box alignItems="center" display="flex" justifyContent="end" gap={1}>
        <DSButton
          variantLayout="tiny"
          variantSemantic="tertiary"
          onClick={goToPayroll}
          disabled={isLoading}
        >
          {'Visualizar recibo'}
        </DSButton>
        {shouldShowSendPayslip(status, payslipStatus) && !isSuccess && (
          <DSButton
            variantLayout="tiny"
            sx={{ ml: 1, minWidth: '100px', minHeight: '32px' }}
            variantSemantic="secondary"
            onClick={onClickSendPayslip}
            isLoading={isLoading}
            disabled={isLoading}
          >
            {'Enviar Recibo'}
            <Box ml={1}>
              <IconSend size={18} />
            </Box>
          </DSButton>
        )}
      </Box>
    </Box>
  );
};
