import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';

import { FilePreviewComponent, FilePreviewType } from './FilePreviewComponent';
import { UploadComponent } from './UploadComponent';

function FileComponent({
  file,
  setFile,
  showDeleteButton = true,
  isLoading = false,
  filePreviewType = 'image',
  disabled = false,
  disabledMessage = '',
  defaultExpanded = true,
  description,
  acceptedFileTypes,
  hideChevron = false,
  maxFileSize = 5000000,
  showDeleteConfirmation = true,
}: {
  file: File | undefined;
  setFile: (file: File | undefined) => void;
  showDeleteButton?: boolean;
  isLoading?: boolean;
  filePreviewType?: FilePreviewType;
  disabled?: boolean;
  disabledMessage?: string;
  description?: string;
  defaultExpanded?: boolean;
  acceptedFileTypes?: Record<string, string[]>;
  hideChevron?: boolean;
  maxFileSize?: number;
  showDeleteConfirmation?: boolean;
}) {
  return (
    <Accordion
      className="borderless"
      defaultExpanded={defaultExpanded}
      sx={{
        bgcolor: '#F7F7F8',
        borderRadius: 1,
        height: '100%',
        border: 'none',
        padding: 0,
      }}
      expanded={hideChevron ? true : undefined}
    >
      <AccordionSummary
        expandIcon={!hideChevron ? <ExpandMore /> : undefined}
        sx={
          hideChevron
            ? {
                pointerEvents: 'none',
                '& .MuiTypography-root': {
                  color: 'text.primary',
                },
              }
            : undefined
        }
      >
        <Typography variant="h4">Anexo</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <Divider sx={{ mb: 2 }} />
        {isLoading && <FileOperationScene type={'Carregando'} />}
        {!isLoading && !file && (
          <UploadComponent
            setFile={setFile}
            disabled={disabled}
            disabledMessage={disabledMessage}
            description={description}
            acceptedFileTypes={acceptedFileTypes}
            maxFileSize={maxFileSize}
          />
        )}
        {!isLoading && file && (
          <FilePreviewComponent
            file={file}
            setFile={setFile}
            showDeleteButton={showDeleteButton}
            filePreviewType={filePreviewType}
            isLoading={isLoading}
            showDeleteConfirmation={showDeleteConfirmation}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

function FileOperationScene({ type }: { type: string }) {
  return (
    <Box
      display={'flex'}
      height={'100%'}
      justifyContent={'center'}
      width={'100%'}
      alignItems={'center'}
    >
      <Box
        display={'flex'}
        height={'100%'}
        px={5}
        py={2}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
      >
        <Typography variant={'caption'} color={'text.secondary'} noWrap={true}>
          {type}...
        </Typography>

        <CircularProgress />
      </Box>
    </Box>
  );
}

export { FileComponent, UploadComponent, FilePreviewComponent };
