import { Box } from '@mui/material';

import { VacationsScheduleSummary } from '@octopus/api';

import { DrawerEmptyState } from './DrawerEmptyState';
import { ScheduleCard } from './ScheduleCard';
import { vacationsDetailsTabsConfig } from './VacationsTabs';

export const RequestsTab = ({
  requests,
  onSuccess,
  isManager,
}: {
  requests: VacationsScheduleSummary[];
  onSuccess: () => Promise<void>;
  isManager: boolean;
}) => {
  return (
    <Box>
      {requests.length > 0 ? (
        requests.map((summary: VacationsScheduleSummary) => {
          return (
            <Box mb={3} key={summary.contractId}>
              <ScheduleCard
                key={summary.contractId}
                summary={summary}
                onSuccess={onSuccess}
                isManager={isManager}
              />
            </Box>
          );
        })
      ) : (
        <DrawerEmptyState
          message={vacationsDetailsTabsConfig.requests.emptyMessage}
        />
      )}
    </Box>
  );
};
