import { useParams } from 'react-router-dom';

import { IconBeach } from '@tabler/icons-react';

import { Box, Container, Typography } from '@mui/material';

import VacationsTable from './VacationsTable';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
  contractId: string | undefined;
  isFFEnabled: boolean;
};

function VacationsPage({
  organizationId,
  contractId,
  isFFEnabled,
}: VacationsProps) {
  const { role } = useParams() as { role: 'manager' | 'admin' };
  const subTitle = {
    manager: 'Liderados',
    admin: 'Organização',
  }[role];

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        py: 9,
        px: 10,
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          alignItems="baseline"
          data-testid="vacations-header"
          gap={1}
          mb={5}
        >
          <IconBeach width={40} height={40} />
          <Typography variant="h1" mr={1.5}>
            Férias
          </Typography>
          {isFFEnabled && (
            <Typography variant="h3" color="strokes.heavy">
              {subTitle}
            </Typography>
          )}
        </Box>

        <Box>
          <VacationsTable
            organizationId={organizationId}
            contractId={contractId}
            isFFEnabled={isFFEnabled}
          />
        </Box>
      </Container>
    </Box>
  );
}

export default VacationsPage;
