import { WarningRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { MobileDrawer } from '../../../modules/components/onboarding/dialogs';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  isSmallDevice: boolean;
  confirmClick: () => void;
};

export function AdmissionInThePastDialog({
  open,
  setOpen,
  isSmallDevice,
  confirmClick,
}: Props) {
  const actions = (
    <>
      <Button
        variant={'contained'}
        color="secondary"
        sx={{ px: isSmallDevice ? 1.25 : 2.25, py: 1.25 }}
        onClick={() => {
          confirmClick();
          setOpen(false);
        }}
      >
        Continuar
      </Button>
      <Button
        variant={isSmallDevice ? 'outlined' : 'contained'}
        color="primaryAlt"
        sx={{ px: isSmallDevice ? 1.25 : 2.25, py: 1.25 }}
        onClick={() => setOpen(false)}
      >
        Alterar data de admissão
      </Button>
    </>
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: isSmallDevice ? 1 : 0,
        alignItems: isSmallDevice ? 'center' : 'flex-start',
        textAlign: isSmallDevice ? 'center' : 'left',
      }}
    >
      <Typography variant="h2" display="flex" flexDirection="row" gap={1}>
        <WarningRounded color="error" fontSize="huge" /> Risco de multa
      </Typography>
      <Typography variant="body1">
        Por lei, a admissão precisa ser enviada ao eSocial até, no máximo, 1 dia
        antes da data de início. Não cumprir esse prazo pode gerar multa para a
        empresa.
      </Typography>
      <Typography variant="body1">
        Você quer continuar com essa data de admissão?
      </Typography>
    </Box>
  );
  return (
    <>
      <MobileDrawer
        open={open}
        loading={false}
        setOpen={setOpen}
        actions={actions}
        content={content}
      ></MobileDrawer>
      <Dialog
        open={open}
        onClose={setOpen}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: { display: 'none' },
        })}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0,0,0, 0.8)',
            },
          },
        }}
      >
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </>
  );
}
