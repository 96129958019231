import { useMutation } from '@tanstack/react-query';
import { v4 } from 'uuid';

import {
  fetchContractsUpdateManager,
  fetchSearchAllContracts,
} from '@octopus/api';
import { translate } from '@octopus/i18n';

import { pollUntil } from '../../../../utils';

import { AddReportInfo, AddReportResult } from './types';

export type ChangeManagerForReportsMutationParameters = {
  organizationId: string;
  newManagerContractId: string;
  effectiveDate: string;
  reports: AddReportInfo[];
  incrementProgress: () => void;
};

export type ChangeManagerForReportsResult = {
  success: AddReportResult[];
  error: AddReportResult[];
};

export function useChangeManagerForReports() {
  return useMutation({
    mutationFn: async ({
      organizationId,
      newManagerContractId,
      effectiveDate,
      reports,
      incrementProgress,
    }: ChangeManagerForReportsMutationParameters) => {
      const idempotencyKey = v4();
      const success: AddReportResult[] = [];
      const error: AddReportResult[] = [];
      for (const report of reports) {
        try {
          await fetchContractsUpdateManager({
            pathParams: {
              organizationId,
              contractId: report.reportContractId,
            },
            body: {
              manager: {
                contractId: newManagerContractId,
              },
              effectiveDate: effectiveDate,
            },
            headers: {
              'x-idempotency-key': idempotencyKey,
            },
          });
          success.push({ ...report, error: undefined });
        } catch (err) {
          let message =
            'Erro inesperado. Tente novamente ou entre em contato com o suporte da Tako.';
          if (
            err instanceof Object &&
            'stack' in err &&
            'details' in err.stack &&
            err.stack.details instanceof Object
          ) {
            Object.keys(err.stack.details).forEach((key) => {
              message = translate(key, 'pt');
            });
          }
          error.push({
            ...report,
            error: message,
          });
        } finally {
          incrementProgress();
        }
      }
      if (success.length > 0) {
        const toPoll = success[success.length - 1];
        await pollUntil({
          action: () =>
            fetchSearchAllContracts({
              pathParams: {
                organizationId,
              },
              body: {
                filtering: {
                  elements: {
                    contractId: [toPoll.reportContractId],
                    'manager.keyword': [newManagerContractId],
                  },
                },
              },
            }),
          assertion: ({ total }) => total > 0,
          intervalMillis: 300,
          timeoutSeconds: 10,
        });
      }

      return { success, error } as ChangeManagerForReportsResult;
    },
  });
}
