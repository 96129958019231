import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import {
  ContractEntry,
  ContractSummary,
  SearchInput,
  useSearchAllContracts,
} from '@octopus/api';
import { contractStatuses } from '@octopus/contract-types';
import {
  DataGrid,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { BackButton } from '../../../modules/components/BackButton';
import { AddReportsDrawer } from '../../../modules/components/contracts/addReports';
import { ExpandableTypography } from '../../../modules/components/ExpandableTypography';
import { PageAlert } from '../../../modules/components/PageAlert';
import UserAvatar from '../../../modules/components/UserAvatar';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { QueryResult } from '../../../modules/types';

export function ContractReportsContent({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  const { showSnackbar } = useSnackbar();
  const [addReportsDrawerOpen, setAddReportsDrawerOpen] = useState(false);
  const [addReportsDrawerKey, setAddReportsDrawerKey] = useState<number | null>(
    null,
  );
  const searchAllContractsQuery = useSearchAllContracts();

  const searchContractsFetch = () => {
    searchAllContractsQuery.mutate({
      pathParams: {
        organizationId: contractQuery.data?.organizationId,
      },
      body: {
        filtering: {
          elements: {
            'manager.keyword': [contractQuery.data?.contractId],
            status: [{ not: contractStatuses.terminated }],
          },
        },
        sorting: [
          {
            field: 'name',
            order: 'asc',
          },
        ],
        pagination: {
          page: 0,
          size: 100,
        },
      } as SearchInput,
    });
  };

  useEffect(() => {
    if (contractQuery.data) {
      searchContractsFetch();
    }
  }, [contractQuery.data?.organizationId, contractQuery.data?.contractId]);

  if (contractQuery.isLoading || !contractQuery.data) {
    return <ContractReportsContentSkeleton />;
  }

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography variant="h5" fontWeight={700}>
          Liderados
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1.5}>
          {searchAllContractsQuery.data !== undefined ? (
            <Typography variant="caption" color="text.secondary">
              {searchAllContractsQuery.data.total} pessoa
              {searchAllContractsQuery.data.total !== 1 && 's'}
            </Typography>
          ) : (
            <Skeleton
              variant="rectangular"
              width="45px"
              sx={{ borderRadius: 0.5 }}
            />
          )}
          <Box height="16px" width="1px" bgcolor="strokes.heavy"></Box>
          <Button
            size="large"
            color="primaryAlt"
            onClick={() => {
              setAddReportsDrawerKey(Date.now());
              setAddReportsDrawerOpen(true);
            }}
          >
            Adicionar liderado
          </Button>
        </Box>
      </Box>
      <ReportsTable searchAllContractsQuery={searchAllContractsQuery} />
      <AddReportsDrawer
        key={addReportsDrawerKey}
        newManagerContract={contractQuery.data}
        open={addReportsDrawerOpen}
        onClose={() => setAddReportsDrawerOpen(false)}
        onSuccess={(snackbar: boolean) => {
          setAddReportsDrawerOpen(false);
          searchContractsFetch();
          if (snackbar) {
            showSnackbar({
              isOpen: true,
              variant: 'default',
              Message: 'Liderado incluído',
              StartAdornment: <CheckCircleIcon />,
            });
          }
        }}
      />
    </Box>
  );
}

function ContractReportsContentSkeleton() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Skeleton variant="rounded" height="48px" />
      <Skeleton variant="rounded" height="80vh" />
    </Box>
  );
}

const REPORTS_TABLE_COLUMNS: GridColDef<ContractSummary>[] = [
  {
    field: 'name',
    headerName: 'Nome',
    renderHeader: (params) => {
      return <Box ml={2}>{params.field}</Box>;
    },
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.name;
    },
    renderCell: ({ value, row }) => {
      return (
        <UserAvatar
          name={value}
          pictureUrl={row.pictureUrl}
          expandNameOnHover={true}
          sx={{
            '--UserAvatar-name-max-width': '12.5em',
          }}
        />
      );
    },
  },
  {
    field: 'titleName',
    headerName: 'Cargo',
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.titleName;
    },
    renderCell: ({ value }) => {
      return (
        <ExpandableTypography sx={{ maxWidth: '140px' }}>
          {value}
        </ExpandableTypography>
      );
    },
  },
  {
    field: 'department',
    headerName: 'Departamento',
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.department;
    },
    renderCell: ({ value }) => {
      return (
        <ExpandableTypography sx={{ maxWidth: '140px' }}>
          {value}
        </ExpandableTypography>
      );
    },
  },
];

function ReportsTable({
  searchAllContractsQuery,
}: {
  searchAllContractsQuery: ReturnType<typeof useSearchAllContracts>;
}) {
  const { isLoading, data, isError, error } = searchAllContractsQuery;
  const { sortingProps, paginationProps } = useDataGrid({});

  if (isLoading || !data) {
    return <Skeleton variant="rounded" height="70vh" />;
  }

  if (isError) {
    console.error(`Failed to load contract reports: ${JSON.stringify(error)}`);
    return (
      <>
        <BackButton destination="/people" />
        <PageAlert
          message="Erro ao carregar dados de liderados do contrato"
          severity="error"
          showRetryMessage={true}
        />
      </>
    );
  }

  return (
    <Box>
      <DataGrid
        sortingProps={sortingProps}
        paginationProps={paginationProps}
        totalRowCount={data.total || 0}
        getRowId={(row) => row.contractId}
        rows={data.data}
        columns={REPORTS_TABLE_COLUMNS}
        onRowClick={(params) =>
          window.open(`/people/${params.row.contractId}`, '_blank')
        }
        emptyMessage="Nenhum liderado registrado."
      />
    </Box>
  );
}
