import { useState } from 'react';

import dayjs from 'dayjs';

import { Theme, lighten } from '@mui/material';

import { EventSourcingEvent, UserData } from '@octopus/api';
import {
  contractFieldChanges,
  isChangedFieldHidden,
} from '@octopus/formatters';

import {
  ContractHistoryEvent,
  useFormattedDiffValues,
  useGetAllAuthoredContractEvents,
} from './data';

export type { ContractHistoryEvent } from './data';

const getHoveredBackgoundColor = (theme: Theme) =>
  lighten(theme.palette.text.primary, 0.96);

function useHoverBackground(hovered = false) {
  const [isHovered, setHover] = useState<boolean>(hovered);

  const hoveredStyle = (theme: Theme) => ({
    backgroundColor: isHovered ? getHoveredBackgoundColor(theme) : undefined,
  });

  const hoverEventHandlers = {
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
  };

  return {
    hoveredStyle,
    hoverEventHandlers,
    isHovered,
  };
}

const SUPPORTED_HISTORY_EVENT_TYPES = new Set([
  'employeeOnAdmission',
  'employeeAdmitted',
  'contractChanged',
  'salaryChanged',
  'internalTransfer',
  'pjAdmitted',
  'pjContractChanged',
  'pjInternalTransfer',
  'companyTransfer',
  'managerChanged',
]);

const CANCELABLE_EVENT_TYPES = new Set<string>([
  'contractChanged',
  'salaryChanged',
  'pjContractChanged',
  'contractTerminated',
]);
const CANCELABLE_SCHEDULED_EVENTS_TYPES = new Set([
  'internalTransfer',
  'companyTransfer',
  'pjInternalTransfer',
  'managerChanged',
]);

const EVENT_TYPES_TO_ACTION_NAME: Record<string, string> = {
  employeeOnAdmission: 'Registro em',
  employeeAdmitted: 'Registro em',
  pjAdmitted: 'Registro em',
};
const getEventActionName = (eventType: string): string =>
  EVENT_TYPES_TO_ACTION_NAME[eventType] ?? 'Alteração em';

const eventIsScheduled = <T extends EventSourcingEvent>(event: T) =>
  dayjs().isBefore(dayjs(event.effectiveDate), 'day');

const supportedHistoryEventsSorted = <T extends EventSourcingEvent>(
  events: T[],
): T[] =>
  events
    .filter((e) => SUPPORTED_HISTORY_EVENT_TYPES.has(e.type))
    .sort((a, b) => -a.effectiveDate.localeCompare(b.effectiveDate));

const canCancelEvent = (event: ContractHistoryEvent) => {
  if (event.canceled) {
    return false;
  }
  // older than a month: false
  if (dayjs().diff(dayjs(event.effectiveDate), 'M') >= 1) {
    return false;
  }
  // not older than a month
  // cancelable regardless of effectiveDate
  if (CANCELABLE_EVENT_TYPES.has(event.type)) {
    return true;
  }
  // cancelable if effectiveDate is in the future
  if (CANCELABLE_SCHEDULED_EVENTS_TYPES.has(event.type)) {
    return eventIsScheduled(event);
  }
  return false;
};

const SYSTEM_USER_NAME = 'Tako';
const SYSTEM_USER_EMAIL_DOMAIN = '@8arm.io';
const getAuthorName = (user?: UserData) =>
  !user
    ? SYSTEM_USER_NAME
    : user.userData.email.endsWith(SYSTEM_USER_EMAIL_DOMAIN)
      ? SYSTEM_USER_NAME
      : user.userData.name;

const getPrimaryLighter = (theme: Theme) => theme.palette.strokes.primary;

const getGrayscaleLighter = (theme: Theme) => theme.palette.strokes.heavy;

const getBackGroundColor = (_: Theme) => '#EBEBEB';

export const Utils = {
  Hooks: {
    useHoverBackground,
    useGetAllAuthoredContractEvents,
    useFormattedDiffValues,
  },
  Events: {
    eventIsScheduled,
    getEventActionName,
    supportedHistoryEventsSorted,
    getAuthorName,
    contractFieldChanges,
    isChangedFieldHidden,
    canCancelEvent,
  },
  Styles: {
    getPrimaryLighter,
    getGrayscaleLighter,
    getHoveredBackgoundColor,
    getBackGroundColor,
  },
};
