import { PeriodFormat } from '../../../modules/types';

const homePagePath = () => `/autonomos`;
const createRpaPagePath = (periodId: PeriodFormat, legalEntityId: string) =>
  `/autonomos/${periodId}/rpa?legalEntityId=${legalEntityId}`;
const editRpaPagePath = (periodId: PeriodFormat, legalEntityId: string) =>
  `/autonomos/${periodId}/rpa?editMode=true&legalEntityId=${legalEntityId}`;
const editRpaDetailsPath = (payrollId: string) => `/autonomos/rpa/${payrollId}`;
const rpaInputsSubmissionPath = (periodId: string, submissionId: string) =>
  `/autonomos/${periodId}/rpa/${submissionId}`;

export const RPANavigation = {
  homePagePath,
  createRpaPagePath,
  editRpaPagePath,
  editRpaDetailsPath,
  rpaInputsSubmissionPath,
};
