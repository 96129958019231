const type = <const T extends `${keyof typeof BASE_UI_TYPE}_${string}`>(
  name: T,
) => name;

export const BASE_UI_TYPE = {
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  SELECT: 'SELECT',
  SELECT_AUTOCOMPLETE: 'SELECT_AUTOCOMPLETE',
  FIELDSET: 'FIELDSET',
} as const;

export const NEW_UI_TYPE = {
  SELECT_AUTOCOMPLETE: 'SELECT_AUTOCOMPLETE',
  CHECKBOX: 'CHECKBOX',
} as const;

//``TODO brand zod type?
export const COMPLEX_UI_TYPE = {
  SELECT_CHECKBOX: type(`SELECT_CHECKBOX`),
  SELECT_RADIO: type(`SELECT_RADIO`),
  SELECT_CHECKBOX_TAG_CLOUD_SEARCH: type(`SELECT_CHECKBOX_TAG_CLOUD_SEARCH`),
  CHECKBOX_CARD: type('CHECKBOX_CARD'),
  TEXT_DATE_PICKER: type('TEXT_DATE_PICKER'),
  TEXT_DATE_PICKER_YEAR_MONTH: type('TEXT_DATE_PICKER_YEAR_MONTH'),
  SELECT_CHECKBOX_LIST: type('SELECT_CHECKBOX_LIST'),
  SELECT_CHECKBOX_LIST_SEARCH: type('SELECT_CHECKBOX_LIST_SEARCH'),
  TEXT_MONEY: type('TEXT_MONEY'),
  TEXT_NUMBER: type('TEXT_NUMBER'),
  SELECT_RADIO_BOOLEAN: type(`SELECT_RADIO_BOOLEAN`),
} as const;

export const LEGACY_UI_TYPE = {
  CHECKBOX_LIST: 'CHECKBOX_LIST',
  DROPDOWN: 'DROPDOWN',
  DATE_PICKER: 'DATE_PICKER',
  DATE_PICKER_YEAR_MONTH: 'DATE_PICKER_YEAR_MONTH',
  SELECT_SEARCH_TAG_CLOUD: 'SELECT_SEARCH_TAG_CLOUD',
  CHECKBOX_TAG_CLOUD_SEARCH: 'CHECKBOX_TAG_CLOUD_SEARCH',
} as const;

export const UI_TYPE = {
  ...BASE_UI_TYPE,
  ...COMPLEX_UI_TYPE,
  ...LEGACY_UI_TYPE,
} as const;
