import { useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { LegalEntityContext } from '../../../../modules/types';

export function SelectLegalEntityModal({
  close,
  selectedLegalEntity,
  setSelectedLegalEntity,
  legalEntities,
}: {
  close: () => void;
  selectedLegalEntity: string;
  setSelectedLegalEntity: (legalEntity: string) => void;
  legalEntities: LegalEntityContext[];
}) {
  const [selectedFormLegalEntity, setSelectedFormLegalEntity] =
    useState<string>(selectedLegalEntity);
  const confirm = () => {
    if (!selectedLegalEntity) {
      return;
    }
    setSelectedLegalEntity(selectedFormLegalEntity);
    close();
  };
  return (
    <InitialScene
      confirm={confirm}
      close={close}
      selectedLegalEntity={selectedFormLegalEntity}
      setSelectedLegalEntity={setSelectedFormLegalEntity}
      legalEntities={legalEntities}
    />
  );
}

function InitialScene({
  close,
  confirm,
  selectedLegalEntity,
  setSelectedLegalEntity,
  legalEntities,
}: {
  close: () => void;
  confirm: () => void;
  selectedLegalEntity: string;
  setSelectedLegalEntity: (company: string) => void;
  legalEntities: LegalEntityContext[];
}) {
  return (
    <Box width={'600px'}>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'flex-start',
            mb: 0,
            width: '100%',
          }}
        >
          <Typography variant="h5" fontSize="24px" fontWeight={700}>
            Selecionar empresa emissora
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{ width: '100%' }}
          >
            <Typography variant="body1">
              Empresa emissora
              <FormControl fullWidth>
                <Select
                  value={selectedLegalEntity}
                  onChange={(event) =>
                    setSelectedLegalEntity(event.target.value)
                  }
                  sx={{ width: '100%' }}
                >
                  <MenuItem value="" disabled>
                    <em>Selecione a empresa</em>
                  </MenuItem>
                  {legalEntities?.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name} - {company.summary.br.cnpj}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'background.paper',
            color: 'text.primary',
            width: '140px',
            height: '40px',
            px: 4,
            ':hover': {
              backgroundColor: 'strokes.light',
            },
          }}
          size="large"
          onClick={() => {
            close();
          }}
          data-testid="cancel-approve-payroll-button"
        >
          Cancelar
        </Button>
        <Button
          color="primaryAlt"
          variant="contained"
          size="large"
          sx={{
            width: '140px',
            height: '40px',
          }}
          onClick={confirm}
          data-testid="confirm-approve-payroll-button"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Box>
  );
}
