import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconUsersGroup } from '@tabler/icons-react';

import { Box, Container, Typography } from '@mui/material';

import { PeopleCount } from './PeopleCount';
import { PeopleTable } from './PeopleTable';

export type PeopleProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

export default function People({ organizationId, companyId }: PeopleProps) {
  const navigate = useNavigate();
  const navigateToContract = (contractId: string) => {
    navigate(`${contractId}`);
  };

  const [showTerminated, setShowTerminated] = useState(false);

  return (
    <Box
      pt={9}
      height="100vh"
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="people-header"
        >
          <IconUsersGroup width={40} height={40} />
          <Typography variant="h1" ml={1}>
            Pessoas
          </Typography>
        </Box>

        <PeopleCount organizationId={organizationId} companyId={companyId} />

        <Box py={3.2}>
          <PeopleTable
            organizationId={organizationId}
            companyId={companyId}
            onContractClick={navigateToContract}
            setShowTerminated={setShowTerminated}
            showTerminated={showTerminated}
          />
        </Box>
      </Container>
    </Box>
  );
}
