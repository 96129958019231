import React from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorIcon from '@mui/icons-material/Error';
import LoopIcon from '@mui/icons-material/Loop';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { pink } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import {
  GetSpreadsheetsResponse,
  fetchGetSpreadsheets,
  getAuthToken,
} from '@octopus/api';
import { formatDateTimeBR } from '@octopus/formatters';

import { download, downloadOriginalFile } from '../../spreadsheets';

type UploadedSpreadsheet = {
  spreadsheetId: string;
  createdOn: string;
  createdBy: string;
  fileType: string;
  fileName: string;
  rows: number;
  status: string;
  logsVersion?: string;
};

type LatestUploadedProps = {
  organizationId: string;
};

let rows: UploadedSpreadsheet[] = [];

function readyToDownload(row: UploadedSpreadsheet) {
  if (row.logsVersion === undefined || row.logsVersion === '1.0') {
    return false;
  }
  return row.status === 'completed' || row.status === 'failed';
}

function renderDownloadButton(row: UploadedSpreadsheet, handler: () => void) {
  return (
    <IconButton disabled={!readyToDownload(row)} onClick={() => handler()}>
      <DownloadIcon />
    </IconButton>
  );
}

export default function LatestUploaded({
  organizationId,
}: LatestUploadedProps) {
  const [loading, setLoading] = React.useState(true);
  const [downloading, setDownloading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const downloadHandler = (spreadsheetId: string) => {
    setDownloading(true);
    download(getAuthToken()!, organizationId, 'output', { spreadsheetId })
      .then(() => {
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
        alert('Ocorreu um erro ao baixar a planilha de retorno.');
      });
  };

  const downloadOriginalSpreadsheetHandler = (
    fileType: string,
    fileName: string,
  ) => {
    downloadOriginalFile(getAuthToken()!, organizationId, fileType, fileName)
      .then(() => {}) // eslint-disable-line @typescript-eslint/no-empty-function
      .catch(() => {
        alert('Ocorreu um erro ao baixar a planilha original.');
      });
  };

  fetchGetSpreadsheets({ pathParams: { organizationId } })
    .catch(() => {
      setError(true);
      setLoading(false);
    })
    .then((res: GetSpreadsheetsResponse | void) => {
      if (!res) return;

      rows = res
        .sort(function (a, b) {
          return (
            new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf()
          );
        })
        .map((item) => ({
          spreadsheetId: item.spreadsheetId!,
          createdOn: formatDateTimeBR(item.createdOn, true),
          createdBy: item.createdBy,
          fileType: item.fileType,
          fileName: item.fileName,
          rows: item.rows,
          status: item.status,
          logsVersion: item.logsVersion,
        }));
      setLoading(false);
      setError(false);
    });

  return (
    <Box pt={4}>
      <Fade
        in={loading && !error}
        style={{
          transitionDelay: loading ? '800ms' : '0ms',
        }}
        unmountOnExit
      >
        <LinearProgress />
      </Fade>

      <Fade in={error} unmountOnExit>
        <Box pt={2} pb={4}>
          <Alert severity="error">
            Ocorreu um erro ao recuperar o histórico de planilhas.
          </Alert>
        </Box>
      </Fade>

      <Dialog
        open={downloading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Preparando o arquivo de retorno para download. Por favor, aguarde.
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Fade in={!loading && !error}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criado em</TableCell>
                <TableCell>Criado por</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Linhas</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Retorno</TableCell>
                <TableCell>Original</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.spreadsheetId}>
                  <TableCell>{row.createdOn}</TableCell>
                  <TableCell>{row.createdBy}</TableCell>
                  <TableCell>
                    {row.fileType === 'contracts' && 'Contratos'}
                    {row.fileType === 'payroll' && 'Folha de Pagamento Mensal'}
                    {row.fileType === 'thirteenth' && '13º Salário'}
                    {row.fileType === 'advance' && 'Adiantamento'}
                    {row.fileType === 'complementary' && 'Complementar'}
                    {row.fileType === 'complementaryTermination' &&
                      'Rescisão Complementar'}
                    {row.fileType === 'metadadosDeRubricas' &&
                      'Metadados de Rubricas'}
                    {row.fileType === 'termination' && 'Desligamento'}
                    {row.fileType === 'vacations' && 'Programação de férias'}
                    {row.fileType === 'historicalEvents' &&
                      'Eventos históricos'}
                    {row.fileType === 'internalTransfer' &&
                      'Transferência interna'}
                    {row.fileType === 'rpa' && 'RPAs'}
                    {row.fileType === 'payrollRecurringEvents' &&
                      'Lançamentos recorrentes'}
                  </TableCell>
                  <TableCell>{row.rows}</TableCell>
                  <TableCell>
                    {row.status === 'completed' && (
                      <Tooltip title="Planilha processada com sucesso">
                        <CheckBoxIcon color="success" />
                      </Tooltip>
                    )}
                    {row.status === 'processing' && (
                      <Tooltip title="Planilha em processamento">
                        <LoopIcon />
                      </Tooltip>
                    )}
                    {row.status === 'failed' && (
                      <Tooltip title="Planilha com erro no processamento">
                        <ErrorIcon sx={{ color: pink[500] }} />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    {renderDownloadButton(row, () =>
                      downloadHandler(row.spreadsheetId),
                    )}
                  </TableCell>
                  <TableCell>
                    {renderDownloadButton(row, () =>
                      downloadOriginalSpreadsheetHandler(
                        row.fileType,
                        row.fileName,
                      ),
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Fade>
    </Box>
  );
}
